<div *ngIf='formGroup' [formGroup]='formGroup' class='max-w-[650px]'>
  <alimento-ipv-frontend-header
    title="{{ 'trainings.basicData.formTitle' | translate }}"
  ></alimento-ipv-frontend-header>

  <div class='form-section'>
    <h3>{{ 'trainings.basicData.general' | translate }}</h3>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [errorTemplate]='customTitleError'
                                      [required]='true'
                                      formField='customTitle'
                                      fieldKey='trainings.customTitle'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{formGroup.get("customTitle")?.value}}</span>
      <input *ngIf='formGroup.enabled'
             id="customTitle"
             formControlName="customTitle"
             type="text"
             pInputText
             class='w-full'
             placeholder="{{ 'trainings.titlePlaceholder' | translate }}"
      />
      <ng-template #customTitleError>
        <small *ngIf="formGroup.get('customTitle')?.hasError('required')">
          {{ 'validation.isRequired' | translate : { field: 'trainings.title' | translate | lowercase } }}
        </small>
        <small *ngIf="formGroup.get('customTitle')?.hasError('maxlength')">
          {{ 'validation.maxLength' | translate : { number: 200 } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-language-code formControlName='languageCode' [required]='showRequiredFieldErrors'>
    </alimento-ipv-frontend-language-code>

    <div *ngIf="formGroup.get('languageCode')?.value" class='conditional-fields'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        [includeTouchedASInvalid]='false'
        [errorTemplate]='treeSelectErrorTemplate'
        formField='trainingTitleId'
        fieldKey='trainings.basicData.treeselect'
      >
        <ng-template #trainingTitle let-trainingTitleReadOnly='item' let-showCurrent='showCurrent'>
          <span *ngIf='trainingTitleReadOnly?.parent?.parent'
                class='{{showCurrent ? "read-only-field" : "text-grey-4 text-sm" }}'>
            {{ trainingTitleReadOnly?.parent?.parent?.label }} >
            {{ trainingTitleReadOnly?.parent?.label }}{{ showCurrent ? ' > ' + trainingTitleReadOnly?.label : '' }}
          </span>

          <div *ngIf='trainingTitleReadOnly?.educationLeaveRecognitions' class='mt-4'>
            <div *ngFor='let recognitionType of trainingTitleReadOnly.educationLeaveRecognitions'
                 class='flex items-center gap-1 flex-wrap'>
              <span>{{ 'trainings.recognizedFor' | translate }}</span>
              <span>{{ getRecognitionType(recognitionType.educationLeaveRecognitionTypeId) | async }}</span>
              <span>{{ 'from' | translate }} {{ recognitionType.from | date: "dd/MM/yyyy" }}</span>
              <span
                *ngIf='recognitionType.to'>{{ 'to' | translate }} {{ recognitionType.to | date: "dd/MM/yyyy" }}</span>
            </div>
          </div>
        </ng-template>

        <div *ngIf='formGroup.disabled' class='read-only-field'>
          <ng-container
            *ngTemplateOutlet='trainingTitle; context: {item: trainingTitleReadOnly, showCurrent: true}'></ng-container>
        </div>

        <ng-container *ngIf='!formGroup.disabled'>
          <p-treeSelect
            [options]='$any(trainingTitles)'
            [filter]='true'
            [filterInputAutoFocus]="true"
            [showClear]='true'
            [resetFilterOnHide]='true'
            (onHide)='closeUnselectedTreeItems()'
            (onClear)='onTreeSelectChange()'
            (onNodeSelect)='onTreeSelectChange()'
            (onNodeUnselect)='onTreeSelectChange()'
            containerStyleClass='w-full'
            formControlName='treeSelect'
            id='treeSelect'
            panelClass='fullWidth'
            placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          >
            <ng-template let-node pTemplate='value'>
              <span *ngIf='node else emptyValue'>
                {{ node?.label }}
              </span>
              <ng-template #emptyValue>{{ 'trainings.basicData.chooseOption' | translate }}</ng-template>
            </ng-template>
          </p-treeSelect>
          <ng-container *ngIf="formGroup.get('treeSelect')?.value">
            <ng-container
              *ngTemplateOutlet='trainingTitle; context: {item: formGroup.get("treeSelect").value, showCurrent: false}'></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #treeSelectErrorTemplate>
          <small *ngIf="formGroup.get('trainingTitleId')?.hasError('required')">
            {{
              formGroup.get('treeSelect')?.value ?
                ('validation.notLowestLevelSelected' | translate) :
                ('validation.isSelected' | translate : { field: 'trainings.basicData.treeselect' | translate | lowercase })
            }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='methodId'
      fieldKey='trainings.basicData.method'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ methodReadOnly }}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='methods'
        [showClear]='true'
        formControlName='methodId'
        id='methodId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>

      <span class='text-grey-4 text-[12px]'>{{ 'trainings.basicData.methodInfo' | translate }}</span>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("year")'
      [formGroup]='formGroup'
      [required]='true'
      [includeTouchedASInvalid]='false'
      formField='year'
      fieldKey='trainings.basicData.year'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled || nrOfSessionsWithDates() > 0'>
        {{ $any(formGroup.get("year")?.value)?.getFullYear() }}
      </span>
      <lib-date-picker
        *ngIf='formGroup.enabled && nrOfSessionsWithDates() === 0'
        [keepInvalid]='false'
        placeholder='yyyy'
        dateFormat='yy'
        formControlName='year'
        id='year'
        view='year'
      ></lib-date-picker>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("letterVersionId")'
      [formGroup]='formGroup'
      [required]='true'
      [includeTouchedASInvalid]='false'
      formField='letterVersionId'
      fieldKey='trainings.basicData.letterVersion'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ letterVersionReadOnly }}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='letterVersions'
        [showClear]='true'
        formControlName='letterVersionId'
        id='letterVersionId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <div class='field field-checkbox' *ngIf='formGroup.contains("mentorTraining")'>
      <p-checkbox
        [binary]='true'
        formControlName='mentorTraining'
        inputId='mentorTraining'
      ></p-checkbox>
      <label for='mentorTraining'>{{ 'trainings.basicData.mentorTraining' | translate }}</label>
    </div>
  </div>

  <div class='form-section'>
    <h3>{{ 'trainings.basicData.management' | translate }}</h3>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      [includeTouchedASInvalid]='false'
      formField='casemanagerId'
      fieldKey='trainings.basicData.caseManager'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{ caseManager?.label }}
        <span *ngIf='caseManager && !caseManager.isActive' class='text-danger italic ml-2'>
          {{ "trainings.basicData.inactive" | translate }}
        </span>
      </span>
      <p-select
        *ngIf='formGroup.enabled'
        #caseManagerField
        [filter]='true'
        [options]='caseManagers'
        [showClear]='true'
        (onClear)='formGroup.get("casemanagerId").markAsTouched()'
        formControlName='casemanagerId'
        id='casemanagerId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      >
        <ng-template pTemplate='selectedItem'>
          <div *ngIf='caseManager'>
           <span>{{ caseManager?.label }}
             <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
          </div>
        </ng-template>
        <ng-template let-caseManager pTemplate='item'>
          <div *ngIf='caseManager'>
           <span>{{ caseManager.label }}
             <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
          </div>
        </ng-template>
      </p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='casemanagerBackupId'
      fieldKey='trainings.basicData.backup'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{ caseManagerBackup?.label }}
        <span *ngIf='caseManagerBackup && !caseManagerBackup.isActive' class='text-danger italic ml-2'>
          {{ "trainings.basicData.inactive" | translate }}
        </span>
      </span>
      <p-select
        #caseManagerBackupField
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='caseManagerBackups'
        [showClear]='true'
        (onClear)='formGroup.get("casemanagerBackupId").markAsTouched()'
        formControlName='casemanagerBackupId'
        id='casemanagerBackupId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      >
        <ng-template pTemplate='selectedItem'>
          <div *ngIf='caseManagerBackup'>
           <span>{{ caseManagerBackup?.label }}
             <span *ngIf='!caseManagerBackup.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
          </div>
        </ng-template>
        <ng-template let-caseManager pTemplate='item'>
          <div *ngIf='caseManager'>
           <span>{{ caseManager.label }}
             <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
          </div>
        </ng-template>
      </p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      [includeTouchedASInvalid]='false'
      formField='counselorId'
      fieldKey='trainings.basicData.counselor'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{ counselor?.label }}
        <span *ngIf='counselor && !counselor.isActive' class='text-danger italic ml-2'>
          {{ "trainings.basicData.inactive" | translate }}
        </span>
      </span>
      <p-select
        *ngIf='formGroup.enabled'
        #counselorField
        [filter]='true'
        [options]='counselors'
        [showClear]='true'
        (onClear)='formGroup.get("counselorId").markAsTouched()'
        formControlName='counselorId'
        id='counselorId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      >
        <ng-template pTemplate='selectedItem'>
          <div *ngIf='counselor'>
           <span>{{ counselor?.label }}
             <span *ngIf='!counselor.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
          </div>
        </ng-template>
        <ng-template let-counselor pTemplate='item'>
          <div *ngIf='counselor'>
           <span>{{ counselor.label }}
             <span *ngIf='!counselor.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
          </div>
        </ng-template>
      </p-select>
    </alimento-ipv-frontend-form-field>

    <div *ngIf='type === TRAINING_TYPE.OPEN_TRAINING'>
      <div class='field-checkbox pb-4'>
        <p-checkbox
          [binary]='true'
          formControlName='partnerCollaboration'
          inputId='partnerCollaboration'
        ></p-checkbox>
        <label for='partnerCollaboration'>{{ 'trainings.basicData.partnerCollaboration' | translate }}</label>
      </div>

      <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                        *ngIf='formGroup.get("partnerCollaboration")?.value'
                                        [required]='true'
                                        [includeTouchedASInvalid]='false'
                                        [errorTemplate]='emailErrorTemplate'
                                        formField='partnerEmail'
                                        fieldKey='trainings.basicData.partnerEmail'
      >
      <span *ngIf='formGroup.disabled' class='read-only-field'>
        {{ formGroup.get("partnerEmail")?.value }}
      </span>
        <input *ngIf='formGroup.enabled'
               class='w-full'
               formControlName='partnerEmail'
               id='partnerEmail'
               pInputText
               type='text'
        />

        <ng-template #emailErrorTemplate>
          <small *ngIf="formGroup.get('partnerEmail')?.errors?.['required']">
            {{ 'validation.isRequired' | translate : { field: 'trainings.basicData.partnerEmail' | translate } }}
          </small>
          <small *ngIf="formGroup.get('partnerEmail')?.errors?.['email']">
            {{ 'validation.invalidEmail' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <div class='form-section'>
    <h3>{{ 'trainings.basicData.financial' | translate }}</h3>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [errorTemplate]='costTypeError'
      [required]='true'
      [includeTouchedASInvalid]='false'
      formField='costTypeId'
      fieldKey='trainings.basicData.costType'
    >
      <div class='radio-buttons'>
        <div class='field-radiobutton' *ngFor='let costType of costTypes'>
          <p-radioButton
            formControlName='costTypeId'
            name='costTypeId'
            value='{{ costType.data }}'
            inputId='costTypeId-{{costType.data}}'
          >
          </p-radioButton>
          <label for='costTypeId-{{costType.data}}'>{{ costType.label }}</label>
        </div>
      </div>
      <ng-template #costTypeError>
        <small>
          {{ 'validation.isSelected' | translate : { field: 'trainings.basicData.costType' | translate | lowercase } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div *ngIf="this.formGroup.get('costTypeId')?.value" class='conditional-fields'>
      <div class='cost-price-info'>
        <span class='material-symbols-outlined'>info</span>
        <span>{{ 'trainings.basicData.costInfo' | translate }}</span>
      </div>

      <ng-template #costFieldTemplate let-field='field'>
        <alimento-ipv-frontend-form-field
          [errorTemplate]='costFieldError'
          [formField]='field'
          [formGroup]='formGroup'
          [required]='true'
          [includeTouchedASInvalid]='false'
          fieldKey='trainings.basicData.{{ field }}'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>
            {{ formGroup.get(field)?.value ? ('€ ' + formGroup.get(field)?.value) : '' }}
          </span>
          <div class='w-[calc(50%-0.5rem)]'>
            <p-inputNumber
              *ngIf='formGroup.enabled'
              [formControlName]='field'
              [maxFractionDigits]='2'
              [max]='9999'
              [minFractionDigits]='0'
              [min]='0'
              currency='EUR'
              id='{{ field }}'
              inputStyleClass='w-full'
              locale='nl-BE'
              mode='currency'
            ></p-inputNumber>
          </div>
          <ng-template #costFieldError>
            <div *ngIf='isFieldInvalid(field)'>
              <small *ngIf="formGroup.get(field).hasError('required')">
                {{ 'validation.isRequired' | translate : { field: 'trainings.basicData.' + field | translate } }}
              </small>
              <small *ngIf="formGroup.get(field).hasError('min')">
                {{ 'validation.minValue' | translate : { number: 0 } }}
              </small>
              <small *ngIf="formGroup.get(field).hasError('max')">
                {{ 'validation.maxValue' | translate : { number: 10000 } }}
              </small>
            </div>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </ng-template>

      <div *ngIf="this.formGroup.get('costTypeId')?.value === costTypesKey.Fixed">
        <ng-container *ngTemplateOutlet="costFieldTemplate; context: { field: 'costFixed' }"></ng-container>
      </div>

      <div *ngIf="this.formGroup.get('costTypeId')?.value === costTypesKey.Paying">
        <ng-container *ngTemplateOutlet="costFieldTemplate; context: { field: 'costFood' }"></ng-container>
      </div>

      <div *ngIf="this.formGroup.get('costTypeId')?.value === costTypesKey.NotPaying">
        <ng-container *ngTemplateOutlet="costFieldTemplate; context: { field: 'costFoodOver100' }"></ng-container>
      </div>

      <div
        *ngIf="
          this.formGroup.get('costTypeId')?.value === costTypesKey.Paying ||
          this.formGroup.get('costTypeId')?.value === costTypesKey.NotPaying
        "
      >
        <ng-container *ngTemplateOutlet="costFieldTemplate; context: { field: 'costNonFood' }"></ng-container>
        <ng-container *ngTemplateOutlet="costFieldTemplate; context: { field: 'costCevora' }"></ng-container>
        <ng-container *ngTemplateOutlet="costFieldTemplate; context: { field: 'costPatroon' }"></ng-container>
      </div>
    </div>

    <alimento-ipv-frontend-form-field *ngIf='formGroup.contains("externalReference")'
                                      [formGroup]='formGroup'
                                      formField='externalReference'
                                      fieldKey='trainings.basicData.externalReference'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{ formGroup.get("externalReference").value }}</span>
      <input *ngIf='formGroup.enabled'
             class='w-full'
             formControlName='externalReference'
             id='externalReference'
             pInputText
             type='text'
      />
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("cancellationFee")'
      [formGroup]='formGroup'
      [errorTemplate]='cancellationFeeError'
      formField='cancellationFee'
      fieldKey='trainings.basicData.cancellationFee'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>
        {{ formGroup.get('cancellationFee')?.value ? ('€ ' + formGroup.get('cancellationFee')?.value) : '' }}
      </span>
      <div class='w-[calc(50%-0.5rem)]'>
        <p-inputNumber
          *ngIf='formGroup.enabled'
          [maxFractionDigits]='2'
          [minFractionDigits]='0'
          [min]='0'
          currency='EUR'
          formControlName='cancellationFee'
          id='cancellationFee'
          inputStyleClass='w-full'
          locale='nl-BE'
          mode='currency'
        ></p-inputNumber>
      </div>
      <ng-template #cancellationFeeError>
        <small *ngIf="formGroup.get('cancellationFee').hasError('required')">
          {{ 'validation.isRequired' | translate : { field: 'trainings.basicData.cancellationFee' | translate } }}
        </small>
        <small *ngIf="formGroup.get('cancellationFee').hasError('min')">
          {{ 'validation.minValue' | translate : { number: 0 } }}
        </small>
        <small *ngIf="formGroup.get('cancellationFee').hasError('max')">
          {{ 'validation.maxValue' | translate : { number: 10000 } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div class='field field-checkbox' *ngIf='formGroup.contains("smeWallet")'>
      <p-checkbox
        [binary]='true'
        formControlName='smeWallet'
        inputId='smeWallet'
      ></p-checkbox>
      <label for='smeWallet'>{{ 'trainings.basicData.smeWallet' | translate }}</label>
    </div>

    <div class='field field-checkbox' *ngIf='formGroup.contains("smeBrochure")'>
      <p-checkbox
        [binary]='true'
        formControlName='smeBrochure'
        inputId='smeBrochure'
      ></p-checkbox>
      <label for='smeBrochure'>{{ 'trainings.descriptionData.kmoBrochure' | translate }}</label>
    </div>
  </div>

  <div class='form-section'>
    <h3>{{ 'trainings.basicData.participants' | translate }}</h3>

    <div class='flex gap-4'>
      <div class='basis-1/2'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='minParticipantsError'
          [required]='true'
          [includeTouchedASInvalid]='false'
          formField='minParticipants'
          fieldKey='trainings.basicData.minParticipants'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{ formGroup.get('minParticipants')?.value }}</span>
          <p-inputNumber
            *ngIf='formGroup.enabled'
            [min]='0'
            formControlName='minParticipants'
            id='minParticipants'
            inputStyleClass='w-full'
          ></p-inputNumber>
          <ng-template #minParticipantsError>
            <small *ngIf="formGroup.get('minParticipants')?.hasError('max')">
              {{ 'validation.maxValue' | translate : { number: 100 } }}
            </small>

            <small *ngIf="formGroup.get('minParticipants')?.hasError('required')">
              {{
                'validation.isRequired'
                  | translate : { field: 'trainings.basicData.minParticipants' | translate | lowercase }
              }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>

      <div class='basis-1/2'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='maxParticipantsError'
          [required]='true'
          [includeTouchedASInvalid]='false'
          formField='maxParticipants'
          fieldKey='trainings.basicData.maxParticipants'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{ formGroup.get('maxParticipants')?.value }}</span>
          <p-inputNumber
            *ngIf='formGroup.enabled'
            [min]='0'
            formControlName='maxParticipants'
            id='maxParticipants'
            inputStyleClass='w-full'
          ></p-inputNumber>
          <ng-template #maxParticipantsError>
            <small *ngIf="formGroup.get('maxParticipants')?.hasError('max')">
              {{ 'validation.maxValue' | translate : { number: 1000 } }}
            </small>

            <small *ngIf="formGroup.get('maxParticipants')?.hasError('lowerThanMinParticipants')">
              {{
                'validation.maxParticipantsTooLow'
                  | translate : { field: 'trainings.basicData.minParticipants' | translate }
              }}
            </small>

            <small *ngIf="formGroup.get('maxParticipants')?.hasError('lowerThanMaxParticipantsPerCompany')">
              {{
                'validation.maxParticipantsTooLow'
                  | translate : { field: 'trainings.basicData.maxParticipantPerCompany' | translate }
              }}
            </small>

            <small *ngIf="formGroup.get('maxParticipants')?.hasError('required')">
              {{
                'validation.isRequired'
                  | translate : { field: 'trainings.basicData.maxParticipants' | translate | lowercase }
              }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <div class='flex gap-4'>
      <div class='basis-1/2'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='maxParticipantPerCompanyError'
          [required]='true'
          [includeTouchedASInvalid]='false'
          formField='maxParticipantsPerCompany'
          fieldKey='trainings.basicData.maxParticipantPerCompany'
        >
          <span *ngIf='formGroup.disabled'
                class='read-only-field'>{{ formGroup.get('maxParticipantsPerCompany')?.value }}</span>
          <p-inputNumber
            *ngIf='formGroup.enabled'
            [min]='0'
            formControlName='maxParticipantsPerCompany'
            id='maxParticipantsPerCompany'
            inputStyleClass='w-full'
          ></p-inputNumber>
          <ng-template #maxParticipantPerCompanyError>
            <small *ngIf="formGroup.get('maxParticipantsPerCompany')?.hasError('max')">
              {{ 'validation.maxValue' | translate : { number: 100 } }}
            </small>

            <small *ngIf="formGroup.get('maxParticipantsPerCompany')?.hasError('required')">
              {{
                'validation.isRequired'
                  | translate : { field: 'trainings.basicData.maxParticipantPerCompany' | translate | lowercase }
              }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <div class='field field-checkbox' *ngIf='formGroup.contains("possibleAsTailored")'>
      <p-checkbox
        [binary]='true'
        formControlName='possibleAsTailored'
        inputId='possibleAsTailored'
      ></p-checkbox>
      <label for='possibleAsTailored'>{{ 'trainings.basicData.possibleAsTailored' | translate }}</label>
    </div>
  </div>

  <div class='form-section' *ngIf='type !== TRAINING_TYPE.CEVORA'>
    <h3>{{ 'trainings.basicData.targetAudience' | translate }}</h3>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("subSectorsAndFederations")'
      [formGroup]='formGroup'
      formField='subSectorsAndFederations'
      fieldKey='trainings.basicData.subsectors'
    >
      <alimento-ipv-frontend-sub-sectors
        formControlName='subSectorsAndFederations'
      ></alimento-ipv-frontend-sub-sectors>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("functionIds")'
      [formGroup]='formGroup'
      formField='functionIds'
      fieldKey='trainings.basicData.functions'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ functionsReadOnly }}</span>
      <p-multiSelect
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='functions'
        [showClear]='true'
        formControlName='functionIds'
        id='functionIds'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
      ></p-multiSelect>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("themeIds")'
      [formGroup]='formGroup'
      formField='themeIds'
      fieldKey='trainings.basicData.themes'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ themesReadOnly }}</span>
      <p-multiSelect
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='themes'
        [showClear]='true'
        formControlName='themeIds'
        id='themeIds'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
      ></p-multiSelect>
    </alimento-ipv-frontend-form-field>
  </div>
</div>
