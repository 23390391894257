<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ getTitle() | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="transition">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"persons.transitions.employment" | translate}}'
        [value]='transition.branchName'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"persons.transitions.type" | translate}}'
        [value]='transitionType'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div class='my-2'>
    {{getActionText() | translate}}
  </div>

  <div [formGroup]="formGroup" class='mb-2'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='comments'
                                      fieldKey='enterprises.accountNumbers.comments'>
      <textarea
        #focusElement
        class="w-full"
        formControlName="comments"
        id="comments"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="submit()"
        [loading]='loading'
        label="{{ getTitle() | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'enterprises.accountNumbers.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
