import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PAID_EDUCATIONAL_LEAVE_ACTION } from '../../../types/paid-educational-leave.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { DataLabelType } from '../../../types/reference-data.type';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';

@Component({
    selector: 'alimento-ipv-frontend-paid-educational-leave-action-dialog',
    templateUrl: './paid-educational-leave-action-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PaidEducationalLeaveActionDialogComponent {

  @Input()
  action: PAID_EDUCATIONAL_LEAVE_ACTION;

  @Input()
  multiple = false;

  @Output()
  submitPopup = new EventEmitter<any>();

  dialogVisible = false;
  loading = false;

  cancelForm: FormGroup;
  cancelReasons$: Observable<DataLabelType[]> = this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_CANCEL_REASONS);

  constructor(private formBuilder: FormBuilder,
              private referenceDataService: ReferenceDataService,
              private messageService: MyMessageService) {
    this.cancelForm = this.formBuilder.group({
      reasonCancelledId: [null, [Validators.required]],
      cancelledComment: [null],
    });
  }

  open(): void {
    this.dialogVisible = true;
  }

  onSubmit(): void {
    if (this.action === PAID_EDUCATIONAL_LEAVE_ACTION.CANCEL && !this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.submitPopup.emit({
      action: this.action,
      data: this.action === PAID_EDUCATIONAL_LEAVE_ACTION.CANCEL ? JSON.parse(JSON.stringify(this.cancelForm.value)) : undefined,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.cancelForm);
    return this.cancelForm.valid;
  }

  protected readonly PAID_EDUCATIONAL_LEAVE_ACTION = PAID_EDUCATIONAL_LEAVE_ACTION;
}
