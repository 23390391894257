<div class="training-catalog">
  <div *ngIf='activeFiltersOnTop' class='mx-5'>
    <ng-container *ngTemplateOutlet='activeFiltersTemplate'></ng-container>
  </div>
  <div class="search-grid flex flex-col content-center justify-items-center">
    <div class="flex gap-6 w-full items-start">
      <div class="bg-white" [ngClass]="filters?.length > 0 && !inlineFilters ? 'max-w-[350px] lg:max-w-full lg:w-1/4' : 'hidden'">
        <ng-content select="[above-search-filters]"></ng-content>
        <ng-container *ngTemplateOutlet='filtersTemplate'></ng-container>
      </div>
      <div class="w-full" [ngClass]="filters?.length > 0 && !inlineFilters ? 'lg:w-3/4': ''">
        <div class="w-full flex justify-between items-center mb-2 gap-4 pr-4 bg-white">
          <div [ngClass]='{"gap-4 flex items-center": inlineFilters}'>
            <div class="w-full lg:w-[700px]">
              <div *ngIf="showSearchFilter" class='p-5'>
                <ml-search-bar-facet
                  [showLabel]="false"
                  (search)='onSearch($event)'
                  [value]="searchValue"
                  [placeholderKey]="searchPlaceholderKey"
                  [autoFocus]="autoFocus"
                ></ml-search-bar-facet>
              </div>
            </div>
          </div>
          <div class='flex flex-col items-end gap-2'>
            <button (click)="export()" *ngIf="exportUrl"
              class="button-no-color hover:bg-purple hover:bg-opacity-10"
            >
              <i class="mr-2" [ngClass]="exportLoad ? 'fas fa-spinner fa-spin' : 'fa fa-download'"></i>
              <span>{{"export" | translate}}</span>
            </button>
            <div *ngIf='sortItems?.length > 0' class='flex items-center flex-nowrap gap-2'>
              <span>{{'search.sortOn' | translate}}</span>
              <ml-sort-filter [selectedValue]='sortValue' [sortItems]='sortItems' (sort)='onSort($event)'></ml-sort-filter>
            </div>
          </div>
        </div>
        <div *ngIf='inlineFilters' class='flex flex-wrap items-center gap-4 mb-2'>
          <ng-container *ngTemplateOutlet='filtersTemplate'></ng-container>
        </div>
        <ng-content select="[below-search]"></ng-content>
        <div *ngIf='!activeFiltersOnTop'>
          <ng-container *ngTemplateOutlet='activeFiltersTemplate'></ng-container>
        </div>
        <div class="mb-2 flex justify-between" *ngIf='showToggle || showCountTop'>
          <div *ngIf='addSelectAll || showToggle' class='flex items-center gap-8'>
            <div *ngIf='addSelectAll ' class='flex items-center gap-2'>
              <a (click)="selectAll()" class="text-primary cursor-pointer hover:underline whitespace-nowrap">{{"selectAll" | translate}} <span *ngIf='maxSelectAll < 9999'>({{"first" | translate}} {{maxSelectAll}})</span></a>
              <span>/</span>
              <a (click)="removeSelection()" class="text-primary cursor-pointer hover:underline whitespace-nowrap">{{"deselectAll" | translate}}</a>
            </div>
            <div class="w-full" *ngIf="showToggle">
              <lib-toggle
                #toggleComponent
                [value]="initialToggleValue"
                (toggleEvent)="toggleEvent($event)"
                label="{{toggleLabel | translate}}"
              ></lib-toggle>
            </div>
          </div>
          <div *ngIf="showToggle || showCountTop"></div>
          <div class="w-full">
            <lib-current-filter-info
              *ngIf='showCountTop'
              [data$]="data$"
              [availableResultsTranslateKey]="availableResultsTranslateKey"
              [loadOnScroll]="loadOnScroll"
            ></lib-current-filter-info>
          </div>
        </div>

        <ng-container *ngIf="cardTemplate">
          <lib-card-view
            [data$]="data$"
            [cardTemplate]="cardTemplate"
            [loadMoreString]="loadMoreString"
            [availableResultsTranslateKey]="availableResultsTranslateKey"
            [selectable]="selectable"
            [multiselect]="multiselect"
            [deletable]="deletable"
            (onSelectChange)="selectChange($event)"
            [loadOnScroll]="loadOnScroll"
            [scrollWindow]="scrollWindow"
            [noMoreTemplate]="noMoreTemplate"
            [canChangeView]="canChangeView && !isMobile"
            [idField]='idField'
            [autoSelectFirstResult]='autoSelectFirstResult'
            [viewMode]="isMobile ? 'grid' : undefined"
            [showCheckboxes]='showCheckboxes'
            [selectedItems]='selectedItems'
          >
          </lib-card-view>
        </ng-container>
        <ng-container *ngTemplateOutlet="tableTemplate; context: {data: data$, scrollWindow: scrollWindow}"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #filtersTemplate>
  <div *ngFor="let filter of filters">
    <div *ngIf="filter.type === SEARCH_FILTER_TYPE.searchBar" class="w-full lg:w-[700px]">
      <ml-search-bar-facet
        [showLabel]='false'
        (search)='onSearch($event)'
        [value]="searchValue"
        [placeholderKey]="searchPlaceholderKey"
        [autoFocus]="autoFocus"
      ></ml-search-bar-facet>
    </div>

    <hr class="text-black text-opacity-20 mx-5" *ngIf="filter.type === 'hr'">

    <ml-date-facet
      *ngIf="filter.type === SEARCH_FILTER_TYPE.date"
      (searchValue)="filterOnDate($event)"
      [selectedDates]="dateFilter"
      [canSelectPastDates]="canSelectPastDates"
      [inlineForm]='inlineFilters'
      dateFormat="dd/mm/yy"
      title="{{(filter.label || 'date') | translate}}"
    ></ml-date-facet>

    <ml-multiselect-facet
      *ngIf="filter.type === SEARCH_FILTER_TYPE.multiselect"
      (searchValues)="filterChanged($event)"
      [data]="filter.data | async"
      [expanded]="expanded[filter.key] || filter.expanded"
      [selectedItems]="activeFilters[filter.key] || []"
      [hasIcon]="filter.hasIcon"
      [iconMapper]='filter.iconMapper'
      [iconClassMapper]='filter.iconClassMapper'
      filterName="{{filter.key}}"
      title='{{(filter.label ? filter.label : filter.key) | translate}}'
      [showHeader]="filter.showHeader === undefined ? true : filter.showHeader"
      [inlineForm]='inlineFilters'
    ></ml-multiselect-facet>

    <ml-lazy-item-facet
      *ngIf="filter.type === SEARCH_FILTER_TYPE.lazyLoadItem"
      (searchValues)="filterChanged($event)"
      [expanded]="expanded[filter.key] || filter.expanded"
      [selectedItems]="activeFilters[filter.key] || []"
      filterName="{{filter.key}}"
      title='{{(filter.label ? filter.label : filter.key) | translate}}'
      [showHeader]="filter.showHeader === undefined ? true : filter.showHeader"
      [dataSource]="filter.dataSource"
      [getItem]="filter.getItem"
      [addEmptyValue]="filter.addEmptyValue"
      [emptyValue]="filter.emptyValue"
      [emptyLabelKey]="filter.emptyLabelKey"
    ></ml-lazy-item-facet>
  </div>
</ng-template>

<ng-template #activeFiltersTemplate>
  <lib-active-filter
    *ngIf="showActiveFilters"
    (removeAllFilters)="removeAllFilters()"
    [filterData]="filters"
    (removeFilter)="removeFilter($event)"
    [multiSelectFilter]="activeFilters || {}"
    [searchFilter]="searchValue"
    [filtersHaveChanged]="filtersHaveChanged"
    [toDateFilter]='dateFilter.toDate'
    [fromDateFilter]='dateFilter.fromDate'
  ></lib-active-filter>
</ng-template>
