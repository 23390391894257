<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  [focusOnShow]='false'
  [closable]="false"
  appendTo="body"
  header="{{ 'persons.employments.stopDialog.title' | translate }}"
>
  <div class="flex p-4 mb-4 bg-grey-0">
    <div class='basis-1/2'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header="{{ 'persons.employments.branch' | translate }}"
        value="{{ employment?.branchName }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>

    <div class='basis-1/2'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header="{{ 'persons.employments.function' | translate }}"
        value="{{ employment?.function }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="stopEmploymentForm" class="w-[202px]">
    <alimento-ipv-frontend-form-field
      [formGroup]='stopEmploymentForm'
      [errorTemplate]='dateErrorTemplate'
      [required]='true'
      formField='stopEmploymentAt'
      fieldKey='trainings.sessions.date'
    >
      <lib-date-picker
        [pAutoFocus]='true'
        [showClear]="true"
        formControlName="stopEmploymentAt"
        id="stopEmploymentAt"
      ></lib-date-picker>
      <ng-template #dateErrorTemplate>
        <small *ngIf='stopEmploymentForm.get("stopEmploymentAt")?.hasError("required")'>
          {{ 'trainings.sessions.dateRequired' | translate }}
        </small>
        <small *ngIf='stopEmploymentForm.get("stopEmploymentAt").hasError("invalidDate")'>
          {{ 'validation.invalidDate' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="stopEmployment()"
        [loading]="loading"
        label="{{ 'persons.employments.stopDialog.buttons.stopEmployment' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'persons.employments.stopDialog.buttons.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
