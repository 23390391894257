<div *ngIf='visible'
     #sidebar
     class='fixed bottom-0 right-0 z-40 max-h-[calc(100%-184px)] h-screen w-[474px] transition-transform -translate-x-full
     sm:translate-x-0 bg-primary-50 p-4 shadow-lg'>
  <div class='mb-4 '>
    <alimento-ipv-frontend-header title='notes.title'>
      <i class='font-material not-italic font-bold cursor-pointer' (click)='close()'>Close</i>
    </alimento-ipv-frontend-header>
  </div>
  <div class='mb-4' *ngIf='!readonly'>
    <p-button
      *ngIf='!showAddNote()'
      (click)='showAddNote.set(true)'
      [disabled]='!!isDirty()'
      label="{{'notes.add' | translate}}"
      [link]='true'
      styleClass='px-0'
      icon='font-material before:content-["Add"]'
    >
    </p-button>
    <div *ngIf='showAddNote()'>
      <alimento-ipv-frontend-note #addNoteComponent
                                  (saveNote)='addNote($event)'
                                  (cancelNote)='cancelAddNote()'
      ></alimento-ipv-frontend-note>
    </div>
  </div>

  <div class='flex flex-col gap-4 overflow-auto' [ngStyle]='{height: (sidebar.clientHeight - 164) + "px"}'>
    <alimento-ipv-frontend-note #noteComponent
                                *ngFor='let note of notes'
                                (saveNote)='editNote($event)'
                                (cancelNote)='cancelNote()'
                                (removeNote)='removeNote($event)'
                                [note]='note'
                                [readonly]='readonly || (isDirty() && isDirty() !== note.id) || showAddNote()'
                                (changes)='noteHasChanges(note.id, noteComponent)'
    ></alimento-ipv-frontend-note>
  </div>
</div>
