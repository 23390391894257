<alimento-ipv-frontend-paid-educational-leave-action-dialog [action]='currentAction'
                                                            [multiple]='true'
                                                            (submitPopup)='executeAction($event)'
></alimento-ipv-frontend-paid-educational-leave-action-dialog>

<alimento-ipv-frontend-overview-page-layout header='paidEducationalLeaves.menuTitle'>
  <ng-container top-right>
    <div class='flex items-center gap-2'>
      <p-button
        *ngIf='canCommunicate'
        (click)='openActionDialog(PAID_EDUCATION_LEAVE_ACTION.ATTEST_REQUESTED)'
        label="{{ 'paidEducationalLeaves.actions.attestRequested' | translate }}"
      ></p-button>
      <p-button
        *ngIf='canGenerate'
        (click)='openActionDialog(PAID_EDUCATION_LEAVE_ACTION.GENERATE)'
        label="{{ 'paidEducationalLeaves.actions.generate' | translate }}"
      ></p-button>
      <p-button
        *ngIf='canSend'
        (click)='openActionDialog(PAID_EDUCATION_LEAVE_ACTION.SEND)'
        label="{{ 'paidEducationalLeaves.actions.send' | translate }}"
      ></p-button>
    </div>
  </ng-container>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [activeFilters]='activeFilter'
              [defaultFilters]='defaultFilter'
              [cardTemplate]='cardTemplate'
              [selectable]='true'
              [expandAll]='false'
              [multiselect]='true'
              [showCheckboxes]='true'
              [addSelectAll]='true'
              [maxSelectAll]='100'
              idField='alimentoId'
              name='paid-educational-leaves'
              searchPlaceholderKey='paidEducationalLeaves.searchPlaceholder'
              availableResultsTranslateKey='paidEducationalLeaves.certificates'
              (onSelectChange)='onselectionchange($event)'
              [onFilterChange]='onFilterChange'
  >
    <div above-search-filters>
      <div class='flex flex-wrap items-center gap-2 ml-4 mt-2 mr-2'>
        <label class='text-base'>{{'paidEducationalLeaves.selectExistingFilter' | translate}}</label>
        <div class='min-w-60 flex-grow'>
          <p-select styleClass='w-full' [options]='savedFilters' [formControl]='savedFiltersControl'></p-select>
        </div>
      </div>
    </div>
    <ng-template #cardTemplate let-item="item" let-selected="selected">
      <alimento-ipv-frontend-paid-educational-leave-card
        [paidEducationalLeaveAttest]='item'
        [selected]='selected'
        [clickable]='true'
        tabindex="0"
      ></alimento-ipv-frontend-paid-educational-leave-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
