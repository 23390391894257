import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { HistoryItem } from '../../types/ui-lib.type';

@Component({
    selector: 'alimento-ipv-frontend-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HistoryComponent implements OnChanges, AfterViewChecked {

  @Input()
  historyItems: HistoryItem[] = [];

  @Input()
  visible = false;

  @Input()
  messageTemplate: TemplateRef<any>;

  @ViewChild('commentsContainer')
  commentsContainer: ElementRef;

  @Output()
  visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  refreshHistoryItems: EventEmitter<void> = new EventEmitter<void>();

  private _disableScrollToBottom = false;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['historyItems']?.currentValue) {
      this._disableScrollToBottom = false;
    }
  }

  ngAfterViewChecked(): void {
    if (!this._disableScrollToBottom) {
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    try {
      this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
    }
    catch (err) {
    }
  }

  onScroll() {
    const element = this.commentsContainer.nativeElement;
    const atBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (this._disableScrollToBottom && atBottom) {
      this._disableScrollToBottom = false;
    }
    else {
      this._disableScrollToBottom = true;
    }
  }

  close(): void {
    this.visible = false;
    this.visibleChange.emit(false);
  }
}
