import { Component, Input } from '@angular/core';

@Component({
    selector: 'alimento-ipv-frontend-header',
    templateUrl: './header.component.html',
    standalone: false
})
export class HeaderComponent {
  @Input()
  title: string;
}
