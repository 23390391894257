import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { validateAllFormFields } from '../../utils/form-utils';

@Component({
    selector: 'alimento-ipv-frontend-form',
    templateUrl: './form.component.html',
    standalone: false
})
export abstract class FormComponent implements OnDestroy {
  formGroup!: FormGroup;

  @Input()
  name?: string;

  @Output()
  changes: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected subscriptions: Subscription[] = [];

  protected _addChangeListener(): void {
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe(() => {
        this.changes.emit(true);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub?.unsubscribe());
  }

  isValid(): boolean {
    if (this.formGroup.disabled) {
      return true;
    }

    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  isFieldInvalid(field: string): boolean {
    return !this.formGroup.get(field).disabled && !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
  }

  getData(): any {
    return JSON.parse(JSON.stringify(this.formGroup.value));
  }

  hasChanges(): boolean {
    return this.formGroup.dirty;
  }

  reset(): void {
    this.formGroup.reset();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.formGroup.setValue(value);
    }
    else {
      this.formGroup.reset();
    }
  }

  setFocus(): void {
    // overwrite function
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.formGroup.disable({ emitEvent: false }) : this.formGroup.enable({ emitEvent: false });
  }
}
