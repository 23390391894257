<div *ngIf='formGroup' [formGroup]='formGroup'>
  <alimento-ipv-frontend-header
    title="{{ 'trainings.basicData.formTitle' | translate }}"
  ></alimento-ipv-frontend-header>

  <h3>{{ 'trainings.basicData.general' | translate }}</h3>

  <div class='max-w-[650px]'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [errorTemplate]='customTitleError'
                                      [required]='true'
                                      formField='customTitle'
                                      fieldKey='trainings.customTitle'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{formGroup.get("customTitle")?.value}}</span>
      <input *ngIf='formGroup.enabled'
             id="customTitle"
             formControlName="customTitle"
             type="text"
             pInputText
             class='w-full'
             placeholder="{{ 'trainings.titlePlaceholder' | translate }}"
      />
      <ng-template #customTitleError>
        <small *ngIf="formGroup.get('customTitle')?.hasError('required')">
          {{ 'validation.isRequired' | translate : { field: 'trainings.title' | translate | lowercase } }}
        </small>
        <small *ngIf="formGroup.get('customTitle')?.hasError('maxlength')">
          {{ 'validation.maxLength' | translate : { number: 200 } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-language-code formControlName='languageCode' [required]='true'>
    </alimento-ipv-frontend-language-code>

    <div *ngIf="formGroup.get('languageCode')?.value" class='conditional-fields'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        [errorTemplate]='treeSelectErrorTemplate'
        formField='trainingTitleId'
        fieldKey='trainings.basicData.treeselect'
      >
        <ng-template #trainingTitle let-trainingTitleReadOnly="item" let-showCurrent="showCurrent">
          <span *ngIf='trainingTitleReadOnly?.parent?.parent' class='{{showCurrent ? "read-only-field" : "text-grey-4 text-sm" }}'>
            {{trainingTitleReadOnly?.parent?.parent?.label}} >
            {{trainingTitleReadOnly?.parent?.label}}{{ showCurrent ? ' > ' + trainingTitleReadOnly?.label : ''}}
          </span>

          <div *ngIf='trainingTitleReadOnly?.educationLeaveRecognitions' class='mt-4'>
            <div *ngFor='let recognitionType of trainingTitleReadOnly.educationLeaveRecognitions' class='flex items-center gap-1 flex-wrap'>
              <span>{{'trainings.recognizedFor' | translate}}</span>
              <span>{{getRecognitionType(recognitionType.educationLeaveRecognitionTypeId) | async}}</span>
              <span>{{'from' | translate}} {{recognitionType.from | date: "dd/MM/yyyy"}}</span>
              <span *ngIf='recognitionType.to'>{{'to' | translate}} {{recognitionType.to | date: "dd/MM/yyyy"}}</span>
            </div>
          </div>
        </ng-template>

        <div *ngIf='formGroup.disabled' class='read-only-field'>
          <ng-container *ngTemplateOutlet='trainingTitle; context: {item: trainingTitleReadOnly, showCurrent: true}'></ng-container>
        </div>
        <ng-container *ngIf='!formGroup.disabled'>
          <p-treeSelect
            [options]='$any(trainingTitles)'
            [filter]='true'
            [showClear]='true'
            [resetFilterOnHide]='true'
            (onHide)='closeUnselectedTreeItems()'
            (onClear)='onTreeSelectChange()'
            (onNodeSelect)='onTreeSelectChange()'
            (onNodeUnselect)='onTreeSelectChange()'
            containerStyleClass='w-full'
            formControlName='treeSelect'
            id='treeSelect'
            panelClass='fullWidth'
            placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          >
            <ng-template let-node pTemplate='value'>
              <span *ngIf='node else emptyValue'>
                {{ node?.label }}
              </span>
              <ng-template #emptyValue>{{ 'trainings.basicData.chooseOption' | translate }}</ng-template>
            </ng-template>
          </p-treeSelect>
          <ng-container *ngIf="formGroup.get('treeSelect')?.value">
            <ng-container *ngTemplateOutlet='trainingTitle; context: {item: formGroup.get("treeSelect").value, showCurrent: false}'></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #treeSelectErrorTemplate>
          <small *ngIf="formGroup.get('trainingTitleId')?.hasError('required')">
            {{ formGroup.get('treeSelect')?.value ?
              ('validation.notLowestLevelSelected' | translate) :
              ('validation.isSelected' | translate : { field: 'trainings.basicData.treeselect' | translate | lowercase })
            }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='methodId'
      fieldKey='trainings.basicData.method'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{methodReadOnly}}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='methods'
        [showClear]='true'
        formControlName='methodId'
        id='methodId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>

      <span class='text-grey-4 text-[12px]'>{{ 'trainings.basicData.methodInfo' | translate }}</span>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      formField='typeId'
      fieldKey='reimbursementRequests.type'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{type}}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='types'
        [showClear]='true'
        formControlName='typeId'
        id='typeId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='teacherBranchId'
                                      fieldKey='reimbursementRequests.training.teacherBranch'
    >
      <span *ngIf='formGroup.disabled'>{{teacherBranchReadOnly}}</span>
      <alimento-ipv-frontend-branch-select
        *ngIf='formGroup.enabled'
        formControlName='teacherBranchId'
        placeholder='trainings.sessions.teacherBranchPlaceholder'
        [extraFilters]='[teacherFilter]'
      ></alimento-ipv-frontend-branch-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='externalReference'
                                      fieldKey='trainings.basicData.externalReference'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{formGroup.get('externalReference').value}}</span>
      <input *ngIf='formGroup.enabled'
             class="w-full"
             formControlName="externalReference"
             id="externalReference"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>

    <div class='field field-checkbox' *ngIf='formGroup.contains("mentorTraining")'>
      <p-checkbox
        [binary]='true'
        formControlName='mentorTraining'
        inputId='mentorTraining'
      ></p-checkbox>
      <label for='mentorTraining'>{{ 'trainings.basicData.mentorTraining' | translate }}</label>
    </div>
  </div>

  <div class='max-w-[650px]'>
    <h3>{{ 'trainings.basicData.management' | translate }}</h3>
    <div class='grid lg:grid-cols-2 gap-x-4'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        formField='caseManagerId'
        fieldKey='trainings.basicData.caseManager'
      >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{caseManager?.label}}
        <span *ngIf='caseManager && !caseManager.isActive' class='text-danger italic ml-2'>
          {{"trainings.basicData.inactive" | translate}}
        </span>
      </span>
        <p-select
          *ngIf='formGroup.enabled'
          #caseManagerField
          [filter]='true'
          [options]='caseManagers'
          [showClear]='true'
          (onClear)='formGroup.get("caseManagerId").markAsTouched()'
          formControlName='caseManagerId'
          id='caseManagerId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf='caseManager'>
            <span>{{caseManager?.label}}
              <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
              </span>
            </span>
            </div>
          </ng-template>
          <ng-template let-caseManager pTemplate="item">
            <div *ngIf='caseManager'>
            <span>{{caseManager.label}}
              <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
             </span>
            </span>
            </div>
          </ng-template>
        </p-select>
      </alimento-ipv-frontend-form-field>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='caseManagerBackupId'
        fieldKey='trainings.basicData.backup'
      >
        <span class='read-only-field' *ngIf='formGroup.disabled'>
          {{caseManagerBackup?.label}}
          <span *ngIf='caseManagerBackup && !caseManagerBackup.isActive' class='text-danger italic ml-2'>
            {{"trainings.basicData.inactive" | translate}}
          </span>
        </span>
        <p-select
          #caseManagerBackupField
          *ngIf='formGroup.enabled'
          [filter]='true'
          [options]='caseManagerBackups'
          [showClear]='true'
          (onClear)='formGroup.get("casemanagerBackupId").markAsTouched()'
          formControlName='caseManagerBackupId'
          id='caseManagerBackupId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf='caseManagerBackup'>
           <span>{{caseManagerBackup?.label}}
             <span *ngIf='!caseManagerBackup.isActive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
              </span>
            </span>
            </div>
          </ng-template>
          <ng-template let-caseManager pTemplate="item">
            <div *ngIf='caseManager'>
           <span>{{caseManager.label}}
             <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
              </span>
            </span>
            </div>
          </ng-template>
        </p-select>
      </alimento-ipv-frontend-form-field>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        formField='counselorId'
        fieldKey='trainings.basicData.counselor'
      >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{counselor?.label}}
        <span *ngIf='counselor && !counselor.isActive' class='text-danger italic ml-2'>
          {{"trainings.basicData.inactive" | translate}}
        </span>
      </span>
        <p-select
          *ngIf='formGroup.enabled'
          #counselorField
          [filter]='true'
          [options]='counselors'
          [showClear]='true'
          (onClear)='formGroup.get("counselorId").markAsTouched()'
          formControlName='counselorId'
          id='counselorId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        >
          <ng-template pTemplate="selectedItem">
            <div *ngIf='counselor'>
           <span>{{counselor?.label}}
             <span *ngIf='!counselor.isActive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
              </span>
            </span>
            </div>
          </ng-template>
          <ng-template let-counselor pTemplate="item">
            <div *ngIf='counselor'>
           <span>{{counselor.label}}
             <span *ngIf='!counselor.isActive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
              </span>
            </span>
            </div>
          </ng-template>
        </p-select>
      </alimento-ipv-frontend-form-field>
    </div>

    <div class='grid lg:grid-cols-2 gap-4' *ngIf='loadingContactPersonsDone'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='contactPerson'
        fieldKey='reimbursementRequests.contactPerson'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{contactPersonsReadOnly[0]}}</span>
        <div *ngIf='formGroup.enabled'>
          <alimento-ipv-frontend-contact-person-select formControlName='contactPerson'
                                                       [contactPersons]='contactPersons'
                                                       [showLabels]='false'
                                                       [showBranchInfo]='isGroupContactPerson'
          ></alimento-ipv-frontend-contact-person-select>
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='contactPerson2'
        fieldKey='reimbursementRequests.contactPerson2'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{contactPersonsReadOnly[1]}}</span>
        <div *ngIf='formGroup.enabled'>
          <alimento-ipv-frontend-contact-person-select formControlName='contactPerson2'
                                                       [contactPersons]='contactPersons'
                                                       [showLabels]='false'
                                                       [showBranchInfo]='isGroupContactPerson'
          ></alimento-ipv-frontend-contact-person-select>
        </div>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>
  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    formField='trainingProgramId'
                                    fieldKey='trainingPrograms.trainingProgram'>
    <alimento-ipv-frontend-select-training-program formControlName='trainingProgramId'
                                                   [branchId]='reimbursementRequest.branchId'
                                                   [yearOfRequest]='reimbursementRequest.year'
                                                   [trainingRubricId]='formGroup.get("rubricId").value'
                                                   [firstSessionDate]='firstSessionDate'
                                                   [lastSessionDate]='lastSessionDate'
    >
    </alimento-ipv-frontend-select-training-program>
  </alimento-ipv-frontend-form-field>
</div>
