import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DataLabelType, IFile, minLengthArray, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { FinancialDocument, FinancialDocumentUpdateEvent } from '../../../types/reimbursement-request.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { FINANCIAL_TYPE } from '../../../types/reimbursement-request.enum';
import { InputNumber } from 'primeng/inputnumber';

@Component({
    selector: 'alimento-ipv-frontend-financial-document-popup',
    templateUrl: './financial-document-popup.component.html',
    standalone: false
})
export class FinancialDocumentPopupComponent implements OnChanges, OnDestroy {
  @Input()
  financialDocument?: FinancialDocument;

  @Output()
  formSubmit = new EventEmitter<FinancialDocumentUpdateEvent>();

  types: Observable<DataLabelType[]> = this.referenceDataService.getFinancialTypes();
  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  referenceRequired = false;

  private _subscriptions: (Subscription | undefined)[] = [];
  files: IFile[] = [];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private referenceDataService: ReferenceDataService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['financialDocument']?.currentValue) {
      this.createForm();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid && this.files.length >= 1;
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    delete data.files;

    const formData: any = new FormData();
    Object.keys(data)
      .filter(key => (data as any)[key])
      .forEach(key => formData.append(key, (data as any)[key]));

    this.files.filter(file => file.data)
      .forEach(file => formData.append('files', file.data));

    this.files.filter(file => !file.data)
      .forEach(file => formData.append('filesToKeep', file.id));

    return formData;
  }

  addOrUpdateFinancialDocument(): void {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });
      return;
    }

    this.formSubmit.emit({
      id: this.financialDocument?.id,
      financialDocument: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm() {
    this.formGroup = this.fb.group({
      financialDocumentTypeId: [this.financialDocument?.financialDocumentTypeId, Validators.required],
      reference: [this.financialDocument?.reference],
      amountWithoutPreparation: [this.financialDocument?.amountWithoutPreparation, Validators.required],
      amountPreparation: [this.financialDocument?.amountPreparation],
      remark: [this.financialDocument?.remark],
      files: [this.financialDocument?.files || [], minLengthArray(1)]
    });

    this.files = this.financialDocument?.files || [];
    this._setValidation();
  }

  filesChange(files: IFile[]) {
    this.formGroup.get('files').setValue(files);
  }

  private _setValidation(): void {
    this._subscriptions.push(
      this.formGroup.get('financialDocumentTypeId').valueChanges.subscribe(newValue => {
        const referenceControl = this.formGroup.get('reference');
        if ([FINANCIAL_TYPE.INVOICE, FINANCIAL_TYPE.CREDIT_NOTE].includes(newValue)) {
          referenceControl.setValidators([Validators.required]);
          this.referenceRequired = true;
        }
        else {
          referenceControl.setValidators([]);
          this.referenceRequired = false;
        }
        referenceControl.updateValueAndValidity();
      })
    );
  }

  onKeyDown($event: KeyboardEvent, numberControl: InputNumber): void {
    if ($event.key === '-' && numberControl.value && ("" + numberControl.value).indexOf("-") < 0) {
      numberControl.writeValue("-" + numberControl.value);
    }
  }
}
