<div *ngIf="communication"
     class="shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-primary-hover relative border border-input-border"
>
  <div class='grid grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_170px] gap-2'>
    <div>
      <ng-container *ngTemplateOutlet='nameContainer; context: {item: communication.from, type: "from", linkedTask: communication.linkedTask}'></ng-container>
      <div *ngIf='!communication.linkedTask'>
        <div *ngFor='let toContact of communication.to || []'>
          <ng-container *ngTemplateOutlet='nameContainer; context: {item: toContact, type: "to"}'></ng-container>
        </div>
        <div *ngFor='let toContact of communication.cc || []'>
          <ng-container *ngTemplateOutlet='nameContainer; context: {item: toContact, type: "cc"}'></ng-container>
        </div>
        <div *ngFor='let toContact of getBCC()'>
          <ng-container *ngTemplateOutlet='nameContainer; context: {item: toContact, type: "bcc"}'></ng-container>
        </div>
      </div>
    </div>

    <div class='flex flex-col gap-1'>
      <span class='font-bold'>{{communication.subject}}</span>
      <span>{{('mailTypes.' + communication.mailTemplate) | translate}}</span>
    </div>

    <div class='flex justify-between'>
      <span>{{communication.dateTime | date:'dd/MM/yyyy HH:mm'}}</span>
      <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
    </div>
  </div>
</div>

<ng-template #nameContainer let-item="item" let-type="type" let-linkedTask="linkedTask">
  <div class='flex gap-4 items-center flex-wrap'>
    <div class='flex gap-4 items-center'>
      <div class='w-5'>
        <span *ngIf='item.statusId || linkedTask'
              class='font-material font-bold text-{{item.statusId ? getColor(item) : getMailColor()}}'>
          {{item.statusId ? getIcon(item) : getMailIcon()}}
        </span>
      </div>
      <span pTooltip='{{item.email}}' [autoHide]='false' tooltipStyleClass='max-w-max'>
        {{'communications.' + type | translate}}: {{item.name}}
      </span>
    </div>
    <span *ngIf='showStatus(item, linkedTask)'
          pTooltip='{{linkedTask?.description || item.linkedTask?.description}}'
          tooltipStyleClass='max-w-max'
          [autoHide]='false'
          class='px-2 rounded bg-opacity-5
          text-{{item.statusId ? getColor(item) : getMailColor()}}
          bg-{{item.statusId ? getColor(item) : getMailColor()}}'>
      {{ ("communications.mailItemStatus." + (item.statusId ? getState(item) : getMailState())) | translate }}
    </span>
  </div>
</ng-template>
