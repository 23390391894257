<div class="error-container p-8 w-1/2 my-8 m-auto bg-white">
  <div class="flex flex-col items-center">
    <h1>{{ 'error.pageNotFound' | translate }}</h1>
    <h2 class="text-xl text-text font-bold my-4">{{ message }}</h2>

    <span *ngIf="showRedirect" class="mb-4">{{ 'error.toPreviousPageText' | translate }}</span>
    <p-button *ngIf="showRedirect"
              label="{{ 'error.toPreviousPage' | translate }}"
              [routerLink]='redirectUrl'
    ></p-button>
  </div>
</div>
