import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IFilter } from '../../../types/search.type';
import { SORT_ORDER } from '@alimento-ipv-frontend/ui-lib';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ml-sort-filter',
    templateUrl: './sort-filter.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SortFilterComponent implements OnChanges, OnDestroy {
  @Input()
  sortItems: IFilter[] = [];

  @Input()
  selectedValue: string;

  @Output()
  sort: EventEmitter<string> = new EventEmitter<string>();

  allSortItems: IFilter[] = [];
  sortFormControl: FormControl<string>;
  private _subscription: Subscription;

  constructor() {
    this.sortFormControl = new FormControl<string>('');
    this._subscription = this.sortFormControl.valueChanges
      .subscribe(newValue => this.sort.emit(newValue));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sortItems']?.currentValue) {
      this._setAllSortItems();
    }
    this.sortFormControl?.setValue(this.selectedValue, { emitEvent: false });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _setAllSortItems(): void {
    this.allSortItems = [];

    this.sortItems.forEach(sortItem => {
      this.allSortItems.push({
        type: SORT_ORDER.ASC,
        label: sortItem.label,
        value: sortItem.value + "_" + SORT_ORDER.ASC
      });

      this.allSortItems.push({
        type: SORT_ORDER.DESC,
        label: sortItem.label,
        value: sortItem.value + "_" + SORT_ORDER.DESC
      });
    })
  }
}
