import { Component, Input } from '@angular/core';
import { SearchEnterpriseGroupItem } from '../../../types/enterprise-group.type';

@Component({
    selector: 'alimento-ipv-frontend-enterprise-group-card',
    templateUrl: './enterprise-group-card.component.html',
    standalone: false
})
export class EnterpriseGroupCardComponent {

  @Input()
  enterpriseGroup: SearchEnterpriseGroupItem;

  @Input()
  selected = false;

  @Input()
  clickable = true;
}
