import { TrainingTemplateListComponent } from './pages/training-template-list/training-template-list.component';
import { Route } from '@angular/router';
import { TrainingTemplateDetailComponent } from './pages/training-template-detail/training-template-detail.component';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';

export const trainingTemplatesRoutes: Route[] = [
  {
    path: '',
    component: TrainingTemplateListComponent,
    data: { title: 'titles.templates.list' }
  },
  {
    path: 'create',
    component: TrainingTemplateDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.templates.create' }
  },
  {
    path: ':templateId',
    component: TrainingTemplateDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.templates.detail' }
  }
];
