<p-dialog
  *ngIf='dialogVisible'
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '80vw', height: '80vh' }"
  appendTo="body"
  header="{{ 'trainingPrograms.selectTrainingProgram' | translate }}"
>

  <p-tabs [(value)]="activeIndex">
    <p-tablist>
      <p-tab value='0'>{{"trainingPrograms.existingTrainingProgram" | translate}}</p-tab>
      <p-tab value='1'>{{"trainingPrograms.newTrainingProgram" | translate}}</p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value='0'>
        <lib-search [data$]='search$'
                    [cardTemplate]='cardTemplate'
                    [selectable]='true'
                    [filters]='searchFilters'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [defaultFromDate]='defaultFromDate'
                    [defaultToDate]='defaultToDate'
                    [showSearchFilter]='false'
                    (onSelectChange)='selectionChange($event)'
                    name='training-program-select'
                    searchPlaceholderKey='trainingPrograms.searchPlaceholder'
                    availableResultsTranslateKey='trainingPrograms.availableTrainingPrograms'
        >
          <ng-template #cardTemplate let-item="item" let-selected="selected">
            <alimento-ipv-frontend-training-program-card [trainingProgram]='item'
                                                         [selected]='selected'
                                                         [clickable]='true'
            >
            </alimento-ipv-frontend-training-program-card>
          </ng-template>
        </lib-search>
      </p-tabpanel>
      <p-tabpanel value='1'>
        <alimento-ipv-frontend-training-program [trainingProgram]='newTrainingProgram'
        ></alimento-ipv-frontend-training-program>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>

  <div *ngIf='notMatchingRubric() || sessionDateOutsideTrainingProgram()' class='my-4 p-4 bg-grey-0 border-l-4 border-l-primary flex flex-col'>
    <span *ngIf='notMatchingRubric()'>{{'trainingPrograms.notMatchingRubric' | translate}}</span>
    <span *ngIf='sessionDateOutsideTrainingProgram()'>{{'trainingPrograms.trainingDateOutsideTrainingProgram' | translate}}</span>

  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="selectTrainingProgram()"
        [loading]='loading'
        label="{{ 'trainingPrograms.selectTrainingProgram' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'leaveDialog.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<div>
  <div *ngIf='trainingProgram' class='flex items-center w-full'>
    <alimento-ipv-frontend-training-program-card-detail
      [trainingProgram]='trainingProgram'
    ></alimento-ipv-frontend-training-program-card-detail>
    <div class='basis-12'>
      <button *ngIf="!disabled" (click)="removeTrainingProgram()" class="p-2 group">
        <i class="pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover"></i>
      </button>
    </div>
  </div>
  <div *ngIf='!trainingProgram && !disabled'
       class='flex items-center w-fit p-2 text-primary hover:bg-primary-hover' >
    <button (click)='addTrainingProgram()'>{{'trainingPrograms.selectTrainingProgram' | translate}}</button>
  </div>
</div>
