<alimento-ipv-frontend-header>
  <ng-container title>
    <div class='flex items-center gap-2'>
      <h2 class='text-xl text-text font-bold'>{{ 'enterprises.smeBonus' | translate }}</h2>
      <span class='text-primary font-bold'>
        {{ (smeBonusActive ? 'enterprises.smeBonusActive' : 'enterprises.smeBonusNotActive') | translate }}
      </span>
    </div>
  </ng-container>
</alimento-ipv-frontend-header>

<div class='max-w-[650px]'>
  <alimento-ipv-frontend-form-field [required]='true'
                                    fieldKey='enterprises.smeTotalBalance'>
    <span>{{ enterprise.smeBonus | currency:"EUR" }}</span>
  </alimento-ipv-frontend-form-field>
  <alimento-ipv-frontend-form-field [required]='true'
                                    fieldKey='enterprises.smeBalance'>
    <span>{{ enterprise.smeBalance | currency:"EUR" }}</span>
  </alimento-ipv-frontend-form-field>

  <div class='grid grid-cols-2 gap-4'>
    <div class='w-[202px]'>
      <alimento-ipv-frontend-form-field [required]='true'
                                        fieldKey='enterprises.smeBonusValidFrom'>
        <span>{{ enterprise.smeBonusValidFrom | date:"dd/MM/yyyy" }}</span>
      </alimento-ipv-frontend-form-field>
    </div>
    <div class='w-[202px]'>
      <alimento-ipv-frontend-form-field [required]='true'
                                        fieldKey='enterprises.smeBonusValidUntil'>
        <span>{{ enterprise.smeBonusValidUntil | date:"dd/MM/yyyy" }}</span>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>
</div>
