<div *ngIf='!inlineForm else inlineFormTemplate' class="">
  <h2 class="text-lg p-5 font-bold">{{title}}</h2>
  <ng-container *ngTemplateOutlet='datesTemplate'></ng-container>
</div>

<ng-template #inlineFormTemplate>
  <div class='flex items-center gap-1 p-2 rounded cursor-pointer hover:bg-primary-hover'
       (click)='overlayPanel.toggle($event)'>
    <span>{{title}}</span>
    <span *ngIf='getFilterCount() > 0'
          class='bg-primary text-white inline-flex items-center justify-center w-4 h-4 text-xs rounded-full'>
      {{getFilterCount()}}
    </span>
    <i class='font-material not-italic'>expand_more</i>
  </div>

  <p-popover #overlayPanel>
    <ng-container *ngTemplateOutlet="datesTemplate"></ng-container>
  </p-popover>
</ng-template>

<ng-template #datesTemplate>
  <div [formGroup]="dates" class='flex flex-col gap-4'>
    <div class="flex flex-wrap xl:flex-nowrap items-center justify-between">
      <span class="ml-5 w-14">{{"search.from" | translate}}</span>
      <div class="ml-5 flex-grow">
        <lib-date-picker
          formControlName="fromDate"
          [dateFormat]="dateFormat"
          [showClear]="canSelectPastDates || isDifferentFromToday('fromDate')"
          [minDate]="canSelectPastDates ? undefined : minDateValue"
          [maxDate]="maxSelectedDate"
          [keepInvalid]='false'
        ></lib-date-picker>
      </div>
    </div>
    <div class="flex flex-wrap xl:flex-nowrap items-center justify-between">
      <span class="ml-5 w-14">{{"search.to" | translate}}</span>
      <div class="ml-5 flex-grow">
        <lib-date-picker
          formControlName="toDate" ngDefaultControl
          [dateFormat]="dateFormat"
          [showClear]="true"
          [minDate]="minSelectedDate ? minSelectedDate : minDateValue"
          [keepInvalid]='false'
        ></lib-date-picker>
      </div>
    </div>
  </div>
</ng-template>
