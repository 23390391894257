<div *ngIf="branch"
     class="shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-primary-hover relative border border-input-border cursor-pointer"
>
  <div class='flex items-center justify-between'>
    <div class='flex flex-col gap-1'>
      <div class='flex items-center gap-4'>
        <span class='font-bold text-lg'>{{branch.alimentoId}} - {{branch.name}}</span>
        <i *ngIf='branch.isMainBranch' class='pi pi-star' style='font-size: 16px'></i>
      </div>

      <span>{{'branches.branchNumber' | translate}}: {{branch.companyNumber}}</span>
    </div>
    <div class='flex items-center gap-4'>
      <span>{{branch.address}}</span>
      <alimento-ipv-frontend-more-actions width='300px' [actions]='actions'></alimento-ipv-frontend-more-actions>
    </div>
  </div>
</div>
