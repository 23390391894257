<div>
  <alimento-ipv-frontend-form-field
    [required]='true'
    [errorTemplate]='errorTemplate'
    [alwaysShowError]='true'
    formGroupClass='pb-0'
    formField='languageCode'
    fieldKey='trainings.basicData.language'
  >
    <div class='field-radiobutton'>
      <p-radioButton
        [formControl]='formControl'
        inputId='language-nl'
        name='languageCode'
        value='nl'
      ></p-radioButton>
      <label for='language-nl'>{{ 'trainings.basicData.dutch' | translate }}</label>
    </div>

    <div class='field-radiobutton'>
      <p-radioButton
        [formControl]='formControl'
        inputId='language-fr'
        name='languageCode'
        value='fr'
      ></p-radioButton>
      <label for='language-fr'>{{ 'trainings.basicData.french' | translate }}</label>
    </div>
    <div class='field-radiobutton' *ngIf='showExtraLanguages'>
      <p-radioButton
        [formControl]='formControl'
        inputId='language-de'
        name='languageCode'
        value='de'
      ></p-radioButton>
      <label for='language-de'>{{ 'trainings.basicData.german' | translate }}</label>
    </div>

    <div class='field-radiobutton' *ngIf='showExtraLanguages'>
      <p-radioButton
        [formControl]='formControl'
        inputId='language-en'
        name='languageCode'
        value='en'
      ></p-radioButton>
      <label for='language-en'>{{ 'trainings.basicData.english' | translate }}</label>
    </div>

    <ng-template #errorTemplate>
      <small *ngIf='formControl.invalid && (formControl.dirty || formControl.touched)'>
        {{'error.field-required' | translate : {field: "trainings.basicData.language" | translate} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>
</div>
