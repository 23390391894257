<p-dialog
  *ngIf="dialogVisible"
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'paidEducationalLeaves.selectContactPerson' | translate }}"
>
  <div [formGroup]='formGroup'>
    <div class='mb-4'>
      <span class='text-lg'>{{'paidEducationalLeaves.selectContactPersonText' | translate}}</span>
    </div>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='contactPersonId'
                                      fieldKey='paidEducationalLeaves.contactPersons'
                                      [required]='true'
    >
      <alimento-ipv-frontend-contact-person-select [contactPersons]='contactPersons'
                                                   [showSelectedPerson]='true'
                                                   [showLabels]='true'
                                                   [isBranchOnlyContactPerson]='true'
                                                   formControlName='contactPersonId'
      ></alimento-ipv-frontend-contact-person-select>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
     <p-button
        (onClick)="onSubmit()"
        [loading]="loading"
        label="{{ 'paidEducationalLeaves.selectContactPerson' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
