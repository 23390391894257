import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { EnrollmentRequestParticipant } from '../../../types/enrollment.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-request-participant-card',
    templateUrl: './enrollment-request-participant-card.component.html',
    standalone: false
})
export class EnrollmentRequestParticipantCardComponent implements OnChanges {
  @Input()
  participant: EnrollmentRequestParticipant;

  @Input()
  readOnly = false;

  @Input()
  valid = false;

  @Output()
  editClicked = new EventEmitter<void>();

  workStatus: string;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['participant']?.currentValue) {
      if (this.participant?.workStatusId) {
        this.referenceDataService.getWorkStatus(this.participant.workStatusId).pipe(first())
          .subscribe(workStatus => this.workStatus = workStatus.label);
      }
    }
  }

  edit(): void {
    this.editClicked.emit()
  }
}
