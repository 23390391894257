import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
    standalone: false
})
export class DurationPipe implements PipeTransform {

  transform(value: string | undefined): string | undefined {
    if (value && value.split(":")?.length === 3) {
      return value.split(":")[0] + ":" + value.split(":")[1];
    }
    return value;
  }

}
