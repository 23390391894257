<div *ngIf='paidEducationalLeaveAttest'
     (click)='onClick($event)'
     class='shadow-grey-2 shadow-md my-2 p-4 relative border border-input-border'
     [ngClass]='{
     "bg-primary-hover border-primary" : selected,
     "bg-white": !selected,
     "cursor-pointer hover:bg-primary-hover": clickable
     }'
>
  <div class='grid grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_minmax(0,_1fr)] gap-8'>
    <div class='flex flex-col items-start'>
      <span class='font-bold'>
        {{ paidEducationalLeaveAttest.alimentoId }} - {{ type }}
      </span>
      <div class='flex items-center gap-1'>
        <a [routerLink]='["/branches", paidEducationalLeaveAttest.employmentBranchId]'
           class='text-primary hover:underline cursor-pointer'>
          {{ paidEducationalLeaveAttest.employmentBranchName }}
        </a>
        <span *ngIf='!showPersonName'> - {{ workStatus }}</span>
      </div>
      <div *ngIf='showPersonName' class='flex items-center gap-1'>
        <a [routerLink]='["/persons", paidEducationalLeaveAttest.personId]'
           class='text-primary hover:underline cursor-pointer'>
          {{ paidEducationalLeaveAttest.personLastName }} {{ paidEducationalLeaveAttest.personFirstName }}
        </a>
        <span> - {{ workStatus }}</span>
      </div>
    </div>
    <div class='flex flex-col'>
      <span>{{ 'paidEducationalLeaves.startDate' |translate }}: {{ paidEducationalLeaveAttest.startDate | date : "dd/MM/yyyy" }}</span>
      <div class='flex items-center gap-2' *ngIf='paidEducationalLeaveAttest.mentorTraining'>
        <span>{{ 'paidEducationalLeaves.mentor' |translate }}</span>
      </div>
      <span>{{ educationalLeaveAttestTitle }}</span>
      <lib-documents *ngIf='paidEducationalLeaveAttest.files && paidEducationalLeaveAttest.files.length > 0'
                     [files]='paidEducationalLeaveAttest.files'
                     [showFilesAsList]='true'
                     [canAdd]='false'
      ></lib-documents>
    </div>
    <div class='flex flex-col items-end'>
      <div>
        <span class='font-bold text-primary'>{{ status }}</span>
        <span *ngIf='paidEducationalLeaveAttest.stateTransitionDate'>
          {{ "on" | translate }} {{ paidEducationalLeaveAttest.stateTransitionDate | date:"dd/MM/yyyy" }}
        </span>
      </div>

      <span>
        {{ 'paidEducationalLeaves.submissionDate' |translate }}
        : {{ paidEducationalLeaveAttest.submissionDate | date : "dd/MM/yyyy" }}</span>
      <span *ngIf='paidEducationalLeaveAttest.attestNeededRequestSentOn && !paidEducationalLeaveAttest.attestSentOn'>
        {{ 'paidEducationalLeaves.attestNeededRequestSentOn' |translate }}
        : {{ paidEducationalLeaveAttest.attestNeededRequestSentOn | date : "dd/MM/yyyy" }}
      </span>

      <span *ngIf='paidEducationalLeaveAttest.attestSentOn'>
        {{ 'paidEducationalLeaves.attestSentOn' |translate }}
        : {{ paidEducationalLeaveAttest.attestSentOn | date : "dd/MM/yyyy" }}
      </span>
    </div>
  </div>
</div>
