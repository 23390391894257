import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator, Validators
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'alimento-ipv-frontend-language-code',
    templateUrl: './language-code.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: LanguageCodeComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: LanguageCodeComponent
        }
    ],
    standalone: false
})
export class LanguageCodeComponent implements ControlValueAccessor, Validator, OnChanges, OnDestroy {
  @Input()
  showExtraLanguages = false;

  @Input()
  required = false;

  formControl: FormControl<string>;
  private subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder) {
    this.formControl = this.fb.control('');
    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['required']?.currentValue !== undefined) {
      this._setRequiredState();
      this.formControl.markAsDirty({emitEvent: false});
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
    else {
      this.formControl.reset(null, { emitEvent: false });
    }
  }

  validate(_: FormControl) {
    return this.formControl.valid ? null : { languageCode: { valid: false } };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl?.disable({ emitEvent: false });
    }
    else {
      this.formControl?.enable({ emitEvent: false });
    }
  }

  private _setRequiredState(): void {
    if (this.formControl) {
      this.formControl.setValidators(this.required ? [Validators.required] : []);
      this.formControl.updateValueAndValidity({emitEvent: false});
    }
  }
}
