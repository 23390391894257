import { NgModule } from '@angular/core';
import { PersonListComponent } from './pages/person-list/person-list.component';
import { PersonDetailComponent } from './pages/person-detail/person-detail.component';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { PersonCardComponent } from './components/person-card/person-card.component';
import { InszPipe } from './utils/insz.pipe';
import { PersonComponent } from './components/person/person.component';
import { EmploymentsComponent } from './components/employments/employments.component';
import { EmploymentListItemComponent } from './components/employment-list-item/employment-list-item.component';
import { EmploymentPopupComponent } from './components/employment-popup/employment-popup.component';
import { StopEmploymentDialogComponent } from './components/stop-employment-dialog/stop-employment-dialog.component';
import { PersonSelectComponent } from './components/person-select/person-select.component';
import { UtilsModule } from '../utils/utils.module';
import { TransitionsComponent } from './components/transitions/transitions.component';
import { TransitionPopupComponent } from './components/transition-popup/transition-popup.component';
import { TransitionCardComponent } from './components/transition-card/transition-card.component';
import {
  TransitionActionDialogComponent
} from './components/transition-action-dialog/transition-action-dialog.component';
import { PaidEducationalLeaveModule } from '../paid-educational-leaves/paid-educational-leave.module';

@NgModule({
  imports: [
    SharedModule,
    TableModule,
    UiLibModule,
    UtilsModule,
    PaidEducationalLeaveModule
  ],
  providers: [CanDeactivateGuard, ConfirmationService],
  declarations: [
    PersonListComponent,
    PersonDetailComponent,
    PersonCardComponent,
    InszPipe,
    PersonComponent,
    EmploymentsComponent,
    EmploymentListItemComponent,
    EmploymentPopupComponent,
    StopEmploymentDialogComponent,
    PersonSelectComponent,
    TransitionsComponent,
    TransitionPopupComponent,
    TransitionCardComponent,
    TransitionActionDialogComponent
  ],
  exports: [
    PersonCardComponent,
    PersonComponent,
    PersonSelectComponent,
    EmploymentPopupComponent,
    EmploymentListItemComponent,
    StopEmploymentDialogComponent,
    InszPipe
  ]
})
export class PersonsModule {
}
