import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CompleteTaskEvent, SearchCommunicationsItem } from '../../../types/communications.type';
import { TaskState } from '../../../types/communications.enum';

@Component({
    selector: 'alimento-ipv-frontend-complete-task-dialog',
    templateUrl: './complete-task-dialog.component.html',
    standalone: false
})
export class CompleteTaskDialogComponent implements OnDestroy {
  communication: SearchCommunicationsItem;

  @Output()
  submitPopup = new EventEmitter<CompleteTaskEvent>();

  dialogVisible = false;
  formGroup: FormGroup;
  loading = false;
  linkedTasks: { [p: string]: any }[];
  nrOfSelectedTasks = 0;

  private _subscription: Subscription;

  constructor(private fb: FormBuilder, private translateService: TranslateService) {
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  open(communication: SearchCommunicationsItem): void {
    this.communication = communication;
    this._createFormGroup();
    this._createListWithOptions();
    this.dialogVisible = true;
  }

  onSubmit(): void {
    this.submitPopup.emit({
      data: JSON.parse(JSON.stringify(this.formGroup.value)),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset({taskIds: []});
  }

  getLabel = (item: any) => {
    return item.label;
  }

  private _createFormGroup(): void {
    this.formGroup = this.fb.group({
      taskIds: [[]],
      comments: [""]
    });

    this._subscription?.unsubscribe();
    this._subscription = this.formGroup.get("taskIds").valueChanges
      .subscribe(newValue => this.nrOfSelectedTasks = newValue.length);
  }

  private _createListWithOptions(): void {
    this.linkedTasks = [];
    if (this.communication.linkedTask?.statusId === TaskState.Open) {
      this.linkedTasks.push({
        id: this.communication.linkedTask.id,
        label: this.translateService.instant("communications.errorWithSending"),
        subText: this.communication.linkedTask.description
      })
    }
    else {
      this.communication.to
        .filter(contact => contact.linkedTask?.statusId === TaskState.Open)
        .forEach(contact => this.linkedTasks.push({
          id: contact.linkedTask.id,
          label: `${this.translateService.instant("communications.to")}: ${contact.name} <${contact.email}>`,
          subText: contact.linkedTask.description
        }));

      this.communication.cc
        .filter(contact => contact.linkedTask?.statusId === TaskState.Open)
        .forEach(contact => this.linkedTasks.push({
          id: contact.linkedTask.id,
          label: `${this.translateService.instant("communications.cc")}: ${contact.name} <${contact.email}>`,
          subText: contact.linkedTask.description
        }));

      this.communication.bcc
        .filter(contact => contact.linkedTask?.statusId === TaskState.Open)
        .forEach(contact => this.linkedTasks.push({
          id: contact.linkedTask.id,
          label: `${this.translateService.instant("communications.bcc")}: ${contact.name} <${contact.email}>`,
          subText: contact.linkedTask.description
        }));
    }
  }
}
