import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BranchListItem } from '../../../types/searches.type';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { BranchActionEnum } from '../../../types/branch.enum';
import { BranchAction } from '../../../types/branch.type';

@Component({
    selector: 'alimento-ipv-frontend-enterprise-branch-card',
    templateUrl: './enterprise-branch-card.component.html',
    standalone: false
})
export class EnterpriseBranchCardComponent implements OnChanges {

  @Input()
  branch: BranchListItem;

  @Input()
  readOnly = false;

  @Output()
  actionClicked = new EventEmitter<BranchAction>();

  actions: MenuItem[] = [];

  constructor(private translate: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branch']?.currentValue) {
      this.actions = this.getActions();
    }
  }

  private _getMenuItem(
    translateKey: string,
    action: BranchActionEnum,
    disabled = false,
    icon?: string
  ): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled,
      command: () => this.actionClicked.emit({ action: action, branch: this.branch })
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px'
      };
    }

    return menuItem;
  }

  getActions(): MenuItem[] {
    if (this.readOnly) {
      return [];
    }

    return [
      this._getMenuItem(
        'enterprises.branches.setAsMainBranch',
        BranchActionEnum.setAsMainBranch,
        this.branch.isMainBranch,
        'pi pi-star'
      )
    ];
  }

}
