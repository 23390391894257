import { Component, Input, OnDestroy, AfterViewChecked } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { NavigationService } from '../../services/navigation.service';
import { NavbarService } from '../../services/navbar.service';

@Component({
    selector: 'alimento-ipv-frontend-detail-page-layout',
    templateUrl: './detail-page-layout.component.html',
    standalone: false
})
export class DetailPageLayoutComponent implements OnDestroy, AfterViewChecked {
  @Input()
  header?: string;

  @Input()
  fallbackUrl = '/';

  @Input()
  navigationQueryParams: any;

  @Input()
  navigateText = 'back';

  @Input()
  breadcrumb: MenuItem[];

  smallSize = false;
  topStyling: { [p: string]: any } | null | undefined;
  private _subscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver,
              private navigationService: NavigationService,
              private navbarService: NavbarService) {
    this._subscription = this.breakpointObserver
      .observe(['(min-width: 1344px)', '(min-width: 1000px)'])
      .pipe(distinctUntilChanged())
      .subscribe(() => this.smallSize = !this.breakpointObserver.isMatched('(min-width: 1340px)'));
  }

  ngAfterViewChecked(): void {
    if (this.navbarService.getNavigationBarElement()) {
      setTimeout(() => {
        this.topStyling = {top: this.navbarService.getNavigationBarElement().offsetHeight + "px"};
      });
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  navigateBack(event: MouseEvent): void {
    this.navigationService.back(this.fallbackUrl, false, event.ctrlKey, false);
  }
}
