<div>
  <div class='w-full'>
    <div *ngFor='let training of data'>
      <div class='w-full hover:bg-primary-hover cursor-pointer p-2'
           (click)='collapsed[training.trainingId] = !collapsed[training.trainingId]'>
        <div class='grid grid-cols-[20px_minmax(100px,_1fr)_150px_100px_150px_150px] items-center gap-6'>
          <i class="pi pi-angle-{{ !collapsed[training.trainingId] ? 'up' : 'down' }}"></i>
          <div>
            <a class='text-primary hover:underline cursor-pointer font-bold' (click)='onTrainingClick(training, $event)'>
              {{ training.alimentoId }} - {{ training.title }} <span class='font-normal text-text'>({{ training.sessions.length }})</span>
            </a>
          </div>

          <span class='justify-self-end'>{{"trainings.sessions.totalDuration" | translate}} {{ training.totalHours | duration }}</span>
          <div>
            <span *ngIf='training.mentor'>{{ 'paidEducationalLeaves.mentor' | translate }}</span>
          </div>
          <a class='flex items-center gap-2 text-primary cursor-pointer hover:underline'
             *ngIf='training.documentSetUrl' href='{{training.documentSetUrl}}' target='_blank'>
            <i class='font-material'>open_in_new</i>
            {{ 'documents' | translate }}
          </a>

          <div *ngIf="training.canValidate"
               class='flex items-center justify-between gap-2 w-full'>
            <p-button label="{{ 'paidEducationalLeaves.actions.validated' | translate }}"
                      [link]='true'
                      styleClass='whitespace-nowrap pl-0'
                      (onClick)='trainingValidatedToggle.writeValue(!trainingValidatedToggle.checked()); $event.stopImmediatePropagation()'
            ></p-button>
            <p-toggle-switch #trainingValidatedToggle [(ngModel)]='training.validated'
                             (click)='$event.stopImmediatePropagation()'
                             (onChange)='trainingToggled(training.trainingId, trainingValidatedToggle.checked())'
            ></p-toggle-switch>
          </div>
        </div>
      </div>

      <div *ngIf='!collapsed[training.trainingId]'>
        <div *ngFor='let session of training.sessions' class='ml-[2.75rem] p-2'>
          <div class='grid grid-cols-[minmax(100px,_1fr)_150px_272px_150px] items-center gap-6'
               [ngClass]='{"text-grey-3": !session.canValidate}'
          >
            <div class='flex items-center gap-4'>
              <span>{{ session.registeredDate | date: "dd/MM/yyyy" }}</span>
              <span *ngIf='session.sessionStartTime'>{{session.sessionStartTime}} - {{session.sessionEndTime}}</span>
            </div>
            <span class='justify-self-end'>{{"paidEducationalLeaves.sessionDuration" | translate}}: {{ session.registeredHours | duration }}</span>
            <div class='flex items-center gap-1 flex-nowrap'>
              <span *ngIf='!session.isAbsent'>{{'persons.present' | translate}}</span>
              <span *ngIf='session.isAbsent'>{{'persons.absent' | translate}}</span>
              <span *ngIf='session.isAbsent'>- {{getReason(session.reasonForAbsence) | async}}</span>
            </div>
            <div *ngIf='!session.canValidate' class='justify-self-end'>
              <span>{{ getHourStatus(session.paidEducationalLeaveHourStatusId) | async }}</span>
            </div>

            <div *ngIf='session.canValidate'
                 class='flex items-center justify-between gap-2 w-full'>
              <p-button label="{{ 'paidEducationalLeaves.actions.validated' | translate }}"
                        [link]='true'
                        styleClass='whitespace-nowrap pl-0'
                        (onClick)='sessionValidatedToggle.writeValue(!sessionValidatedToggle.checked()); $event.stopImmediatePropagation()'
              ></p-button>
              <p-toggle-switch #sessionValidatedToggle
                               [(ngModel)]='session.validated'
                               (onChange)='sessionToggled(training.trainingId, session.id, sessionValidatedToggle.checked())'
              ></p-toggle-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
