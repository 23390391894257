<div class="w-full flex items-center justify-between px-8 py-0 bg-white border-b-grey-1 border-b">
  <div class="flex items-center flex-wrap gap-x-8 gap-y-1">
    <a [routerLink]='["/"]'>
      <img src="/assets/images/logo.svg" class="cursor-pointer h-4" alt="Logo Alimento" />
    </a>
    <span
      *ngIf="!environment.production"
      class="p-3 px-8 {{ environment.color }} bg-opacity-50 text-white rounded ml-4"
    >
      DALI - {{ environment.environmentName | uppercase }}
    </span>

<!--    <alimento-ipv-frontend-app-menu [menuItems]="appMenuItems"></alimento-ipv-frontend-app-menu>-->
  </div>

  <div class="flex items-center gap-4">
    <alimento-ipv-frontend-app-menu [menuItems]="appMenuItems"></alimento-ipv-frontend-app-menu>
    <alimento-ipv-frontend-user-menu></alimento-ipv-frontend-user-menu>
  </div>
</div>
