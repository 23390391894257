import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, of, Subscription } from 'rxjs';
import {
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest, TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { BranchListItem } from '../../../types/searches.type';
import { SearchesService } from '../../../services/searches.service';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';

@Component({
    selector: 'alimento-ipv-frontend-branch-list',
    templateUrl: './branch-list.component.html',
    standalone: false
})
export class BranchListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<BranchListItem>;
  searchFilters: SearchFilter[];
  private _subscription: Subscription;

  constructor(private branchesMapper: BranchesMapper,
              private searchesService: SearchesService,
              private translationService: TranslationService) {
  }

  ngOnInit(): void {
    this._subscription = this.translationService.languageChange$.subscribe(() =>
      this._createSearch()
    )
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    this.searchData$ = new InfiniteScrollDataAdapter<BranchListItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<BranchListItem>> => {
      return this.searchesService.searchBranches(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.status',
        key: FilterType.filterBranchState,
        data: this.branchesMapper.getStatesFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.category',
        key: FilterType.filterBranchCategory,
        hasIcon: true,
        iconMapper: (value) => this.branchesMapper.getCategoryOptions()
          .filter(category => category.value === value)[0].icon,
        data: of(this.branchesMapper.getCategoryOptions(false))
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterBranchCategory,
              label: type.label,
              value: type.value
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'branches.numberOfEmployees',
        key: FilterType.filterNumberEmployees,
        data: this.branchesMapper.getNumberOfEmployeesOptions()
      }
    ];
  }
}
