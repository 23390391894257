import { NgModule } from '@angular/core';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService } from 'primeng/api';
import {
  ReimbursementRequestListComponent
} from './pages/reimbursement-request-list/reimbursement-request-list.component';
import {
  ReimbursementRequestDetailComponent
} from './pages/reimbursement-request-detail/reimbursement-request-detail.component';
import { ReimbursementRequestComponent } from './components/reimbursement-request/reimbursement-request.component';
import { UtilsModule } from '../utils/utils.module';
import { BranchesModule } from '../branches/branches.module';
import { PersonsModule } from '../persons/persons.module';
import { SessionsModule } from '../sessions/sessions.module';
import { EnrollmentsModule } from '../enrollments/enrollments.module';
import { TrainingsModule } from '../trainings/trainings.module';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    BranchesModule,
    PersonsModule,
    SessionsModule,
    EnrollmentsModule,
    TrainingsModule
  ],
  providers: [ConfirmationService, CanDeactivateGuard],
  declarations: [
    ReimbursementRequestListComponent,
    ReimbursementRequestDetailComponent,
    ReimbursementRequestComponent
  ]
})
export class ReimbursementRequestsModule {
}
