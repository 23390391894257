import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NavbarService } from '../../services/navbar.service';

@Component({
    selector: 'alimento-ipv-frontend-overview-page-layout',
    templateUrl: './overview-page-layout.component.html',
    standalone: false
})
export class OverviewPageLayoutComponent implements AfterViewChecked {
  @Input()
  header: string;

  @ViewChild('#navigation-bar')
  navigationBar!: ElementRef<HTMLDivElement>;

  topStyling: { [p: string]: any } | null | undefined;

  constructor(private navbarService: NavbarService) {
  }

  ngAfterViewChecked(): void {
    if (this.navbarService.getNavigationBarElement()) {
      setTimeout(() => {
        this.topStyling = { top: this.navbarService.getNavigationBarElement().offsetHeight + "px" };
      });
    }
  }
}
