<alimento-ipv-frontend-session-popup
  [trainingSession]="currentSession()"
  [trainingMethodId]="trainingMethodId"
  [trainingType]='trainingType'
  [yearOfRequest]='yearOfRequest'
  #sessionPopupComponent
  (formSubmit)="onSessionPopupSubmit($event, sessionPopupComponent)"
></alimento-ipv-frontend-session-popup>

<alimento-ipv-frontend-cancel-session-dialog (cancelSessionConfirmed)="cancelSession($event)">
</alimento-ipv-frontend-cancel-session-dialog>

<alimento-ipv-frontend-register-absence-dialog
  #absenceDialog
  [readOnly]='readOnly'
  (submitPopup)="registerAbsence($event, absenceDialog)"
></alimento-ipv-frontend-register-absence-dialog>

<alimento-ipv-frontend-header>
  <div title class='flex gap-4 items-center'>
    <h2 class="text-xl text-text font-bold">{{ 'trainings.sessionsHeader' | translate }}</h2>
    <span>{{'trainings.sessions.totalDuration' | translate}}: {{totalDuration()}}</span>
  </div>
  <p-button
    *ngIf='!readOnly'
    [disabled]="addSessionDisabled"
    pTooltip="{{ !trainingId ? ('trainings.sessions.canNotAddSessions' | translate) : '' }}"
    (onClick)="addSession(sessionPopupComponent)"
    label="{{ 'trainings.addSession' | translate  }}"
    icon="pi pi-calendar-plus"
  ></p-button>
</alimento-ipv-frontend-header>

<div class="session-list">
  <alimento-ipv-frontend-session-list-item
    *ngFor="let session of sessions(); let i = index"
    [session]="session"
    [trainingStatus]="trainingStatus"
    [index]="i"
    [readOnly]='readOnly'
    [trainingType]='trainingType'
    (actionClicked)="sessionAction($event, sessionPopupComponent)"
  ></alimento-ipv-frontend-session-list-item>
  <div class="session-list--empty" *ngIf="sessions().length === 0">
    <span>{{ (trainingType === TRAINING_TYPE.TEMPLATE ? 'templates.noSessions' : 'trainings.noSessions') | translate }}</span>
  </div>
</div>
