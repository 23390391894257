import { NgModule } from '@angular/core';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { RouterLink, RouterModule } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MoreActionsComponent } from './components/more-actions/more-actions.component';
import { MenuModule } from 'primeng/menu';
import { DetailPageLayoutComponent } from './components/detail-page-layout/detail-page-layout.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MetadataItemComponent } from './components/metadata-item/metadata-item.component';
import { EllipsisDirective } from './utils/ellipsis.directive';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { HeaderComponent } from './components/header/header.component';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { LeaveConfirmDialogComponent } from './components/leave-confirm-dialog/leave-confirm-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { TextInputComponent } from './components/text-input/text-input.component';
import { InputTextModule } from 'primeng/inputtext';
import { AutofocusDirective } from './utils/auto-focus.directive';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { InitialsPipe } from './utils/initials.pipe';
import { ScrollTrackerDirective } from './utils/scroll-tracker.directive';
import { HideIfAllUnauthorizedDirective } from './utils/hide-if-all-unauthorized.directive';
import { CardViewComponent } from './components/card-view/card-view.component';
import { LazyDropdownComponent } from './components/lazy-dropdown/lazy-dropdown.component';
import { SkeletonModule } from 'primeng/skeleton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { LowerFirstLetterPipe } from './utils/lower-first-letter.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { SelectListComponent } from './components/select-list/select-list.component';
import { CheckboxModule } from 'primeng/checkbox';
import { JwtTokenPageComponent } from './pages/jwt-token-page/jwt-token-page.component';
import { CalendarModule } from 'primeng/calendar';
import { NoteComponent } from './components/note/note.component';
import { NotesSidebarComponent } from './components/notes-sidebar/notes-sidebar.component';
import { SidebarModule } from 'primeng/sidebar';
import { SearchComponent } from './components/search/search/search.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SearchBarFacetComponent } from './components/search/search-bar-facet/search-bar-facet.component';
import { ActiveFilterComponent } from './components/search/active-filter/active-filter.component';
import { DateFacetComponent } from './components/search/date-facet/date-facet.component';
import { MultiselectFacetComponent } from './components/search/multiselect-facet/multiselect-facet.component';
import { LazyItemFacetComponent } from './components/search/lazy-item-facet/lazy-item-facet.component';
import { CurrentFilterInfoComponent } from './components/search/current-filter-info/current-filter-info.component';
import { AccordionModule } from 'primeng/accordion';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoWidthDirective } from './utils/auto-width.directive';
import { BodyContentComponent } from './components/body-content/body-content.component';
import { RemoveIfFeatureOffDirective } from './utils/remove-if-feature-off.directive';
import { RemoveIfFeatureOnDirective } from './utils/remove-if-feature-on.directive';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SortFilterComponent } from './components/search/sort-filter/sort-filter.component';
import { AccountNumberPipe } from './utils/account-number.pipe';
import { HistoryComponent } from './components/history/history.component';
import { TimelineModule } from 'primeng/timeline';
import { DocumentsComponent } from './components/documents/documents.component';
import { DragAndDropDirective } from './utils/drag-and-drop.directive';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TreeSelectModule } from 'primeng/treeselect';
import { CardComponent } from './components/card/card.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { DurationPipe } from './utils/duration.pipe';
import { TextareaModule } from 'primeng/textarea';
import { TreeModule } from 'primeng/tree';
import { DatePicker } from 'primeng/datepicker';
import { PopoverModule } from 'primeng/popover';
import { IconField } from 'primeng/iconfield';
import { InputIcon } from 'primeng/inputicon';
import { SelectModule } from 'primeng/select';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { TabsModule } from 'primeng/tabs';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import { MessageModule } from 'primeng/message';
import { StepperModule } from 'primeng/stepper';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { OverviewPageLayoutComponent } from './components/overview-page-layout/overview-page-layout.component';
import { AutoFocus } from 'primeng/autofocus';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    DropdownModule,
    ToastModule,
    ButtonModule,
    RippleModule,
    RouterModule,
    ProgressSpinnerModule,
    MenuModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    InputNumberModule,
    DialogModule,
    InputTextModule,
    SkeletonModule,
    SelectButtonModule,
    TooltipModule,
    CheckboxModule,
    TextareaModule,
    SidebarModule,
    AccordionModule,
    CalendarModule,
    InputSwitchModule,
    OverlayPanelModule,
    TimelineModule,
    SplitButtonModule,
    TreeModule,
    DatePicker,
    PopoverModule,
    IconField,
    InputIcon,
    SelectModule,
    ToggleSwitchModule,
    TabsModule,
    MessageModule,
    AutoFocus
  ],
  declarations: [
    TabMenuComponent,
    ErrorPageComponent,
    LoaderComponent,
    MoreActionsComponent,
    DetailPageLayoutComponent,
    MetadataItemComponent,
    EllipsisDirective,
    TimePickerComponent,
    HeaderComponent,
    FormFieldComponent,
    LeaveConfirmDialogComponent,
    TextInputComponent,
    AutofocusDirective,
    UserMenuComponent,
    InitialsPipe,
    ScrollTrackerDirective,
    HideIfAllUnauthorizedDirective,
    CardViewComponent,
    LazyDropdownComponent,
    CurrentFilterInfoComponent,
    LowerFirstLetterPipe,
    SelectListComponent,
    JwtTokenPageComponent,
    NoteComponent,
    NotesSidebarComponent,
    SearchComponent,
    ToggleComponent,
    SearchBarFacetComponent,
    ActiveFilterComponent,
    DateFacetComponent,
    MultiselectFacetComponent,
    LazyItemFacetComponent,
    SortFilterComponent,
    DatePickerComponent,
    AutoWidthDirective,
    BodyContentComponent,
    RemoveIfFeatureOffDirective,
    RemoveIfFeatureOnDirective,
    AccountNumberPipe,
    HistoryComponent,
    DocumentsComponent,
    DragAndDropDirective,
    CardComponent,
    CardListComponent,
    DurationPipe,
    BreadcrumbComponent,
    OverviewPageLayoutComponent
  ],
  exports: [
    CommonModule,
    TabMenuComponent,
    ReactiveFormsModule,
    DropdownModule,
    TranslateModule,
    LoaderComponent,
    MoreActionsComponent,
    DetailPageLayoutComponent,
    MetadataItemComponent,
    EllipsisDirective,
    TimePickerComponent,
    HeaderComponent,
    FormFieldComponent,
    InputNumberModule,
    LeaveConfirmDialogComponent,
    TextInputComponent,
    AutofocusDirective,
    UserMenuComponent,
    InitialsPipe,
    ScrollTrackerDirective,
    HideIfAllUnauthorizedDirective,
    CardViewComponent,
    LazyDropdownComponent,
    SkeletonModule,
    SelectButtonModule,
    CurrentFilterInfoComponent,
    LowerFirstLetterPipe,
    TooltipModule,
    SelectListComponent,
    JwtTokenPageComponent,
    DialogModule,
    ButtonModule,
    CalendarModule,
    NoteComponent,
    NotesSidebarComponent,
    SearchComponent,
    ToggleComponent,
    DatePickerComponent,
    AutoWidthDirective,
    BodyContentComponent,
    RemoveIfFeatureOffDirective,
    RemoveIfFeatureOnDirective,
    AccountNumberPipe,
    HistoryComponent,
    DocumentsComponent,
    SplitButtonModule,
    TreeSelectModule,
    CardComponent,
    CardListComponent,
    SearchBarFacetComponent,
    DurationPipe,
    TextareaModule,
    RouterLink,
    PopoverModule,
    IconField,
    InputIcon,
    SelectModule,
    ToggleSwitchModule,
    TabsModule,
    ConfirmDialogModule,
    MultiSelectModule,
    CheckboxModule,
    RadioButtonModule,
    MenuModule,
    EditorModule,
    InputTextModule,
    FormsModule,
    TreeModule,
    InputMaskModule,
    AccordionModule,
    MessageModule,
    StepperModule,
    OverviewPageLayoutComponent,
    AutoFocus
  ],
  providers: [ConfirmationService]
})
export class UiLibModule {
}
