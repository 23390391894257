<p-toast></p-toast>

<p-toast key='multiple-errors'>
  <ng-template let-message pTemplate="message">
    <div class='w-full flex flex-col gap-2'>
      <span>{{"error.multiple-errors" | translate}}</span>
      <ul class='list-inside list-disc'>
        <li *ngFor='let error of message.data?.errors' [innerHTML]='error'></li>
      </ul>
    </div>
  </ng-template>
</p-toast>

<alimento-ipv-frontend-loader></alimento-ipv-frontend-loader>
<div *ngIf='ready'>
  <div class="sticky top-0 z-[500]" #navigationBar>
    <alimento-ipv-frontend-navbar></alimento-ipv-frontend-navbar>
  </div>
  <div class="flex flex-col justify-between" [ngStyle]="{ 'min-height': 'calc(100vh - 4em)' }">
    <div class="h-full flex-1">
      <router-outlet></router-outlet>
    </div>
    <alimento-ipv-frontend-footer></alimento-ipv-frontend-footer>
  </div>
</div>
