import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Reimbursement, ReimbursementAction } from '../../../types/reimbursement-request.type';
import {
  REIMBURSEMENT_ACTION_ENUM,
  REIMBURSEMENT_STATUS,
  REIMBURSEMENT_TYPE
} from '../../../types/reimbursement-request.enum';
import { TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { map, Observable, Subscription } from 'rxjs';
import { ReferenceDataService } from '@alimento-ipv-frontend/application-lib';

@Component({
    selector: 'alimento-ipv-frontend-reimbursement-card',
    templateUrl: './reimbursement-card.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ReimbursementCardComponent implements OnChanges, OnDestroy {
  @Input()
  reimbursement!: Reimbursement;

  @Input()
  readOnly = false;

  @Input()
  corrections: Reimbursement[];

  @Output()
  actionClicked = new EventEmitter<ReimbursementAction>();

  actions: {[key: string]: MenuItem[]} = {};

  protected readonly REIMBURSEMENT_STATUS = REIMBURSEMENT_STATUS;
  protected readonly REIMBURSEMENT_ACTION_ENUM = REIMBURSEMENT_ACTION_ENUM;
  protected readonly REIMBURSEMENT_TYPE = REIMBURSEMENT_TYPE;
  private _subscription: Subscription;

  constructor(private translate: TranslateService,
              private referenceDataService: ReferenceDataService,
              private translationService: TranslationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["reimbursement"]?.currentValue) {
      this._subscription?.unsubscribe();
      this._subscription = this.translationService.languageChange$.subscribe(() => {
        this._setActions();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _setActions(): void {
    this.actions = {};
    this.actions[this.reimbursement.reimbursementId] = this._getActions(this.reimbursement);
    this.corrections
      .forEach(correction => this.actions[correction.reimbursementId] = this._getActions(correction));
  }

  private _getActions(reimbursement: Reimbursement): MenuItem[] {
    if (this.readOnly) {
      return [];
    }

    const menuItems = [];
    if (reimbursement.statusId === REIMBURSEMENT_STATUS.CALCULATED) {
      menuItems.push({
        label: this.translate.instant('reimbursements.edit'),
        command: () => this.toggleAction(reimbursement, REIMBURSEMENT_ACTION_ENUM.edit)
      });

      const deleteItem = {
        label: this.translate.instant('reimbursements.delete'),
        icon: 'pi pi-trash',
        iconStyle: {
          position: 'absolute',
          right: '2px'
        },
        styleClass: "danger",
        command: () => this.toggleAction(reimbursement, REIMBURSEMENT_ACTION_ENUM.delete)
      };

      menuItems.push(deleteItem);
    }
    else if (reimbursement.statusId === REIMBURSEMENT_STATUS.CONFIRMED) {
      if (reimbursement.reimbursementTypeId !== REIMBURSEMENT_TYPE.CORRECTION &&
        this.corrections.every(correction => correction.statusId !== REIMBURSEMENT_STATUS.CALCULATED)) {
        menuItems.push({
          label: this.translate.instant('reimbursements.addCorrection'),
          command: () => this.toggleAction(reimbursement, REIMBURSEMENT_ACTION_ENUM.addCorrection)
        });
      }
    }

    return menuItems;
  }

  toggleAction(reimbursement: Reimbursement, action: REIMBURSEMENT_ACTION_ENUM, event?: MouseEvent): void {
    this.actionClicked.emit({ action: action, reimbursement: reimbursement });
    event?.stopImmediatePropagation();
  }

  getTransitionType(type: string): Observable<string> {
    return this.referenceDataService.getTransitionType(type).pipe(map(type => type.label));
  }
}
