<alimento-ipv-frontend-overview-page-layout header='trainings.menuTitle'>
  <ng-container top-right>
    <p-menu #menu [model]='addButtonItems' [popup]='true' appendTo='body'></p-menu>
    <p-button (click)="menu.toggle($event)"
              *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
              icon='pi pi-chevron-down'
              iconPos='right'
              label="{{ 'trainings.actions.newFormation' | translate }}"
    ></p-button>
  </ng-container>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              [sortItems]='sortFilters'
              [expandAll]='false'
              name='trainings-list'
              searchPlaceholderKey='trainings.searchbarPlaceholder'
              availableResultsTranslateKey='trainings.menuTitle'
  >
    <ng-template #cardTemplate let-training="item">
      <alimento-ipv-frontend-training-card [training]='training'>
      </alimento-ipv-frontend-training-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
