<alimento-ipv-frontend-purchase-entry-create-dialog #createDialog
                                                    [branches]='branches'
                                                    (submitPopup)='executeCreatePurchaseEntries($event, createDialog)'
></alimento-ipv-frontend-purchase-entry-create-dialog>

<alimento-ipv-frontend-approve-sales-invoice-dialog [salesInvoice]='currentSalesInvoice'
                                                    (approveSalesInvoice)='executeApproveSalesInvoice($event)'
>
</alimento-ipv-frontend-approve-sales-invoice-dialog>

<alimento-ipv-frontend-sales-invoice-popup (formSubmit)='salesInvoiceUpdate($event)'
                                           (changeSelectedInvoiceIndex)='changeSelectedInvoiceIndex($event)'
></alimento-ipv-frontend-sales-invoice-popup>

<alimento-ipv-frontend-header title="{{ 'trainings.invoices.menuTitle' | translate }}">
  <p-button
    *ngIf="showCreatePurchaseEntriesButton"
    (onClick)='createPurchaseEntries(createDialog)'
    label="{{ 'trainings.purchaseEntries.create' | translate  }}"
  ></p-button>
</alimento-ipv-frontend-header>

<p-tabs [(value)]='activeIndex'>
  <p-tablist>
    <p-tab value='0'>{{"trainings.salesInvoices.menuTitle" | translate }} ({{salesInvoices()?.length}})</p-tab>
    <p-tab value='1'>{{"trainings.purchaseEntries.menuTitle" | translate}}  ({{purchaseEntries()?.length}})</p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel value='0'>
      <div *ngIf="salesInvoices()">
        <div class='flex flex-col'>
          <div *ngFor='let salesInvoice of salesInvoices(); let index = index' class='border-b border-b-grey-1 last:border-none'>
            <alimento-ipv-frontend-sales-invoice-card
              [salesInvoice]='salesInvoice'
              [readOnly]='readOnly'
              (actionClicked)='onSaleInvoiceActionClicked(index, salesInvoice, $event)'
            ></alimento-ipv-frontend-sales-invoice-card>
          </div>
        </div>

        <div *ngIf='salesInvoices().length === 0'>{{ 'trainings.salesInvoices.noSalesInvoices' | translate }}</div>
      </div>
    </p-tabpanel>
    <p-tabpanel value='1'>
      <div *ngIf='purchaseEntries()'>
        <div class='flex flex-col'>
          <div *ngFor='let purchaseEntry of purchaseEntries()' class='border-b border-b-grey-1 last:border-none'>
            <alimento-ipv-frontend-purchase-entry-card
              [purchaseEntry]='purchaseEntry'
              [readOnly]='readOnly'
            ></alimento-ipv-frontend-purchase-entry-card>
          </div>
        </div>

        <div *ngIf='purchaseEntries().length === 0'>{{ 'trainings.purchaseEntries.noPurchaseEntries' | translate }}</div>
      </div>
    </p-tabpanel>
  </p-tabpanels>
</p-tabs>
