<alimento-ipv-frontend-employment-popup
  #employmentPopupComponent
  [employmentAction]='currentEmploymentAction'
  (formSubmit)="onEmploymentPopupSubmit($event, employmentPopupComponent)"
></alimento-ipv-frontend-employment-popup>

<alimento-ipv-frontend-stop-employment-dialog
  (stopEmploymentConfirmed)="stopEmployment($event)"
></alimento-ipv-frontend-stop-employment-dialog>

<alimento-ipv-frontend-header title="{{ 'persons.employmentsTitle' | translate }}">
  <p-button
    *ngIf='!readOnly'
    (onClick)="addEmployment(employmentPopupComponent)"
    label="{{ 'persons.employments.actions.add' | translate }}"
    icon="pi pi-map-marker"
  ></p-button>
</alimento-ipv-frontend-header>

<div class="employments-list" *ngIf="employments() as employments">
  <p-tabs [(value)]="employmentIndex">
    <p-tablist>
      <p-tab value='0'>{{ 'persons.employments.current' | translate }} ({{ employments.current.length }})</p-tab>
      <p-tab value='1'>{{ 'persons.employments.previous' | translate }}</p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value="0">
        <alimento-ipv-frontend-employment-list-item
          *ngFor="let employment of employments.current; let i = index"
          [readOnly]="readOnly"
          [employment]="employment"
          (actionClicked)="employmentAction($event, employmentPopupComponent)"
        ></alimento-ipv-frontend-employment-list-item>

        <div class="employments-list--empty" *ngIf="employments.current.length === 0">
          <span>{{ 'persons.employments.noCurrentEmployments' | translate }}</span>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">
        <alimento-ipv-frontend-employment-list-item
          *ngFor="let employment of employments.previous; let i = index"
          [readOnly]="readOnly"
          [employment]="employment"
          (actionClicked)="employmentAction($event, employmentPopupComponent)"
        ></alimento-ipv-frontend-employment-list-item>

        <div class="employments-list--empty" *ngIf="employments.previous.length === 0">
          <span>{{ 'persons.employments.noPreviousEmployments' | translate }}</span>
        </div>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</div>
