import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { first, Subscription } from 'rxjs';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { FinancialDocument, FinancialDocumentAction } from '../../../types/reimbursement-request.type';
import { FINANCIAL_DOCUMENT_ACTION_ENUM } from '../../../types/reimbursement-request.enum';

@Component({
    selector: 'alimento-ipv-frontend-financial-document-card',
    templateUrl: './financial-document-card.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FinancialDocumentCardComponent implements OnChanges, OnDestroy {
  @Input()
  financialDocument!: FinancialDocument;
  @Input()
  readOnly = false;

  @Output()
  actionClicked = new EventEmitter<FinancialDocumentAction>();

  showExtraInfo = false;
  actions: MenuItem[] = [];
  type: string;

  private _subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private translationService: TranslationService,
              private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['financialDocument']?.currentValue) {
      if (this.financialDocument.financialDocumentTypeId) {
        this.referenceDataService.getFinancialType(this.financialDocument.financialDocumentTypeId).pipe(first())
          .subscribe(type => this.type = type.label);
      }

      this._clearSubscriptions();
      this._subscriptions.push(
        this.translationService.languageChange$.subscribe(() => this.actions = this.getActions())
      );
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  toggleShowExtraInfo() {
    this.showExtraInfo = !this.showExtraInfo;
  }

  private _getMenuItem(
    translateKey: string,
    action: FINANCIAL_DOCUMENT_ACTION_ENUM,
    disabled = false,
    icon?: string
  ): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled: disabled,
      command: () => this.actionClicked.emit({ action: action, financialDocument: this.financialDocument })
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px'
      };
    }

    return menuItem;
  }

  getActions(): MenuItem[] {
    if (this.readOnly) {
      return [];
    }

    const menuItems = [
      this._getMenuItem('financialDocuments.edit', FINANCIAL_DOCUMENT_ACTION_ENUM.edit)
    ];

    const deleteItem = this._getMenuItem(
      'financialDocuments.delete',
      FINANCIAL_DOCUMENT_ACTION_ENUM.delete,
      false,
      'pi pi-trash'
    );
    deleteItem.styleClass = 'danger';
    menuItems.push(deleteItem);

    return menuItems;
  }
}
