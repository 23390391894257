import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  APP_CONFIG_TOKEN,
  AppConfig,
  FilterType,
  PaginatedResponse,
  SearchMapper
} from '@alimento-ipv-frontend/ui-lib';
import { InterimOffice, InterimOfficeListItem } from '../types/branch.type';

@Injectable({
  providedIn: 'root'
})
export class InterimOfficeService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getInterimOffice(interimOfficeId: string): Observable<InterimOffice> {
    return this.http.get<InterimOffice>(`${this.config.readApiUrl}/interimoffices/${interimOfficeId}`);
  }

  getInterimOffices(searchParams?: any): Observable<PaginatedResponse<InterimOfficeListItem>> {
    const params = SearchMapper.getParams(searchParams);
    if (params && params[FilterType.search] && params[FilterType.search][0]) {
      params[FilterType.searchName] = params[FilterType.search];
    }
    delete params[FilterType.search];
    return this.http.get<PaginatedResponse<InterimOfficeListItem>>(`${this.config.readApiUrl}/interimoffices`, { params: params });
  }

  createInterimOffice(interimOffice: InterimOffice): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/interimoffices/`, interimOffice);
  }

  updateInterimOffice(interimOfficeId: string, interimOffice: InterimOffice): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/interimoffices/${interimOfficeId}`, interimOffice);
  }
}
