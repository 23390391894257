<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '54rem' }"
  appendTo="body"
  header="{{ 'trainings.sessions.registerAbsenceDialog.title' | translate }}"
>
  <div *ngIf="dialogVisible" [formGroup]="formGroup" class="register-absence-dialog">
    <div class="flex gap-4">
      <div class="flex-grow">
        <div class="w-full flex items-center p-4 font-bold border-b border-b-grey-3 pr-4">
          <span class="w-1/2">{{ 'trainings.sessions.registerAbsenceDialog.participants' | translate }}</span>
          <span class="w-1/2">{{ 'trainings.sessions.registerAbsenceDialog.absent' | translate }}</span>
        </div>

        <div class="w-full p-4 bg-grey-0 border-b border-b-grey-2 flex justify-between items-center">
          <input
            pInputText
            [formControl]="searchParticipantsControl"
            [placeholder]="'trainings.sessions.registerAbsenceDialog.searchParticipant' | translate"
          />
          <p-button
            icon="pi pi-filter-slash"
            (click)="clearFilters()"
            [pTooltip]="'clearFilters' | translate"
            tooltipPosition="left"
            [text]='true'
            severity='danger'
          ></p-button>
        </div>
        <div class="w-full border-b border-b-grey-3 overflow-y-auto max-h-72" formArrayName="absences">
          <ng-container *ngFor="let scheduleForm of participants.controls; let i = index">
            <div *ngIf="matchingIds.includes(i)" class="p-4 not-last:border-b border-b-grey-2">
              <div [formGroupName]="i" class="flex items-center min-h-[2.4rem]">
                <div class="w-1/2">
                  {{ scheduleForm.get('lastName').value }} {{ scheduleForm.get('firstName').value }}
                </div>
                <div class="flex items-center gap-4 w-1/2">
                  <p-checkbox [binary]="true" formControlName="absent"></p-checkbox>
                  <div *ngIf='!readOnly'>
                    <p-select
                      *ngIf="scheduleForm.get('absent').value === true"
                      [options]="absenceReasons"
                      formControlName="reasonForAbsenceId"
                      optionValue="data"
                      optionLabel="label"
                      [placeholder]="'trainings.sessions.registerAbsenceDialog.reasonPlaceholder' | translate"
                      appendTo="body"
                      styleClass="w-full"
                    ></p-select>
                  </div>
                  <span *ngIf='readOnly'>
                    {{getReasonLabel(scheduleForm.get('reasonForAbsenceId')?.value)}}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="bg-grey-0 w-52 flex flex-col gap-4 p-4">
        <alimento-ipv-frontend-metadata-item
          *ngIf="session.title"
          [header]="'trainings.sessions.registerAbsenceDialog.session' | translate"
          [value]="session.title"
        >
        </alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item
          [header]="'trainings.sessions.registerAbsenceDialog.sessionDate' | translate"
          [value]="session.date | date : 'YYYY-MM-dd'"
        >
        </alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item
          *ngIf="session.startTime"
          [header]="'trainings.sessions.registerAbsenceDialog.sessionStartTime' | translate"
          [value]="session.startTime"
        >
        </alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item
          *ngIf="session.endTime"
          [header]="'trainings.sessions.registerAbsenceDialog.sessionEndTime' | translate"
          [value]="session.endTime"
        >
        </alimento-ipv-frontend-metadata-item>
      </div>
    </div>

    <div class="w-full p-4 field-checkbox">
      <p-checkbox
        formControlName="absenceRegistrationComplete"
        [binary]="true"
        inputId='absenceRegistrationComplete'
      ></p-checkbox>
      <label for='absenceRegistrationComplete'>{{'trainings.sessions.registerAbsenceDialog.isComplete' | translate}}</label>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="onSubmit()"
        [loading]="loading"
        label="{{ 'trainings.sessions.registerAbsenceDialog.submit' | translate }} ({{ nrOfAbsences }})"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.sessions.registerAbsenceDialog.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
