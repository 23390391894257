import {Component, Input} from '@angular/core';
import {MenuItem} from "primeng/api";
import {NavigationService} from "../../services/navigation.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  standalone: false
})
export class BreadcrumbComponent {

  @Input()
  items: MenuItem[] = []

  constructor(private navigationService: NavigationService) { }

  itemClicked(menuItem: MenuItem, event: MouseEvent) {
    if (menuItem.url) {
      this.navigationService.back(menuItem.url, true,  event.ctrlKey, true);
    }
  }

  getItemClass(item: MenuItem, last: boolean) {
    let result = last ? 'font-bold' : 'hover:text-primary';
    if (item.url || item.routerLink) {
      result += " cursor-pointer";
    }
    return result;
  }
}
