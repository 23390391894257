import { NgModule } from '@angular/core';
import { CommunicationListComponent } from './pages/communication-list/communication-list.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { CommunicationCardComponent } from './components/communication-card/communication-card.component';
import { CompleteTaskDialogComponent } from './components/complete-task-dialog/complete-task-dialog.component';
import {
  PageDetailCommunicationsComponent
} from './components/page-detail-communications/page-detail-communications.component';

@NgModule({
  declarations: [
    CommunicationListComponent,
    CommunicationCardComponent,
    CompleteTaskDialogComponent,
    PageDetailCommunicationsComponent
  ],
  imports: [
    SharedModule,
    UiLibModule
  ],
  providers: [CanDeactivateGuard, ConfirmationService],
  exports: [
    CommunicationCardComponent,
    CompleteTaskDialogComponent,
    PageDetailCommunicationsComponent
  ]
})
export class CommunicationsModule {

}
