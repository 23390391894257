<p-dialog
  *ngIf='dialogVisible'
  (blur)="dialogVisible"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.cloneDialog.title' | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="training">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.cancelDialog.labels.trainingName" | translate}}'
        [value]='training.customTitle'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.cancelDialog.labels.firstSessionDate" | translate}}'
        [value]='firstSessionDate ? (firstSessionDate | date:"dd/MM/yyyy") : "/"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="cloneTrainingForm" class='flex flex-col gap-4 mb-4'>
    <span>{{'trainings.cloneDialog.info' | translate}}</span>

    <div class='field-checkbox'>
      <p-checkbox
        [binary]="true"
        formControlName="includeSessions"
        inputId='includeSessions'
      ></p-checkbox>
      <label for='includeSessions'>{{ 'trainings.cloneDialog.includeSessions' | translate }}</label>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="clone()"
        [loading]='loading'
        label="{{ 'trainings.cloneDialog.buttons.cloneTraining' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
