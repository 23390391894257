<p-dialog
  (blur)="dialogVisible"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '21rem' }"
  [closable]='false'
  appendTo="body"
  header="{{ 'validation.saveChangesTitle' | translate }}"
>
  <div>
    <span>{{'validation.saveChangesLong' | translate}}</span>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-col gap-2 w-full">
      <p-button
        #saveButton
        styleClass='w-full'
        label="{{ 'validation.saveChanges' | translate }}"
        loadingIcon="pi pi-spin pi-spinner"
        (click)="saveChanges()"
        [loading]="saving"
        [style]="{width: '100%'}"
      ></p-button>
      <p-button
        (onClick)="cancelChanges()"
        [style]="{width: '100%'}"
        label="{{ 'validation.dontSaveChanges' | translate }}"
        variant='text'
        styleClass='w-full'
      ></p-button>

      <p-button
        (onClick)="cancel()"
        [style]="{width: '100%'}"
        label="{{ 'validation.stayOnPage' | translate }}"
        variant='text'
        styleClass='w-full'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
