import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { Subscription } from 'rxjs';
import {
  EnrollmentActionEnum,
  EnrollmentBody,
  EnrollmentDetail,
  EnrollmentUpdateEvent
} from '../../../types/enrollment.type';
import { EnrollmentExtraFormComponent } from '../enrollment-extra-form/enrollment-extra-form.component';
import { ENROLLMENT_VIA } from '../../../types/enrollment.enum';
import { EnrollmentCommentsFormComponent } from '../enrollment-comments-form/enrollment-comments-form.component';
import { Training } from '../../../types/training.type';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-request-participant-popup2',
    templateUrl: './enrollment-request-participant-popup2.component.html',
    standalone: false
})
export class EnrollmentRequestParticipantPopup2Component implements OnDestroy {
  @Input()
  readOnly = false;

  @Input()
  training: Training;

  @Input()
  groupId?: string;

  @Input()
  enterpriseId?: string;

  @Input()
  trainingStartDate?: Date;

  @Output()
  formSubmit = new EventEmitter<EnrollmentUpdateEvent>();

  popupVisible = false;
  formGroup: FormGroup;
  loading = false;

  enrollment: EnrollmentDetail;
  enrollmentActionEnum?: EnrollmentActionEnum;

  private _subscriptions: (Subscription | undefined)[] = [];
  protected readonly ENROLLMENT_VIA = ENROLLMENT_VIA;
  private _titleMap: Map<any, any>;

  @ViewChild(EnrollmentExtraFormComponent)
  extraFormComponent: EnrollmentExtraFormComponent;

  constructor(private formBuilder: FormBuilder,
              private messageService: MyMessageService) {
    this._createTitleMap();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  private _createTitleMap(): void {
    this._titleMap = new Map();
    this._titleMap.set(EnrollmentActionEnum.view, {
      title: 'enrollments.viewEnrollment',
      complete: 'enrollments.close'
    });
    this._titleMap.set(EnrollmentActionEnum.create, {
      title: 'enrollments.addEnrollment',
      complete: 'enrollments.completeAddEnrollment'
    });
    this._titleMap.set(EnrollmentActionEnum.edit, {
      title: 'enrollments.editEnrollment',
      complete: 'enrollments.completeEditEnrollment'
    });
    this._titleMap.set(EnrollmentActionEnum.reEnroll, {
      title: 'enrollments.reEnrollParticipant',
      complete: 'enrollments.reEnrollParticipant'
    });
    this._titleMap.set(EnrollmentActionEnum.addToReserve, {
      title: 'enrollments.addParticipantAsBackup',
      complete: 'enrollments.addParticipantAsBackup'
    });
  }

  get titleObject(): { title: string, complete: string } {
    return this._titleMap.get(this.enrollmentActionEnum);
  }

  openPopup(enrollment: EnrollmentDetail, enrollmentActionEnum: EnrollmentActionEnum): void {
    this.enrollment = enrollment;
    this.enrollmentActionEnum = enrollmentActionEnum;
    this._createFormGroup();
    this.popupVisible = true;
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);

    return this.extraFormComponent.isValid() && this.formGroup.valid;
  }

  getFormData(): EnrollmentBody {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    return {
      person: {
        personId: this.enrollment.person?.personId,
        firstName: this.enrollment.person?.firstName,
        lastName: this.enrollment.person?.lastName,
        dateOfBirth: this.enrollment.person?.dateOfBirth ? new Date(this.enrollment.person?.dateOfBirth) : null,
        gender: this.enrollment.person?.gender?.data,
        language: this.enrollment.person?.language,
        nationality: this.enrollment.person?.nationality,
        nationalIdentificationNumber: this.enrollment.person?.nationalIdentificationNumber,
        educationLevel: this.enrollment.person?.educationLevel?.data,
        phoneNumber: this.enrollment.person?.phoneNumber,
        email: this.enrollment.person?.email,
        street: this.enrollment.person?.street || "",
        houseNumber: this.enrollment.person?.houseNumber || "",
        mailbox: this.enrollment.person?.mailbox || "",
        city: this.enrollment.person?.city || "",
        postalCode: this.enrollment.person?.postalCode || "",
        country: this.enrollment.person?.country || ""
      } as any,
      enrollment: {
        ...this.enrollment.enrollment,
        ...data.extraForm.enrollment,
        branchId: data.extraForm.employment.employer?.branchId || this.enrollment.enrollment.branch.data
      },
      employment: data.extraForm?.employment
    };
  }

  submit(): void {
    this._submitForm();
  }

  private _submitForm(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      enrollment: this.getFormData(),
      enrollmentId: this.enrollment?.enrollment.enrollmentId,
      action: this.enrollmentActionEnum,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      personForm: { ...this.enrollment.person },
      extraForm: EnrollmentExtraFormComponent.createFormData(this.enrollment),
      commentsForm: EnrollmentCommentsFormComponent.createFormData(this.enrollment)
    });

    if (this.readOnly) {
      this.formGroup.disable();
    }
    else {
      this.formGroup.enable();
    }
  }
}
