import { AfterViewChecked, Component, Input, OnChanges, SimpleChanges, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Editor } from 'primeng/editor';
import { FormComponent, HtmlUtils, maxLengthRichText } from '@alimento-ipv-frontend/ui-lib';
import { delay } from 'rxjs';
import { conditionallyRequiredValidator } from '../../utils/conditionally-required-validator';
import { Training } from '../../../types/training.type';
import { TrainingTemplate } from '../../../types/training-template.type';
import {
  isEventType, isPupilProjectType, isTeacherProjectType,
  TRAINING_TYPE
} from '../../../types/reference-data.enum';

@Component({
    selector: 'alimento-ipv-frontend-summary-description',
    templateUrl: './summary-description.component.html',
    styleUrls: ['./summary-description.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SummaryDescriptionComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: SummaryDescriptionComponent
        }
    ],
    standalone: false
})
export class SummaryDescriptionComponent extends FormComponent implements OnChanges, AfterViewChecked, ControlValueAccessor, Validator {
  @Input()
  showRequiredFieldErrors = false;

  @Input()
  trainingType: TRAINING_TYPE;

  @ViewChildren(Editor)
  editors: Editor[];

  private quillEditorsInit = false;

  constructor(private fb: FormBuilder, private htmlUtils: HtmlUtils) {
    super();
    this._createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['trainingType']?.currentValue) {
      this._createFormGroup();
    }

    if (this.formGroup && changes['showRequiredFieldErrors']) {
      for (const [key, _] of Object.entries(this.formGroup.controls)) {
        this.formGroup.get(key)?.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  ngAfterViewChecked() {
    if (this.quillEditorsInit) {
      return;
    }
    this.editors.forEach(editor => {
      editor.getQuill()?.editor.scroll.domNode.addEventListener('blur', (event: FocusEvent) => {
        if (!editor.el.nativeElement.contains(event.relatedTarget)) {
          editor.getQuill().root.classList.remove('focused');
          const value = editor.getQuill().scroll.domNode.innerHTML;
          editor.onTextChange.emit({
            source: 'onBlur',
            textValue: value,
            htmlValue: value,
            delta: editor.getQuill()?.editor?.delta
          });
        }
      });
      if (editor.getQuill()) {
        this.quillEditorsInit = true;
      }
    });
  }

  public static createFormData(training: Training | TrainingTemplate): any {
    return {
      summary: training.summary,
      description: training.description,
      smeBrochure: !!training.smeBrochure,
      certificateSummary: training.certificateSummary
    };
  }

  override writeValue(value: any): void {
    if (value) {
      this.formGroup.patchValue(value, { emitEvent: false });
      this.formGroup.get('description').setValue(this.htmlUtils.sanitize(value.description || ''), { emitEvent: false });
      this.formGroup.get('certificateSummary')?.setValue(this.htmlUtils.sanitize(value.certificateSummary || ''), { emitEvent: false });
    }
  }

  validate(): ValidationErrors | null {
    return (!this.hasErrors() && this.allRequiredFieldsFilled(this.formGroup) === null) ? null : { summaryDescriptionInvalid: true };
  }

  hasErrors(): boolean {
    if (this.formGroup.disabled) {
      return false;
    }

    let isValid = true;
    Object.keys(this.formGroup.controls).forEach(field => isValid = isValid && this.formGroup.get(field).valid);
    return !isValid;
  }

  override isFieldInvalid(field: string): boolean {
    return !this.formGroup.get(field).disabled && !this.formGroup.get(field).valid && this.showRequiredFieldErrors;
  }

  editorSelectionChange(event: any, editor: Editor): void {
    if (event.range === null && event.oldRange !== null) {
      editor.quill.root.classList.remove('focused');
    }
    else if (event.range !== null && event.oldRange === null) {
      editor.quill.root.classList.add('focused');
    }
  }

  textChange(controlName: string, event: any): void {
    if (event.source === 'onBlur' || event.delta?.ops?.length > 3 || event.htmlValue?.indexOf('<img') >= 0) {
      setTimeout(() => this.formGroup.get(controlName)?.setValue(this.htmlUtils.sanitize(event.htmlValue)));
    }
  }

  private _createFormGroup(): void {
    const fields = {
      summary: ['', Validators.maxLength(4000)],
      description: ['', [
        conditionallyRequiredValidator(() => this.showRequiredFieldErrors),
        maxLengthRichText(4000, this.htmlUtils)]],
      certificateSummary: ['', maxLengthRichText(4000, this.htmlUtils)],
      smeBrochure: [false]
    };

    if (isEventType(this.trainingType) || isPupilProjectType(this.trainingType) || isTeacherProjectType(this.trainingType)) {
      delete fields.certificateSummary;
      delete fields.smeBrochure;
    }
    if (isEventType(this.trainingType)) {
      delete fields.summary;
    }

    this.formGroup = this.fb.group(fields, { validators: this.allRequiredFieldsFilled });

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe((value) => {
        const result = JSON.parse(JSON.stringify(value));
        result.description = this.htmlUtils.convertQuillListsToHtml(value.description || '');
        if (result.certificateSummary) {
          result.certificateSummary = this.htmlUtils.convertQuillListsToHtml(value.certificateSummary || '');
        }
        this.onChange(result);
        this.onTouched();
      })
    );
  }

  private allRequiredFieldsFilled(control: AbstractControl): ValidationErrors | null {
    const controlValue = control.value;
    const isValid = controlValue?.description;

    return isValid ? null : { required: true };
  }
}
