import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'insz',
    standalone: false
})
export class InszPipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (value && value.length === 11) {
      return value.replace(/^([0-9][0-9])([0-9][0-9])([0-9][0-9])([0-9][0-9][0-9])([0-9][0-9])$/, '$1.$2.$3-$4.$5');
    }
    return value;
  }
}
