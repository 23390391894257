import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private navigationBarElement: HTMLDivElement | null = null;

  setNavigationBarElement(element: HTMLDivElement): void {
    this.navigationBarElement = element;
  }

  getNavigationBarElement(): HTMLDivElement | null {
    return this.navigationBarElement;
  }
}
