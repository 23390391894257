import { CSP_NONCE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { APP_CONFIG_TOKEN, LocaleProvider, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment, PrimeStyling } from '@alimento-ipv-frontend/environments';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AcceptLanguageInterceptor } from '../interceptors/accept-language.interceptor';
import { ErrorHandlingInterceptor } from '../interceptors/error-handling.interceptor';
import { ETagInterceptor } from '../interceptors/e-tag.interceptor';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl-BE';
import localeFR from '@angular/common/locales/fr-BE';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { AppMenuComponent } from '../components/app-menu/app-menu.component';
import { FooterComponent } from '../components/footer/footer.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { Menubar } from 'primeng/menubar';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
registerLocaleData(localeNL, 'nl');
registerLocaleData(localeFR, 'fr');

const nonce = (
  document.querySelector('meta[name="CSP_NONCE"]') as HTMLMetaElement
)?.content;

@NgModule({
  declarations: [AppComponent, NavbarComponent, AppMenuComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    UiLibModule,
    ToastModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority: environment.authority,
          knownAuthorities: [environment.authority],
          redirectUri: environment.redirectUri
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE
        }
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.scopes
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.readApiUrl, environment.scopes],
          [environment.writeApiUrl, environment.scopes]
        ])
      }
    ),
    Menubar
  ],
  providers: [
    LocaleProvider,
    {
      provide: APP_CONFIG_TOKEN,
      useValue: {
        readApiUrl: environment.readApiUrl,
        writeApiUrl: environment.writeApiUrl
      }
    },
    { provide: CSP_NONCE, useValue: nonce },
    { provide: HTTP_INTERCEPTORS, useClass: ETagInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AcceptLanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
    MessageService,
    provideAnimationsAsync(),
    providePrimeNG(PrimeStyling),
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
