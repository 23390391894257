import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

export const PrimeStyling = {
  theme: {
    preset: definePreset(Aura, {
      semantic: {
        primary: {
          50:  '#F3F9FA',
          100: '#E7F2F6',
          200: '#D1E6ED',
          300: '#B9DAE4',
          400: '#A2CDDB',
          500: '#8BC1D2',
          600: '#74B5CA',
          700: '#5CA8C1',
          800: '#459CB8',
          900: '#2E8FAF',
          950: '#2389AB',
          hover: '#f4f9fb'
        },
        grey: {
          0: '#f3f3f3',
          1: '#ebebeb',
          2: '#ccced2',
          3: '#939598',
          4: '#575756',
          5: '#3c3c3c',
          8: '#cfcfcf',
          9: '#404040',
        },
        colorScheme: {
          light: {
            primary: {
              color: '#1783a6',
              inverseColor: '#ffffff',
              hoverColor: '#136681',
              activeColor: '#136681'
            },
            highlight: {
              background: '{primary.300}',
              focusBackground: '{primary.100}',
              color: '#404040',
              focusColor: '#404040'
            },
          }
        }
      },
      components: {
        button: {
          colorScheme: {
            outlined: {
              primaryBorderColor: "{primary.color}"
            },
            text: {
              primaryHoverBackground: "{primary.100}",
            },
            secondary: {
              background: '#4d4d4d',
              color: '#ffffff',
              hoverBackground: '#000000',
              hoverColor: '#ffffff',
            },
          }
        },
        datepicker: {
          colorScheme: {
            dropdownBackground: '{primary.color}',
            dropdownColor: "{primary.inverseColor}",
            dropdownHoverBackground: '{primary.hoverColor}',
            dropdownHoverColor: '{primary.inverseColor}',
          }
        },
        dialog: {
          borderRadius: ".375rem"
        },
        tabs: {
          tablist: {
            background: "transparent"
          },
          tab: {
            borderWidth: "0 0 2px 0",
          },
          tabpanel: {
            padding: '0.5rem 0 0 0',
            background: "transparent",
          }
        },
        toast: {
          detailFontSize: "16px",
          iconSize: "2rem"
        },
        toggleswitch: {
          colorScheme: {
            disabledBackground: "#6FB0C5",
            handleDisabledBackground: "#ffffff"
          }
        },
      }
    }),
    options: {
      cssLayer: {
        name: 'primeng',
        order: 'tailwind-base, primeng, tailwind-utilities'
      },
      darkModeSelector: false
    }
  }
}

export const detailAccordion = {
  colorScheme: {
    headerHoverBackground: "{primary.hover}",
    headerActiveHoverBackground: "{primary.hover}",
  },
  contentPadding: 0,
  header: {
    firstBorderWidth: "1px 0 1px 0",
    borderColor: "{grey.3} {grey.2} {grey.2} {grey.2}",
    borderRadius: "0",
    firstTopBorderRadius: "0",
    lastTopBorderRadius: "0",
  },
}
