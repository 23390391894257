import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Note, NoteUpdateEvent } from '../../types/ui-lib.type';
import { FormComponent } from '../form/form.component';
import { MenuItem, MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'alimento-ipv-frontend-note',
    templateUrl: './note.component.html',
    standalone: false
})
export class NoteComponent extends FormComponent {

  @Input()
  note?: Note;

  @Input()
  readonly = false;

  @Output()
  saveNote: EventEmitter<NoteUpdateEvent> = new EventEmitter<NoteUpdateEvent>();

  @Output()
  cancelNote: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  removeNote: EventEmitter<NoteUpdateEvent> = new EventEmitter<NoteUpdateEvent>();

  editMode = false;
  showMore = false;
  loading = false;
  actions: MenuItem[];

  constructor(private formBuilder: FormBuilder,
              private messageService: MessageService,
              private translateService: TranslateService) {
    super();
    this._createFormGroup();
    this._createActions();
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [this.note?.id],
      content: [this.note?.content || '', Validators.required]
    });
  }

  toggleShowMore(): void {
    this.showMore = !this.showMore;
  }

  hasOverflow(element: HTMLDivElement): boolean {
    return element.scrollHeight > element.clientHeight;
  }

  private _createActions(): void {
    this.actions = [
      {
        label: this.translateService.instant('notes.edit'),
        command: () => {
          this._createFormGroup();
          this.editMode = true;
          this.changes.emit(true);
        }
      },
      {
        label: this.translateService.instant('notes.delete'),
        command: () => {
          this.loading = true;
          this.removeNote.emit({
            note: this.note,
            setLoading: state => this.loading = state
          });
        }
      }
    ];
  }

  addNote(): void {
    if (!this.isValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors'),
      });
      return;
    }

    this.loading = true;
    this.saveNote.emit({
      note: this.getData(),
      setLoading: state => this.loading = state
    });
  }

  cancelAddNote(): void {
    if (this.note?.id) {
      this.editMode = false;
    }
    else {
      this.reset();
    }
    this.cancelNote.emit();
  }
}
