import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DateMapper, dateValid, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { Calendar } from 'primeng/calendar';
import { Employment, StopEmploymentEvent } from '../../../types/person.type';

@Component({
    selector: 'alimento-ipv-frontend-stop-employment-dialog',
    templateUrl: './stop-employment-dialog.component.html',
    standalone: false
})
export class StopEmploymentDialogComponent {
  @Output()
  stopEmploymentConfirmed = new EventEmitter<StopEmploymentEvent>();

  @ViewChild("focusElement")
  focusElement: Calendar;

  employment!: Employment | undefined;
  dialogVisible = false;
  stopEmploymentForm: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.stopEmploymentForm = this.fb.group({
      stopEmploymentAt: [new Date(), [Validators.required, dateValid()]],
    });
  }

  open(employment: Employment) {
    this.employment = employment;

    this.stopEmploymentForm.patchValue({
      stopEmploymentAt: new Date(),
    });

    this.dialogVisible = true;
    setTimeout(() => {
      this.focusElement?.inputfieldViewChild.nativeElement.focus();
    });
  }

  stopEmployment() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields'),
      });
      return;
    }

    let stopDate = this.stopEmploymentForm.get('stopEmploymentAt')?.value;
    stopDate = DateMapper.getDateFromDateTimeAsString(stopDate);

    this.stopEmploymentConfirmed.emit({
      stopEmploymentAt: stopDate,
      setLoading: (value: boolean) => (this.loading = value),
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.stopEmploymentForm.reset();
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.stopEmploymentForm);
    return this.stopEmploymentForm.valid;
  }
}
