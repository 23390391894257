import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  InfiniteScrollDataAdapter,
  LazyDropdownComponent,
  PaginatedResponse,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { Person, SearchPersonItem } from '../../../types/person.type';
import { PersonService } from '../../../services/person.service';
import { SearchesService } from '../../../services/searches.service';

@Component({
    selector: 'alimento-ipv-frontend-person-select',
    templateUrl: './person-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PersonSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PersonSelectComponent),
            multi: true
        }
    ],
    standalone: false
})
export class PersonSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  formControl: FormControl<SearchPersonItem> = new FormControl<SearchPersonItem>(undefined);

  @Input()
  includeEmployments = false;

  @Input()
  showEmployments = false;

  private _subscriptions: Subscription[] = [];
  private NUMBER_OF_LOADED_ITEMS = 30;

  data$: InfiniteScrollDataAdapter<SearchPersonItem>;

  @ViewChild(LazyDropdownComponent)
  private dropdownComponent: LazyDropdownComponent;

  get value(): SearchPersonItem {
    return this.formControl.value;
  }

  set value(value: SearchPersonItem) {
    this.formControl.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private personService: PersonService, private searchesService: SearchesService) {
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value?.personId);
        this.onTouched();
      })
    );
    this._createDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["includeEmployments"]?.currentValue !== null) {
      this._createDataSource();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  focus(): void {
    setTimeout(() => this.dropdownComponent.setFocus());
  }

  getSelectedPersonString = (item: SearchPersonItem) => (item.lastName + ' ' + item.firstName);

  _createDataSource(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };

    if (this.includeEmployments) {
      searchRequest.filters.push({
        type: FilterType.embed,
        values: ['employments']
      });
    }

    this.data$ = new InfiniteScrollDataAdapter<SearchPersonItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchPersonItem>> => {
      return this.searchesService.searchPerson(searchRequest);
    }, searchRequest, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value && (typeof value === 'string')) {
      this.personService
        .getPerson(value)
        .pipe(first())
        .subscribe((person) =>
          this.formControl.setValue(this._getPersonListItem(person), { emitEvent: false }));
    }
  }

  private _getPersonListItem(person: Person): SearchPersonItem {
    return {
      personAlimentoId: person.personAlimentoId,
      personId: person.personId,
      firstName: person.firstName,
      lastName: person.lastName,
      nationalIdentificationNumber: person.nationalIdentificationNumber,
      dateOfBirth: person.dateOfBirth
    };
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.formControl.valid ? null : { person: { valid: false } };
  }

  clear(): void {
    this.formControl.setValue(null);
  }
}
