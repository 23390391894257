import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from "../services/authorization.service";
import { Role } from '../types/Roles.enum';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[hideIfAllUnauthorized]',
    standalone: false
})
export class HideIfAllUnauthorizedDirective {

  @Input() set hideIfAllUnauthorized(roles: Role[]) {
    this.authorizationService.initialize().subscribe(_ => {
      if (this.authorizationService.hasAnyRole(roles)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      else {
        this.viewContainer.clear();
      }
    });
  }

  constructor(private authorizationService: AuthorizationService,
              public templateRef: TemplateRef<any>,
              public viewContainer: ViewContainerRef) {
  }

}
