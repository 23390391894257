import { Component, Input } from '@angular/core';
import { SearchReimbursementRequestItem } from '../../../types/reimbursement-request.type';

@Component({
    selector: 'alimento-ipv-frontend-reimbursement-request-card',
    templateUrl: './reimbursement-request-card.component.html',
    standalone: false
})
export class ReimbursementRequestCardComponent {

  @Input()
  reimbursementRequest: SearchReimbursementRequestItem;
}
