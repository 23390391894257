import { Component, OnInit } from '@angular/core';
import {
  FilterType, IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse, Role,
  SearchFilter, SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { map, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SearchReimbursementRequestItem } from '../../../types/reimbursement-request.type';
import { REIMBURSEMENT_REQUEST_SORT_FIELDS } from '../../../types/reimbursement-request.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { SearchesService } from '../../../services/searches.service';

@Component({
    selector: 'alimento-ipv-frontend-reimbursement-request-list',
    templateUrl: './reimbursement-request-list.component.html',
    standalone: false
})
export class ReimbursementRequestListComponent implements OnInit {
  searchData$: InfiniteScrollDataAdapter<SearchReimbursementRequestItem>;
  searchFilters: SearchFilter[];
  sortFilters: IFilter[];

  private NUMBER_OF_LOADED_ITEMS = 10;

  protected readonly Role = Role;

  constructor(private searchesService: SearchesService,
              private referenceDataService: ReferenceDataService,
              private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this._createSearch();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: [],
      sortField: REIMBURSEMENT_REQUEST_SORT_FIELDS.ALIMENTO_ID,
      sortOrder: 0
    };

    this.searchData$ = new InfiniteScrollDataAdapter<SearchReimbursementRequestItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchReimbursementRequestItem>> => {
      return this.searchesService.searchReimbursementRequests(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'reimbursementRequests.status',
        key: FilterType.filterStatuses,
        data: this.referenceDataService.getReimbursementRequestStatuses()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterStatuses,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'reimbursementRequests.year',
        key: FilterType.filterYear,
        data: of([-1,0,1,2,3,4])
          .pipe(map(years => years.map(year =>
            ({
              type: FilterType.filterYear,
              label: new Date().getFullYear() - year + "",
              value: new Date().getFullYear() - year + ""
            }) as IFilter)))
      }
    ];

    this.sortFilters = [
      {
        type: 'sort',
        label: this.translateService.instant('reimbursementRequests.sort.alimentoId'),
        value: REIMBURSEMENT_REQUEST_SORT_FIELDS.ALIMENTO_ID
      },
      {
        type: 'sort',
        label: this.translateService.instant('reimbursementRequests.sort.year'),
        value: REIMBURSEMENT_REQUEST_SORT_FIELDS.YEAR
      },
      {
        type: 'sort',
        label: this.translateService.instant('reimbursementRequests.sort.branchName'),
        value: REIMBURSEMENT_REQUEST_SORT_FIELDS.BRANCH_NAME
      }
    ];
  }
}
