import { Component, ElementRef, EventEmitter, Input, Output, Signal, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { CloseTraining, Training } from '../../../types/training.type';

@Component({
    selector: 'alimento-ipv-frontend-close-training-dialog',
    templateUrl: './close-training-dialog.component.html',
    standalone: false
})
export class CloseTrainingDialogComponent {
  @Input()
  training: Signal<Training>;

  @Input()
  firstSessionDate?: Date;

  @Output()
  closeTrainingConfirmed = new EventEmitter<CloseTraining>();

  @ViewChild('focusElement')
  focusElement: ElementRef;

  dialogVisible = false;

  closeTrainingForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.closeTrainingForm = this.fb.group({
      comments: [null]
    });
  }

  open(): void {
    this.dialogVisible = true;
    setTimeout(() => this.focusElement?.nativeElement.focus());
  }

  closeTraining(): void {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields')
      });
      return;
    }

    this.closeTrainingConfirmed.emit(this.closeTrainingForm.value);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.closeTrainingForm.reset();
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.closeTrainingForm);
    return this.closeTrainingForm.valid;
  }
}
