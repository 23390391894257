import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar, CalendarTypeView } from 'primeng/calendar';
import { distinctUntilChanged, skipWhile, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'lib-date-picker',
    templateUrl: './date-picker.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        }
    ],
    standalone: false
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  disabledDays: number[] = [];

  @Input()
  dateFormat = 'dd/mm/yy';

  @Input()
  showClear = false;

  @Input()
  minDate: Date | undefined = undefined;

  @Input()
  maxDate: Date = undefined;

  @Input()
  showIcon = true;

  @Input()
  keepInvalid = true;

  @Input()
  showOnFocus = false;

  @Input()
  placeholder = 'dd/mm/yyyy';

  @Input()
  view: CalendarTypeView = 'date';

  @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onBlur: EventEmitter<any> = new EventEmitter<any>();

  @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onClose: EventEmitter<any> = new EventEmitter<any>();

  @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onSelect: EventEmitter<any> = new EventEmitter<any>();

  isSmall = false;
  isMobile = false;
  formControl: FormControl<any> = new FormControl(null);

  private readonly MOBILE_HEIGHT = 800;
  private _subscriptions: Subscription[] = [];
  private _initialValue: Date;
  private _first = true;

  @ViewChild(Calendar)
  calendar: Calendar;

  @HostListener('window:resize')
  onResize(): void {
    this.isSmall = window.innerHeight < this.MOBILE_HEIGHT;
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this._subscriptions.push(
      this.formControl.valueChanges.pipe(
        distinctUntilChanged(),
        skipWhile(value => {
          const skip = this._first && value === this._initialValue;
          this._first = false;
          return skip;
        })
      ).subscribe(newValue => {
        this.onChange(newValue);
        this.onTouched();
      })
    );

    this._subscriptions.push(
      this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
        this.isMobile = result.matches;
      })
    );
  }

  ngOnInit(): void {
    this.isSmall = window.innerHeight < this.MOBILE_HEIGHT;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  writeValue(value: Date): void {
    this._initialValue = value;
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

}
