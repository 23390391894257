<div *ngIf="enterpriseGroup"
     class="shadow-grey-2 shadow-md my-2 p-4 relative border border-input-border"
     [ngClass]='{
     "bg-primary-hover border-primary" : selected,
     "bg-white": !selected,
     "cursor-pointer hover:bg-primary-hover": clickable
     }'
>
  <div class='flex items-center justify-between'>
    <div class='flex items-center gap-1'>
      <a *ngIf="!clickable" class='font-bold text-lg hover:underline'
         [routerLink]='["/enterprise-groups", enterpriseGroup.enterpriseGroupId]'>
        {{enterpriseGroup.groupAlimentoId}} - {{enterpriseGroup.groupName}}
      </a>
      <span *ngIf='clickable' class='font-bold text-lg'>
        {{enterpriseGroup.groupAlimentoId}} - {{enterpriseGroup.groupName}}
      </span>

      <span>({{enterpriseGroup.enterpriseNumber}})</span>
      <!--      <span>{{enterprise.address}}</span>-->
    </div>
  </div>

  <div class='flex items-center justify-between'>
    <div class='flex flex-col gap-1'>
      <span>{{'branches.rszNumber' | translate}}: {{enterpriseGroup.mainEnterpriseRszNumber}}</span>
      <span>{{'branches.vatNumber' | translate}}: {{enterpriseGroup.mainEnterpriseVatNumber}}</span>
    </div>

    <div class='flex items-center justify-between'>
      <span>{{enterpriseGroup.amountOfEmployees}} {{'enterprises.employees' | translate}},
        {{enterpriseGroup.amountOfEnterprises}} {{'enterpriseGroups.enterprisesTitle' | translate}}</span>
    </div>
  </div>
</div>
