import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  FormBuilder, Validators
} from '@angular/forms';
import { AccountNumberPipe, emailPattern, FormComponent, HtmlUtils } from '@alimento-ipv-frontend/ui-lib';
import { Branch } from '../../../types/branch.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';
import { EnterprisesService } from '../../../services/enterprises.service';
import { AccountNumberMapper } from '../../../utils/mapper/account-number.mapper';
import { DataLabelType } from '../../../types/reference-data.type';
import { Editor } from 'primeng/editor';

@Component({
    selector: 'alimento-ipv-frontend-branch',
    templateUrl: './branch.component.html',
    providers: [
        { provide: FormComponent, useExisting: BranchComponent }
    ],
    standalone: false
})
export class BranchComponent extends FormComponent implements OnChanges {
  @Input()
  branch: Branch;

  @Input()
  readOnly = false;

  @ViewChild(FormComponent)
  addressComponent?: FormComponent;

  accountNumberReadOnly: string;
  paymentMethodWhenSupplierReadOnly: string;
  paymentMethodWhenClientReadOnly: string;
  languageReadOnly: string;
  accountNumbers: any[];
  paymentMethods: any[];
  languages: DataLabelType[];
  showEventLocationFields = false;
  showMoreAdministrativeRules = false;

  constructor(private formBuilder: FormBuilder,
              private referenceDataService: ReferenceDataService,
              private enterpriseService: EnterprisesService,
              private accountNumberMapper: AccountNumberMapper,
              private htmlUtils: HtmlUtils) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branch']?.currentValue) {
      this.createBranchFormGroup(this.branch);
    }

    super.setDisabledState(this.readOnly);
  }
  override getData(): Branch {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));

    if (data.address) {
      data.street = data.address.street;
      data.houseNumber = data.address.houseNumber;
      data.mailbox = data.address.mailbox;
      data.city = data.address.city;
      data.postalCode = data.address.postalCode;
      data.country = data.address.country;
      delete data.address;
    }

    return data;
  }

  private createBranchFormGroup(branch: Branch): void {
    this.formGroup = this.formBuilder.group({
      branchId: [branch.branchId],
      enterpriseId: [branch.enterpriseId],
      branchName: [branch.branchName, Validators.required],
      address: [
        {
          street: branch.street || '',
          houseNumber: branch.houseNumber || '',
          mailbox: branch.mailbox || '',
          city: branch.city || '',
          postalCode: branch.postalCode || '',
          country: branch.country || 'BE'
        }
      ],
      accessibility: [branch.accessibility],
      website: [branch.website],
      phoneNumber: [branch.phoneNumber],
      email: [branch.email, emailPattern()],
      active: [branch.active === undefined ? true : branch.active],
      isTeacher: [branch.isTeacher || false],
      eventLocation: this.formBuilder.group({
        firstName: [branch.eventLocation?.firstName],
        lastName: [branch.eventLocation?.lastName],
        email: [branch.eventLocation?.email, emailPattern()],
        phoneNumber: [branch.eventLocation?.phoneNumber],
        comment: [branch.eventLocation?.comment],
        active: [branch.eventLocation?.active || false]
      }),
      defaultAccountNumberId: [branch.defaultAccountNumber?.data],
      defaultPaymentMethodIdWhenClient : [branch.defaultPaymentMethodIdWhenClient],
      defaultPaymentMethodIdWhenSupplier : [branch.defaultPaymentMethodIdWhenSupplier],
      isClient: [branch.isClient || false],
      isSupplier: [branch.isSupplier || false],
      language: [branch.language],
      noCorrespondence: [branch.noCorrespondence || false],
      administrativeRules: [branch?.administrativeRules],
      remarks: [branch.remarks],
      invoiceReferenceRequired: [branch?.invoiceReferenceRequired || false],
      defaultInvoiceEmail: [branch?.defaultInvoiceEmail, emailPattern()]
    });

    super._addChangeListener();

    this.accountNumberReadOnly = branch.defaultAccountNumber?.label;
    this.showEventLocationFields = branch?.eventLocation?.active;

    this.enterpriseService.getAccountNumbers(branch.enterpriseId).pipe(first())
      .subscribe(accountNumbers => this.accountNumbers = accountNumbers
        .filter(accountNumber =>
          accountNumber.approved && this.accountNumberMapper.isActive(accountNumber))
        .map(accountNumber => ({
          label: new AccountNumberPipe().transform(accountNumber.iban),
          data: accountNumber.accountNumberId
        })));

    this.referenceDataService.getPaymentMethods().pipe(first())
      .subscribe(paymentMethods => {
        this.paymentMethods = paymentMethods;

        if (branch?.defaultPaymentMethodIdWhenSupplier) {
          this.paymentMethodWhenSupplierReadOnly = this.paymentMethods
            .filter(method => method.data === branch.defaultPaymentMethodIdWhenSupplier)[0].label;
        }

        if (branch?.defaultPaymentMethodIdWhenClient) {
          this.paymentMethodWhenClientReadOnly = this.paymentMethods
            .filter(method => method.data === branch.defaultPaymentMethodIdWhenClient)[0].label;
        }
      });

    this.referenceDataService.getLanguageCodes().pipe(first())
      .subscribe(languages => {
        this.languages = languages;
        if (branch?.language) {
          this.languageReadOnly = this.languages
            .filter(language => language.data === branch.language)[0].label;
        }
      })

    this.subscriptions.push(
      this.formGroup.get("eventLocation.active").valueChanges.subscribe(newValue => {
        if (newValue) {
          this.showEventLocationFields = true;
        }
      })
    )
  }

  override isValid(): boolean {
    if (this.readOnly) {
      return true;
    }

    const valid = super.isValid();
    return (!this.addressComponent || this.addressComponent.isValid()) && valid;
  }

  editorSelectionChange(event: any, editor: Editor): void {
    if (event.range === null && event.oldRange !== null) {
      editor.quill.root.classList.remove('focused');
    }
    else if (event.range !== null && event.oldRange === null) {
      editor.quill.root.classList.add('focused');
    }
  }

  textChange(controlName: string, event: any): void {
    if (event.source === 'onBlur' || event.delta?.ops?.length > 3 || event.htmlValue?.indexOf('<img') >= 0) {
      setTimeout(() => this.formGroup.get(controlName)?.setValue(this.htmlUtils.sanitize(event.htmlValue)));
    }
  }

  toggleShowMoreAdministrativeRules(): void {
    this.showMoreAdministrativeRules = !this.showMoreAdministrativeRules;
  }

  hasOverflow(element: HTMLDivElement): boolean {
    return element.scrollHeight > element.clientHeight;
  }
}
