import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonService } from '../../services/person.service';
import { NationalIdValidationResponse } from '../../types/person.type';

export class NationalIdValidator {
  static createValidator(personService: PersonService, disableUniqueValidation = false): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const nationalId: string = control.value?.replace(/[-._]/g, '');
      const personId: string = control.parent?.get('personId')?.value;

      if (!nationalId) {
        return of(null);
      }
      else if (nationalId?.length !== 11) {
        return of({nationalIdInvalid: true});
      }

      return personService.validateNationalIdentificationNumber(nationalId).pipe(
        map((nationalIdValidationResponse: NationalIdValidationResponse) => {
          if (!disableUniqueValidation && nationalIdValidationResponse.isDuplicate && !personId) {
            return {
              duplicateNationalId: {
                firstName: nationalIdValidationResponse.firstName,
                lastName: nationalIdValidationResponse.lastName,
              },
            };
          }

          if (!nationalIdValidationResponse.isValid) {
            return { nationalIdInvalid: true };
          }

          return null;
        })
      );
    };
  }
}
