<div *ngIf='currentUser' [ngClass]='{"shadow-menu": userMenuOpen}'>
  <div class="h-16 w-16 bg-white text-primary flex justify-center items-center cursor-pointer group" (click)="toggleUserMenu()">
    <span class="rounded-full border border-text text-text p-2 w-10 text-center group-hover:bg-primary-hover select-none">
      {{currentUser.name | initials}}
    </span>
  </div>

  <div class="absolute min-w-[200px] right-8 z-50 p-2 flex flex-col items-center gap-2 bg-white shadow-menu" *ngIf="userMenuOpen">
    <span class='font-bold text-title p-2 select-none'>{{currentUser.name}}</span>
    <div class='w-full flex items-center justify-center text-primary gap-4 border-y border-y-grey-2 py-2'>
      <a *ngFor='let lang of languages'
         class='cursor-pointer hover:bg-primary-hover p-2'
         [ngClass]='{"font-bold": lang.data === currentLang}'
         (click)='changeLanguage(lang.data)'>
        {{lang.label}}
      </a>
    </div>
    <div class='w-full flex items-center justify-between cursor-pointer text-danger hover:bg-primary-hover p-2' [tabIndex]='0' (click)='logout()'>
      <span>{{'logout' | translate}}</span>
      <i class='pi pi-times'></i>
    </div>
  </div>
</div>
