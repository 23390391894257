<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<form *ngIf='enterprise'>
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='enterprise.enterpriseId'
                                       [readonly]='readOnly'
                                       (hasChanges)='notesHasChanges = $event'
                                       [type]='EnterpriseNote'>
  </alimento-ipv-frontend-notes-sidebar>
  <alimento-ipv-frontend-detail-page-layout
    [breadcrumb]='breadcrumb'
    fallbackUrl='/enterprises'
    header="{{ (enterpriseId ? 'enterprises.enterprise' : 'enterprises.newEnterprise') | translate }}"
  >
    <ng-container status>
      <alimento-ipv-frontend-enterprises-status-chip [isActive]='enterprise.active'>
      </alimento-ipv-frontend-enterprises-status-chip>
    </ng-container>

    <ng-container top-action>
      <div class='buttons' *ngIf='!readOnly'>
        <p-button
          label="{{ 'enterprises.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon='pi pi-spin pi-spinner'
          (click)='saveEnterprise()'
          [loading]='savingEnterprise'
          [disabled]='!hasChanges'
          *removeIfFeatureOff='FEATURE.BRANCH_AND_ENTERPRISE_MANAGEMENT'
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)='setActiveTabIndex($event)'
        [activeTabIndex]='activeTabIndex'
        [data]='tabMenuItems'
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]='activeTabIndex !== 0'>
        <alimento-ipv-frontend-header
          title="{{ 'enterprises.basicDataTitle' | translate }}"
        ></alimento-ipv-frontend-header>

        <alimento-ipv-frontend-enterprise
          [enterprise]='enterprise'
          [readOnly]='readOnly'
          (changes)='hasChanges = true'
        ></alimento-ipv-frontend-enterprise>
      </div>

      <div *ngIf='activeTabIndex === 1'>
        <alimento-ipv-frontend-account-number-popup #accountNumberPopupComponent
                                                    [accountNumber]='currentAccountNumber'
                                                    (formSubmit)='accountNumberSubmit($event, accountNumberPopupComponent)'
        ></alimento-ipv-frontend-account-number-popup>
        <alimento-ipv-frontend-account-number-action-dialog [accountNumber]='currentAccountNumber'
                                                            [action]='currentAccountNumberAction'
                                                            (submitDialog)='executeAccountNumberAction($event)'
        ></alimento-ipv-frontend-account-number-action-dialog>

        <alimento-ipv-frontend-header title="{{ 'enterprises.financialDataTitle' | translate }}">
          <p-button
            *ngIf='!accountNumberReadOnly'
            icon='pi pi-plus'
            label="{{ 'enterprises.accountNumbers.new' | translate  }}"
            (onClick)='createNewAccountNumber(accountNumberPopupComponent)'
          ></p-button>
        </alimento-ipv-frontend-header>

        <h3>{{ 'enterprises.accountNumbersTitle' | translate }}</h3>

        <div *ngIf='accountNumbers | async as accountNumbers' class='flex flex-col gap-2'>
          <div *ngFor='let accountNumber of accountNumbers'>
            <alimento-ipv-frontend-account-number-card
              [accountNumber]='accountNumber'
              [readOnly]='accountNumberReadOnly'
              (actionClicked)='accountNumberActionClicked($event, accountNumberPopupComponent)'
            ></alimento-ipv-frontend-account-number-card>
          </div>
          <div *ngIf='accountNumbers.length === 0'>{{ 'enterprises.noAccountNumbers' | translate }}</div>
        </div>
      </div>

      <div *ngIf='activeTabIndex === 2'>
        <alimento-ipv-frontend-header title="{{ 'enterprises.branchesTitle' | translate }}">
          <p-button
            *ngIf='!readOnly'
            [routerLink]='["/branches/create"]'
            [queryParams]='{enterpriseId: enterpriseId}'
            label="{{ 'enterprises.addBranch' | translate }}"
          ></p-button>
        </alimento-ipv-frontend-header>

        <p-tabs [(value)]='activeBranchIndex'>
          <p-tablist>
            <p-tab value='0'>
              {{ 'enterprises.branches.current' | translate }} ({{ searchBranches$.totalCount$ | async }})
            </p-tab>
            <p-tab value='1'>
              {{ 'enterprises.branches.previous' | translate }}
            </p-tab>
          </p-tablist>
          <p-tabpanels>
            <p-tabpanel value='0'>
              <lib-search #searchBranches
                          [activeFiltersOnTop]='false'
                          [cardTemplate]='branchCardTemplate'
                          [data$]='searchBranches$'
                          [filters]='searchBranchesFilters'
                          [inlineFilters]='true'
                          [showSearchFilter]='false'
                          availableResultsTranslateKey='enterprises.availableBranches'
                          name='enterprise-branch-search'
                          searchPlaceholderKey='enterprises.branches.searchPlaceholder'
              >
                <ng-template #branchCardTemplate let-item='item'>
                  <alimento-ipv-frontend-enterprise-branch-card [branch]='item'
                                                                (click)='navigateToBranch(item, $event)'
                                                                (actionClicked)='branchCardClicked($event)'
                  ></alimento-ipv-frontend-enterprise-branch-card>
                </ng-template>
              </lib-search>
            </p-tabpanel>
            <p-tabpanel value='1'>
              <lib-search #searchPreviousBranches
                          [activeFiltersOnTop]='false'
                          [cardTemplate]='previousBranchCardTemplate'
                          [data$]='searchPreviousBranches$'
                          [filters]='searchPreviousBranchesFilters'
                          [inlineFilters]='true'
                          [showSearchFilter]='false'
                          availableResultsTranslateKey='enterprises.availableBranches'
                          name='enterprise-previous-branch-search'
                          searchPlaceholderKey='enterprises.branches.searchPlaceholder'
              >
                <ng-template #previousBranchCardTemplate let-item='item'>
                  <alimento-ipv-frontend-enterprise-branch-card [branch]='item'
                                                                (click)='navigateToBranch(item, $event)'
                                                                (actionClicked)='branchCardClicked($event)'
                  ></alimento-ipv-frontend-enterprise-branch-card>
                </ng-template>
              </lib-search>
            </p-tabpanel>
          </p-tabpanels>
        </p-tabs>
      </div>

      <div *ngIf='activeTabIndex === 3'>
        <alimento-ipv-frontend-enterprises-sme-bonus [enterprise]='enterprise'
                                                     *ngIf='enterprise'
                                                     [smeBonusActive]='smeBonusActive'
        ></alimento-ipv-frontend-enterprises-sme-bonus>
      </div>

    </ng-container>
    <ng-container metadata>
      <alimento-ipv-frontend-metadata-item *ngIf='enterpriseGroup'
                                           [isLink]='true'
                                           header='enterprises.enterpriseGroup'
                                           value='{{ enterpriseGroup.name }}'
                                           [link]='["/enterprise-groups", enterpriseGroup.enterpriseGroupId]'
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf='enterprise'
                                           header='enterprises.smeBonus'
                                           [value]='(smeBonusActive ? "enterprises.smeBonusActive" : "enterprises.smeBonusNotActive") | translate'
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</form>
