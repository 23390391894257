<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.closeDialog.title' | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="training()">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.closeDialog.labels.trainingName" | translate}}'
        [value]='training().customTitle'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.closeDialog.labels.firstSessionDate" | translate}}'
        [value]='firstSessionDate ? (firstSessionDate | date:"dd/MM/yyyy") : "/"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="closeTrainingForm">
    <div class="field">
      <label class="block" for="comments"
        >{{ 'trainings.closeDialog.labels.remarks' | translate }}
        <small>({{ 'trainings.optional' | translate }})</small></label
      >
      <textarea
        #focusElement
        class="w-full"
        formControlName="comments"
        id="comments"
        pTextarea
        rows="2"
      ></textarea>
    </div>
  </div>

  <div class='my-2'>
    {{'trainings.closeDialog.text' | translate}}
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="closeTraining()"
        label="{{ 'trainings.closeDialog.buttons.submit' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.closeDialog.buttons.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
