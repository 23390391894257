<!--<div class='flex flex-wrap items-center gap-4'>-->
<!--  <div *ngFor='let menuItem of menuItems'-->
<!--       class='flex justify-between items-center'-->
<!--  >-->
<!--    <a class='flex gap-2 items-center text-lg cursor-pointer p-2 hover:bg-primary-hover'-->
<!--       (click)='triggerOnClickAction(menuItem, menu, $event)'-->
<!--       [routerLink]='menuItem.routerLink'-->
<!--       [ngClass]='{ "text-primary font-bold": isActive(menuItem) }'-->
<!--    >-->
<!--&lt;!&ndash;      <span class='material-symbols-outlined'>{{ menuItem.icon }}</span>&ndash;&gt;-->
<!--      <span>{{ menuItem.label }}</span>-->
<!--      <i class='font-material text-lg' *ngIf='menuItem.items?.length > 0'>keyboard_arrow_down</i>-->
<!--      <span *ngIf='menuItem.badge > 0'-->
<!--            class='inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-alimento-red border-2 border-white rounded-full top-2 right-0'>-->
<!--      {{menuItem.badge}}-->
<!--    </span>-->
<!--    </a>-->

<!--    <p-menu #menu-->
<!--            [model]='$any(menuItem.items)'-->
<!--            [popup]='true'-->
<!--            appendTo='body'-->
<!--    ></p-menu>-->
<!--  </div>-->
<!--</div>-->


<div [ngClass]='{"shadow-menu": appMenuOpen}' class='app-menu'>
  <div (click)='toggleAppMenu()'
       class='h-16 w-16 relative bg-white text-primary flex justify-center items-center cursor-pointer group'>
    <span class='material-symbols-outlined group-hover:bg-primary-hover group-hover:text-primary-button-hover p-2'>
      apps
    </span>
    <span *ngIf='totalCount > 0' class='absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white
    bg-alimento-red border-2 border-white rounded-full top-2 right-0'>{{totalCount}}</span>
  </div>

  <div *ngIf='appMenuOpen'
       class='app-menu-content absolute min-w-[200px] right-[112px] z-50 p-4 flex flex-col bg-white shadow-menu '>
    <a
      (click)='triggerOnClickAction(menuItem)'
      [routerLink]='menuItem.routerLink'
      *ngFor='let menuItem of menuItems'
      class='text-primary flex justify-between items-center cursor-pointer p-2 font-bold hover:bg-primary-hover'
    >
      <div class='flex gap-2 items-center'>
        <span class='material-symbols-outlined'>{{ menuItem.icon }}</span>
        <span>{{ menuItem.label }}</span>
      </div>
      <span *ngIf='menuItem.badge > 0' class='inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white
    bg-alimento-red border-2 border-white rounded-full top-2 right-0'>{{menuItem.badge}}</span>
    </a>
  </div>
</div>
