import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { Subscription } from 'rxjs';
import { SessionAction, SessionListItem } from '../../../types/session.type';
import {
  getTrainingStatusKey,
  SessionMethodKey,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import { SessionActionEnum } from '../../../types/session.enum';

@Component({
    selector: 'alimento-ipv-frontend-session-list-item',
    templateUrl: './session-list-item.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SessionListItemComponent implements OnChanges, OnDestroy {
  @Input()
  session!: SessionListItem;
  @Input()
  trainingStatus!: TrainingStatusKey;
  @Input({ required: true })
  index: number;
  @Input()
  readOnly = false;
  @Input()
  trainingType: TRAINING_TYPE;

  @Output()
  actionClicked = new EventEmitter<SessionAction>();

  showExtraInfo = false;
  sessionTeachers: { label: string, link?: string[], teachers?: { label: string, link?: string[] }[] }[];
  actions: MenuItem[] = [];
  primaryAction?: { action: SessionActionEnum; icon: string };
  methodHasLocation = false;
  isOpenExternOfCustom = false;
  cancelledStatus = getTrainingStatusKey(TrainingStatusKey.Cancelled);
  primaryActionTooltip = '';

  private _subscriptions: Subscription[] = [];
  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  constructor(private translate: TranslateService,
              private translationService: TranslationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['session']?.currentValue) {
      if (this.session.teacherBranches?.length > 0) {
        this.sessionTeachers = this.session.teacherBranches.map(branch => (
          {
            label: `${branch.label}`,
            link: ['/branches', branch.data],
            teachers: this.session.teacherEmployments
              .filter(employment => employment.branchId === branch.data)
              .map(teacher => ({
                  label: `${teacher.firstName} ${teacher.lastName}`,
                  link: ['/persons', teacher.personId]
                })
              )
          }
        ));
      }
      else {
        this.sessionTeachers = [{ label: this.translate.instant('trainings.sessions.noTeachers') }];
      }

      this.methodHasLocation = [SessionMethodKey.Physical, SessionMethodKey.Hybrid].includes(
        this.session.sessionMethod?.data as any
      );

      this._clearSubscriptions();
      this._subscriptions.push(
        this.translationService.languageChange$.subscribe(() => this.actions = this.getActions())
      );
      this._setPrimaryAction();
    }

    if (changes['trainingType']?.currentValue) {
      this.isOpenExternOfCustom = [TRAINING_TYPE.OPEN_EXTERN, TRAINING_TYPE.CUSTOM].includes(this.trainingType);
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  toggleShowExtraInfo() {
    this.showExtraInfo = !this.showExtraInfo;
  }

  private _setPrimaryAction(): void {
    if (this.session.sessionCancelled || this.trainingStatus === TrainingStatusKey.Cancelled) {
      return;
    }

    if (this.readOnly) {
      if (this.session.numberOfRegisteredAbsences > 0) {
        this.primaryAction = { action: SessionActionEnum.registerAbsence, icon: 'm-howToReg' };
        this.primaryActionTooltip = 'trainings.sessions.tooltips.viewAbsences';
      }
      return;
    }

    if (new Date(this.session.date) < new Date()) {
      if (!this.session.absenceRegistrationComplete) {
        this.primaryAction = { action: SessionActionEnum.registerAbsence, icon: 'm-group' };
        this.primaryActionTooltip = 'trainings.sessions.tooltips.registerAbsence';
      }
      else {
        this.primaryAction = { action: SessionActionEnum.registerAbsence, icon: 'm-howToReg' };
        this.primaryActionTooltip = 'trainings.sessions.tooltips.viewAbsences';
      }
    }
    else {
      this.primaryAction = { action: SessionActionEnum.edit, icon: 'm-edit' };
      this.primaryActionTooltip = 'trainings.sessions.tooltips.edit';
    }
  }

  toggleAction(action: SessionActionEnum, event?: any): void {
    this.actionClicked.emit({ action: action, session: this.session });
    event?.stopImmediatePropagation();
  }

  private _getMenuItem(
    translateKey: string,
    action: SessionActionEnum,
    disabled = false,
    icon?: string
  ): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled: disabled,
      command: () => this.toggleAction(action)
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px'
      };
    }

    return menuItem;
  }

  getActions(): MenuItem[] {
    if (this.readOnly || this.trainingStatus === TrainingStatusKey.Cancelled) {
      return [];
    }

    const menuItems = [
      this._getMenuItem('trainings.sessions.actions.edit', SessionActionEnum.edit, this.session.sessionCancelled),
      this._getMenuItem('trainings.sessions.actions.copy', SessionActionEnum.copy),
      this._getMenuItem(
        'trainings.sessions.actions.registerAbsence',
        SessionActionEnum.registerAbsence,
        this.session.sessionCancelled
      )
    ];

    const cancelSession = this._getMenuItem(
      'trainings.sessions.actions.cancel',
      SessionActionEnum.cancel,
      this.session.sessionCancelled,
      'pi pi-times'
    );
    cancelSession.styleClass = 'danger';

    const deleteSession = this._getMenuItem(
      'trainings.sessions.actions.delete',
      SessionActionEnum.delete,
      false,
      'pi pi-trash'
    );
    deleteSession.styleClass = 'danger';

    if (this.trainingStatus === TrainingStatusKey.Active &&
      [TRAINING_TYPE.OPEN_EXTERN, TRAINING_TYPE.CUSTOM, TRAINING_TYPE.CEVORA].includes(this.trainingType)) {
      menuItems.push(cancelSession);
      menuItems.push(deleteSession);
    }
    else if (
      this.trainingStatus === TrainingStatusKey.Draft ||
      (this.trainingStatus === TrainingStatusKey.Active && this.trainingType === TRAINING_TYPE.TEMPLATE)
    ) {
      menuItems.push(deleteSession);
    }
    else {
      menuItems.push(cancelSession);
    }

    return menuItems;
  }
}
