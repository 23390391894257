import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'initials',
    standalone: false
})
export class InitialsPipe implements PipeTransform {

  transform(value: string): unknown {
    const initials = value.split(" ")
      .reduce((previousValue, currentValue) => previousValue + currentValue.charAt(0).toUpperCase(), "")
      .substring(0, 2);
    if (initials.length < 2) {
      return value.substring(0, 2);
    }
    return initials;
  }
}
