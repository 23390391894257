import { Component, computed, EventEmitter, OnDestroy, Output, Signal, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ParticipantsListEvent } from '../../../types/training.type';
import { SessionListItem } from '../../../types/session.type';
import { SessionService } from '../../../services/session.service';
import { DateMapper } from '@alimento-ipv-frontend/ui-lib';

@Component({
    selector: 'alimento-ipv-frontend-participants-list-dialog',
    templateUrl: './participants-list-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ParticipantsListDialogComponent implements OnDestroy {

  @Output()
  submitPopup = new EventEmitter<ParticipantsListEvent>();

  dialogVisible = false;
  formGroup: FormGroup;
  loading = false;
  sessions: Signal<SessionListItem[]> =
    computed(() => this.sessionService.sessions().filter(session => !session.sessionCancelled));
  nrOfSessions = 0;
  private _subscriptions: Subscription[] = [];
  teachersWithNoEmail: {session: SessionListItem, teacher: string}[] = [];
  noTeacherEmployments: SessionListItem[] = [];

  constructor(private fb: FormBuilder,
              private sessionService: SessionService,
              private dateMapper: DateMapper,
              private translateService: TranslateService) {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  open(): void {
    this._createFormGroup();
    this.dialogVisible = true;
  }

  onSubmit(sendMail: boolean): void {
    this.submitPopup.emit({
      sendMail: sendMail,
      data: JSON.parse(JSON.stringify(this.formGroup.value)),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset({sessions: []});
  }

  private _createFormGroup(): void {
    this.formGroup = this.fb.group({
      sessions: [[]],
      individualFiles: [false],
      requestPermissionForPhotos: [false]
    });

    this._subscriptions.push(
      this.formGroup.get('sessions').valueChanges.subscribe(newValue => {
        this._checkIfAllSelectedHaveEmails();
        if (newValue) {
          this.nrOfSessions = newValue.length;
        }
      })
    );
  }

  getSessionLabel = (session: SessionListItem, index: number) => {
    return session.title || (session.date ?
      this.translateService.instant('trainings.sessions.sessionOf', { date: this.dateMapper.formatDates(new Date(session.date), undefined, 'dd/MM/yyyy') }) :
      this.translateService.instant('trainings.sessions.sessionNr', { number: index }));
  }

  _checkIfAllSelectedHaveEmails(): void {
    const sessionIds = this.formGroup.get("sessions").value;
    this.noTeacherEmployments = [];
    this.teachersWithNoEmail = [];
    this.sessions().filter(session => sessionIds.includes(session.id))
      .forEach(session => {
        if ((session.teacherEmployments || []).length === 0) {
          this.noTeacherEmployments.push(session);
        }
        else {
          session.teacherEmployments.filter(employment => !employment.professionalEmail)
            .forEach(teacher => {
              this.teachersWithNoEmail.push({session:session, teacher: teacher.lastName + " " + teacher.firstName});
            });
        }
      });

  }
}
