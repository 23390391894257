import { Component, Input, OnChanges, signal, Signal, SimpleChanges, ViewChild, WritableSignal } from '@angular/core';
import { EmploymentPopupComponent } from '../employment-popup/employment-popup.component';
import { first } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { StopEmploymentDialogComponent } from '../stop-employment-dialog/stop-employment-dialog.component';
import {
  Employment,
  EmploymentAction,
  EmploymentUpdateEvent,
  MappedEmployments,
  Person, StopEmploymentEvent
} from '../../../types/person.type';
import { EmploymentActionEnum } from '../../../types/person.enum';
import { EmploymentService } from '../../../services/employment.service';
import { DateMapper } from '@alimento-ipv-frontend/ui-lib';

@Component({
    selector: 'alimento-ipv-frontend-employments',
    templateUrl: './employments.component.html',
    styleUrls: ['./employments.component.scss'],
    standalone: false
})
export class EmploymentsComponent implements OnChanges {
  @Input({ required: true })
  person: Person;

  @Input()
  readOnly = false;

  currentEmploymentAction: EmploymentActionEnum;
  employments: Signal<MappedEmployments> = this.employmentService.employments;

  @ViewChild(StopEmploymentDialogComponent)
  stopEmploymentDialogComponent!: StopEmploymentDialogComponent;
  employmentIndex: WritableSignal<string> = signal("0");

  constructor(
    private employmentService: EmploymentService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['person']?.currentValue) {
      this.employmentService.loadEmployments(this.person.personId);
    }
  }

  onEmploymentPopupSubmit(event: EmploymentUpdateEvent, employmentPopupComponent: EmploymentPopupComponent): void {
    const employment: Employment = event.employment;

    event.setLoading(true);

    const createOrUpdateSession$ = event.employmentId
      ? this.employmentService.updateEmployment(this.person.personId, event.employmentId, employment)
      : this.employmentService.createEmployment(this.person.personId, employment);

    createOrUpdateSession$.subscribe({
      next: (response: {
        id: string
      }) => {
        const employmentId: string = event.employmentId ?? response?.id;

        this.employmentService.getEmployment(employmentId).subscribe(() => {
          event.setLoading(false);

          employmentPopupComponent.employmentForm.reset();
          employmentPopupComponent.closePopup();

          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('persons.employments.saved') + '!'
          });

          this.employmentService.loadEmployments(this.person.personId);
        });
      },
      error: () => {
        event.setLoading(false);
      }
    });
  }

  addEmployment(employmentPopupComponent: EmploymentPopupComponent) {
    this.currentEmploymentAction = EmploymentActionEnum.create;
    employmentPopupComponent.openPopup({} as Employment);
  }

  employmentAction(employmentAction: EmploymentAction, employmentPopupComponent: EmploymentPopupComponent) {
    this.currentEmploymentAction = employmentAction.action;
    if (employmentAction.action === EmploymentActionEnum.edit || employmentAction.action === EmploymentActionEnum.view) {
      employmentPopupComponent.openPopup(employmentAction.employment);
    }
    else if (employmentAction.action === EmploymentActionEnum.setAsMainEmployment) {
      this.employmentService
        .setAsMainEmployment(this.person.personId, employmentAction.employment.employmentId)
        .pipe(first())
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('persons.employments.mainEmploymentChanged')
            });

            this.employmentService.loadEmployments(this.person.personId);
          }
        });
    }
    else if (employmentAction.action === EmploymentActionEnum.stopEmployment) {
      this.stopEmploymentDialogComponent.open(employmentAction.employment);
    }
  }

  stopEmployment(stopEmploymentEvent: StopEmploymentEvent) {
    if (this.stopEmploymentDialogComponent.employment?.employmentId) {
      stopEmploymentEvent.setLoading(true);
      this.employmentService
        .stopEmployment(
          this.person.personId,
          this.stopEmploymentDialogComponent.employment?.employmentId,
          DateMapper.getDateFromDateTimeAsString(stopEmploymentEvent.stopEmploymentAt)
        )
        .pipe(first())
        .subscribe({
          next: () => {
            stopEmploymentEvent.setLoading(false);
            this.stopEmploymentDialogComponent.closeDialog();

            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('persons.employments.employmentStopped') + '!'
            });

            this.employmentService.loadEmployments(this.person.personId);
          },
          error: () => stopEmploymentEvent.setLoading(false)
        });
    }
  }
}
