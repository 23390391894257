import { Component, ElementRef, HostListener, Input, OnChanges, OnDestroy } from '@angular/core';
import { AppMenuItem } from '@alimento-ipv-frontend/ui-lib';
import { first, Subscription, timer } from 'rxjs';
// import { Menu } from 'primeng/menu';
import { Router } from '@angular/router';

@Component({
    selector: 'alimento-ipv-frontend-app-menu',
    templateUrl: './app-menu.component.html',
    standalone: false
})
export class AppMenuComponent implements OnChanges, OnDestroy {
  @Input()
  menuItems: AppMenuItem[] = [];

  appMenuOpen = false;
  totalCount = 0;
  private _subscriptions: Subscription[] = [];

  @HostListener('document:click', ['$event'])
  clickOutsideOfComponent(event: MouseEvent) {
    if (this.appMenuOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.appMenuOpen = false;
    }
  }

  constructor(private elementRef: ElementRef, private router: Router) {
  }

  ngOnChanges(): void {
    this._clearSubscription();
    this.menuItems.filter(item => item.badgeFunction).forEach(item => {
      this._subscriptions.push(timer(0, 5 * 1000 * 100)
        .subscribe(() => this.calculateBadge(item)));
    });
  }

  ngOnDestroy(): void {
    this._clearSubscription();
  }

  toggleAppMenu() {
    this.appMenuOpen = !this.appMenuOpen;
  }

  triggerOnClickAction(menuItem: AppMenuItem) {
    this.calculateBadge(menuItem);
    this.appMenuOpen = false;
  }

  // triggerOnClickAction(menuItem: AppMenuItem, menu: Menu, event: MouseEvent): void {
  //   this.calculateBadge(menuItem);
  //   if (menuItem.items?.length > 0) {
  //     menu.show(event);
  //   }
  // }

  calculateBadge(menuItem: AppMenuItem): void {
    if (menuItem.badgeFunction) {
      menuItem.badgeFunction.pipe(first())
        .subscribe(count => {
          menuItem.badge = count > 99 ? 99 : count;
          this._updateTotalCount();
        });
    }
  }

  isActive(menuItem: AppMenuItem): boolean {
    if (menuItem.items?.length > 0) {
      return menuItem.items.some(item => this._isActive(item.routerLink.join("/")));
    }
    else {
      return this._isActive(menuItem.routerLink.join("/"));
    }
  }

  private _isActive(routerLink: string): boolean {
    return this.router.isActive(routerLink, {paths: 'subset', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'});
  }

  private _updateTotalCount(): void {
    this.totalCount = 0;
    this.menuItems.forEach(menuItem => this.totalCount += menuItem.badge || 0)
  }

  private _clearSubscription(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
    this._subscriptions = [];
  }
}
