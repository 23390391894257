import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { Subscription } from 'rxjs';
import { AccountNumberAction, SearchAccountNumber } from '../../../types/enterprise.type';
import { AccountNumberActionEnum, AccountNumberState } from '../../../types/enterprises.enum';
import { AccountNumberMapper } from '../../../utils/mapper/account-number.mapper';

@Component({
    selector: 'alimento-ipv-frontend-account-number-card',
    templateUrl: './account-number-card.component.html',
    standalone: false
})
export class AccountNumberCardComponent implements OnChanges, OnDestroy {
  @Input()
  accountNumber!: SearchAccountNumber;
  @Input()
  readOnly = false;

  @Output()
  actionClicked = new EventEmitter<AccountNumberAction>();

  showExtraInfo = false;
  actions: MenuItem[] = [];
  primaryAction?: { action: AccountNumberActionEnum; icon: string };
  primaryActionTooltip = '';
  accountNumberState: AccountNumberState;
  lastAction: string;

  private _subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private translationService: TranslationService,
              private accountNumberMapper: AccountNumberMapper) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['accountNumber']?.currentValue) {
      this._clearSubscriptions();
      this._subscriptions.push(
        this.translationService.languageChange$.subscribe(() => this.actions = this.getActions())
      );
      this._setPrimaryAction();

      if (this.accountNumberMapper.isActive(this.accountNumber)) {
        if (this.accountNumber.approved) {
          this.accountNumberState = AccountNumberState.active;
        }
        else {
          this.accountNumberState = AccountNumberState.draft;
        }
      }
      else {
        this.accountNumberState = AccountNumberState.notActive;
      }

      if (this.accountNumber.approved) {
        this.lastAction = 'enterprises.accountNumbers.approved';
      }
      else {
        this.lastAction = 'enterprises.accountNumbers.created';
      }
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  toggleShowExtraInfo() {
    this.showExtraInfo = !this.showExtraInfo;
  }

  private _setPrimaryAction(): void {
    if (this.readOnly) {
      return;
    }
    else {
      this.primaryAction = { action: AccountNumberActionEnum.edit, icon: 'm-edit' };
      this.primaryActionTooltip = 'enterprises.accountNumbers.editAccountNumber';
    }
  }

  toggleAction(action: AccountNumberActionEnum, event?: any): void {
    this.actionClicked.emit({ action: action, accountNumber: this.accountNumber });
    event?.stopImmediatePropagation();
  }

  private _getMenuItem(
    translateKey: string,
    action: AccountNumberActionEnum,
    disabled = false,
    icon?: string
  ): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled: disabled,
      command: () => this.toggleAction(action)
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px'
      };
    }

    return menuItem;
  }

  getActions(): MenuItem[] {
    if (this.readOnly) {
      return [];
    }

    const menuItems = [
      this._getMenuItem('enterprises.accountNumbers.edit', AccountNumberActionEnum.edit)
    ];

    if (!this.accountNumber.approved) {
      menuItems.push(this._getMenuItem('enterprises.accountNumbers.approve', AccountNumberActionEnum.approve));
      menuItems.push(this._getMenuItem('branches.trainingPlans.reject', AccountNumberActionEnum.reject));
    }
    else {
      menuItems.push(this._getMenuItem(
        'enterprises.AccountNumbers.setAsDefault',
        AccountNumberActionEnum.setAsDefault,
        this.accountNumber.default,
        'pi pi-star'
      ));
    }

    const deleteItem = this._getMenuItem(
      'branches.trainingPlans.delete',
      AccountNumberActionEnum.delete,
      true,
      'pi pi-trash'
    );
    deleteItem.styleClass = 'danger';
    menuItems.push(deleteItem);

    return menuItems;
  }

  getStateColor(): string {
    if (this.accountNumberState === AccountNumberState.draft) {
      return 'text-primary';
    }
    else if (this.accountNumberState === AccountNumberState.active) {
      return 'text-alimento-green';
    }
    else if (this.accountNumberState === AccountNumberState.notActive) {
      return 'font-bold'
    }
    else {
      return "";
    }
  }
}
