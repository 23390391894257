import { Component, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  InfiniteScrollDataAdapter,
  LazyDropdownComponent,
  PaginatedResponse,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { InterimOffice, InterimOfficeListItem } from '../../../types/branch.type';
import { InterimOfficeService } from '../../../services/interim-office.service';

@Component({
    selector: 'alimento-ipv-frontend-interim-office-select',
    templateUrl: './interim-office-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InterimOfficeSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InterimOfficeSelectComponent),
            multi: true
        }
    ],
    standalone: false
})
export class InterimOfficeSelectComponent implements ControlValueAccessor, OnDestroy {
  @Input()
  formControl: FormControl<InterimOfficeListItem> = new FormControl<InterimOfficeListItem>(undefined);

  private _subscriptions: Subscription[] = [];
  private NUMBER_OF_LOADED_ITEMS = 30;

  @ViewChild(LazyDropdownComponent)
  private lazyDropdownComponent: LazyDropdownComponent;

  data$: InfiniteScrollDataAdapter<InterimOfficeListItem>;

  constructor(private interimOfficeService: InterimOfficeService) {
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value?.id);
        this.onTouched();
      })
    );
    this._createDataSource();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  focus(): void {
    this.lazyDropdownComponent.setFocus();
  }

  getSelectedString = (interimOffice: InterimOfficeListItem) => (interimOffice.name);

  _createDataSource(): void {
    const searchRequest: any = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };
    searchRequest.filters.push({type: FilterType.isBlocked, values: ['false']});

    this.data$ = new InfiniteScrollDataAdapter<InterimOfficeListItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<InterimOfficeListItem>> => {
      return this.interimOfficeService.getInterimOffices(searchRequest);
    }, searchRequest, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: string) {
    if (value) {
      this.interimOfficeService
        .getInterimOffice(value)
        .pipe(first())
        .subscribe((interimOffice) =>
          this.formControl.setValue(this._getInterimOfficeListItem(interimOffice), { emitEvent: false }));
    }
  }

  private _getInterimOfficeListItem(interimOffice: InterimOffice): InterimOfficeListItem {
    return {
      id: interimOffice.id,
      name: interimOffice.name,
      city: interimOffice.city,
      number: interimOffice.houseNumber,
      postalCode: interimOffice.postalCode,
      street: interimOffice.street,
      blocked: interimOffice.blocked
    };
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.formControl.valid ? null : { branch: { valid: false } };
  }

  clear(): void {
    this.formControl.setValue(null);
  }
}
