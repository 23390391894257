import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, signal,
  Signal,
  SimpleChanges,
  WritableSignal
} from '@angular/core';
import { KeyValue } from '@angular/common';
import {
  EnrollmentAction,
  EnrollmentListItem,
  EnrollmentRequest,
  EnrollmentRequestAction, ValidationRequest
} from '../../../types/enrollment.type';
import { Training } from '../../../types/training.type';
import {
  EnrollmentRequestStatusKey,
  EnrollmentStatusKey, getEnrollmentRequestStatusKey,
  getEnrollmentStatusKey
} from '../../../types/reference-data.enum';
import { detailAccordion } from '@alimento-ipv-frontend/environments';

@Component({
    selector: 'alimento-ipv-frontend-enrollments-overview',
    templateUrl: './enrollments-overview.component.html',
    standalone: false
})
export class EnrollmentsOverviewComponent implements OnInit, OnChanges {
  @Input()
  enrollments: EnrollmentListItem[] = [];

  @Input()
  enrollmentRequests: EnrollmentRequest[] = [];

  @Input()
  readOnly = false;

  @Input()
  training: Signal<Training>;

  @Input()
  isOpenExternOrCustom = false;

  @Input()
  validationRequest: ValidationRequest;


  @Output()
  enrollmentAction = new EventEmitter<EnrollmentAction>();

  @Output()
  enrollmentRequestAction = new EventEmitter<EnrollmentRequestAction>();

  enrollmentsMap: Map<string, EnrollmentListItem[]>;
  enrollmentRequestsMap: Map<string, EnrollmentRequest[]>;
  activeIndex: WritableSignal<string> = signal("0");
  openEnrollments: string[] = [
    EnrollmentStatusKey.ToValidate,
    EnrollmentStatusKey.Enrolled,
    EnrollmentStatusKey.Reserve,
    EnrollmentStatusKey.Cancelled
  ].map(key => getEnrollmentStatusKey(key).toLowerCase());

  openEnrollmentRequests: string[] = [
    EnrollmentRequestStatusKey.toVerify,
    EnrollmentRequestStatusKey.toValidate,
    EnrollmentRequestStatusKey.confirmed,
    EnrollmentRequestStatusKey.cancelled,
  ].map(key => getEnrollmentRequestStatusKey(key).toLowerCase());

  private statusOrder = [
    EnrollmentStatusKey.ToValidate,
    EnrollmentStatusKey.Enrolled,
    EnrollmentStatusKey.Reserve,
    EnrollmentStatusKey.Cancelled
  ].map(key => getEnrollmentStatusKey(key).toLowerCase());

  private requestStatusOrder = [
    EnrollmentRequestStatusKey.toVerify,
    EnrollmentRequestStatusKey.toValidate,
    EnrollmentRequestStatusKey.confirmed,
    EnrollmentRequestStatusKey.cancelled,
  ].map(key => getEnrollmentRequestStatusKey(key).toLowerCase());

  protected readonly detailAccordion = detailAccordion;

  ngOnInit(): void {
    this.createEnrollmentsMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enrollments']?.currentValue) {
      this.createEnrollmentsMap();
    }

    if (changes['enrollmentRequests']?.currentValue) {
      this.createEnrollmentRequestsMap();

      this.activeIndex.set("" + (this.enrollmentRequests.filter(request =>
        request.stateId === EnrollmentRequestStatusKey.toVerify ||
        request.stateId === EnrollmentRequestStatusKey.toValidate).length > 0 ? 0 : 1));
    }
  }

  private createEnrollmentsMap(): void {
    this.enrollmentsMap = new Map<string, EnrollmentListItem[]>();

    this.enrollments.forEach((enrollment) => {
      const status: string = getEnrollmentStatusKey(enrollment.statusId as EnrollmentStatusKey).toLowerCase();

      if (!this.enrollmentsMap.get(status)) {
        this.enrollmentsMap.set(status, []);
      }
      this.enrollmentsMap.get(status).push(enrollment);
    });
  }

  private createEnrollmentRequestsMap(): void {
    this.enrollmentRequestsMap = new Map<string, EnrollmentRequest[]>();

    this.enrollmentRequests.forEach((enrollmentRequest) => {
      const status: string = getEnrollmentRequestStatusKey(enrollmentRequest.stateId as EnrollmentRequestStatusKey).toLowerCase();

      if (!this.enrollmentRequestsMap.get(status)) {
        this.enrollmentRequestsMap.set(status, []);
      }
      this.enrollmentRequestsMap.get(status).push(enrollmentRequest);
    });
  }

  orderByStatus = (a: KeyValue<string,any>, b: KeyValue<string,any>): number => {
    return this.statusOrder.indexOf(a.key) - this.statusOrder.indexOf(b.key);
  }

  orderRequestsByStatus = (a: KeyValue<string,any>, b: KeyValue<string,any>): number => {
    return this.requestStatusOrder.indexOf(a.key) - this.requestStatusOrder.indexOf(b.key);
  }
}
