<p-dialog
  *ngIf='popupVisible'
  (blur)='popupVisible = false'
  (onHide)='closePopup()'
  [(visible)]='popupVisible'
  [closable]='false'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [focusOnShow]='false'
  [style]="{ width: '80rem' }"
  appendTo='body'
  header="{{'enrollments.branch.validateBranch' | translate}}"
>

  <div *ngIf='request'>
    <div class='mb-4'>
      <h2 class='text-[21px] font-bold mb-4'>{{ 'enrollments.branch.enrolledBranch' | translate }}</h2>
      <div class='flex flex-col gap-1 ml-1'>
        <span>{{ 'enrollments.branch.name' | translate }}: {{ request.branchName }}</span>
        <span *ngIf='request.vatNumber'>
          {{ 'enrollments.branch.vatNumber' | translate }}: {{ request.vatNumber }}
        </span>
        <span *ngIf='request?.rszNumber'>
          {{ 'enrollments.branch.rszNumber' | translate }}: {{ request?.rszNumber }}
        </span>
        <div class='flex gap-1'>
          <span>
            {{ 'enrollments.branch.address' | translate }}:
          </span>
          <div class='flex gap-1'>
            <span>{{ request.branchStreet }}</span>
            <span>{{ request.branchNumber }},</span>
            <span *ngIf='request.branchMailbox'>bus {{ request.branchMailbox }}</span>
            <span>{{ request.branchPostalCode }}</span>
            <span>{{ request.branchCity }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class='flex flex-col gap-2'>
      <div class='flex flex-col gap-2'>
        <div class='field-radiobutton'>
          <p-radioButton
            [formControl]='branchFormControl'
            inputId='existing'
            name='branchMatch'
            value='existing'
          ></p-radioButton>
          <label for='existing'>{{ 'enrollments.branch.existing' | translate }}</label>
        </div>

        <div *ngIf='branchFormControl.value === "existing"'>
          <alimento-ipv-frontend-branch-select
            [formControl]="searchBranchFormControl"
          ></alimento-ipv-frontend-branch-select>

          <div *ngIf='branch' class='mt-4'>
            <alimento-ipv-frontend-branch-search-card [branchListItem]='branch'
                                                      [selected]='true'
                                                      [selectable]='false'
            ></alimento-ipv-frontend-branch-search-card>
          </div>
        </div>
      </div>
      <div class='field-radiobutton'>
        <p-radioButton
          [formControl]='branchFormControl'
          inputId='no-branch'
          name='branchMatch'
          value='no-branch'
        ></p-radioButton>
        <label for='no-branch'>{{ 'enrollments.branch.noBranch' | translate }}</label>
      </div>
    </div>
  </div>

  <ng-template pTemplate='footer'>
    <div class='flex items-center gap-2 w-full'>
      <p-button
        *ngIf='request'
        (onClick)='addOrUpdateForm()'
        label="{{ 'enrollments.branch.validateBranch' | translate }}"
      ></p-button>
      <p-button
        (onClick)='closePopup()'
        label="{{ 'persons.transitions.actions.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
