<div *ngIf='!showEmployments' class="items-center gap-2 grid grid-cols-[minmax(0,_1fr)_200px]">
  <div isEllipsis pTooltip>
    <span *ngIf="person">{{ person.lastName }} {{ person.firstName }}</span>
    <span *ngIf="!person" class="font-bold">{{ 'persons.name' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="person && person.nationalIdentificationNumber">{{ person.nationalIdentificationNumber | insz }}</span>
    <span *ngIf="person && !person.nationalIdentificationNumber">{{ person.dateOfBirth | date : 'dd/MM/yyyy' }}</span>
    <span *ngIf="!person" class="font-bold">{{ 'persons.idNoOrDateOfBirth' | translate }}</span>
  </div>
</div>

<div *ngIf='showEmployments'
     class="shadow-grey-2 shadow-md p-4 relative border border-input-border cursor-pointer
{{selected ? 'bg-primary bg-opacity-20' : 'bg-white hover:bg-primary-hover'}}">
  <div class='grid grid-cols-[minmax(0,_1fr)_minmax(0,_2fr)] gap-4 items-start'>
    <div class='flex items-center gap-x-4 flex-wrap'>
      <span class='font-bold whitespace-pre-line'>{{person?.personAlimentoId}} - {{person?.lastName}} {{person?.firstName}}</span>
      <span>{{person?.nationalIdentificationNumber | insz}}</span>
    </div>
    <div>
      <div *ngFor='let employment of (person?.employments || [])' class='grid gap-8
            {{showExtraInfo ? "grid-cols-[minmax(0,_3fr)_minmax(0,_2fr)_100px]" : "grid-cols-[minmax(0,_3fr)_minmax(0,_2fr)]"}}'>
        <div class='flex items-end flex-wrap gap-x-2'>
          <span class='font-bold whitespace-pre-line'>{{employment.branchName}}</span>
          <span class='text-sm leading-5 whitespace-pre-line'>{{employment.branchCity}}</span>
          <i *ngIf='employment.isMainEmployment' class='font-material'>star</i>
          <span class="text-danger"
                *ngIf='employment.endDate'>
            {{'persons.employments.inactiveSince' | translate}} {{employment.endDate | date:'dd/MM/yyyy'}}
          </span>
        </div>
        <span *ngIf='!employment.workStatusId' class='whitespace-pre-line'>{{employment.workStatus?.label}}</span>
        <span *ngIf='employment.workStatusId' class='whitespace-pre-line'>{{getWorkStatus(employment.workStatusId) | async}}</span>
        <div class='flex items-center gap-2' *ngIf='showExtraInfo'>
          <div class='w-4'>
            <i *ngIf='employment.isTeacher'
               pTooltip='{{"persons.isTeacher" | translate}}'
               tooltipPosition='bottom'
               tooltipStyleClass='max-w-max'
               class='font-material'>school</i>
          </div>
          <div class='w-4'>
            <i *ngIf='employment.isContactPerson'
               pTooltip='{{"persons.isContactPerson" | translate}}'
               tooltipPosition='bottom'
               tooltipStyleClass='max-w-max'
               class='font-material'>contact_mail</i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class='absolute right-4 top-4'>
    <a *ngIf='showExternalLink'
       [routerLink]='["/persons", person?.personId]'
       target='_blank'
       class='pi pi-external-link'></a>
  </div>
</div>
