import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';
import { Reimbursement, ReimbursementActionEvent } from '../../../types/reimbursement-request.type';
import { REIMBURSEMENT_ACTION_ENUM } from '../../../types/reimbursement-request.enum';
import { ContactPerson } from '../../../types/branch.type';

@Component({
    selector: 'alimento-ipv-frontend-reimbursement-action-dialog',
    templateUrl: './reimbursement-action-dialog.component.html',
    standalone: false
})
export class ReimbursementActionDialogComponent implements OnChanges {
  @Input()
  reimbursement: Reimbursement;

  @Input()
  action: REIMBURSEMENT_ACTION_ENUM;

  @Input()
  selectedContactPersons: ContactPerson[];

  @Output()
  submitDialog = new EventEmitter<ReimbursementActionEvent>();

  @ViewChild("focusElement")
  focusElement: ElementRef;

  dialogVisible = false;
  loading = false;
  formGroup: FormGroup;
  type: string;
  contactPersonsWithNoEmail: ContactPerson[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MyMessageService,
    private referenceDataService: ReferenceDataService
  ) {
    this.formGroup = this.formBuilder.group({
      comments: [null]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reimbursement']?.currentValue) {
      if (this.reimbursement.reimbursementTypeId) {
        this.referenceDataService.getReimbursementType(this.reimbursement.reimbursementTypeId).pipe(first())
          .subscribe(type => this.type = type.label);
      }
    }

    if (changes['selectedContactPersons']?.currentValue) {
      this.contactPersonsWithNoEmail = this.selectedContactPersons.filter(contactPerson => !contactPerson.email);
    }
  }

  open(): void {
    this.dialogVisible = true;
    setTimeout(() => this.focusElement?.nativeElement.focus());
  }

  submit(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.submitDialog.emit({
      id: this.reimbursement.reimbursementId,
      data: this.formGroup.value,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset();
  }

  getTitle(): string {
    return 'reimbursements.' + this.action;
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getActionText(): string {
    if (this.action === REIMBURSEMENT_ACTION_ENUM.confirmForPayment) {
      return 'reimbursements.confirmForPaymentText';
    }
    return '';
  }
}
