<div *ngIf='formGroup' [formGroup]='formGroup'>
  <alimento-ipv-frontend-header
    title="{{ 'trainings.basicData.formTitle' | translate }}"
  ></alimento-ipv-frontend-header>

  <h3>{{ 'trainings.basicData.general' | translate }}</h3>

  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    [errorTemplate]='customTitleError'
                                    [required]='true'
                                    formField='customTitle'
                                    fieldKey='trainings.customTitle'
  >
    <span class='read-only-field' *ngIf='formGroup.disabled'>{{formGroup.get("customTitle")?.value}}</span>
    <input *ngIf='formGroup.enabled'
           id="customTitle"
           formControlName="customTitle"
           type="text"
           pInputText
           class='w-full'
           placeholder="{{ 'trainings.titlePlaceholder' | translate }}"
    />
    <ng-template #customTitleError>
      <small *ngIf="formGroup.get('customTitle')?.hasError('required')">
        {{ 'validation.isRequired' | translate : { field: 'trainings.title' | translate | lowercase } }}
      </small>
      <small *ngIf="formGroup.get('customTitle')?.hasError('maxlength')">
        {{ 'validation.maxLength' | translate : { number: 200 } }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <div class='max-w-[650px]'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      [hideError]='true'
                                      [includeTouchedASInvalid]='isEventType(trainingType)'
                                      formField='languageCode'
                                      fieldKey='trainings.basicData.language'
                                      labelClass='{{isActiveProject ? "" : "hidden"}}'
    >
      <span *ngIf='isActiveProject' class='read-only-field'>{{ formGroup.get("languageCode")?.value }}</span>
      <alimento-ipv-frontend-language-code formControlName='languageCode'
                                           *ngIf='!isActiveProject'
                                           [required]='showRequiredFieldErrors'
                                           [showExtraLanguages]='true'>
      </alimento-ipv-frontend-language-code>
    </alimento-ipv-frontend-form-field>

    <div *ngIf="formGroup.get('languageCode')?.value === DUTCH_LANGUAGE_CODE && !isEventType(trainingType)"
         class='conditional-fields'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [includeTouchedASInvalid]='false'
        [required]='true'
        formField='typeId'
        fieldKey='reimbursementRequests.type'
      >
        <span class='read-only-field' *ngIf='formGroup.disabled || isActiveProject'>{{ type?.label }}</span>
        <p-select
          *ngIf='formGroup.enabled && !isActiveProject'
          [filter]='true'
          [options]='types'
          [showClear]='true'
          formControlName='typeId'
          id='typeId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        ></p-select>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='!isEventType(trainingType)'
      [includeTouchedASInvalid]='isEventType(trainingType)'
      formField='subjectId'
      fieldKey='trainings.basicData.subjectId'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ subjectType?.label }}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='subjectTypes'
        [showClear]='true'
        formControlName='subjectId'
        id='subjectId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [includeTouchedASInvalid]='isEventType(trainingType)'
      [required]='true'
      formField='methodId'
      fieldKey='trainings.basicData.method'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ method?.label }}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='methods'
        [showClear]='true'
        formControlName='methodId'
        id='methodId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>

      <span class='text-grey-4 text-[12px]'>{{ 'trainings.basicData.methodInfo' | translate }}</span>
    </alimento-ipv-frontend-form-field>

    <div class='flex flex-col gap-2 mb-4'>
      <span *ngIf='firstSessionDate'>{{ "trainings.start" | translate }}
        : {{ firstSessionDate | date:'dd/MM/yyyy' }}</span>
      <span *ngIf='lastSessionDate'>{{ "trainings.end" | translate }}: {{ lastSessionDate | date:'dd/MM/yyyy' }}</span>
    </div>
  </div>

  <div class='max-w-[650px]'>
    <h3>{{ 'trainings.basicData.management' | translate }}</h3>
    <div class='grid lg:grid-cols-2 gap-x-4'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        [includeTouchedASInvalid]='isEventType(trainingType)'
        formField='caseManagerId'
        fieldKey='trainings.basicData.caseManager'
      >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{ caseManager?.label }}
        <span *ngIf='caseManager && !caseManager.isActive' class='text-danger italic ml-2'>
          {{ "trainings.basicData.inactive" | translate }}
        </span>
      </span>
        <p-select
          *ngIf='formGroup.enabled'
          #caseManagerField
          [filter]='true'
          [options]='caseManagers'
          [showClear]='true'
          (onClear)='formGroup.get("caseManagerId").markAsTouched()'
          formControlName='caseManagerId'
          id='caseManagerId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        >
          <ng-template pTemplate='selectedItem'>
            <div *ngIf='caseManager'>
            <span>{{ caseManager?.label }}
              <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
            </div>
          </ng-template>
          <ng-template let-caseManager pTemplate='item'>
            <div *ngIf='caseManager'>
           <span>{{ caseManager.label }}
             <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
            </div>
          </ng-template>
        </p-select>
      </alimento-ipv-frontend-form-field>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='caseManagerBackupId'
        fieldKey='trainings.basicData.backup'
      >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{ caseManagerBackup?.label }}
        <span *ngIf='caseManagerBackup && !caseManagerBackup.isActive' class='text-danger italic ml-2'>
          {{ "trainings.basicData.inactive" | translate }}
        </span>
      </span>
        <p-select
          #caseManagerBackupField
          *ngIf='formGroup.enabled'
          [filter]='true'
          [options]='caseManagerBackups'
          [showClear]='true'
          (onClear)='formGroup.get("casemanagerBackupId").markAsTouched()'
          formControlName='caseManagerBackupId'
          id='caseManagerBackupId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        >
          <ng-template pTemplate='selectedItem'>
            <div *ngIf='caseManagerBackup'>
           <span>{{ caseManagerBackup?.label }}
             <span *ngIf='!caseManagerBackup.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
            </div>
          </ng-template>
          <ng-template let-caseManager pTemplate='item'>
            <div *ngIf='caseManager'>
           <span>{{ caseManager.label }}
             <span *ngIf='!caseManager.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
            </div>
          </ng-template>
        </p-select>
      </alimento-ipv-frontend-form-field>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [includeTouchedASInvalid]='isEventType(trainingType)'
        [required]='true'
        formField='counselorId'
        fieldKey='trainings.basicData.counselor'
      >
      <span class='read-only-field' *ngIf='formGroup.disabled'>
        {{ counselor?.label }}
        <span *ngIf='counselor && !counselor.isActive' class='text-danger italic ml-2'>
          {{ "trainings.basicData.inactive" | translate }}
        </span>
      </span>
        <p-select
          *ngIf='formGroup.enabled'
          #counselorField
          [filter]='true'
          [options]='counselors'
          [showClear]='true'
          (onClear)='formGroup.get("counselorId").markAsTouched()'
          formControlName='counselorId'
          id='counselorId'
          optionLabel='label'
          optionValue='data'
          placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
          styleClass='w-full'
          appendTo='body'
        >
          <ng-template pTemplate='selectedItem'>
            <div *ngIf='counselor'>
           <span>{{ counselor?.label }}
             <span *ngIf='!counselor.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
            </div>
          </ng-template>
          <ng-template let-counselor pTemplate='item'>
            <div *ngIf='counselor'>
           <span>{{ counselor.label }}
             <span *ngIf='!counselor.isActive' class='text-danger italic ml-2'>
                {{ "trainings.basicData.inactive" | translate }}
              </span>
            </span>
            </div>
          </ng-template>
        </p-select>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      [includeTouchedASInvalid]='isEventType(trainingType)'
      formField='subsidizedId'
      fieldKey='trainings.basicData.subsidizedId'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ subsidyType?.label }}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='subsidyTypes'
        [showClear]='true'
        formControlName='subsidizedId'
        id='subsidizedId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <div *ngIf='isEventType(trainingType)'>
      <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                        [includeTouchedASInvalid]='isEventType(trainingType)'
                                        formField='organizationId'
                                        fieldKey='trainings.basicData.organizationId'
      >
        <alimento-ipv-frontend-branch-select
          formControlName='organizationId'
          placeholder='trainings.basicData.organizationIdPlaceholder'
        ></alimento-ipv-frontend-branch-select>
      </alimento-ipv-frontend-form-field>
    </div>

    <alimento-ipv-frontend-form-field
      *ngIf='formGroup.contains("educationLevelIds")'
      [formGroup]='formGroup'
      [required]='true'
      [errorTemplate]='educationErrorTemplate'
      [includeTouchedASInvalid]='isEventType(trainingType)'
      formField='educationLevelIds'
      fieldKey='trainings.basicData.educationLevel'
    >
      <alimento-ipv-frontend-education-level-dialog
        formControlName='educationLevelIds'
      ></alimento-ipv-frontend-education-level-dialog>
      <ng-template #educationErrorTemplate>
        <small *ngIf='formGroup.get("educationLevelIds")?.hasError("required")'>
          {{ 'error.field-required' | translate: { field: 'trainings.basicData.educationLevel' | translate } }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [errorTemplate]='maxParticipantsError'
      [required]='true'
      [includeTouchedASInvalid]='isEventType(trainingType)'
      formField='maxParticipants'
      fieldKey='trainings.basicData.maxNrParticipants'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{ formGroup.get('maxParticipants')?.value }}</span>
      <p-inputNumber
        *ngIf='formGroup.enabled'
        [min]='0'
        formControlName='maxParticipants'
        id='maxParticipants'
        inputStyleClass='w-full'
      ></p-inputNumber>
      <ng-template #maxParticipantsError>
        <small *ngIf="formGroup.get('maxParticipants')?.hasError('max')">
          {{ 'validation.maxValue' | translate : { number: 1000 } }}
        </small>

        <small *ngIf="formGroup.get('maxParticipants')?.hasError('lowerThanMinParticipants')">
          {{
            'validation.maxParticipantsTooLow'
              | translate : { field: 'trainings.basicData.minParticipants' | translate }
          }}
        </small>

        <small *ngIf="formGroup.get('maxParticipants')?.hasError('lowerThanMaxParticipantsPerCompany')">
          {{
            'validation.maxParticipantsTooLow'
              | translate : { field: 'trainings.basicData.maxParticipantPerCompany' | translate }
          }}
        </small>

        <small *ngIf="formGroup.get('maxParticipants')?.hasError('required')">
          {{
            'validation.isRequired'
              | translate : { field: 'trainings.basicData.maxParticipants' | translate | lowercase }
          }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      *ngIf='!isEventType(trainingType) && formGroup.contains("themeIds")'
      [formGroup]='formGroup'
      formField='themeIds'
      fieldKey='trainings.basicData.themes'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{ themesReadOnly }}</span>
      <p-multiSelect
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='themes'
        [showClear]='true'
        formControlName='themeIds'
        id='themeIds'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
      ></p-multiSelect>
    </alimento-ipv-frontend-form-field>
  </div>
</div>
