import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, of, Subscription } from 'rxjs';
import {
  FEATURE,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest, TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { SearchEnterpriseItem } from '../../../types/enterprise.type';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';
import { TranslateService } from '@ngx-translate/core';
import { SearchesService } from '../../../services/searches.service';

@Component({
    selector: 'alimento-ipv-frontend-enterprise-list',
    templateUrl: './enterprise-list.component.html',
    standalone: false
})
export class EnterpriseListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<SearchEnterpriseItem>;
  searchFilters: SearchFilter[];
  private _subscription: Subscription;

  protected readonly FEATURE = FEATURE;

  constructor(private searchesService: SearchesService,
              private branchesMapper: BranchesMapper,
              private translationService: TranslationService,
              private translateService: TranslateService,) {
  }

  ngOnInit(): void {
    this._subscription = this.translationService.languageChange$.subscribe(() =>
      this._createSearch()
    )
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    this.searchData$ = new InfiniteScrollDataAdapter<SearchEnterpriseItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchEnterpriseItem>> => {
      return this.searchesService.searchEnterprises(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.status',
        key: FilterType.filterBranchState,
        data: this.branchesMapper.getStatesFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.category',
        key: FilterType.filterBranchCategory,
        hasIcon: true,
        iconMapper: (value) => this.branchesMapper.getCategoryOptions()
          .filter(category => category.value === value)[0].icon,
        data: of(this.branchesMapper.getCategoryOptions(true))
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterBranchCategory,
              label: type.label,
              value: type.value
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'branches.numberOfEmployees',
        key: FilterType.filterNumberEmployees,
        data: this.branchesMapper.getNumberOfEmployeesOptions()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enterprises.toValidateAccountNumbers',
        key: FilterType.filterAccountNumbersToValidate,
        data: of([
          {
            type: FilterType.filterAccountNumbersToValidate,
            label: this.translateService.instant('yes'),
            value: 'true'
          } as IFilter,
          {
            type: FilterType.filterAccountNumbersToValidate,
            label: this.translateService.instant('no'),
            value: 'false'
          } as IFilter
        ])
      }
    ];
  }
}
