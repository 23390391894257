<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.salesInvoices.approveTitle' | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="salesInvoice">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.salesInvoices.attendee" | translate}}'
        value='{{salesInvoice.enrollment?.firstName}} {{salesInvoice.enrollment?.lastName}}'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        header='{{"trainings.salesInvoices.amount" | translate}}'
        [value]='salesInvoice.amount | currency:"EUR"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div>
    <span>{{"trainings.salesInvoices.approveInfo" | translate}}</span>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="cancelTraining()"
        [loading]='loading'
        label="{{ 'trainings.salesInvoices.approve' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.salesInvoices.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
