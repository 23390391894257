export enum TRAINING_SORT_FIELDS {
  ALIMENTO_ID = 'alimentoid',
  START_DATE = 'startdate',
}

export enum TRAINING_PROJECT_AUDIENCE {
  PUPIL = 'pupil',
  TEACHER = 'teacher'
}

export enum SALES_INVOICE_ACTION {
  view,
  edit,
  approve
}

export enum SALES_INVOICE_CLIENT_TYPE {
  BRANCH = 'branch',
  PERSON = "person"
}

export enum SALES_INVOICE_STATUS {
  DRAFT = "ffb896a1-e0d3-4668-bb3d-d964de1377f4",
  APPROVED = "685174c9-0e1a-4939-af51-aac0c7575267"
}

export enum TRAINING_STATUS {
  DRAFT = "2c1f4fb7-7541-44c7-bb56-6e62c5c67d3f",
  ACTIVE = "b59d0747-9250-409f-b710-3440f0c59871",
  CANCELLED = "a234b2d5-ddb7-4db0-9677-412e29f236e6",
  DELETED = "34065c15-a7f4-4736-be6d-8b3e0e161892",
  CLOSED = "4144a3de-454b-4f69-ba6f-310237a438fa"
}
