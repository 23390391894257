import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { first, Subscription } from 'rxjs';
import {
  DateMapper,
  dateValid,
  MyMessageService,
  validateAllFormFields,
  validateMinValue
} from '@alimento-ipv-frontend/ui-lib';
import { ContactPerson, TrainingPlan, TrainingPlanUpdateEvent } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { TRAINING_PLAN_STATE } from '../../../types/branch.enum';

@Component({
    selector: 'alimento-ipv-frontend-training-plan-request-popup',
    templateUrl: './training-plan-request-popup.component.html',
    standalone: false
})
export class TrainingPlanRequestPopupComponent implements OnDestroy {
  @Input()
  branchId?: string;

  @Output()
  formSubmit = new EventEmitter<TrainingPlanUpdateEvent>();

  latestTrainingPlan?: TrainingPlan;
  contactPersons: ContactPerson[] = [];
  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;

  REQUEST_TYPE_CURRENT = "current";
  REQUEST_TYPE_PERIOD = "period";

  private _subscriptions: (Subscription | undefined)[] = [];

  constructor(private fb: FormBuilder,
              private branchService: BranchService,
              private messageService: MyMessageService) {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(latestTrainingPlan?: TrainingPlan): void {
    this.popupVisible = true;
    this.latestTrainingPlan = latestTrainingPlan;
    this.createForm();
    this.branchService.getContactPersons(this.branchId)
      .subscribe(contactPersons => this.contactPersons = contactPersons);

    if (this.latestTrainingPlan?.stateId === TRAINING_PLAN_STATE.REQUESTED) {
      this.formGroup.get("requestType").setValue(this.REQUEST_TYPE_PERIOD);
      this.formGroup.get("requestType").disable();
    }
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid; // && this.contactPersonHasEmail;
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));

    if (this.formGroup.get("requestType").value === this.REQUEST_TYPE_PERIOD) {
      data.validFrom = DateMapper.getDateFromDateTimeAsString(data.validFrom);
      data.validTo = DateMapper.getDateFromDateTimeAsString(data.validTo);
    }
    else {
      delete data.validFrom;
      delete data.validTo;
    }
    delete data.requestType;

    return data;
  }

  requestTrainingPlan(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      trainingPlan: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      contactPersonId: [null, [Validators.required, this.validContactPerson()]],
      validFrom: [undefined],
      validTo: [undefined],
      requestType: [this.REQUEST_TYPE_CURRENT]
    });

    this._updateDatesValidations();
    this._addSubscriptions();
  }

  private _addSubscriptions(): void {
    this._subscriptions.push(
      this.formGroup.get('validFrom')?.valueChanges.subscribe(() => {
        this.formGroup.get('validTo')?.updateValueAndValidity();
      })
    );
    this._subscriptions.push(
      this.formGroup.get("requestType").valueChanges.subscribe(() => this._updateDatesValidations())
    );
  }

  private _updateDatesValidations(): void {
    if (this.formGroup.get('requestType').value === this.REQUEST_TYPE_PERIOD) {
      this.formGroup.get('validFrom').setValidators([Validators.required, dateValid()]);
      this.formGroup.get('validTo').setValidators([Validators.required, validateMinValue(this.formGroup.get('validFrom')), dateValid()]);
    }
    else {
      this.formGroup.get('validFrom').setValidators([]);
      this.formGroup.get('validTo').setValidators([]);
    }

    this.formGroup.get('validFrom').updateValueAndValidity();
    this.formGroup.get('validTo').updateValueAndValidity();
  }

  sendReminder(): void {
    this.branchService.sendTrainingPlanReminder(this.latestTrainingPlan.id).pipe(first())
      .subscribe(() => {
        this.messageService.success();
        this.closePopup();
      });
  }

  private validContactPerson(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const person = this.contactPersons
          .filter(contactPerson => contactPerson.personId === control.value)[0];
        if (!person.email) {
          return { invalidContactPerson: true };
        }
      }
      return null;
    };
  }
}
