<alimento-ipv-frontend-overview-page-layout header='enterpriseGroups.menuTitle'>
  <ng-container top-right>
    <p-button
      label="{{ 'enterpriseGroups.actions.add' | translate }}"
      [routerLink]="['create']"
    ></p-button>
  </ng-container>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              name='enterpriseGroups'
              searchPlaceholderKey='enterpriseGroups.searchPlaceholder'
              availableResultsTranslateKey='enterpriseGroups.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-enterprise-group-card
        [enterpriseGroup]='item'
        [routerLink]='["/enterprise-groups", item.groupId]'
        tabindex="0"
      ></alimento-ipv-frontend-enterprise-group-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
