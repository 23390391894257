import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-body-content',
    templateUrl: './body-content.component.html',
    standalone: false
})
export class BodyContentComponent implements AfterViewInit,  OnDestroy {

  @ViewChild('contentRef')
  public contentRef!: TemplateRef<any>;

  @Input()
  removeIfScrollOutside = false;

  private viewContainerRef: ViewContainerRef;

  constructor(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  public ngAfterViewInit(): void {
    const embeddedViewRef = this.viewContainerRef.createEmbeddedView(this.contentRef);
    embeddedViewRef.detectChanges();

    for (const node of embeddedViewRef.rootNodes) {
      document.body.appendChild(node);
    }
  }

  ngOnDestroy(): void {
    this.viewContainerRef.clear();
  }
}
