import {
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DateMapper,
  dateValid,
  minLengthArray,
  MyMessageService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { TRANSITION_STATE, TRANSITION_TYPE, TransitionActionEnum, WORK_STATUS } from '../../../types/person.enum';
import {
  Employment,
  Person,
  Transition,
  TransitionItem,
  TransitionType,
  TransitionUpdateEvent
} from '../../../types/person.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first, Subscription } from 'rxjs';
import { EmploymentService } from '../../../services/employment.service';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'alimento-ipv-frontend-transition-popup',
    templateUrl: './transition-popup.component.html',
    standalone: false
})
export class TransitionPopupComponent implements OnDestroy {
  @Input()
  person: Person;

  @Input()
  transitionAction: TransitionActionEnum = TransitionActionEnum.view;

  @Output()
  formSubmit = new EventEmitter<TransitionUpdateEvent>();

  transition?: TransitionItem;
  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  typeReadOnly?: string;

  transitionTypes: TreeNode[];
  rubrics$ = this.referenceDataService.getTrainingRubrics();
  matchingEmployment: Employment;
  employments = computed(() =>
    [...this.employmentService.employments().current, ...this.employmentService.employments().previous]
      .filter(employment => employment.branchIsFood)
      .filter(employment => employment.workStatus?.data === WORK_STATUS.PC_118 || employment.workStatus?.data === WORK_STATUS.PC_220));

  parentTypeId: WritableSignal<TRANSITION_TYPE> = signal(undefined);
  typeId: WritableSignal<TRANSITION_TYPE> = signal(undefined);
  employment: WritableSignal<Employment> = signal(undefined);

  private _selectedNode: TreeNode;
  private _subscriptions: (Subscription | undefined)[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private employmentService: EmploymentService,
    private messageService: MyMessageService
  ) {
    effect(() => {
      if (this.parentTypeId() && this.typeId()) {
        this.employment();
        this._setRequiredAndDisabledFields(false);
        this._fillFieldsAfterSelectionTypeOrEmployment();
      }
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  openPopup(transition?: TransitionItem): void {
    this.transition = transition;
    this._selectedNode = undefined;
    this.popupVisible = true;
    this._createFormGroup();

    if (this.transition?.transitionStatusId !== TRANSITION_STATE.DRAFT) {
      this.matchingEmployment = [...this.employmentService.employments().current, ...this.employmentService.employments().previous]
        .filter(employment => employment.employmentId === this.transition?.employmentId)[0];
    }
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
    this.parentTypeId.set(undefined);
    this.employment.set(undefined);
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getFormData(): Transition {
    delete this.formGroup.value.typeId.parent;
    const data = JSON.parse(JSON.stringify(this.formGroup.value));

    ['startDate', 'endDate', 'dateOfBirth'].forEach(field => {
      data[field] = DateMapper.getDateFromDateTimeAsString(data[field]);
    });

    if (data.typeId?.data) {
      data.typeId = data.typeId.data;
    }

    return data;
  }

  addOrUpdateTransition(): void {
    this._submitForm(false);
  }

  activateTransition(): void {
    this._setRequiredAndDisabledFields(true);
    this._submitForm(true);
  }

  private _submitForm(isActivate: boolean): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      transitionId: this.transition?.id,
      transition: this.getFormData(),
      activate: isActivate,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      id: [this.transition?.id],
      employmentId: [this.transition?.employmentId, [Validators.required]],
      typeId: [this.transition?.transitionTypeId, [Validators.required]],
      rubricIds: [this.transition?.rubricIds],
      startDate: [this.transition?.startDate ? new Date(this.transition.startDate) : undefined,
        [dateValid()]],
      endDate: [this.transition?.endDate ? new Date(this.transition.endDate) : undefined,
        [dateValid()]],
      // totalBudget: [this.transition?.totalBudget, [Validators.required]],
      dateOfBirth: [this.transition?.dateOfBirth ? new Date(this.transition.dateOfBirth) : undefined,
        [dateValid()]],
      function: [this.transition?.function]
    });

    this._subscriptions.push(
      this.formGroup.get('employmentId')?.valueChanges.subscribe((employmentId) => this._setEmployment(employmentId))
    );

    if (this.transition?.employmentId) {
      this._setEmployment(this.transition?.employmentId);
    }

    this._setRequiredAndDisabledFields(false);
    this._setTransitionTypesAndParentType();
  }

  private _setRequiredAndDisabledFields(toActivate: boolean): void {
    const updateField = (field: string, required: boolean, otherValidators: any[] = []) => {
      this.formGroup.get(field)?.setValidators((toActivate && required) ? [...otherValidators, Validators.required] : otherValidators);
      this.formGroup.get(field)?.updateValueAndValidity();
      if (required) {
        this.formGroup.get(field)?.enable();
      }
      else {
        this.formGroup.get(field)?.disable();
      }
    };

    updateField('rubricIds', this.parentTypeId() === TRANSITION_TYPE.FUNCTION_CHANGE || this.parentTypeId() === TRANSITION_TYPE.RESUMPTION,
      [minLengthArray(1)]);
    updateField('startDate', this.parentTypeId() === TRANSITION_TYPE.IN, [dateValid()]);
    updateField('endDate', this.parentTypeId() === TRANSITION_TYPE.OUT, [dateValid()]);
    updateField('dateOfBirth', this.parentTypeId() === TRANSITION_TYPE.IN &&
      (this._selectedNode.data === TRANSITION_TYPE.YOUNG || this._selectedNode.data === TRANSITION_TYPE.OLD), [dateValid()]);
    updateField('function', this.parentTypeId() === TRANSITION_TYPE.FUNCTION_CHANGE);
  }

  private _fillFieldsAfterSelectionTypeOrEmployment(): void {
    if (this.parentTypeId() && this.employment()) {
      if (this.parentTypeId() === TRANSITION_TYPE.FUNCTION_CHANGE) {
        this.formGroup.get('function').setValue(this.employment().function);
      }
      if (this.parentTypeId() === TRANSITION_TYPE.IN &&
        (this._selectedNode.data === TRANSITION_TYPE.YOUNG || this._selectedNode.data === TRANSITION_TYPE.OLD)) {
        this.formGroup.get('dateOfBirth').setValue(this.person.dateOfBirth ? new Date(this.person.dateOfBirth) : undefined);
      }
      if (this.parentTypeId() === TRANSITION_TYPE.OUT) {
        this.formGroup.get('endDate').setValue(this.employment().endDate ? new Date(this.employment().endDate) : undefined);
      }
      if (this.parentTypeId() === TRANSITION_TYPE.IN) {
        this.formGroup.get('startDate').setValue(this.employment().startDate ? new Date(this.employment().startDate) : undefined);
      }
    }
  }

  private _setTransitionTypesAndParentType(): void {
    this.referenceDataService.getTransitionTypes().pipe(first())
      .subscribe(types => {
        this.transitionTypes = types.map(type => this._toTreeNodeAndGetParentType(type));
        if (this._selectedNode) {
          this.formGroup.get('typeId').setValue(this._selectedNode, { emitEvent: false });
        }
        else {
          this.formGroup.get('typeId').setValue(undefined, { emitEvent: false });
        }
        this.typeId.set(this.transition?.transitionTypeId as TRANSITION_TYPE);
        this.typeReadOnly = this._selectedNode?.label;
      });
  }

  private _toTreeNodeAndGetParentType(type: TransitionType): TreeNode {
    const node = {
      key: type.data,
      data: type.data,
      label: type.label,
      children: [] as TreeNode[],
      selectable: !type.transitionTypes || type.transitionTypes.length === 0
    };

    if (this.transition?.transitionTypeId && type.data === this.transition?.transitionTypeId) {
      this.parentTypeId.set(type.data as TRANSITION_TYPE);
      this._selectedNode = node;
    }

    type.transitionTypes?.forEach(subType => {
      const childNode = {
        key: subType.data,
        data: subType.data,
        label: subType.label,
        parent: node,
        leaf: true
      } as TreeNode;
      node.children.push(childNode);

      if (this.transition?.transitionTypeId && subType.data === this.transition?.transitionTypeId) {
        this.parentTypeId.set(type.data as TRANSITION_TYPE);
        this._selectedNode = childNode;
      }
    });
    return node;
  }

  private _setEmployment(employmentId: string): void {
    this.employment.set(employmentId ? this.employments()
      .filter(employment => employment.employmentId === employmentId)[0] : undefined);
  }

  closeUnselectedTreeItems(): void {
    const selectedItem = this.formGroup?.get('typeId')?.value;
    this.transitionTypes.forEach(treeItem => {
      treeItem.expanded = selectedItem?.parent?.data === treeItem.data;
    });
  }

  onTypeChange(node: TreeNode): void {
    if (node.parent) {
      this.parentTypeId.set(node.parent.data as TRANSITION_TYPE);
    }
    else {
      this.parentTypeId.set(node.data as TRANSITION_TYPE);
    }
    this.typeId.set(node.data as TRANSITION_TYPE);
    this._selectedNode = node;
  }

  protected readonly TRANSITION_STATE = TRANSITION_STATE;
}
