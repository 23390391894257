<div class="flex items-center gap-2 justify-between text-primary cursor-pointer"
     (click)="buttonClicked($event, menu)">
  <p-button [link]='true'
            styleClass='px-0'
            label="{{ label | translate }}"
  ></p-button>
  <i class='pi pi-chevron-down'></i>
</div>
<p-menu #menu [model]="generateItems" [popup]="true" >
  <ng-template pTemplate="item" let-item>
    <a class="p-menuitem-link flex gap-2 justify-between items-center p-3">
      <span>{{ item.label }}</span>
      <span class='font-material text-lg'>{{item.icon}}</span>
    </a>
  </ng-template>
</p-menu>
