import { Component, effect, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { first } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { EducationLevel } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { TreeSelect } from 'primeng/treeselect';

@Component({
    selector: 'alimento-ipv-frontend-education-level-dialog',
    templateUrl: './education-level-dialog.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: EducationLevelDialogComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: EducationLevelDialogComponent
        }
    ],
    standalone: false
})
export class EducationLevelDialogComponent implements ControlValueAccessor, Validator {
  dialogVisible = false;
  educationLevelSelection: TreeNode | TreeNode[] = [];
  educationLevels: WritableSignal<TreeNode[]> = signal(undefined);
  editEducationLevel: EducationLevel[] = [];
  currentEducationLevels: EducationLevel[] = [];
  disabled = false;
  formValue: WritableSignal<string[]> = signal(undefined);

  @ViewChild('tree')
  tree: TreeSelect;

  constructor(private referenceDataService: ReferenceDataService) {
    effect(() => {
      if (this.educationLevels() && this.formValue()) {
        this.currentEducationLevels = this._mapArraysToEducationLevels(this.formValue());
      }
    });
    this.referenceDataService.getEducationLevels().pipe(first())
      .subscribe(educationLevels =>
        this.educationLevels.set(educationLevels.map(educationLevel => this._toTreeNode(educationLevel))));
  }

  writeValue(value?: string[]): void {
    this.formValue.set(value);
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }

  onSubmit(): void {
    this.currentEducationLevels = this._mapTreeNodesToEducationLevels(this._getEducationLevelSelection());
    this.onChange(this._mapTreeToArray(this.currentEducationLevels));
    this.onTouched();
    this.dialogVisible = false;
  }

  updateFormControl = () => {
    this.currentEducationLevels = this.currentEducationLevels.filter(educationLevel => educationLevel.levels?.length > 0);
    this.onChange(this._mapTreeToArray(this.currentEducationLevels));
    this.onTouched();
  };

  updateEditEducationLevel = () => {
    this.editEducationLevel = this.editEducationLevel.filter(educationLevel => educationLevel.levels?.length > 0);
    this.educationLevelSelection = this._mapEducationLevelsToTreeNodes(this.editEducationLevel);
  };

  openPopup(): void {
    this.dialogVisible = true;
    this.editEducationLevel = JSON.parse(JSON.stringify(this.currentEducationLevels));
    this.educationLevelSelection = this._mapEducationLevelsToTreeNodes(this.currentEducationLevels);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : { educationLevelInvalid: true };
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChange(): void {
    this.editEducationLevel = this._mapTreeNodesToEducationLevels(this._getEducationLevelSelection());
  }

  expandAll(): void {
    this.educationLevels().forEach(node => node.expanded = true);
  }

  collapseAll(): void {
    this.educationLevels().forEach(node => node.expanded = false);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private _getEducationLevelSelection(): TreeNode[] {
    return this.educationLevelSelection as TreeNode[];
  }

  private _toTreeNode(educationLevel: EducationLevel): TreeNode {
    const node = {
      data: educationLevel.data,
      label: educationLevel.label,
      children: [] as TreeNode[]
    };

    educationLevel.levels?.forEach(subType => {
      node.children.push({
        data: subType.data,
        label: subType.label,
        parent: node
      } as TreeNode);
    });
    return node;
  }

  private _mapEducationLevelsToTreeNodes(educationLevels: EducationLevel[]): TreeNode[] {
    const treeNodes: TreeNode[] = [];
    this.educationLevels().forEach(node => node.partialSelected = false);
    educationLevels.forEach(educationLevel => {
      const matchingTreeNode = this.educationLevels()
        .filter(educationLevelTreeNode => educationLevelTreeNode.data === educationLevel.data)[0];

      if (educationLevel.levels?.length > 0) {
        treeNodes.push(matchingTreeNode);
        if (educationLevel.levels.length !== matchingTreeNode.children.length) {
          matchingTreeNode.partialSelected = true;
        }
      }

      educationLevel.levels?.forEach(subType => {
        treeNodes.push(matchingTreeNode.children
          .filter(subTypeTreeNode => subTypeTreeNode.data === subType.data)[0]);
      });
    });
    setTimeout(() => {
      this._removeHighlight();
    });
    return treeNodes;
  }

  private _mapTreeNodesToEducationLevels(treeNodes: TreeNode[]): EducationLevel[] {
    const educationLevels: EducationLevel[] = [];

    treeNodes.filter(educationLevel => educationLevel.parent)
      .forEach(subType => {
        let educationLevel = educationLevels.filter(parent => parent.data === subType.parent.data)[0];
        if (!educationLevel) {
          educationLevel = { data: subType.parent.data, label: subType.parent.label, levels: [] } as EducationLevel;
          educationLevels.push(educationLevel);
        }

        if (!educationLevel.levels) {
          educationLevel.levels = [];
        }
        educationLevel.levels.push({ data: subType.data, label: subType.label });
      });

    return educationLevels;
  }

  private _mapArraysToEducationLevels(subtypeIds: string[]): EducationLevel[] {
    return this.educationLevels().filter(educationLevel =>
      (educationLevel.children || []).map(child => child.data)
        .some(childId => (subtypeIds || []).includes(childId)))
      .map(educationLevel => ({
        data: educationLevel.data,
        label: educationLevel.label,
        levels: (educationLevel.children || [])
          .filter(subType => (subtypeIds || []).includes(subType.data))
          .map(subType => ({ data: subType.data, label: subType.label }))
      }));
  }

  private _mapTreeToArray(educationLevels: EducationLevel[]): string[] {
    return educationLevels
      .map(educationLevel => educationLevel.levels).flat(1)
      .map(subType => subType.data);
  }

  private _removeHighlight(): void {
    this.tree.el?.nativeElement.querySelectorAll('.p-highlight.p-indeterminate')
      .forEach((el: any) => {
        el.classList.remove('p-highlight');
      });
  }
}
