import {
  Component,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'lib-toggle',
    templateUrl: './toggle.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ToggleComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  value: boolean = false;

  switchControl: FormControl<boolean> = new FormControl<boolean>(false);

  @Input()
  label: string = "";

  @Input()
  labelFirst: boolean = true;

  @Input()
  disabled: boolean = false;

  @Output()
  toggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _subscription: Subscription;

  constructor() { }

  ngOnInit(): void {
    this._createControl();
  }

  private _createControl(): void {
    this._subscription?.unsubscribe();
    this.switchControl = new FormControl<boolean>(this.value);
    if (this.disabled) {
      this.switchControl.disable({emitEvent: false});
    }
    this._subscription = this.switchControl.valueChanges.subscribe(newValue => {
      this.toggleEvent.emit(newValue);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]?.currentValue !== undefined || changes['disabled']?.currentValue !== undefined) {
      this._createControl();
    }
  }

  changeValue(newValue: boolean, emitEvent: boolean = false): void {
    this.switchControl.setValue(newValue, {emitEvent: emitEvent});
  }

  toggleDisable(disabled: boolean, emitEvent: boolean = false) {
    if (disabled) {
      this.switchControl.enable({emitEvent: emitEvent});
    }
    else {
      this.switchControl.disable({emitEvent: emitEvent});
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
