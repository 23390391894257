<p-dialog
  *ngIf="dialogVisible"
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ ('paidEducationalLeaves.actionsTitle.' + action) | translate }}"
>
  <div>
    <div class='mb-4'>
      <span class='text-lg'>{{('paidEducationalLeaves.actionsText.' + action + (multiple ? "Multiple" : "")) | translate}}</span>
    </div>
  </div>

  <div [formGroup]="cancelForm" *ngIf='action === PAID_EDUCATIONAL_LEAVE_ACTION.CANCEL'>
    <div class="field">
      <label for="reasonCancelledId" class="block">{{
          'trainings.cancelDialog.labels.reasonTrainingCancelled' | translate
        }}</label>

      <p-select
        class="w-full"
        id="reasonCancelledId"
        *ngIf="cancelReasons$ | async as cancelReasons"
        [autofocus]='true'
        [options]="cancelReasons"
        formControlName="reasonCancelledId"
        optionValue="data"
        optionLabel="label"
        placeholder="{{ 'trainings.cancelDialog.labels.chooseAnOption' | translate }}"
        appendTo='body'
      ></p-select>

      <small
        *ngIf="
          (cancelForm.get('reasonCancelledId')?.dirty ||
            cancelForm.get('reasonCancelledId')?.touched) &&
          cancelForm.get('reasonCancelledId')?.hasError('required')
        "
        class="p-error block"
      >
        {{ 'trainings.cancelDialog.errors.reasonRequired' | translate }}
      </small>
    </div>

    <div class="field">
      <label class="block" for="cancelledComment"
      >{{ 'trainings.cancelDialog.labels.remarks' | translate }}
        <small>({{ 'trainings.optional' | translate }})</small></label
      >
      <textarea
        class="w-full"
        formControlName="cancelledComment"
        id="cancelledComment"
        pTextarea
        rows="2"
      ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
     <p-button
        (onClick)="onSubmit()"
        [loading]="loading"
        label="{{ 'paidEducationalLeaves.actions.executeAction' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
