<ng-container *ngIf='!inlineForm else dropdownSelect'>
  <p-accordion [(value)]='accordionValue' *ngIf="showHeader" styleClass='search-multiselect'>
    <p-accordion-panel value="0">
      <p-accordion-header>{{title}}</p-accordion-header>
      <p-accordion-content>
        <ng-container *ngTemplateOutlet="multiSelects"></ng-container>
      </p-accordion-content>
    </p-accordion-panel>
  </p-accordion>

  <div *ngIf="!showHeader" class="p-5 border-b border-b-grey border-solid border-opacity-20">
    <ng-container *ngTemplateOutlet="multiSelects"></ng-container>
  </div>
</ng-container>

<ng-template #dropdownSelect>
  <div class='flex items-center gap-1 p-2 rounded cursor-pointer hover:bg-primary-hover'
       (click)='overlayPanel.toggle($event)'>
    <span>{{title}}</span>
    <span *ngIf='selectedItems.length > 0'
          class='bg-primary text-white inline-flex items-center justify-center w-4 h-4 text-xs rounded-full'>
      {{selectedItems.length}}
    </span>
    <i class='font-material not-italic'>expand_more</i>
  </div>

  <p-popover #overlayPanel>
    <ng-container *ngTemplateOutlet="multiSelects"></ng-container>
  </p-popover>
</ng-template>

<ng-template #multiSelects>
  <form [formGroup]="selectGroup" >
    <div *ngFor="let item of data; let i = index" formArrayName="items">
      <div class="form-check flex items-center">
        <input class="checkbox-styling shrink-0"
               (change)="onCheckChange(item.value, $event)"
               [formControlName]="i"
               tabindex="0"
               type="checkbox"
               [value]="item.value"
               id="{{uniqueId}}.{{filterName}}.{{item.value}}">
        <label class="flex items-center gap-2 mt-1 text-lg text-text cursor-pointer" for="{{uniqueId}}.{{filterName}}.{{item.value}}">
          <span *ngIf="hasIcon" class="w-5 text-center font-material {{getIconClass(item.value)}}">{{getIcon(item.value)}}</span>
          {{item.label}}
        </label>
      </div>
    </div>
  </form>
</ng-template>
