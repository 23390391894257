import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';
import {
  PaidEducationalLeaveAttest,
  paidEducationalLeaveHour,
} from '../types/paid-educational-leave.type';

@Injectable({
  providedIn: 'root'
})
export class PaidEducationalLeaveService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getpaidEducationalLeave(paidEducationalLeaveAttestId: string): Observable<PaidEducationalLeaveAttest> {
    return this.http.get<PaidEducationalLeaveAttest>(`${this.config.readApiUrl}/paideducationalleaveattests/${paidEducationalLeaveAttestId}`);
  }

  updatepaidEducationalLeaveHours(paidEducationalLeaveAttestId: string, hours: paidEducationalLeaveHour[]): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/${paidEducationalLeaveAttestId}/paideducationalleavehours`, hours);
  }

  requestAttestNeeded(paidEducationalLeaveAttestIds: string[]): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/requestattestneeded`, paidEducationalLeaveAttestIds);
  }

  generateAttest(paidEducationalLeaveAttestIds: string[]): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/generate`, paidEducationalLeaveAttestIds);
  }

  sendAttest(paidEducationalLeaveAttestIds: string[]): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/send`, paidEducationalLeaveAttestIds);
  }

  cancelAttest(paidEducationalLeaveAttestId: string, data: any): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/${paidEducationalLeaveAttestId}/cancel`, data);
  }

  putAttestOnHold(paidEducationalLeaveAttestId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/${paidEducationalLeaveAttestId}/putonhold`, {});
  }

  reactivateAttest(paidEducationalLeaveAttestId: string): Observable<void> {
    return this.http.put<void>(
      `${this.config.writeApiUrl}/paideducationalleaveattests/${paidEducationalLeaveAttestId}/reactivate`, {});
  }
}
