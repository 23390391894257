import { NgModule } from '@angular/core';
import { BranchDetailComponent } from './pages/branch-detail/branch-detail.component';
import { BranchListComponent } from './pages/branch-list/branch-list.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService } from 'primeng/api';
import { InterimOfficeSelectComponent } from './components/interim-office-select/interim-office-select.component';
import { InterimOfficeCardComponent } from './components/interim-office-card/interim-office-card.component';
import { EmploymentSelectComponent } from './components/employment-select/employment-select.component';
import { UtilsModule } from '../utils/utils.module';
import { PersonsModule } from '../persons/persons.module';
import { TrainingPlanCardComponent } from './components/training-plan-card/training-plan-card.component';
import { TrainingPlanPopupComponent } from './components/training-plan-popup/training-plan-popup.component';
import {
  TrainingPlanActionDialogComponent
} from './components/training-plan-action-dialog/training-plan-action-dialog.component';
import { BranchComponent } from './components/branch/branch.component';
import { BranchSearchCardComponent } from './components/branch-search-card/branch-search-card.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { TrainingPlansComponent } from './components/training-plans/training-plans.component';
import { CommunicationsModule } from '../communications/communications.module';
import { BranchCommunicationsComponent } from './components/branch-communications/branch-communications.component';
import {
  TrainingPlanRequestPopupComponent
} from './components/training-plan-request-popup/training-plan-request-popup.component';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    PersonsModule,
    CommunicationsModule
  ],
  providers: [
    CanDeactivateGuard,
    ConfirmationService
  ],
  declarations: [
    BranchDetailComponent,
    BranchListComponent,
    InterimOfficeSelectComponent,
    InterimOfficeCardComponent,
    EmploymentSelectComponent,
    TrainingPlanCardComponent,
    TrainingPlanPopupComponent,
    TrainingPlanActionDialogComponent,
    BranchComponent,
    BranchSearchCardComponent,
    StatusChipComponent,
    TrainingPlansComponent,
    BranchCommunicationsComponent,
    TrainingPlanRequestPopupComponent
  ],
  exports: [
    InterimOfficeSelectComponent,
    InterimOfficeCardComponent,
    EmploymentSelectComponent,
    BranchSearchCardComponent,
    TrainingPlansComponent
  ]
})
export class BranchesModule {
}
