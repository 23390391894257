import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PurchaseEntry } from '../../../types/training.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';

@Component({
    selector: 'alimento-ipv-frontend-purchase-entry-card',
    templateUrl: './purchase-entry-card.component.html',
    standalone: false
})
export class PurchaseEntryCardComponent implements OnChanges {
  @Input()
  purchaseEntry!: PurchaseEntry;

  @Input()
  readOnly = false;

  status: string;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['purchaseEntry']?.currentValue) {
      this.referenceDataService.getPurchaseEntryStatus(this.purchaseEntry.statusId).pipe(first())
        .subscribe(status => this.status = status.label);
    }
  }
}
