import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { first, Observable, Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DateMapper, dateValid, FilterType, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { Session, SessionUpdateEvent } from '../../../types/session.type';
import { isEventType, SessionMethodKey, TRAINING_TYPE } from '../../../types/reference-data.enum';
import { DataLabelType, IncludedSessionOptions, TrainingMethod } from '../../../types/reference-data.type';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import { EmploymentSearchItem } from '../../../types/searches.type';

@Component({
    selector: 'alimento-ipv-frontend-session-popup',
    templateUrl: './session-popup.component.html',
    standalone: false
})
export class SessionPopupComponent implements OnChanges, OnDestroy {
  @Input()
  trainingSession: Session | null | undefined;

  @Input()
  trainingType: TRAINING_TYPE;

  @Input()
  trainingMethodId!: string;

  @Input()
  yearOfRequest?: number;

  @Output()
  formSubmit = new EventEmitter<SessionUpdateEvent>();

  popupVisible = false;
  sessionForm!: FormGroup;
  loading = false;

  includedSessionOptions$: Observable<IncludedSessionOptions[]> = this.referenceDataService
    .getIncludedSessionOptions()
    .pipe(first());
  methods$: Observable<TrainingMethod[]> = this.referenceDataService.getReferenceData(OPTIONS_LIST_TYPE.TRAINING_METHODS).pipe(first());
  showLocationFields = false;
  teacherCount = 0;
  teacherFilter = {type: FilterType.filterIsTeacher, values: ["true"]}
  locationFilter = {type: FilterType.filterIsLocation, values: ["true"]}

  private _subscriptions: (Subscription | undefined)[] = [];
  private sessionMethodsWithLocation =
    [SessionMethodKey.Physical, SessionMethodKey.Hybrid, SessionMethodKey.VR];

  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  constructor(
    private fb: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingSession']?.currentValue) {
      this.createSessionForm();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createSessionForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.sessionForm;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.sessionForm);
    return (!this.sessionForm.contains("teacherBranches") || (this._validateTeacherBranches() && this._validateTeachers())) &&
      this.sessionForm.valid;
  }

  getFormData(): any {
    const sessionFormValue = JSON.parse(JSON.stringify(this.sessionForm.value));

    if (!this.sessionMethodsWithLocation.includes(sessionFormValue.sessionMethodId)) {
      delete sessionFormValue.locationId;
      delete sessionFormValue.includedSessionOptionIds;
    }

    if (sessionFormValue.locationId?.branchId) {
      sessionFormValue.locationId = sessionFormValue.locationId.branchId;
    }

    return {
      id: sessionFormValue.id,
      title: sessionFormValue.title,
      date: DateMapper.getDateFromDateTimeAsString(sessionFormValue.date),
      startTime: sessionFormValue.startTime !== '' ? sessionFormValue.startTime : null,
      endTime: sessionFormValue.endTime !== '' ? sessionFormValue.endTime : null,
      duration: sessionFormValue.duration !== '' ? sessionFormValue.duration + ':00' : null,
      teacherBranchIds: sessionFormValue.teacherBranches
        ?.filter((teacherBranch: any) => teacherBranch?.branch?.branchId)
        .map((teacherBranch: any) => teacherBranch?.branch?.branchId),
      teacherEmploymentIds: this._getTeacherEmploymentIds(sessionFormValue.teacherBranches),
      locationId: sessionFormValue.locationId,
      sessionMethodId: sessionFormValue.sessionMethodId,
      sessionComments: sessionFormValue.sessionComments,
      includedSessionOptionIds: sessionFormValue.includedSessionOptionIds,
      siteVisitBranchId: sessionFormValue.siteVisitBranch?.branchId,
      siteVisitEmploymentId: sessionFormValue.siteVisitEmploymentId,
    };
  }

  addOrUpdateSession() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors'),
      });
      return;
    }

    this.formSubmit.emit({
      session: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value),
    });
  }

  get teacherBranches(): FormArray {
    return this.sessionForm.controls['teacherBranches'] as FormArray;
  }

  addTeacherBranch(teacherBranch?: DataLabelType, teachers?: EmploymentSearchItem[]) {
    const control = this.fb.group({
      branch: [{
        branchId: teacherBranch?.data || undefined,
        name: teacherBranch?.label || ""
      }],
      teachers: this.fb.array(teachers || [])
    });
    this.teacherBranches.push(control);
    this._subscriptions.push(control.valueChanges
      .subscribe(value => {
        if (!value.branch) {
          this.teacherCount -= value.teachers.length;
          (control.get('teachers') as FormArray).clear();
        }
    }));
    this.teacherCount += teachers?.length || 0;
  }

  deleteTeacherBranch(index: number) {
    this.teacherCount -= this.teacherBranches.value[index]?.teachers?.length || 0;
    this.teacherBranches.removeAt(index);
  }

  getTeachers(formControl: FormGroup): FormArray {
    return formControl.controls['teachers'] as FormArray;
  }

  addTeacher(formGroup: FormGroup) {
    this.teacherCount++;
    this.getTeachers(formGroup).push(this.fb.control(undefined));
  }

  deleteTeacher(formControl: FormGroup, index: number) {
    this.teacherCount--;
    this.getTeachers(formControl).removeAt(index);
  }

  private createSessionForm() {
    const startTime = this.fb.control(this.trainingSession?.startTime, Validators.required);
    this.teacherCount = 0;

    const sessionMethodId: string = this.trainingSession?.sessionMethodId
      ? this.trainingSession?.sessionMethodId
      : this.trainingMethodId;

    const siteVisitBranch = this.trainingSession?.siteVisitBranch ? {
      branchId: this.trainingSession.siteVisitBranch.data,
      name: this.trainingSession.siteVisitBranch.label
    } : undefined;

    const sessionFields= {
      id: [this.trainingSession?.id],
      title: [this.trainingSession?.title, [Validators.maxLength(500)]],
      startTime: startTime,
      endTime: [this.trainingSession?.endTime, [Validators.required, this.validateMinValue(startTime)]],
      duration: [
        this.trainingSession?.duration ? this.trainingSession.duration.replace(/(.*:.*):.*/, '$1') : '',
        [Validators.required, this.validateDuration(), this.validateMinDuration()],
      ],
      teacherBranches: this.fb.array([]),
      sessionMethodId: [sessionMethodId, Validators.required],
      locationId: [this.trainingSession?.location?.data],
      sessionComments: [this.trainingSession?.sessionComments],
      includedSessionOptionIds: [this.trainingSession?.includedSessionOptionIds],
      date: [this.trainingSession?.date ? new Date(this.trainingSession.date) : null, [dateValid()]],
      siteVisitBranch: [siteVisitBranch, [Validators.required]],
      siteVisitEmploymentId: [this.trainingSession?.siteVisitEmployment?.employmentId]
    };

    if (this.trainingType === TRAINING_TYPE.TEMPLATE) {
      delete sessionFields.date;
    }
    else if ([TRAINING_TYPE.OPEN_EXTERN, TRAINING_TYPE.CUSTOM].includes(this.trainingType)) {
      delete sessionFields.locationId;
      delete sessionFields.sessionComments;
      delete sessionFields.includedSessionOptionIds;
    }

    if (this.trainingType !== TRAINING_TYPE.EDUCATION_COMPANY_VISIT) {
      delete sessionFields.siteVisitBranch;
      delete sessionFields.siteVisitEmploymentId;
    }

    if (isEventType(this.trainingType)) {
      sessionFields.date = [this.trainingSession?.date ? new Date(this.trainingSession.date) : null, [dateValid(), Validators.required]];
    }

    this.sessionForm = this.fb.group(sessionFields);

    this._fillTeacherData();
    this._setShowLocationField();
    this._addSubscriptions();
  }

  private _fillTeacherData(): void {
    if (this.trainingSession?.teacherBranches?.length > 0) {
      this.trainingSession.teacherBranches.forEach(teacherBranch => {
        const teachers = (this.trainingSession.teacherEmployments || [])
          .filter(teacher => teacher.branchId === teacherBranch.data);
        this.addTeacherBranch(teacherBranch, teachers);
      });
    }
    else {
      this.addTeacherBranch();
    }
  }

  private _addSubscriptions(): void {
    this._subscriptions.push(
      this.sessionForm.get('startTime')?.valueChanges.subscribe(() => {
        this.sessionForm.get('endTime')?.updateValueAndValidity();
        this.sessionForm.get('duration')?.updateValueAndValidity();
      })
    );

    this._subscriptions.push(
      this.sessionForm.get('endTime')?.valueChanges.subscribe(() => {
        this.sessionForm.get('duration')?.updateValueAndValidity();
      })
    );

    this._subscriptions.push(
      this.sessionForm.get('sessionMethodId')?.valueChanges.subscribe(() => {
        this._setShowLocationField();
      })
    );

    this._subscriptions.push(
      this.sessionForm.get("siteVisitBranch")?.valueChanges.subscribe(() =>
        this.sessionForm.get("siteVisitEmploymentId").setValue(undefined))
    );
  }

  private _getDurationAsNumber(duration: string, isEndTime = false): number {
    const value = Number(duration.replace(":", ""));
    if (value === 0 && isEndTime) {
      return 2400;
    }
    return value;
  }

  private validateMinValue(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        control.value &&
        otherControl.value &&
        this._getDurationAsNumber(control.value, true) <= this._getDurationAsNumber(otherControl.value)
      ) {
        return { minValueToLow: true };
      }
      return null;
    };
  }

  private validateMinDuration(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && this._getDurationAsNumber(control.value) <= 0) {
        return { minDurationToLow: true };
      }
      return null;
    };
  }

  private validateDuration(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const startTime = this.sessionForm?.get('startTime')?.value;
      const endTime = this.sessionForm?.get('endTime')?.value;
      if (
        control.value &&
        startTime &&
        endTime &&
        this._getDurationAsNumber(endTime, true) - this._getDurationAsNumber(startTime) < this._getDurationAsNumber(control.value)
      ) {
        return { invalidDuration: true };
      }
      return null;
    };
  }

  private _validateTeacherBranches(): boolean {
    const teacherBranchIds = this.teacherBranches.value.filter((teacherBranch: any) => teacherBranch?.branch?.branchId)
      .map((teacherBranch: any) => teacherBranch?.branch?.branchId)
    const uniqueItems = [...new Set(teacherBranchIds)]
    if (teacherBranchIds.length != uniqueItems.length) {
      this.teacherBranches.setErrors({branchesUnique: true});
      return false;
    }
    return true;
  }

  private _getTeacherEmploymentIds(value: any): string[] {
    return value?.map((teacherBranch: any) => teacherBranch.teachers)
      .flat(1)
      .filter((teacher: EmploymentSearchItem) => teacher?.employmentId)
      .map((teacher: EmploymentSearchItem) => teacher.employmentId)
  }

  private _validateTeachers(): boolean {
    const teacherIds = this._getTeacherEmploymentIds(this.teacherBranches.value);
    const uniqueItems = [...new Set(teacherIds)]
    if (teacherIds.length != uniqueItems.length) {
      this.teacherBranches.setErrors({teachersUnique: true});
      return false;
    }
    return true;
  }

  private _setShowLocationField(): void {
    this.showLocationFields = this.sessionMethodsWithLocation.includes(this.sessionForm.get('sessionMethodId')?.value) &&
      ![TRAINING_TYPE.OPEN_EXTERN, TRAINING_TYPE.CUSTOM].includes(this.trainingType);
  }

  showOutsideYearOfRequestWarning(): boolean {
    const dateValue = this.sessionForm.get("date")?.value;
    return !!this.yearOfRequest && !!dateValue && new Date(dateValue).getFullYear() !== this.yearOfRequest;
  }

  protected readonly isEventType = isEventType;
}
