import {Component, Input} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'lib-card',
    templateUrl: './card.component.html',
    standalone: false
})
export class CardComponent {
  @Input()
  selectable: boolean = false;

  @Input()
  elementClass: string;

  @Input()
  isSelected: boolean = false;

  @Input()
  navigable: boolean = true;

  @Input()
  styling: number = 1;

}
