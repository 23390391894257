<alimento-ipv-frontend-overview-page-layout header='templates.menuTitle'>
  <ng-container top-right>
    <p-button
      *ngIf='!readOnly'
      label="{{ 'templates.actions.add' | translate }}"
      [routerLink]="['create']"
    ></p-button>
  </ng-container>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              [expandAll]='false'
              name='templates-list'
              searchPlaceholderKey='templates.searchbarPlaceholder'
              availableResultsTranslateKey='templates.menuTitle'
  >
    <ng-template #cardTemplate let-template="item">
      <alimento-ipv-frontend-template-card [template]='template'
                                           [readOnly]='readOnly'
                                           (onCreateForTemplate)='createTrainingFromTemplate($event)'
      >
      </alimento-ipv-frontend-template-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
