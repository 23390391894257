import { Pipe, PipeTransform } from '@angular/core';
import './string.prototype';

@Pipe({
    name: 'lowerFirstLetter',
    standalone: false
})
export class LowerFirstLetterPipe implements PipeTransform {

  transform(value: string): string {
    return value.toLowerFirstLetter();
  }

}
