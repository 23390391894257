<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
></alimento-ipv-frontend-notes-sidebar>

<div *ngIf='trainingForm' [formGroup]="trainingForm">
  <alimento-ipv-frontend-detail-page-layout
    header="{{ getTitle() | async | translate }}"
    [breadcrumb]='breadcrumb'
    fallbackUrl='/trainings'
  >
    <ng-container status>
      <alimento-ipv-frontend-training-status-chip
        [status]="trainingStatus"
        [trainingOngoing]="isOngoing()"
      ></alimento-ipv-frontend-training-status-chip>
    </ng-container>
    <ng-container top-action>
      <div class="flex gap-4" *ngIf='!readOnly'>
        <p-button
          type="button"
          label="{{ 'trainings.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveTraining()"
          [loading]="savingTraining"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        *ngIf='type === TRAINING_TYPE.OPEN_TRAINING'
        [type]='type'
        [nrOfSessionsWithDates]='sessionService.nrOfSessionsWithDates'
        formControlName="basicDataForm"
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>
      <alimento-ipv-frontend-training-project
        [hidden]="activeTabIndex !== 0"
        *ngIf='isTeacherProjectType(type) || isPupilProjectType(type) || isEventType(type)'
        [trainingType]='type'
        [trainingStatus]='trainingStatus'
        [showRequiredFieldErrors]="isEventType(type) || showRequiredFieldErrors"
        [firstSessionDate]='sessionService.sessions().length > 0 ? sessionService.sessions()[0]?.date : undefined'
        [lastSessionDate]='sessionService.sessions().length > 0 ? sessionService.sessions()[sessionService.sessions().length - 1]?.date : undefined'
        formControlName="basicDataForm"
        #trainingProjectComponent
      >
      </alimento-ipv-frontend-training-project>

      <alimento-ipv-frontend-summary-description
        [hidden]="activeTabIndex !== 1"
        formControlName="descriptionForm"
        [showRequiredFieldErrors]="isEventType(type) || showRequiredFieldErrors"
        [trainingType]='type'
        #summaryDescriptionComponent
      >
      </alimento-ipv-frontend-summary-description>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingMethodId]="methodId"
        [trainingType]='type'
        (sessionUpdated)='loadTrainingData()'
        (hasChanges)='onSessionChange($event)'
      >
      </alimento-ipv-frontend-sessions>

      <alimento-ipv-frontend-enrollments
        [hidden]="activeTabIndex !== 3 || isUnsupportedEnrollment()"
        [training]="training"
        [trainingStatus]="trainingStatus"
        [trainingType]="type"
        (hasChange)='onEnrollmentChange($event)'
      ></alimento-ipv-frontend-enrollments>

      <alimento-ipv-frontend-training-communications
        *ngIf='activeTabIndex === 4'
        [hidden]='activeTabIndex !== 4'
        [readOnly]='readOnly'
        [training]='training()'
      ></alimento-ipv-frontend-training-communications>

      <alimento-ipv-frontend-invoices-overview *ngIf='activeTabIndex === 5'
                                                       [readOnly]='readOnly'
                                                       [trainingId]='trainingId'
                                                       [hasCounselor]='!!training()?.counselorId'
                                                       [purchaseEntries]='purchaseEntries'
                                                       [salesInvoices]='salesInvoices'
                                                       (purchaseEntryChanges)='getPurchaseEntries()'
                                                       (salesInvoiceChanges)='getSalesInvoices()'
      >
      </alimento-ipv-frontend-invoices-overview>
    </ng-container>

    <ng-container metadata>
      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Draft && !isEventType(type)' class='flex flex-col gap-4'>
        <p-button
          type="button"
          label="{{ 'trainings.actions.saveAndActivate' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="activatingTraining"
          (click)="activateTraining()"
          [disabled]="!trainingReadyToActivate || savingTraining"
        ></p-button>

        <div *ngIf="!trainingReadyToActivate" >
          <div class="text-[16px]">
            <span [innerHTML]="'trainings.activateNotPossible' | translate"></span>
          </div>

          <div class='flex items-center justify-between gap-2 w-full'>
            <p-button label="{{ 'trainings.showErrors' | translate }}"
                      [link]='true'
                      styleClass='whitespace-nowrap pl-0'
                      (onClick)='showRequiredToggle.writeValue(!showRequiredToggle.checked())'
            ></p-button>
            <p-toggle-switch #showRequiredToggle [formControl]="requiredFieldToggle"
            ></p-toggle-switch>
          </div>

          <span class="h-[1px] bg-grey-2"></span>
        </div>

        <p-button styleClass='px-0 text-danger '
                  label="{{ 'trainings.deleteDialog.delete' | translate }}"
                  [link]='true'
                  (onClick)='deleteTraining()'
        ></p-button>
      </div>

      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Cancelled' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-reactivate-training-dialog
          #reactivateTrainingDialog
          [training]='training'
          [firstSessionDate]='firstSessionDate'
          (reactivateTrainingConfirmed)="reactivateTraining($event)"
        >
        </alimento-ipv-frontend-reactivate-training-dialog>

        <p-button
          type="button"
          label="{{ 'trainings.actions.reactivate' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="reactivatingTraining"
          (click)="reactivateTrainingDialog.open()"
        ></p-button>

      </div>

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <div class='flex flex-col'>
          <alimento-ipv-frontend-close-training-dialog
            #closeTrainingDialog
            [training]='training'
            [firstSessionDate]='firstSessionDate'
            (closeTrainingConfirmed)="closeTraining($event)"
          >
          </alimento-ipv-frontend-close-training-dialog>
          <p-button
            *ngIf='trainingStatus !== trainingStatusKeys.Completed'
            type="button"
            label="{{ 'trainings.actions.close' | translate }}"
            variant='outlined'
            loadingIcon="pi pi-spin pi-spinner"
            styleClass='w-full'
            [loading]="closingTraining"
            [disabled]='!canCloseTraining'
            (click)="closeTrainingDialog.open()"
          ></p-button>

          <span class="text-[16px]" *ngIf='!canCloseTraining'>
            {{'trainings.cannotCloseTraining' | translate}}
          </span>
        </div>

        <div *ngIf='!isEventType(type)'>
          <div class='flex items-center justify-between gap-2 w-full'
               [pTooltip]='canPublish() ? "" : (trainingStatus === trainingStatusKeys.Completed ? "trainings.canNotPublishCompleted" : "trainings.publishNotAvailableTooltip") | translate'>
            <p-button label="{{ 'trainings.actions.publish' | translate }}"
                      [link]='true'
                      styleClass='whitespace-nowrap pl-0'
                      (onClick)='publishedToggleEl.writeValue(!publishedToggleEl.checked())'
            ></p-button>
            <p-toggle-switch #publishedToggleEl [formControl]="publishedToggle"
            ></p-toggle-switch>
          </div>
        </div>

        <div *ngIf='sessionService.nrOfSessions() > 0 && enrollmentService.enrollments()?.length > 0'>
          <div class='flex items-center justify-between gap-2 w-full'>
            <p-button label="{{ 'trainings.actions.completeAllAbsences' | translate }}"
                      [link]='true'
                      styleClass='whitespace-nowrap px-0'
                      (onClick)='!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined'
            ></p-button>
            <p-toggle-switch #completeAllAbsences
                             [formControl]="completeAllAbsencesToggle"
            ></p-toggle-switch>
          </div>
        </div>

        <span class="h-[1px] bg-grey-2"></span>

        <div>
          <alimento-ipv-frontend-participants-list-dialog (submitPopup)='requestParticipantsList($event)'
          ></alimento-ipv-frontend-participants-list-dialog>
          <alimento-ipv-frontend-certificates-dialog (submitPopup)='requestCertificates($event)'
                                                     [trainingId]='trainingId'
          ></alimento-ipv-frontend-certificates-dialog>
          <alimento-ipv-frontend-communicate-changes-dialog (submitPopup)='communicateChanges($event)'
          ></alimento-ipv-frontend-communicate-changes-dialog>
          <alimento-ipv-frontend-approve-enrollments-dialog (submitPopup)='approveEnrollments($event)'
          ></alimento-ipv-frontend-approve-enrollments-dialog>

          <alimento-ipv-frontend-detail-actions [generateItems]='generateItems'
                                                (onClick)='createGenerateItems()'
          ></alimento-ipv-frontend-detail-actions>
        </div>

        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId && !isOngoing()'>
          <alimento-ipv-frontend-cancel-training-dialog
            #cancelTrainingDialog
            [training]='training()'
            [firstSessionDate]='firstSessionDate'
            (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"
          >
          </alimento-ipv-frontend-cancel-training-dialog>
          <p-button styleClass='px-0 text-danger '
                    label="{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}"
                    [link]='true'
                    [pTooltip]="isOngoing() ? ('trainings.firstSessionAlreadyOver' | translate) : ''"
                    (onClick)='cancelTrainingDialog.open()'
          ></p-button>
        </div>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>

      <ng-container *ngIf="reasonCancelled">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'trainings.reasonCancelled' | translate }}"
          value="{{ reasonCancelled }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.cancelledComment">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'trainings.cancelledComment' | translate }}"
          value="{{ training().cancelledComment }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdBy">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'trainings.createdBy' | translate }}"
          value="{{ training().createdBy }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdOn">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'trainings.createdOn' | translate }}"
          value="{{ training().createdOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
