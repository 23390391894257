<div class='w-full pt-1 pb-2 px-1 border-y border-y-grey-3 cursor-pointer hover:bg-primary-hover'
     (click)='toggleSelectAllControl($event)'>
  <div class='field-checkbox'>
    <p-checkbox [formControl]='selectAllControl'
                [binary]='true'
                (click)='$event.stopImmediatePropagation()'
                inputId='select-all-list'
    ></p-checkbox>
    <label for='select-all-list'>{{selectAllKey | translate}}</label>
  </div>
</div>
<div class='overflow-y-auto {{containerClass}} mb-4'>
  <div *ngFor="let item of items; let i = index"
       class="pt-1 pb-2 px-1 w-full cursor-pointer hover:bg-primary-hover flex flex-col"
       (click)='toggle(item[idField], $event)'>
    <div class='field-checkbox'>
      <p-checkbox
        [formControl]="$any(formGroup.controls['items'])"
        inputId="itemIds-{{ i }}"
        name="itemIds"
        (click)='$event.stopImmediatePropagation()'
        value="{{ item[idField] }}"
      >
      </p-checkbox>
      <label for='itemIds-{{ i }}'>{{label(item, i + 1)}}</label>
    </div>
    <span class='ml-6 {{subTextClass}}' *ngIf='item[subTextKey]'>{{item[subTextKey]}}</span>
  </div>
</div>
