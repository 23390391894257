<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<form [formGroup]="templateForm">
  <alimento-ipv-frontend-detail-page-layout
    header="{{ 'templates.templateOpenTraining' | translate }}"
    [breadcrumb]='breadcrumb'
    fallbackUrl='/training-templates'
  >
    <ng-container status>
      <alimento-ipv-frontend-template-status-chip
        [status]="templateStatus"
      ></alimento-ipv-frontend-template-status-chip>
    </ng-container>

    <ng-container top-action>
      <div class="flex gap-4" *ngIf='!readOnly'>
        <p-button
          label="{{ 'templates.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="savingTemplate"
          (click)="saveTemplate()"
          [disabled]="isActivateDisabled"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        formControlName="basicDataForm"
        [type]='TRAINING_TYPE.TEMPLATE'
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>

      <alimento-ipv-frontend-summary-description
        [hidden]="activeTabIndex !== 1"
        formControlName="descriptionForm"
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #summaryDescriptionComponent
      >
      </alimento-ipv-frontend-summary-description>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="templateId"
        [trainingMethodId]="basicInfoComponent.formGroup.get('methodId')?.value"
        [trainingType]='TRAINING_TYPE.TEMPLATE'
      >
      </alimento-ipv-frontend-sessions>
    </ng-container>

    <ng-container metadata>
       <p-button
        styleClass='w-full'
        label="{{ 'templates.actions.saveAndActivate' | translate }}"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="activatingTemplate"
        (click)="activateTemplate()"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft"
        [disabled]="!templateReadyToActivate || savingTemplate"
      ></p-button>

      <p-button
        styleClass='w-full'
        label="{{ 'templates.actions.createTrainingFromTemplate' | translate }}"
        variant='outlined'
        (click)="createTrainingFromTemplate(templateId)"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Active"
      ></p-button>

      <div
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
        class="activation-not-possible"
      >
        <span [innerHTML]="'templates.activateNotPossible' | translate"></span>
      </div>

      <div *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
           class='flex items-center justify-between gap-2 w-full'>
        <p-button label="{{ 'trainings.showErrors' | translate }}"
                  [link]='true'
                  styleClass='whitespace-nowrap pl-0'
                  (onClick)='showRequiredToggle.writeValue(!showRequiredToggle.checked())'
        ></p-button>
        <p-toggle-switch #showRequiredToggle [formControl]="requiredFieldToggle"
        ></p-toggle-switch>
      </div>

      <span
        class="h-[1px] bg-grey-2"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
      ></span>

      <p-button *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && templateId"
                styleClass='px-0 text-danger '
                label="{{ 'templates.actions.delete' | translate }}"
                [link]='true'
                (onClick)='deleteTemplate()'
      ></p-button>

      <span class="h-[1px] bg-grey-2" *ngIf="templateId"></span>

      <ng-container *ngIf="templateCreatedBy">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'templates.createdBy' | translate }}"
          value="{{ templateCreatedBy }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="templateCreatedOn">
        <alimento-ipv-frontend-metadata-item
          header="{{ 'templates.createdOn' | translate }}"
          value="{{ templateCreatedOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</form>
