import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  signal,
  SimpleChanges,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {
  AuthenticationService,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  MAX_FETCH_COUNT,
  PaginatedResponse,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { first, map, Observable, Subscription, takeWhile, tap, timer } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CompleteTaskEvent, SearchCommunicationsItem } from '../../../types/communications.type';
import { CommunicationsService } from '../../../services/communications.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { CommunicationsMapper } from '../../../communications/utils/communications.mapper';
import { MailContextType, MailLogState } from '../../../types/communications.enum';
import {
  CompleteTaskDialogComponent
} from '../../../communications/components/complete-task-dialog/complete-task-dialog.component';

@Component({
    selector: 'alimento-ipv-frontend-page-detail-communications',
    templateUrl: './page-detail-communications.component.html',
    standalone: false
})
export class PageDetailCommunicationsComponent implements OnChanges, OnDestroy {

  searchData$: InfiniteScrollDataAdapter<SearchCommunicationsItem>;
  searchFilters: SearchFilter[];

  @Input()
  contextValue: string;

  @Input()
  contextType: MailContextType;

  @Input()
  readOnly = true;

  @Input()
  showFilters = true;

  @ViewChild(SearchComponent)
  searchComponent: SearchComponent;

  communicationsCount: WritableSignal<number> = signal(0);
  private NUMBER_OF_LOADED_ITEMS = 10;
  private _getCommunicationCountSub: Subscription;

  constructor(private communicationsService: CommunicationsService,
              private referenceDataService: ReferenceDataService,
              private messageService: MessageService,
              private mapper: CommunicationsMapper,
              private communicationService: CommunicationsService,
              private translateService: TranslateService,
              private authenticationService: AuthenticationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['contextValue']?.currentValue) {
      this._createSearch();
    }
  }

  ngOnDestroy(): void {
    this._getCommunicationCountSub?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };

    searchRequest.filters.push({ type: FilterType.filterContextType, values: [this.contextType] });
    searchRequest.filters.push({ type: FilterType.filterContextValue, values: [this.contextValue] });

    this.searchData$ = new InfiniteScrollDataAdapter<SearchCommunicationsItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchCommunicationsItem>> => {
      return this.communicationsService.searchCommunications(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.date,
        label: 'communications.sendDate',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'communications.mailStatus',
        key: FilterType.mailStatus,
        expanded: true,
        hasIcon: true,
        iconMapper: (item: string) => this.mapper.getMailLogStateIcon(item as MailLogState),
        iconClassMapper: (item: string) => ('text-xl text-' + this.mapper.getMailLogColor(item as MailLogState)),
        data: this.referenceDataService.getMailStatus()
          .pipe(map(types => types.map(type =>
            ({ type: FilterType.mailStatus, label: type.label, value: type.data }) as IFilter)))
      }
    ];
  }

  completeTasks(event: CompleteTaskEvent, completeTaskDialog: CompleteTaskDialogComponent, searchComponent: SearchComponent): void {
    event.setLoading(true);
    this.communicationsService.completeTasks(event.data).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('communications.tasksCompleted')
          });
          event.setLoading(false);
          completeTaskDialog.closeDialog();
          searchComponent.refresh();
        },
        error: () => event.setLoading(false)
      });
  }

  beforeSearchRequest = (searchComponent: SearchComponent, searchRequest: SearchRequest) => {
    if (searchComponent.isToggled) {
      searchRequest.filters.push({
        type: FilterType.filterSenderId,
        values: [this.authenticationService.getCurrentUserId()]
      });
    }

    this.mapper.changeStatusFilter(searchRequest);
  };

  private _loadCommunicationsCount(fetching: WritableSignal<number>): void {
    this.communicationService.getCommunicationsCount(this.searchData$.getCurrentSearchRequest()).pipe(first())
      .subscribe(count => {
        const currentCount = this.communicationsCount();
        if (fetching && currentCount !== undefined && count > currentCount) {
          fetching.set(MAX_FETCH_COUNT);
          this.searchComponent.refresh();
        }
        this.communicationsCount.set(count);
      });
  }

  checkForNewCommunication(): void {
    this._getCommunicationCountSub?.unsubscribe();
    const fetching = signal(0);
    this.searchData$.totalCount$.pipe(first())
      .subscribe(totalCount => {
        this.communicationsCount.set(totalCount);
        this._getCommunicationCountSub = timer(0, 3000)
          .pipe(
            takeWhile(() => fetching() < MAX_FETCH_COUNT),
            tap(() => {
              this._loadCommunicationsCount(fetching);
              fetching.set(fetching() + 1);
            })
          )
          .subscribe();
      });
  }

}
