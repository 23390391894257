import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'alimento-ipv-frontend-detail-actions',
  templateUrl: './detail-actions.component.html',
  standalone: false
})
export class DetailActionsComponent {
  @Input()
  label: string = 'trainings.actions.generate';

  @Input()
  generateItems: MenuItem[] = [];

  @Output()
  onClick: EventEmitter<any> = new EventEmitter();

  buttonClicked(event: Event, menu: Menu): void {
    this.onClick.emit();
    menu.toggle(event);
  }
}
