<div *ngIf="enterprise"
     class="shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-primary-hover relative border border-input-border cursor-pointer"
>

  <div class='flex items-center justify-between'>
    <div class='flex items-center gap-1'>
      <span class='font-bold text-lg'>{{enterprise.alimentoId}} - {{enterprise.name}}</span>
      <span>({{enterprise.enterpriseNumber}})</span>
      <i *ngIf='isMainEnterprise' class='font-material'>star</i>
      <!--      <span>{{enterprise.address}}</span>-->
    </div>

    <div class='flex items-center gap-2'>
      <alimento-ipv-frontend-enterprises-status-chip [isActive]='enterprise.isActive'></alimento-ipv-frontend-enterprises-status-chip>
      <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
    </div>
  </div>

  <div class='flex items-center justify-between'>
    <div class='flex flex-col gap-1'>
      <span>{{'branches.rszNumber' | translate}}: {{enterprise.rszNumber}}</span>
      <span>{{'branches.vatNumber' | translate}}: {{enterprise.vatNumber}}</span>
    </div>

    <div class='flex items-center justify-between'>
      <div class='flex items-center gap-2 px-1'>
        <span *ngFor='let category of branchesMapper.getCategoryOptions(true)'
              class='font-material w-4'
              pTooltip='{{category.label}}'
              tooltipPosition='bottom'>
          {{$any(enterprise)[category.key] ? category.icon : ''}}
        </span>
      </div>
      <span>{{enterprise.employees}} {{'enterprises.employees' | translate}},
        {{enterprise.branches}} {{'enterprises.branchesTitle' | translate}}</span>
    </div>
  </div>

</div>
