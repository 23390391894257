<lib-lazy-dropdown
  *ngIf='formControl.enabled'
  [data$]='data$'
  [template]='itemTemplate'
  [headerTemplate]='headerTemplate'
  [formControl]='formControl'
  [showSearchIcon]='false'
  [showClear]='true'
  [width]='900'
  [displayValue]='getSelectedString'
  dropdownIcon='pi pi-search'
  placeholder="{{ placeholder | translate }}"
  field='name'
>
  <ng-template #headerTemplate>
    <alimento-ipv-frontend-branch-card></alimento-ipv-frontend-branch-card>
  </ng-template>
  <ng-template #itemTemplate let-item="item">
    <alimento-ipv-frontend-branch-card
      [branch]="item"
    ></alimento-ipv-frontend-branch-card>
  </ng-template>
</lib-lazy-dropdown>
<div *ngIf='formControl.disabled && formControl?.value'>
  <alimento-ipv-frontend-branch-card [branch]='formControl.value'></alimento-ipv-frontend-branch-card>
</div>
