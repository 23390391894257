import {
  Component,
  effect,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  signal,
  Signal,
  SimpleChanges
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuItemUtilsService, TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { map, Observable, of, Subscription } from 'rxjs';
import {
  EnrollmentRequest,
  EnrollmentRequestAction,
  EnrollmentRequestParticipant,
  ValidationRequest
} from '../../../types/enrollment.type';
import { Training } from '../../../types/training.type';
import { ENROLLMENT_RESULT_ID, ENROLLMENT_VIA, EnrollmentRequestActionEnum } from '../../../types/enrollment.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { EnrollmentRequestStatusKey } from '../../../types/reference-data.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'alimento-ipv-frontend-enrollment-request-card',
    templateUrl: './enrollment-request-card.component.html',
    standalone: false
})
export class EnrollmentRequestCardComponent implements OnChanges, OnDestroy {
  @Input()
  enrollmentRequest: EnrollmentRequest;

  @Input()
  readOnly = false;

  @Input()
  training: Signal<Training> = signal(undefined);

  @Input()
  validationRequest: ValidationRequest;

  @Output()
  actionClicked = new EventEmitter<EnrollmentRequestAction>();

  actions: MenuItem[] = [];
  participantActions: MenuItem[] = [];

  private _enrollmentRequest = signal<EnrollmentRequest>(undefined);
  private _subscriptions: Subscription[] = [];
  readonly CEVORA = ENROLLMENT_VIA.CEVORA;

  protected readonly ENROLLMENT_RESULT_ID = ENROLLMENT_RESULT_ID;
  protected readonly EnrollmentRequestActionEnum = EnrollmentRequestActionEnum;
  protected readonly EnrollmentRequestStatusKey = EnrollmentRequestStatusKey;

  constructor(private menuItemUtils: MenuItemUtilsService,
              private translationService: TranslationService,
              private referenceDataService: ReferenceDataService,
              private translate: TranslateService) {
    effect(() => {
      this.training();
      this._enrollmentRequest();
      this.translationService.languageChange();
      this.actions = this.getActions();
      this.participantActions = this.getParticipantActions();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enrollmentRequest']?.currentValue) {
      this._clearSubscriptions();
    }

    if (changes['validationRequest']?.currentValue) {
      this.actions = this.getActions();
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  menuItemAction(action: EnrollmentRequestActionEnum, participant?: EnrollmentRequestParticipant): () => any {
    return () => this.actionClicked.emit({
      action: action,
      enrollmentRequest: this.enrollmentRequest,
      participant: participant
    });
  }

  getParticipantActions(): MenuItem[] {
    if (this.enrollmentRequest.stateId !== EnrollmentRequestStatusKey.toVerify || this.readOnly) {
      return [];
    }
    else {
      return [
        {
          label: this.translate.instant('enrollments.editParticipant'),
          command: (event) => {
            const item = event.item as any;
            this.menuItemAction(EnrollmentRequestActionEnum.editParticipant, item.participant)();
          }
        },
        {
          label: this.translate.instant('enrollments.cancelParticipant'),
          styleClass: 'danger',
          icon: 'pi pi-times',
          iconStyle: {
            position: 'absolute',
            right: '2px',
          },
          command: (event) => {
            const item = event.item as any;
            this.menuItemAction(EnrollmentRequestActionEnum.cancelParticipant, item.participant)();
          }
        }
      ];
    }
  }

  getActions(): MenuItem[] {
    if (!this.enrollmentRequest) {
      return [];
    }
    const menuItems = [
      this.menuItemUtils.getMenuItem(
        'enrollments.actions.viewDetails',
        this.menuItemAction(EnrollmentRequestActionEnum.view)
      )
    ];

    if (this.enrollmentRequest.branchId) {
      menuItems.push(this.menuItemUtils.getMenuItem(
        'enrollments.actions.navigateToBranch',
        this.menuItemAction(EnrollmentRequestActionEnum.navigateToBranch),
        false,
        'pi pi-external-link'
      ));
    }

    if (this.readOnly) {
      return menuItems;
    }

    const deleteItem = this.menuItemUtils.getMenuItem(
      'enrollments.actions.delete',
      this.menuItemAction(EnrollmentRequestActionEnum.delete),
      false,
      'pi pi-times'
    );
    deleteItem.styleClass = 'danger';

    const cancelItem = this.menuItemUtils.getMenuItem(
      'enrollments.actions.cancel',
      this.menuItemAction(EnrollmentRequestActionEnum.cancel),
      false,
      'pi pi-times'
    );
    cancelItem.styleClass = 'danger';

    if (this.enrollmentRequest.stateId === EnrollmentRequestStatusKey.toValidate) {
      menuItems.splice(1, 0, this.menuItemUtils.getMenuItem(
        'enrollments.actions.validate',
        this.menuItemAction(EnrollmentRequestActionEnum.validate)
      ));
      menuItems.push(deleteItem);
    }
    else if (this.enrollmentRequest.stateId === EnrollmentRequestStatusKey.toVerify) {
      menuItems.splice(1, 0, this.menuItemUtils.getMenuItem(
        'enrollments.actions.confirmVerify',
        this.menuItemAction(EnrollmentRequestActionEnum.verify)
      ));

      if (this.validationRequest) {
        menuItems.push(cancelItem);
      }
      else {
        menuItems.push(deleteItem);
      }
    }

    return menuItems;
  }

  getWorkStatus(workStatusId: string): Observable<string> {
    if (workStatusId) {
      return this.referenceDataService.getWorkStatus(workStatusId).pipe(map(status => status.label));
    }
    return of("");
  }

  updateActionItem(participant: EnrollmentRequestParticipant): (item: any) => void {
    return (item: any) => {
      item.participant = participant;
    };
  }
}
