import {
  Component,
  computed,
  effect,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FEATURE,
  FeatureFlagService,
  FilterType,
  flattenObject,
  LeaveConfirmService,
  MenuItemUtilsService,
  MyMessageService,
  NOTES_TYPE,
  NotesSidebarComponent,
  Role,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import {
  defaultIfEmpty,
  first,
  forkJoin,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  takeWhile,
  tap,
  timer
} from 'rxjs';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BasicTrainingComponent } from '../../components/basic-training/basic-training.component';
import {
  BaseTraining,
  FinancialDocument,
  Reimbursement,
  ReimbursementRequest,
  TrainingData
} from '../../../types/reimbursement-request.type';
import { ReimbursementRequestService } from '../../../services/reimbursement-request.service';
import {
  ParticipantsListDialogComponent
} from '../../../trainings/components/participants-list-dialog/participants-list-dialog.component';
import {
  CertificatesDialogComponent
} from '../../../trainings/components/certificates-dialog/certificates-dialog.component';
import {
  CommunicateChangesDialogComponent
} from '../../../trainings/components/communicate-changes-dialog/communicate-changes-dialog.component';
import {
  ApproveEnrollmentsDialogComponent
} from '../../../trainings/components/approve-enrollments-dialog/approve-enrollments-dialog.component';
import {
  DOCUMENT_SET_TYPE,
  getTrainingStatusKey,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import { Branch, ContactPerson } from '../../../types/branch.type';
import { SessionService } from '../../../services/session.service';
import { EnrollmentService } from '../../../services/enrollment.service';
import { BranchService } from '../../../services/branch.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { Enterprise } from '../../../types/enterprise.type';
import { EnterprisesService } from '../../../services/enterprises.service';
import {
  CancelTrainingEvent,
  CloneTrainingEvent,
  CloseTraining,
  MoveTrainingEvent,
  PurchaseEntry,
  ReactivateTraining,
  SearchSalesInvoice
} from '../../../types/training.type';
import { PurchaseEntryService } from '../../../services/purchase-entries.service';
import { DocumentService } from '../../../services/document.service';
import { SearchesService } from '../../../services/searches.service';
import { PersonService } from '../../../services/person.service';
import { CommunicationsService } from '../../../services/communications.service';
import { MailContextType } from '../../../types/communications.enum';
import {
  CancelTrainingDialogComponent
} from '../../components/cancel-training-dialog/cancel-training-dialog.component';
import { TrainingService } from '../../../services/training.service';
import { EnterpriseGroupService } from '../../../services/enterprise-group.service';
import { CloneTrainingDialogComponent } from '../../components/clone-training-dialog/clone-training-dialog.component';
import { MoveTrainingDialogComponent } from '../../components/move-training-dialog/move-training-dialog.component';
import { REIMBURSEMENT_STATUS } from '../../../types/reimbursement-request.enum';
import { EmploymentService } from '../../../services/employment.service';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-request-training-detail',
  templateUrl: './reimbursement-request-training-detail.component.html',
  standalone: false
})
export class ReimbursementRequestTrainingDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('basicTrainingComponent')
  basicTrainingComponent!: BasicTrainingComponent;
  @ViewChild(ParticipantsListDialogComponent)
  participantsPopup: ParticipantsListDialogComponent;
  @ViewChild(CertificatesDialogComponent)
  certificatesPopup: CertificatesDialogComponent;
  @ViewChild(CommunicateChangesDialogComponent)
  communicateChangesPopup: CommunicateChangesDialogComponent;
  @ViewChild(ApproveEnrollmentsDialogComponent)
  approveEnrollmentsPopup: ApproveEnrollmentsDialogComponent;
  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;
  @ViewChild(CloneTrainingDialogComponent)
  cloneTrainingDialog: CloneTrainingDialogComponent;
  @ViewChild(MoveTrainingDialogComponent)
  moveTrainingDialog: MoveTrainingDialogComponent;

  @Input()
  readOnly = true;

  sessionsMenuItem: TabMenuItem;
  participantsMenuItem: TabMenuItem;
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  breadcrumb: MenuItem[];
  activeTabIndex = 0;
  trainingForm: FormGroup;
  trainingId!: string;
  type: TRAINING_TYPE;
  reimbursementRequestId!: string;
  title: string;

  trainingStatus: TrainingStatusKey = TrainingStatusKey.Draft;
  getTrainingStatusKey = getTrainingStatusKey;
  training: WritableSignal<BaseTraining> = signal(undefined);
  isOngoing: Signal<boolean> = this.sessionService.isOngoing;
  savingTraining = false;
  activatingTraining = false;
  loading = false;
  trainingStatusKeys = TrainingStatusKey;
  firstSessionDate?: Date;
  lastSessionDate?: Date;
  showNotes = false;
  TrainingNote = NOTES_TYPE.TrainingNote;
  notesHasChanges = false;
  documentCount: WritableSignal<number> = signal(undefined);
  communicationsCount: WritableSignal<number> = signal(undefined);
  reimbursementRequest: WritableSignal<ReimbursementRequest> = signal(undefined);
  branch: Branch;
  contactPersons: ContactPerson[] = [];
  selectedContactPersons: ContactPerson[];
  isGroupContactPerson = false;
  enterprise: WritableSignal<Enterprise> = signal(undefined);
  financialDocumentsFeatureOn: boolean;
  reimbursementFeatureOn: boolean;
  completeAllAbsencesToggle = new FormControl(false);
  financialDocuments: WritableSignal<FinancialDocument[]> = signal([]);
  reimbursements: WritableSignal<Reimbursement[]> = signal([]);
  trainingData: Signal<TrainingData> = computed(() => (
    {
      totalAmountToPayout: this.training()?.totalPreparationCost + this.training()?.totalCostWithoutPreparation -
        this.reimbursements()?.reduce((previousValue, currentValue) => currentValue.amount + previousValue, 0),
      sumTotalAmount: this.training()?.totalCostWithoutPreparation,
      sumPreparationAmount: this.training()?.totalPreparationCost,
      nrOfParticipants: this.enrollmentService.nrOfParticipants(),
      smeBalance: this.enterprise()?.smeBalance,
      smeBonus: this.enterprise()?.smeBonus,
      smeBonusValidFrom: this.enterprise()?.smeBonusValidFrom,
      smeBonusValidUntil: this.enterprise()?.smeBonusValidUntil,
      totalDuration: ''
    })
  );
  purchaseEntries: WritableSignal<PurchaseEntry[]> = signal([]);
  salesInvoices: WritableSignal<SearchSalesInvoice[]> = signal([]);
  trainingStartDate = computed(() => {
    const date = this.sessionService.sessions()?.filter(session => session.date)[0]?.date;
    return date ? new Date(date) : undefined;
  });

  private _subscriptions: Subscription[] = [];
  private _getCommunicationCountSub: Subscription;
  private readonly MAX_FETCH_COUNT = 3;
  protected readonly TRAINING_TYPE = TRAINING_TYPE;
  reactivatingTraining = false;
  closingTraining = false;
  reasonCancelled: string;
  extraActions: MenuItem[];

  protected readonly TrainingStatusKey = TrainingStatusKey;

  get isActivateDisabled(): boolean {
    return this.activatingTraining ||
      this.trainingStatus === this.trainingStatusKeys.Cancelled ||
      !this.basicTrainingComponent?.formGroup.get("customTitle").value;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  constructor(
    private fb: FormBuilder,
    private reimbursementRequestService: ReimbursementRequestService,
    private messageService: MyMessageService,
    private confirmationService: ConfirmationService,
    private leaveConfirmationService: LeaveConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translateService: TranslateService,
    private translationService: TranslationService,
    public sessionService: SessionService,
    public enrollmentService: EnrollmentService,
    private authorizationService: AuthorizationService,
    private titleService: TitleService,
    private branchService: BranchService,
    private enterpriseGroupService: EnterpriseGroupService,
    private referenceDataService: ReferenceDataService,
    private featureService: FeatureFlagService,
    private enterpriseService: EnterprisesService,
    private purchaseEntryService: PurchaseEntryService,
    private documentService: DocumentService,
    private searchesService: SearchesService,
    private personService: PersonService,
    private communicationService: CommunicationsService,
    private trainingService: TrainingService,
    private menuItemService: MenuItemUtilsService,
    private employmentService: EmploymentService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
    this._createTrainingForm();

    effect(() => {
      const sessionsWithDates = this.sessionService.sessions().filter(session => session.date);
      this.firstSessionDate = sessionsWithDates.length > 0 ? new Date(sessionsWithDates[0].date) : undefined;
      this.lastSessionDate = sessionsWithDates.length > 0 ? new Date(sessionsWithDates[sessionsWithDates.length - 1].date) : undefined;

      const absencesComplete = this.sessionService.sessions()
        .filter(session => !session.absenceRegistrationComplete).length === 0;
      this.completeAllAbsencesToggle.setValue(absencesComplete, { emitEvent: false });
      if (absencesComplete) {
        this.completeAllAbsencesToggle.disable({ emitEvent: false });
      }
      else {
        this.completeAllAbsencesToggle.enable({ emitEvent: false });
      }
    });

    effect(() => {
      if (this.training()) {
        this.trainingStatus = (this.training().statusId as TrainingStatusKey) ?? TrainingStatusKey.Draft;
        if (this.training().reasonCancelledId) {
          this.referenceDataService.getCancelReimbursementTrainingReason(this.training().reasonCancelledId)
            .subscribe(reason => this.reasonCancelled = reason.label);
        }
        else {
          this.reasonCancelled = undefined;
        }
        if (this.reimbursementRequest()) {
          this._fillTrainingForm(this.training());
          this._setTitle();
        }
      }
      this._createMenuItems();
    });

    effect(() => {
      if (this.reimbursementRequest()) {
        if (this.training()) {
          this._fillTrainingForm(this.training());
          this._setTitle();
          this.getPurchaseEntries();
          this.getSalesInvoices();
        }
      }
      this._createMenuItems();
      this._createBreadcrumb();
    });
  }

  ngOnInit(): void {
    this._subscriptions.push(
      this.route.params.subscribe(params => {
        this.reimbursementRequestId = params['reimbursementRequestId'];
        this.trainingId = params['trainingId'];
        this.route.data.pipe(first())
          .subscribe(data => {
            this.type = data['trainingType'];

            if (this.reimbursementRequestId) {
              this._fetchReimbursementRequestData();
            }
            else if (this.trainingId) {
              this._fetchTrainingData();
            }
            else {
              this.router.navigate(['error'], {
                state: {
                  message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                    item: this.translateService.instant('trainings.openTraining'),
                    id: this.trainingId
                  }),
                  redirectUrl: '/reimbursement-requests'
                }
              });
            }
          });
      })
    );

    this._subscriptions.push(
      this.route.queryParams.subscribe(
        queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0
      )
    );

    this._subscriptions.push(
      this.completeAllAbsencesToggle.valueChanges.subscribe(newValue => {
        this.trainingService.completeAllAbsences(this.trainingId).pipe(first()).subscribe({
          next: () => {
            this.messageService.success();

            this.loadTrainingData();
            this.sessionService.loadSessions(this.trainingId, this.type);
            this.enrollmentService.loadEnrollments(this.trainingId);
            this.enrollmentService.loadEnrollmentRequests(this.trainingId);

            this.completeAllAbsencesToggle.setValue(newValue, { emitEvent: false });
          },
          error: () => {
            this.completeAllAbsencesToggle.setValue(!newValue, { emitEvent: false });
          }
        });
      })
    );
  }

  private _fetchReimbursementRequestData(): void {
    this.reimbursementRequestService.getRequest(this.reimbursementRequestId)
      .pipe(first())
      .subscribe({
        next: reimbursementRequest => {
          this.reimbursementRequest.set(reimbursementRequest);
          this._fetchBranchData();

          if (!this.training()) {
            if (this.trainingId) {
              this._fetchTrainingData();
            }
            else {
              this._fillTrainingForm({} as BaseTraining);
            }
          }

          this._createMenuItems();
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('reimbursementRequests.request'),
                id: this.reimbursementRequestId
              }),
              redirectUrl: '/reimbursement-requests'
            }
          });
        }
      });
  }

  private _fetchTrainingData(): void {
    this.reimbursementRequestService.getTraining(this.trainingId, this.type)
      .pipe(first())
      .subscribe(training => {
        this.training.set(training);
        if (!this.reimbursementRequest()) {
          this.reimbursementRequestId = this.training().trainingAllowanceApplicationId;
          this._fetchReimbursementRequestData();
        }
        this.sessionService.loadSessions(this.trainingId, training.typeId);
        this.enrollmentService.loadEnrollments(this.trainingId);
        this.enrollmentService.loadEnrollmentRequests(this.trainingId);
        this._checkForNewCommunication();
        if (!window.history.state?.newTraining) {
          this._loadDocuments();
        }
        this.featureService.initialize().pipe(first()).subscribe(() => {
          this.financialDocumentsFeatureOn = this.featureService.featureOn(FEATURE.FINANCIAL_DOCUMENTS);
          this.reimbursementFeatureOn = this.featureService.featureOn(FEATURE.REIMBURSEMENT);
          this.getFinancialDocuments();
          this.getReimbursements();
        });
        this._setDetailUrl();
      });
  }

  private _fetchBranchData(): void {
    this.branchService.getBranch(this.reimbursementRequest().branchId).pipe(first())
      .subscribe(branch => {
        this.branch = branch;
        this._fetchEnterpriseData();
        this._setTitle();
      });
  }

  private _fetchEnterpriseData(): void {
    if (this.branch) {
      this.enterpriseService.getEnterprise(this.branch.enterpriseId).pipe(first())
        .subscribe(enterprise => {
          this.enterprise.set(enterprise);
          this._getContactPersons();
        });
    }
  }

  private _getContactPersons(): void {
    if (this.reimbursementRequest()?.branchId) {
      let contactPersonRequest;
      if (this.enterprise()?.enterpriseGroupId) {
        contactPersonRequest = this.enterpriseGroupService.getContactPersons(this.enterprise().enterpriseGroupId);
        this.isGroupContactPerson = true;
      }
      else {
        contactPersonRequest = this.branchService.getContactPersons(this.reimbursementRequest().branchId);
        this.isGroupContactPerson = false;
      }

      contactPersonRequest.pipe(first())
        .subscribe(contactPersons => {
          const contactPersonData = contactPersons;
          const extraRequests: Observable<ContactPerson>[] = [];
          (this.training()?.contactEmploymentIds || this.reimbursementRequest().contactEmploymentIds)
            .forEach(contactPersonId => {
              const matchingContact = contactPersonData
                .filter(contact => contact.employmentId === contactPersonId)[0];
              if (matchingContact) {
                extraRequests.push(of(matchingContact));
              }
              else {
                extraRequests.push(
                  this.employmentService.getEmployment(contactPersonId).pipe(
                    first(),
                    map(employment => {
                      return {
                        employmentId: employment.employmentId,
                        personId: employment.personId,
                        firstName: employment.firstName,
                        lastName: employment.lastName,
                        phoneNumber: employment.phoneNumber,
                        email: employment.professionalEmail,
                        responsibilities: employment.responsibilities,
                        isInactive: true
                      };
                    }),
                    tap(contactPerson => {
                      contactPersonData.push(contactPerson);
                    })
                  )
                );
              }
            });

          forkJoin(extraRequests).pipe(defaultIfEmpty([]))
            .subscribe((persons) => {
              this.contactPersons = contactPersonData;
              this.selectedContactPersons = persons;
            });
        });
    }
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.trainingForm.dirty && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.trainingForm.dirty) {
      saveActions.push(this._saveTrainingData());
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this.enrollmentService.clearEnrollments();
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem): void {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveTraining(): void {
    const executeSave = () => {
      this.savingTraining = true;
      this._saveTrainingData()
        .subscribe({
          next: () => {
            this.savingTraining = false;
            this._getContactPersons();
          },
          error: () => this.savingTraining = false
        });
    };

    if (this.basicTrainingComponent.hasInactiveCaseManagersOrCounselors()) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
        message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
        acceptIcon: 'hidden',
        rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
        rejectButtonStyleClass: 'inverted-button',
        accept: () => executeSave(),
        reject: () => {
          this.activeTabIndex = 0;
          setTimeout(() => this.basicTrainingComponent.focusFirstInactiveCaseManager());
        }
      });
    }
    else {
      executeSave();
    }
  }

  loadTrainingData(): void {
    this.reimbursementRequestService.getTraining(this.trainingId, this.training()?.typeId || this.type)
      .pipe(first())
      .subscribe((training: BaseTraining) => {
        this.training.set(training);
        this._checkForNewCommunication();
      });
  }

  getFinancialDocuments(): void {
    if (this.trainingId && this.financialDocumentsFeatureOn) {
      this.reimbursementRequestService.getFinancialDocuments(this.trainingId).pipe(first())
        .subscribe(financialDocuments => this.financialDocuments.set(financialDocuments.data));
    }
  }

  financialDocumentsChanged(): void {
    this.getFinancialDocuments();
    this.loadTrainingData();
  }

  getReimbursements(): void {
    if (this.trainingId && this.reimbursementFeatureOn) {
      this.reimbursementRequestService.getReimbursements(this.trainingId).pipe(first())
        .subscribe(reimbursements => this.reimbursements.set(reimbursements));
    }
  }

  reimbursementsChanged(): void {
    this.loadTrainingData();
    this.getReimbursements();
  }

  private _createTrainingForm(): void {
    this.trainingForm = this.fb.group({
      trainingAlimentoId: [null],
      trainingAllowanceApplicationId: [],
      basicDataForm: []
    });
  }

  private _fillTrainingForm(training: BaseTraining) {
    this.trainingStatus = (training.statusId as TrainingStatusKey) ?? TrainingStatusKey.Draft;

    this.trainingForm.patchValue({
      trainingAlimentoId: training.trainingAlimentoId,
      trainingAllowanceApplicationId: this.reimbursementRequestId,
      basicDataForm: BasicTrainingComponent.createFormData(training, this.reimbursementRequest())
    }, { emitEvent: false });

    this.trainingForm.markAsPristine();

    if (this.readOnly) {
      this.trainingForm.disable({ emitEvent: false });
    }
  }

  private _saveTrainingData(): Observable<{ id: string }> {
    const training: BaseTraining = { ...(flattenObject(this.trainingForm.value) as BaseTraining) };

    this.basicTrainingComponent.validateFormFields();
    validateAllFormFields(this.trainingForm);
    if (!this.trainingForm.valid) {
      this.messageService.notAllFieldsValid();
      return new Observable<{ id: string }>((observable) => observable.error());
    }

    let changeType$: Observable<void>;
    if (this.training()?.trainingId && training.typeId !== this.training().typeId) {
      changeType$ = this.reimbursementRequestService.changeTrainingType(this.training()?.trainingId, this.training().typeId, training.typeId)
        .pipe(first());
    }
    else {
      changeType$ = new Observable<void>(observable => observable.next());
    }

    return changeType$.pipe(
      switchMap(() =>
        this.trainingId
          ? this.reimbursementRequestService.updateTraining(this.trainingId, training)
          : this.reimbursementRequestService.createTraining(training)),
      tap({
        next: (response: { id: string }) => {
          this.trainingId = response.id;
          this.type = training.typeId;
          this.trainingForm.markAsPristine();
          this.messageService.success('trainings.saved');
          this._fetchTrainingData();
        }
      }),
      first()
    );
  }

  private _setDetailUrl(): void {
    if (this.trainingId) {
      const basePath = this.type === TRAINING_TYPE.CUSTOM ? 'custom' : 'openextern';
      this.location.replaceState(
        this.router
          .createUrlTree(['/trainings', basePath, this.trainingId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.sessionsMenuItem = {
          index: 1,
          name: 'sessions',
          title: this.translateService.instant('trainings.sessions.sessions'),
          count: this.sessionService.nrOfSessions,
          disabled: !this.training()?.trainingId
        };
        this.participantsMenuItem = {
          index: 2,
          name: 'participants',
          title: this.translateService.instant('trainings.participantsHeader'),
          count: this.enrollmentService.nrOfParticipants,
          // maxCount: this.training()?.maxParticipants,
          disabled: !this.training()?.trainingId
        };
        this.tabMenuItems = [
          { index: 0, name: 'basicData', title: this.translateService.instant('trainings.basicData.formTitle') },
          this.sessionsMenuItem,
          this.participantsMenuItem,
          {
            index: 3,
            name: 'communications',
            title: this.translateService.instant('trainings.communications.communications'),
            count: this.communicationsCount,
            disabled: !this.training()?.trainingId
          },
          {
            index: 4,
            name: 'financialDocuments',
            disabled: !this.financialDocumentsFeatureOn,
            title: this.translateService.instant('financialDocuments.menuTitle')
          },
          {
            index: 5,
            name: 'reimbursements',
            disabled: !this.reimbursementFeatureOn,
            title: this.translateService.instant('reimbursements.menuTitle')
          },
          {
            index: 6,
            name: 'invoices',
            title: this.translateService.instant('trainings.invoices.menuTitle'),
            disabled: !this.training()?.trainingId,
            count: computed(() => this.purchaseEntries().length + this.salesInvoices().length)
          }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('trainings.notesData.formTitle'),
            disabled: !this.training()?.trainingId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          {
            name: 'documents',
            title: this.translateService.instant('documents'),
            disabled: !this.training()?.trainingId || !this.training()?.documentSetUrl,
            count: this.documentCount,
            command: () => {
              window.open(this.training()?.documentSetUrl, '_blank');
            }
          }
        ];
      })
    );
  }

  private _createBreadcrumb(): void {
    this.breadcrumb = [
      {label: "titles.trainings.list", routerLink: ['/trainings']},
      {label: this.training()?.trainingId ? (this.training().trainingAlimentoId + " - " + this.training()?.customTitle) : 'trainings.actions.newFormation'}
    ];
  }

  setExtraActions(): void {
    this.extraActions = [];

    const canMove = this.reimbursements()?.filter(reimbursement => reimbursement.statusId !== REIMBURSEMENT_STATUS.CALCULATED)?.length === 0;
    this.extraActions.push(this.menuItemService.getMenuItem('trainings.cloneDialog.title',
        () => this.cloneTrainingDialog.open()),
      this.menuItemService.getMenuItem('trainings.moveDialog.title', () => this.moveTrainingDialog.open(), !canMove));
  }

  private _setTitle(): void {
    if (this.branch && this.training()) {
      this.referenceDataService.getTrainingType(this.training().typeId).pipe(first())
        .subscribe(type => {
          this.title = `${this.translateService.instant('reimbursementRequests.request')} ${this.branch.branchName} - ${type.label}`;
          this.titleService.setTitle([this.title]);
        });
    }
  }

  private _loadDocuments(): void {
    if (this.training()?.documentSetUrl) {
      this.documentService.getDocumentCount(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
              .subscribe(documentSet => {
                this.training.update(training => {
                  training.documentSetUrl = documentSet.url;
                  return training;
                });
                this.documentCount.set(0);
              });
          }
        });
    }
  }

  private _loadCommunicationsCount(fetching: WritableSignal<number>): void {
    if (this.training()) {
      const searchRequest: SearchRequest = { filters: [] };
      searchRequest.filters.push({ type: FilterType.filterContextType, values: [MailContextType.Training] });
      searchRequest.filters.push({ type: FilterType.filterContextValue, values: [this.training().trainingId] });
      this.communicationService.getCommunicationsCount(searchRequest).pipe(first())
        .subscribe(count => {
          const currentCount = this.communicationsCount();
          if (fetching && currentCount !== undefined && count > currentCount) {
            this.messageService.success('trainings.communicationSent');
          }
          fetching.set(this.MAX_FETCH_COUNT);
          this.communicationsCount.set(count);
        });
    }
  }

  private _checkForNewCommunication(): void {
    this._getCommunicationCountSub?.unsubscribe();
    const fetching = signal(0);
    this._getCommunicationCountSub = timer(0, 3000)
      .pipe(
        takeWhile(() => fetching() < this.MAX_FETCH_COUNT),
        tap(() => {
          this._loadCommunicationsCount(fetching);
          fetching.set(fetching() + 1);
        })
      )
      .subscribe();
  }

  getPurchaseEntries(): void {
    if (this.trainingId) {
      this.purchaseEntryService.getPurchaseEntries(this.trainingId).pipe(first())
        .subscribe(purchaseEntries => this.purchaseEntries.set(purchaseEntries));
    }
  }

  getSalesInvoices(): void {
    if (this.trainingId) {
      const searchRequest: SearchRequest = {
        filters: [{ type: FilterType.filterTrainingId, values: [this.trainingId] }]
      };

      this.searchesService.searchSalesInvoices(searchRequest).pipe(first())
        .subscribe(response => this.salesInvoices.set(response.data));
    }
  }

  cancelTraining(event: CancelTrainingEvent, dialog: CancelTrainingDialogComponent): void {
    event.setLoading(true);
    this.trainingService.cancelTraining(this.trainingId, event.data, this.type).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Cancelled;

        this.messageService.success('trainings.cancelled');

        event.setLoading(false);
        dialog.closeDialog();

        this.loadTrainingData();
        this.sessionService.loadSessions(this.trainingId, this.type);
        this.enrollmentService.loadEnrollments(this.trainingId);
        this.enrollmentService.loadEnrollmentRequests(this.trainingId);
      }
    });
  }

  closeTraining(closeTraining: CloseTraining): void {
    this.trainingService.closeTraining(this.trainingId, closeTraining, this.type).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Completed;

        this.messageService.success('states.completed');

        this.sessionService.loadSessions(this.trainingId, this.type);
        this.enrollmentService.loadEnrollments(this.trainingId);
        this.enrollmentService.loadEnrollmentRequests(this.trainingId);
      }
    });
  }

  reactivateTraining(reactivateTraining: ReactivateTraining): void {
    this.trainingService.reactivateTraining(this.trainingId, reactivateTraining, this.type).subscribe({
      next: () => {
        this.trainingStatus = TrainingStatusKey.Active;

        this.messageService.success('states.reactivated');

        this.sessionService.loadSessions(this.trainingId, this.type);
        this.enrollmentService.loadEnrollments(this.trainingId);
        this.enrollmentService.loadEnrollmentRequests(this.trainingId);
      }
    });
  }

  cloneTraining(event: CloneTrainingEvent): void {
    event.setLoading(true);
    this._saveTrainingData().pipe(switchMap(() =>
      this.trainingService.cloneTraining(event.trainingId, this.type, event.includeSessions)), first())
      .subscribe({
        next: newId => {
          event.setLoading(false);
          this.cloneTrainingDialog.closeDialog();
          setTimeout(() => this.messageService.success('trainings.cloneDialog.success'));

          this.reimbursementRequest.set(undefined);
          delete this.reimbursementRequestId;

          const basePath = this.type === TRAINING_TYPE.CUSTOM ? 'custom' : 'openextern';
          this.router.navigate(['/trainings', basePath, newId]);
        },
        error: () => event.setLoading(false)
      });
  }

  moveTraining(event: MoveTrainingEvent): void {
    event.setLoading(true);
    this._saveTrainingData().pipe(switchMap(() =>
      this.trainingService.moveTraining(event.trainingId, this.type, event.trainingAllowanceApplicationId)), first())
      .subscribe({
        next: () => {
          event.setLoading(false);
          this.moveTrainingDialog.closeDialog();
          this.messageService.success('trainings.moveDialog.success');

          this.reimbursementRequest.set(undefined);
          delete this.reimbursementRequestId;
          this._fetchTrainingData();
        },
        error: () => event.setLoading(false)
      });
  }
}
