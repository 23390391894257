import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { TrainingTemplateSearchItem } from '../../../types/training-template.type';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'alimento-ipv-frontend-template-card',
  templateUrl: './template-card.component.html',
  standalone: false
})
export class TemplateCardComponent implements OnChanges {
  @Input()
  template: TrainingTemplateSearchItem;

  @Input()
  readOnly = true;

  @Output()
  onCreateForTemplate = new EventEmitter<string>();

  rubric: string;
  actions: MenuItem[];

  constructor(private referenceDataService: ReferenceDataService,
              private translate: TranslateService,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['template']?.currentValue) {
      if (this.template.rubricId) {
        this.referenceDataService.getTrainingRubric(this.template.rubricId).pipe(first())
          .subscribe(rubric => this.rubric = rubric.label);
      }
      this.setActions();
    }

    if (changes['readOnly']?.currentValue) {
      this.setActions();
    }
  }

  navigateToTemplate(event?: MouseEvent): void {
    const path = ['/training-templates', this.template.trainingTemplateId];
    if (event?.ctrlKey) {
      window.open(path.join('/'), '_blank');
    }
    else {
      this.router.navigate(path);
    }
  }

  private setActions(): void {
   this.actions = [
      {
        id: 'showDetail',
        label: this.translate.instant('templates.actions.showDetail'),
        icon: 'pi pi-eye',
        iconStyle: {
          position: 'absolute',
          right: '2px'
        },
        command: () => this.navigateToTemplate()
      }
    ];

    if (!this.readOnly) {
      this.actions.push({
        id: 'createTrainingFromTemplate',
        label: this.translate.instant('templates.actions.createTrainingFromTemplate'),
        command: () => this.onCreateForTemplate.emit(this.template.trainingTemplateId)
      });
    }
  }

}
