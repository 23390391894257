import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
  private history: string[] = [];
  private _routerEventSub: Subscription;
  private isPopState: boolean;

  constructor(private router: Router,
              private location: Location) {
    this.history.push(this.location.path());

    this._routerEventSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isPopState = event.navigationTrigger === 'popstate';
      }
      if (event instanceof NavigationEnd) {
        if (this.isPopState) {
          // dit klopt niet 100%. Kan niet zien of je back of forward gedrukt hebt.
        }
        else {
          this.history.push(event.urlAfterRedirects);
          if (this.history.length > 50) {
            this.history.shift();
          }
          window.scrollTo(0, 0);
        }
      }
    });
  }

  replaceState(path: string, query?: string, state?: any) {
    this.history.pop();
    this.location.replaceState(path, query, state);
    this.history.push(path);
  }

  back(fallBack = '/', toPreviousRoute = false, ctrlKeyPressed = false, fallbackBaseUrl = false): void {
    if (this.history.length > 1) {
      const currentUrl = this.history.pop();

      if (toPreviousRoute) {
        let isDifferent = false;
        let previousUrl;
        while (!isDifferent && this.history.length > 0) {
          previousUrl = this.history.pop();
          if (fallbackBaseUrl) {
            isDifferent = previousUrl.replace(/\?.*/, '') === fallBack;
          }
          else {
            isDifferent = (currentUrl.split('/')[1] !== previousUrl.split('/')[1]);
          }
        }
        if (this.history.length > 0 || isDifferent) {
          this._navigate(previousUrl, ctrlKeyPressed);
        }
        else {
          this._navigate(fallBack, ctrlKeyPressed);
        }
      }
      else {
        this._navigate(this.history.pop(), ctrlKeyPressed);
      }
    }
    else {
      this._navigate(fallBack, ctrlKeyPressed);
    }
  }

  private _navigate(url: string, ctrlKeyPressed: boolean) {
    if (ctrlKeyPressed) {
      window.open(url);
    }
    else {
      this.router.navigateByUrl(url);
    }
  }

  getCurrentParameters(): Params {
    if (this.router.url === this.location.path()) {
      return this.router.routerState.snapshot.root.queryParams;
    }
    return {};
  }

  ngOnDestroy(): void {
    this._routerEventSub.unsubscribe();
  }
}
