import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { delay, filter, first, map, mergeMap, Observable, Subscription, switchMap, tap } from 'rxjs';
import {
  AuthorizationService,
  CanComponentDeactivate,
  flattenObject,
  LeaveConfirmService,
  LoaderService,
  Role,
  TabMenuItem,
  TitleService,
  TranslationService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { BasicInfoComponent } from '../../../utils/components/basic-info/basic-info.component';
import {
  SummaryDescriptionComponent
} from '../../../utils/components/summary-description/summary-description.component';
import { TRAINING_TYPE, TemplateStatusKey, getTemplateStatusKey } from '../../../types/reference-data.enum';
import { TrainingTemplate } from '../../../types/training-template.type';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: 'alimento-ipv-frontend-training-template-detail',
    templateUrl: './training-template-detail.component.html',
    standalone: false
})
export class TrainingTemplateDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('basicInfoComponent')
  basicInfoComponent!: BasicInfoComponent;
  @ViewChild('summaryDescriptionComponent')
  summaryDescriptionComponent!: SummaryDescriptionComponent;

  sessionsMenuItem: TabMenuItem = {
    index: 2,
    name: 'sessions',
    title: this.translateService.instant("trainings.sessions.sessions"),
    count: this.sessionService.nrOfSessions
  };

  tabMenuItems: TabMenuItem[] = [
    { index: 0, name: 'basicData', title: 'Basisgegevens' },
    { index: 1, name: 'description', title: 'Beschrijving' },
    this.sessionsMenuItem
  ];

  activeTabIndex = 0;
  templateForm: FormGroup;
  templateId!: string;
  templateStatus: TemplateStatusKey = TemplateStatusKey.Draft;
  getTemplateStatusKey = getTemplateStatusKey;
  templateCreatedBy!: string | undefined;
  templateCreatedOn!: Date | undefined;
  showRequiredFieldErrors = false;
  templateReadyToActivate = false;
  template!: TrainingTemplate;
  savingTemplate = false;
  activatingTemplate = false;
  templateStatusKeys = TemplateStatusKey;
  requiredFieldToggle = new FormControl(false);
  readOnly = false;
  breadcrumb: MenuItem[];

  private _subscriptions: Subscription[] = [];
  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  get isActivateDisabled(): boolean {
    return this.activatingTemplate ||
      this.templateStatus === this.templateStatusKeys.Removed ||
      !this.basicInfoComponent?.formGroup.get("customTitle").value;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = "Are you sure";
    }
  }

  constructor(
    private fb: FormBuilder,
    private trainingTemplateService: TrainingTemplateService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private leaveConfirmationService: LeaveConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translateService: TranslateService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private loaderService: LoaderService,
    private authorizationService: AuthorizationService,
    private titleService: TitleService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._setMenuItems();
    this._createBreadcrumb();
    this._createTemplateForm();
  }

  ngOnInit() {
    this.route.params
      .pipe(
        map((params) => params['templateId']),
        filter((templateId) => !!templateId),
        tap((trainingId) => (this.templateId = trainingId)),
        tap(() => this.loaderService.showLoader()),
        mergeMap((templateId) => this.trainingTemplateService.getTemplate(templateId))
      )
      .subscribe({
        next: (template: TrainingTemplate) => {
          this.template = template;
          this.titleService.setTitle(
            [`${this.translateService.instant("titles.templates.detail")} - ${template.customTitle}`]);
          this._fillTemplateForm(template);
          this.sessionService.loadSessions(this.templateId, TRAINING_TYPE.TEMPLATE);
          this.loaderService.hideLoader();
          this._createBreadcrumb();
          this._setMenuItems();
        },
        error: () => {
          this.loaderService.hideLoader();
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('templates.templateOpenTraining'),
                id: this.templateId
              }),
              redirectUrl: '/training-templates'
            }
          });
        }
      });

    this._subscriptions.push(
      this.route.queryParams.subscribe(
        (queryParams) => (this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0)
      )
    );

    this._subscriptions.push(
      this.templateForm.statusChanges.pipe(delay(1)).subscribe(() => this._calculateTabMenuStatus())
    );

    this._subscriptions.push(
      this.requiredFieldToggle.valueChanges.subscribe((newValue) => {
        this.showRequiredFieldErrors = !!newValue;
        setTimeout(() => this._calculateTabMenuStatus());
      })
    );
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.templateForm.dirty) {
      return true;
    }

    return this.leaveConfirmationService.leaveDialog(() =>
      this.saveTemplateData(this.template?.status?.data === TemplateStatusKey.Active)
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this.setDetailUrl();
  }

  saveTemplate() {
    const executeSave = () => {
      this.savingTemplate = true;
      this.saveTemplateData(this.template?.status?.data === TemplateStatusKey.Active).subscribe({
        next: () => (this.savingTemplate = false),
        error: () => (this.savingTemplate = false)
      });
    }

    if (this.basicInfoComponent.hasInactiveCaseManagersOrCounselors()) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
        message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
        acceptIcon: 'hidden',
        rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
        rejectButtonStyleClass: 'inverted-button',
        accept: () => executeSave(),
        reject: () => {
          this.activeTabIndex = 0;
          setTimeout(() => this.basicInfoComponent.focusFirstInactiveCaseManager());
        }
      });
    }
    else {
      executeSave();
    }
  }

  deleteTemplate() {
    this.confirmationService.confirm({
      header: this.translateService.instant('templates.deleteDialog.title'),
      message: this.translateService.instant('templates.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('templates.deleteDialog.delete'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('templates.deleteDialog.cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        this.trainingTemplateService
          .deleteTemplate(this.templateId)
          .subscribe(() => this.router.navigate(['/training-templates']));
      }
    });
  }

  activateTemplate() {
    if (this.templateStatus === TemplateStatusKey.Draft) {
      const executeActivate = () => {
        this.activatingTemplate = true;
        this.saveTemplateData(true)
          .pipe(switchMap(() => this.trainingTemplateService.activateTemplate(this.templateId)), first())
          .subscribe({
            next: () => {
              this.templateStatus = TemplateStatusKey.Active;
              this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant('templates.activated') + '!'
              });
              this.activatingTemplate = false;

              this.sessionService.loadSessions(this.templateId, TRAINING_TYPE.TEMPLATE);
            },
            error: () => (this.activatingTemplate = false)
          });
      }

      if (this.basicInfoComponent.hasInactiveCaseManagersOrCounselors()) {
        this.confirmationService.confirm({
          header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
          message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
          acceptIcon: 'hidden',
          rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
          rejectButtonStyleClass: 'inverted-button',
          accept: () => executeActivate(),
          reject: () => {
            this.activeTabIndex = 0;
            setTimeout(() => this.basicInfoComponent.focusFirstInactiveCaseManager());
          }
        });
      }
      else {
        executeActivate();
      }
    }
  }

  createTrainingFromTemplate(templateId: string) {
    this.trainingTemplateService
      .createTrainingFromTemplate(templateId)
      .pipe(first())
      .subscribe(trainingId =>
        this.router.navigate(['/trainings', "open", trainingId], {
          relativeTo: this.route.parent,
          state: {
            newTraining: true
          }
        })
      );
  }

  private _createTemplateForm(): void {
    this.templateForm = this.fb.group({
      templateAlimentoId: [null],
      trainingTypeId: [TRAINING_TYPE.OPEN_TRAINING],
      basicDataForm: [],
      descriptionForm: []
    });

    if (this.readOnly) {
      this.templateForm.disable();
    }
  }

  private _fillTemplateForm(template: TrainingTemplate) {
    this.templateStatus = (template.status?.data as TemplateStatusKey) ?? TemplateStatusKey.Draft;
    this.templateCreatedBy = template.createdBy;
    this.templateCreatedOn = template.createdOn;
    this.showRequiredFieldErrors = this.templateStatus === TemplateStatusKey.Active;

    this.templateForm.patchValue({
      templateAlimentoId: template.trainingTemplateAlimentoId,
      trainingTypeId: template.trainingTypeId,
      basicDataForm: BasicInfoComponent.createFormData(template),
      descriptionForm: SummaryDescriptionComponent.createFormData(template)
    });
  }

  private _calculateTabMenuStatus(): void {
    const basicDataForm: AbstractControl | null = this.templateForm.get('basicDataForm');
    const descriptionForm: AbstractControl | null = this.templateForm.get('descriptionForm');

    const basicDataMenuItem: TabMenuItem = this.tabMenuItems.filter((menuItem) => menuItem.name === 'basicData')[0];
    const descriptionMenuItem: TabMenuItem = this.tabMenuItems.filter((menuItem) => menuItem.name === 'description')[0];

    basicDataMenuItem.errors = this.basicInfoComponent.hasErrors();
    basicDataMenuItem.completed = !!basicDataForm?.valid;

    descriptionMenuItem.errors = this.summaryDescriptionComponent.hasErrors();
    descriptionMenuItem.completed = !!descriptionForm?.valid;

    this.templateReadyToActivate = !!basicDataForm?.valid && !!descriptionForm?.valid;
  }

  private saveTemplateData(validate = false): Observable<{ id: string }> {
    const template: TrainingTemplate = { ...(flattenObject(this.templateForm.value) as TrainingTemplate) };

    if (this.isActivateDisabled || validate) {
      validateAllFormFields(this.templateForm);

      if (!this.templateForm.valid) {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('trainings.sessions.hasErrors')
        });
        return new Observable<{ id: string }>((observable) => observable.error());
      }
    }

    const createOrUpdateTemplate$ = this.templateId
      ? this.trainingTemplateService.updateTemplate(this.templateId, template)
      : this.trainingTemplateService.createTemplate(template);

    return createOrUpdateTemplate$.pipe(
      tap((response: { id: string }) => {
        this.templateId = response.id;
        this.templateForm.markAsPristine();
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('templates.saved') + '!'
        });
        this.setDetailUrl();
        this._createBreadcrumb();

        this.setAlimentoIdAfterFirstSave(this.templateId);
      })
    );
  }

  private setDetailUrl(): void {
    if (this.templateId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/training-templates', this.templateId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private setAlimentoIdAfterFirstSave(templateId: string) {
    if (!this.templateForm.value.templateAlimentoId) {
      this.trainingTemplateService.getTemplate(templateId).pipe(first())
        .subscribe((template: TrainingTemplate) => {
          this.template = template;
          this.templateCreatedBy = template.createdBy;
          this.templateCreatedOn = template.createdOn;
          this.titleService.setTitle(
            [`${this.translateService.instant("titles.templates.detail")} - ${template.customTitle}`]);
          this.templateForm.patchValue({ templateAlimentoId: template.trainingTemplateAlimentoId });
        });
    }
  }

  private _setMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.sessionsMenuItem = {
          index: 2,
          name: 'sessions',
          title: this.translateService.instant('trainings.sessions.sessions'),
          count: this.sessionService.nrOfSessions
        };

        this.tabMenuItems = [
          { index: 0, name: 'basicData', title: this.translateService.instant('trainings.basicData.formTitle') },
          { index: 1, name: 'description', title: this.translateService.instant('trainings.descriptionData.formTitle') },
          this.sessionsMenuItem
        ];

        if (this.templateForm) {
          this._calculateTabMenuStatus();
        }
      })
    )
  }

  private _createBreadcrumb(): void {
    this.breadcrumb = [
      {label: "titles.templates.list", routerLink: ['/training-templates']},
      {label: this.template?.trainingTemplateAlimentoId ? (this.template.trainingTemplateAlimentoId + " - " + this.template?.customTitle) : 'titles.templates.create'}
    ];
  }
}
