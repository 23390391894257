<p-dialog
  *ngIf='dialogVisible'
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'enterpriseGroups.selectEnterprise' | translate }}"
>
  <div class='mt-2' [formGroup]='formGroup'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      formField='enterprise'
                                      fieldKey='enterprises.enterprise'
    >
      <alimento-ipv-frontend-enterprise-select formControlName='enterprise' [showHasGroup]='true'>
      </alimento-ipv-frontend-enterprise-select>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="selectEnterprise()"
        [loading]='loading'
        label="{{ 'enterpriseGroups.selectEnterprise' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'leaveDialog.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
