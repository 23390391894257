<p-select [options]="allSortItems" [formControl]="sortFormControl" styleClass="border-0">
  <ng-template pTemplate="selectedItem" let-item>
    <div class='flex items-center gap-2 flex-nowrap'>
      <span>{{item.label}}</span>
      <span class='font-material'>{{item.type === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</span>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class='flex items-center gap-2 flex-nowrap'>
      <span>{{item.label}}</span>
      <span class='font-material'>{{item.type === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</span>
    </div>
  </ng-template>
</p-select>
