<div class="flex flex-col items-start">
  <h1 *ngIf="showLabel" class="text-lg font-bold mb-2">{{"search" | translate}}</h1>
  <p-icon-field styleClass='w-full'>
    <p-inputicon styleClass="pi pi-search"></p-inputicon>
    <input
      [formControl]="formControl"
      #searchField
      class="w-full px-10"
      pInputText
      placeholder='{{(placeholderKey || "search.searchbarPlaceholder") | translate}}'
      type="text"
    />
    <p-inputicon *ngIf="formControl.value"
                 (click)="clearValue()"
                 styleClass="pi pi-times hover:text-black cursor-pointer"
    ></p-inputicon>
  </p-icon-field>
</div>
