<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [focusOnShow]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.participantsPopup.header' | translate }}"
>
  <div *ngIf="dialogVisible" [formGroup]="formGroup">
    <div class='mb-4'>
      <span class='text-lg'>{{'trainings.participantsPopup.whichSessions' | translate}}</span>
    </div>

    <alimento-ipv-frontend-select-list
      formControlName='sessions'
      [items]='sessions()'
      [label]='getSessionLabel'
      selectAllKey='trainings.participantsPopup.allSessions'
    >
    </alimento-ipv-frontend-select-list>

    <div>
      <label class='font-bold text-lg'>{{"trainings.participantsPopup.options" | translate}}</label>
      <div>
        <div class='field-checkbox'>
          <p-checkbox formControlName='individualFiles'
                      [binary]='true'
                      (click)='$event.stopImmediatePropagation()'
                      inputId='individualFiles'
          ></p-checkbox>
          <label for='individualFiles'>{{"trainings.participantsPopup.individualFiles" | translate}}</label>
        </div>
        <div class='field-checkbox'>
          <p-checkbox formControlName='requestPermissionForPhotos'
                      [binary]='true'
                      (click)='$event.stopImmediatePropagation()'
                      inputId='requestPermissionForPhotos'
          ></p-checkbox>
          <label for='requestPermissionForPhotos'>{{"trainings.participantsPopup.requestPermissionForPhotos" | translate}}</label>
        </div>
      </div>
    </div>

    <ng-container *ngIf='noTeacherEmployments.length > 0 || teachersWithNoEmail.length > 0'>
      <small class="flex gap-4 w-full items-center p-2 mb-2 mt-4 bg-warning-bg text-warning rounded">
        <span class="material-symbols-outlined"> warning </span>
        <div class='flex flex-col gap-1'>
          <span>{{ 'trainings.participantsPopup.couldNotBeSend' | translate }}</span>
          <span *ngFor='let session of noTeacherEmployments'>
            {{session.title }}: {{'trainings.participantsPopup.noTeachers' | translate}}
          </span>
          <span *ngFor='let item of teachersWithNoEmail'>
            {{item.session.title}}: {{'trainings.participantsPopup.noEmailForTeacher' | translate: {name: item.teacher} }}
          </span>
        </div>
        <span></span>
      </small>
    </ng-container>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="onSubmit(false)"
        [disabled]='nrOfSessions < 1'
        [loading]="loading"
        label="{{ 'trainings.participantsPopup.generate' | translate }} ({{ nrOfSessions }})"
      ></p-button>

      <p-button
        (onClick)="onSubmit(true)"
        [disabled]='nrOfSessions < 1'
        [loading]="loading"
        label="{{ 'trainings.participantsPopup.sendMail' | translate }} ({{ nrOfSessions }})"
      ></p-button>

      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.sessions.registerAbsenceDialog.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
