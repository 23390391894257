import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {debounceTime, Subscription} from "rxjs";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ml-search-bar-facet',
    templateUrl: './search-bar-facet.component.html',
    standalone: false
})
export class SearchBarFacetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  value = "";

  @Input()
  showLabel = true;

  @Input()
  placeholderKey: string;

  @Input()
  autoFocus = true;

  @ViewChild('searchField')
  searchField: ElementRef;

  formControl = new FormControl('');

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();
  private _formSub: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    this.formControl.setValue(changes["value"].currentValue, {emitEvent: false});
  }

  ngOnInit(): void {
    this._formSub = this.formControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(newValue => this.search.emit(newValue));
    this.formControl.setValue(this.value, {emitEvent: false});

    if (this.autoFocus) {
      this.setFocus();
    }
  }

  clearValue(): void {
    this.formControl.setValue("");
  }

  ngOnDestroy(): void {
    this._formSub.unsubscribe();
  }

  setFocus(): void {
    setTimeout(() => this.searchField.nativeElement.focus());
  }
}
