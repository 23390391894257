<div *ngIf='employment' class='border-b border-b-grey-2 p-4 hover:bg-primary hover:bg-opacity-[7%]'>
  <div class='gap-2 grid grid-cols-[minmax(0,_1fr)_30px] w-full'>
    <div class='flex gap-4 items-center'>
      <div isEllipsis pTooltip>
        <span *ngIf='viewMode === EMPLOYMENT_ITEM_VIEW_MODE.PERSON' class='font-bold text-title'>
          {{ isNoBranch ? ('persons.employments.noBranch' | translate) : employment.branchName }}
        </span>
        <span *ngIf='viewMode === EMPLOYMENT_ITEM_VIEW_MODE.BRANCH' class='font-bold text-title'>
          {{ employment.lastName }} {{ employment.firstName }}
        </span>
      </div>
      <i *ngIf='employment.isMainEmployment' class='pi pi-star' style='font-size: 16px'></i>
      <span *ngIf='employment.isContactPerson && !isNoBranch'>-</span>
      <span *ngIf='employment.isContactPerson && !isNoBranch'>{{
          'persons.employments.contactPerson' | translate
        }}</span>
    </div>
    <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
  </div>
  <div class='flex w-full'>
    <div *ngIf='!isNoBranch && viewMode === EMPLOYMENT_ITEM_VIEW_MODE.PERSON' class='w-1/4'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        [value]='employment.branchCity'
        header="{{ 'persons.employments.city' | translate }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class='w-1/4'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        [value]='employment.function'
        header="{{ 'persons.employments.function' | translate }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class='w-1/4'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        [value]="employment.workStatus?.label || ''"
        header="{{ 'persons.employments.workStatus' | translate }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div *ngIf='!employment.endDate && employment.responsibilities?.length > 0' class='w-1/4'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        [value]="responsibilities"
        header="{{ 'persons.employments.responsibilities' | translate }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div *ngIf='employment.endDate' class='w-1/4'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        [value]="employment.endDate | date : 'dd/MM/yyyy'"
        header="{{ 'persons.employments.endEmployment' | translate }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>
</div>
