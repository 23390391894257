import { Component, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { LeaveConfirmService } from '../../services/leave-confirm.service';
import { Subscription } from 'rxjs';
import { LeaveConfirm } from '../../types/Leave-confirm.type';
import { Button } from 'primeng/button';

@Component({
  selector: 'alimento-ipv-frontend-leave-confirm-dialog',
  templateUrl: './leave-confirm-dialog.component.html',
  standalone: false
})
export class LeaveConfirmDialogComponent implements OnDestroy {
  dialogVisible = false;
  saving = false;

  @ViewChild('saveButton')
  saveButton: Button;

  private subscription: Subscription;
  private confirmation: LeaveConfirm;

  constructor(private leaveConfirmService: LeaveConfirmService) {
    this.subscription = this.leaveConfirmService.requireConfirmation$.subscribe(confirmation => {
      if (!confirmation) {
        this.closeDialog();
        return;
      }

      this.confirmation = confirmation;
      if (this.confirmation.saveChanges) {
        this.confirmation.saveChangesEvent = new EventEmitter();
        this.confirmation.saveChangesEvent.subscribe(this.confirmation.saveChanges);
      }

      if (this.confirmation.cancelChanges) {
        this.confirmation.cancelChangesEvent = new EventEmitter();
        this.confirmation.cancelChangesEvent.subscribe(this.confirmation.cancelChanges);
      }

      if (this.confirmation.cancel) {
        this.confirmation.cancelEvent = new EventEmitter();
        this.confirmation.cancelEvent.subscribe(this.confirmation.cancel);
      }

      this.saving = false;
      this.dialogVisible = true;
      setTimeout(() => this.saveButton?.rootEl.focus());
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.saving = false;
  }

  saveChanges(): void {
    if (this.confirmation?.saveChangesEvent) {
      this.confirmation.saveChangesEvent.emit(this);
    }
  }

  cancelChanges(): void {
    if (this.confirmation?.cancelChangesEvent) {
      this.confirmation.cancelChangesEvent.emit();
    }

    this.closeDialog();
  }

  cancel(): void {
    if (this.confirmation?.cancelEvent) {
      this.confirmation.cancelEvent.emit();
    }

    this.closeDialog();
  }
}
