<alimento-ipv-frontend-financial-document-popup #financialDocumentPopupComponent
                                                (formSubmit)='financialDocumentSubmit($event, financialDocumentPopupComponent)'
                                                [financialDocument]='currentFinancialDocument'>
</alimento-ipv-frontend-financial-document-popup>

<alimento-ipv-frontend-header title="{{ 'financialDocuments.menuTitle' | translate }}">
  <div>
    <p-button
      *ngIf="!readOnly"
      (onClick)='addFinancialDocument(financialDocumentPopupComponent)'
      icon="pi pi-plus"
      label="{{ 'financialDocuments.create' | translate  }}"
    ></p-button>
  </div>
</alimento-ipv-frontend-header>

<div *ngIf='financialDocuments()'>
  <div class='grid grid-cols-2 w-fit gap-x-4 mb-4'>
    <span>{{ "financialDocuments.sumTotalAmount" | translate }}:</span>
    <span>{{trainingData().sumTotalAmount | currency:'EUR'}}</span>
    <span>{{ "financialDocuments.sumPreparationAmount" | translate }}</span>
    <span>{{trainingData().sumPreparationAmount | currency:'EUR'}}</span>
  </div>

  <h3>{{ 'financialDocuments.documents' | translate }}</h3>
  <div class='flex flex-col gap-2'>
    <div *ngFor='let document of financialDocuments()'>
      <alimento-ipv-frontend-financial-document-card
        [financialDocument]='document'
        [readOnly]='readOnly'
        (actionClicked)='financialDocumentActionClicked($event, financialDocumentPopupComponent)'
      ></alimento-ipv-frontend-financial-document-card>
    </div>
  </div>
  <div *ngIf='financialDocuments().length === 0'>{{ 'financialDocuments.noDocuments' | translate }}</div>
</div>
