<p-tabs *ngIf='enrollmentRequests.length > 0' [(value)]='activeIndex'>
  <p-tablist>
    <p-tab value='0'>
      {{"enrollments.enrollmentRequests" | translate}} ({{enrollmentRequests.length}})
    </p-tab>
    <p-tab value='1'>
      {{ 'enrollments.enrollments' | translate }} ({{enrollments.length}})
    </p-tab>
  </p-tablist>
  <p-tabpanels>
    <p-tabpanel value='0'>
      <alimento-ipv-frontend-enrollment-card></alimento-ipv-frontend-enrollment-card>
      <p-accordion [multiple]="true" [(value)]='openEnrollmentRequests' [dt]='detailAccordion'>
        <p-accordion-panel [value]='groupedEnrollments.key' *ngFor="let groupedEnrollments of enrollmentRequestsMap | keyvalue: orderRequestsByStatus">
          <p-accordion-header>
            {{ 'states.' + groupedEnrollments.key | translate }} ({{ groupedEnrollments.value.length }})
          </p-accordion-header>
          <p-accordion-content>
            <div *ngFor="let enrollmentRequest of groupedEnrollments.value">
              <alimento-ipv-frontend-enrollment-request-card
                (actionClicked)="enrollmentRequestAction.emit($event)"
                [enrollmentRequest]="$any(enrollmentRequest)"
                [validationRequest]='validationRequest'
                [training]='training'
                [readOnly]='readOnly'
              ></alimento-ipv-frontend-enrollment-request-card>
            </div>
          </p-accordion-content>
        </p-accordion-panel>
      </p-accordion>
    </p-tabpanel>
    <p-tabpanel value='1'>
      <ng-container *ngTemplateOutlet='enrollmentsTemplate'></ng-container>
    </p-tabpanel>
  </p-tabpanels>
</p-tabs>

<div *ngIf='enrollmentRequests.length === 0'>
  <ng-container *ngTemplateOutlet='enrollmentsTemplate'></ng-container>
</div>

<ng-template #enrollmentsTemplate>
  <alimento-ipv-frontend-enrollment-card></alimento-ipv-frontend-enrollment-card>
  <p-accordion [multiple]="true" [(value)]='openEnrollments' [dt]='detailAccordion'>
    <p-accordion-panel *ngFor="let groupedEnrollments of enrollmentsMap | keyvalue: orderByStatus" [value]='groupedEnrollments.key'>
      <p-accordion-header>
        {{ 'states.' + groupedEnrollments.key | translate }} ({{ groupedEnrollments.value.length }})
      </p-accordion-header>
      <p-accordion-content>
        <div *ngFor="let enrollment of groupedEnrollments.value">
          <alimento-ipv-frontend-enrollment-card
            (actionClicked)="enrollmentAction.emit($event)"
            [enrollment]="$any(enrollment)"
            [training]='training'
            [readOnly]='readOnly'
            [isOpenExternOrCustom]='isOpenExternOrCustom'
          ></alimento-ipv-frontend-enrollment-card>
        </div>
      </p-accordion-content>
    </p-accordion-panel>
  </p-accordion>
</ng-template>
