<div *ngIf='branch' [formGroup]="formGroup" class="max-w-[650px]">

  <div class='mb-4'>
    <h3>{{ 'branches.generalTitle' | translate }}</h3>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='branchName'
      fieldKey='branches.branchName'
      [required]='true'
    >
      <span *ngIf='readOnly' class='read-only-field'>{{branch?.branchName}}</span>
      <input *ngIf='!readOnly' class="w-full" formControlName="branchName" id="branchName" pInputText type="text" />
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [errorTemplate]='emailErrorTemplate'
                                      fieldKey='branches.email'
                                      formField='email'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch?.email ? branch.email : '-' }}
      </span>
      <input *ngIf='!readOnly'
             class="w-full"
             formControlName="email"
             id="email"
             pInputText
             type="text"
      />

      <ng-template #emailErrorTemplate>
        <small *ngIf="formGroup.get('email')?.errors?.['required']">
          {{ 'validation.isRequired' | translate : { field: 'email' | translate } }}
        </small>
        <small *ngIf="formGroup.get('email')?.errors?.['email']">
          {{ 'validation.invalidEmail' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.phoneNumber'
                                      formField='phoneNumber'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch?.phoneNumber ? branch.phoneNumber : '-' }}
      </span>
      <input *ngIf='!readOnly'
             class="w-full"
             formControlName="phoneNumber"
             id="phoneNumber"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.website'
                                      formField='website'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch?.website ? branch.website : '-' }}
      </span>
      <input *ngIf='!readOnly'
             class="w-full"
             formControlName="website"
             id="website"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.language'
                                      formField='language'
    >
      <span class='read-only-field' *ngIf='readOnly'>{{languageReadOnly}}</span>
      <p-select
        *ngIf='!readOnly'
        [filter]='true'
        [options]='languages'
        [showClear]='true'
        formControlName='language'
        id='language'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.remarks'
                                      formField='remarks'
    >
      <span class='read-only-field' *ngIf='readOnly'>{{branch.remarks}}</span>
      <textarea
        *ngIf='!readOnly'
        class="w-full"
        formControlName="remarks"
        id="remarks"
        pTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>

    <div class='pb-4 field-checkbox'>
      <p-checkbox
        [binary]='true'
        formControlName='active'
        inputId='branchStillActive'
      ></p-checkbox>
      <label for='branchStillActive'>{{ 'branches.branchStillActive' | translate }}</label>
    </div>

    <div class='pb-4 field-checkbox'>
      <p-checkbox
        [binary]='true'
        formControlName='noCorrespondence'
        inputId='noCorrespondence'
      ></p-checkbox>
      <label for='noCorrespondence'>{{ 'branches.noCorrespondence' | translate }}</label>
    </div>
  </div>

  <div class='mb-4'>
    <h3>{{ 'branches.formations' | translate }}</h3>
    <div class='pb-4 field-checkbox'>
      <p-checkbox
        [binary]='true'
        formControlName='isTeacher'
        inputId='isTeacher'
      ></p-checkbox>
      <label for='isTeacher'>{{ 'branches.isTeacher' | translate }}</label>
    </div>
  </div>

  <div class='mb-4'>
    <h3>{{ 'branches.address' | translate }}</h3>

    <alimento-ipv-frontend-address
      [formControl]="$any(formGroup.get('address'))"
      [optional]="true"
      [requiredFields]='["city", "country"]'
      [readOnly]='readOnly'
    >
    </alimento-ipv-frontend-address>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.accessibility'
                                      formField='accessibility'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch?.accessibility ? branch.accessibility : '-' }}
      </span>
      <input *ngIf='!readOnly'
             class="w-full"
             formControlName="accessibility"
             id="accessibility"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>
  </div>

  <div class='mb-4'>
    <h3>{{ 'branches.locationData' | translate }}</h3>

    <div formGroupName='eventLocation' class='field-checkbox mb-4'>
      <p-checkbox
        formControlName='active'
        [binary]='true'
        inputId='branch-active'
      ></p-checkbox>
      <label for='branch-active'>{{ 'branches.eventLocation.active' | translate }}</label>
    </div>

    <span *ngIf='!showEventLocationFields'
          class='cursor-pointer text-primary hover:text-primary-button-hover'
          (click)='showEventLocationFields = true'>
      {{'branches.showEventLocationFields' | translate}}
    </span>

    <div class='conditional-fields' [hidden]='!showEventLocationFields'>
      <div class='grid grid-cols-2 gap-4'>
        <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                          fieldKey='branches.eventLocation.firstName'
                                          formField='eventLocation.firstName'>
        <span *ngIf='readOnly' class='read-only-field'>
          {{ branch.eventLocation?.firstName ? branch.eventLocation?.firstName : '-' }}
        </span>
          <div formGroupName='eventLocation'>
            <input *ngIf='!readOnly'
                   class="w-full"
                   formControlName="firstName"
                   id="eventLocation.firstName"
                   pInputText
                   type="text"
            />
          </div>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                          fieldKey='branches.eventLocation.lastName'
                                          formField='eventLocation.lastName'>
        <span *ngIf='readOnly' class='read-only-field'>
          {{ branch.eventLocation?.lastName ? branch.eventLocation?.lastName : '-' }}
        </span>
          <div formGroupName='eventLocation'>
            <input *ngIf='!readOnly'
                   class="w-full"
                   formControlName="lastName"
                   id="eventLocation.lastName"
                   pInputText
                   type="text"
            />
          </div>
        </alimento-ipv-frontend-form-field>
      </div>

      <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                        [errorTemplate]='eventLocationEmailErrorTemplate'
                                        fieldKey='branches.eventLocation.email'
                                        formField='eventLocation.email'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch.eventLocation?.email ? branch.eventLocation?.email : '-' }}
      </span>
        <div formGroupName='eventLocation'>
          <input *ngIf='!readOnly'
                 class="w-full"
                 formControlName="email"
                 id="eventLocation.email"
                 pInputText
                 type="text"
          />
        </div>

        <ng-template #eventLocationEmailErrorTemplate>
          <small *ngIf="formGroup.get('eventLocation.email')?.errors?.['required']">
            {{ 'validation.isRequired' | translate : { field: 'eventLocation.email' | translate } }}
          </small>
          <small *ngIf="formGroup.get('eventLocation.email')?.errors?.['email']">
            {{ 'validation.invalidEmail' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                        fieldKey='branches.eventLocation.phoneNumber'
                                        formField='eventLocation.phoneNumber'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch.eventLocation?.phoneNumber ? branch.eventLocation?.phoneNumber : '-' }}
      </span>
        <div formGroupName='eventLocation'>
          <input *ngIf='!readOnly'
                 class="w-full"
                 formControlName="phoneNumber"
                 id="eventLocation.phoneNumber"
                 pInputText
                 type="text"
          />
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                        fieldKey='branches.eventLocation.comment'
                                        formField='eventLocation.comment'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch.eventLocation?.comment ? branch.eventLocation?.comment : '-' }}
      </span>
        <div formGroupName='eventLocation'>
          <input *ngIf='!readOnly'
                 class="w-full"
                 formControlName="comment"
                 id="eventLocation.comment"
                 pInputText
                 type="text"
          />
        </div>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <div class='mb-4'>
    <h3>{{ 'branches.financialData' | translate }}</h3>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='defaultAccountNumberId'
      fieldKey='branches.defaultAccountNumber'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{accountNumberReadOnly}}</span>
      <p-select
        *ngIf='formGroup.enabled'
        [filter]='true'
        [options]='accountNumbers'
        [showClear]='true'
        formControlName='defaultAccountNumberId'
        id='defaultAccountNumberId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-select>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [errorTemplate]='defaultInvoiceEmailErrorTemplate'
                                      fieldKey='branches.defaultInvoiceEmail'
                                      formField='defaultInvoiceEmail'>
      <span *ngIf='readOnly' class='read-only-field'>
        {{ branch?.defaultInvoiceEmail ? branch.defaultInvoiceEmail : '-' }}
      </span>
      <input *ngIf='!readOnly'
             class="w-full"
             formControlName="defaultInvoiceEmail"
             id="defaultInvoiceEmail"
             pInputText
             type="text"
      />

      <ng-template #defaultInvoiceEmailErrorTemplate>
        <small *ngIf="formGroup.get('defaultInvoiceEmail')?.errors?.['required']">
          {{ 'validation.isRequired' | translate : { field: 'branches.defaultInvoiceEmail' | translate } }}
        </small>
        <small *ngIf="formGroup.get('defaultInvoiceEmail')?.errors?.['email']">
          {{ 'validation.invalidEmail' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <div class='field-checkbox mb-4'>
      <p-checkbox
        [binary]='true'
        formControlName='invoiceReferenceRequired'
        inputId='invoiceReferenceRequired'
      ></p-checkbox>
      <label for='invoiceReferenceRequired'>{{ 'branches.invoiceReferenceRequired' | translate }}</label>
    </div>

    <div class='mb-4'>
      <div class='field-checkbox'>
        <p-checkbox
          [binary]='true'
          formControlName='isClient'
          inputId='isClient'
        ></p-checkbox>
        <label for='isClient'>{{ 'branches.isClient' | translate }}</label>
      </div>

      <div *ngIf='formGroup.get("isClient").value' class='conditional-fields mt-2'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='defaultPaymentMethodIdWhenClient'
          fieldKey='branches.defaultPaymentMethod'
        >
          <span class='read-only-field' *ngIf='formGroup.disabled'>{{paymentMethodWhenClientReadOnly}}</span>
          <p-select
            *ngIf='formGroup.enabled'
            [filter]='true'
            [options]='paymentMethods'
            [showClear]='true'
            formControlName='defaultPaymentMethodIdWhenClient'
            id='defaultPaymentMethodIdWhenClient'
            optionLabel='label'
            optionValue='data'
            placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
            styleClass='w-full'
            appendTo='body'
          ></p-select>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <div class='mb-4'>
      <div class='field-checkbox'>
        <p-checkbox
          [binary]='true'
          formControlName='isSupplier'
          inputId='isSupplier'
        ></p-checkbox>
        <label for='isSupplier'>{{ 'branches.isSupplier' | translate }}</label>
      </div>

      <div *ngIf='formGroup.get("isSupplier").value' class='conditional-fields mt-2'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='defaultPaymentMethodIdWhenSupplier'
          fieldKey='branches.defaultPaymentMethod'
        >
          <span class='read-only-field' *ngIf='formGroup.disabled'>{{paymentMethodWhenSupplierReadOnly}}</span>
          <p-select
            *ngIf='formGroup.enabled'
            [filter]='true'
            [options]='paymentMethods'
            [showClear]='true'
            formControlName='defaultPaymentMethodIdWhenSupplier'
            id='defaultPaymentMethodIdWhenSupplier'
            optionLabel='label'
            optionValue='data'
            placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
            styleClass='w-full'
            appendTo='body'
          ></p-select>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

  </div>

  <div>
    <h3>{{'branches.administrativeRules' | translate}} ({{'branches.optional' | translate}})</h3>

    <ng-template #editorHeader>
      <div class="flex items-center">
      <span class="ql-formats">
          <button aria-label="Bold" class="ql-bold"></button>
          <button aria-label="Italic" class="ql-italic"></button>
          <button aria-label="underline" class="ql-underline"></button>
        </span>
        <span class="ql-formats">
          <button type="button" class="ql-list" aria-label="List" value="ordered"></button>
          <button type="button" class="ql-list" aria-label="Bullet" value="bullet"></button>
        </span>
      </div>
    </ng-template>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='administrativeRules'
                                      labelClass='hidden'
    >
      <div *ngIf='formGroup.disabled'>
        <div #content
             class='text-text whitespace-pre-line'
             [ngClass]='{ "h-full overflow-auto": showMoreAdministrativeRules,
              "max-h-[72px] overflow-hidden": !showMoreAdministrativeRules
              }'
             [innerHTML]='branch?.administrativeRules'
        ></div>
        <button *ngIf='showMoreAdministrativeRules || hasOverflow(content)'
                class='link-button font-bold'
                (click)="toggleShowMoreAdministrativeRules()">
          {{ (showMoreAdministrativeRules ? 'notes.showLess': 'notes.showMore') | translate }}
        </button>
      </div>
      <p-editor
        #administrativeRules
        *ngIf='formGroup.enabled'
        formControlName="administrativeRules"
        (onTextChange)="textChange('administrativeRules', $event)"
        (onSelectionChange)="editorSelectionChange($event, administrativeRules)"
        [style]="{ height: '320px' }"
      >
        <p-header>
          <ng-container *ngTemplateOutlet="editorHeader"></ng-container>
        </p-header>
      </p-editor>
    </alimento-ipv-frontend-form-field>
  </div>

</div>
