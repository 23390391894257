import { Component, EventEmitter, Output } from '@angular/core';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'alimento-ipv-frontend-select-enterprise-popup',
    templateUrl: './select-enterprise-popup.component.html',
    standalone: false
})
export class SelectEnterprisePopupComponent {
  dialogVisible = false;
  formGroup: FormGroup;
  loading = false;

  @Output()
  enterpriseSelected: EventEmitter<string> = new EventEmitter();

  constructor(private messageService: MyMessageService,
              private formBuilder: FormBuilder) {
  }

  openDialog(): void {
    this.dialogVisible = true;
    this.formGroup = this.formBuilder.group({
      enterprise: [undefined, Validators.required]
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }

  selectEnterprise() {
    validateAllFormFields(this.formGroup);
    if (this.formGroup.valid) {
      this.enterpriseSelected.emit(this.formGroup.value.enterprise?.enterpriseId);
      this.closeDialog();
    }
    else {
      this.messageService.notAllFieldsValid();
    }
  }
}
