import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { TrainingPlan, TrainingPlanActionEvent } from '../../../types/branch.type';
import { TrainingPlanActionEnum } from '../../../types/branch.enum';

@Component({
    selector: 'alimento-ipv-frontend-training-plan-action-dialog',
    templateUrl: './training-plan-action-dialog.component.html',
    standalone: false
})
export class TrainingPlanActionDialogComponent {
  @Input()
  trainingPlan: TrainingPlan;

  @Input()
  action: TrainingPlanActionEnum;

  @Output()
  submitDialog = new EventEmitter<TrainingPlanActionEvent>();

  @ViewChild('focusElement')
  focusElement: ElementRef;

  dialogVisible = false;
  loading = false;

  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.formGroup = this.formBuilder.group({
      comments: [null]
    });
  }

  open(): void {
    this.dialogVisible = true;
    setTimeout(() => this.focusElement.nativeElement.focus());
  }

  submit(): void {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields')
      });
      return;
    }

    this.submitDialog.emit({
      data: this.formGroup.value,
      setLoading: (value: boolean) => (this.loading = value)
    });
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset();
  }

  getTitle(): string {
    if (this.action === TrainingPlanActionEnum.register) {
      return 'branches.trainingPlans.register';
    }
    else if (this.action === TrainingPlanActionEnum.approve) {
      return 'branches.trainingPlans.approve';
    }
    else if (this.action === TrainingPlanActionEnum.reject) {
      return 'branches.trainingPlans.reject';
    }
    return "";
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getActionText(): string {
    if (this.action === TrainingPlanActionEnum.register) {
      return 'branches.trainingPlans.registerText';
    }
    else if (this.action === TrainingPlanActionEnum.approve) {
      return 'branches.trainingPlans.approveText';
    }
    else if (this.action === TrainingPlanActionEnum.reject) {
      return 'branches.trainingPlans.rejectText';
    }
    return "";
  }
}
