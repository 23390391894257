import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { first, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IFilter, IFilterEvent, PaginatedResponse, SearchRequest } from '../../../types/search.type';
import { LazyDropdownComponent } from '../../lazy-dropdown/lazy-dropdown.component';
import { InfiniteScrollDataAdapter } from '../../../utils/InfiniteScrollDataAdapter';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ml-lazy-item-facet',
    templateUrl: './lazy-item-facet.component.html',
    styleUrls: ['./lazy-item-facet.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class LazyItemFacetComponent implements OnChanges {
  @Input()
  expanded = false;
  @Input()
  filterName = "";
  @Input()
  selectedItems: string[] = [];
  @Input()
  title = "";
  @Input()
  showHeader = true;
  @Input()
  getItem: (value: string) => Observable<IFilter>;
  @Input()
  dataSource: (request: SearchRequest) => Observable<PaginatedResponse<IFilter>>;
  @Input()
  addEmptyValue = false;
  @Input()
  emptyValue: any = "0";
  @Input()
  emptyLabelKey = "noValue";
  @Output()
  searchValues: EventEmitter<IFilterEvent> = new EventEmitter<IFilterEvent>();

  data: InfiniteScrollDataAdapter<any>;
  _selectedItems: IFilter[] = [];
  accordionValue: string;


  constructor(private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']?.currentValue) {
      this.data = new InfiniteScrollDataAdapter(this.dataSource, {});
    }
    if (changes['selectedItems']?.currentValue) {
      this.selectedItems.forEach(value => {
        if (!this._selectedItems.map(item => item.value).includes(value)) {
          if (this.addEmptyValue && value === this.emptyValue) {
            this._selectedItems.push({
              value: this.emptyValue,
              label: this.translateService.instant(this.emptyLabelKey)
            } as IFilter);
          }
          else {
            this.getItem(value).pipe(first())
              .subscribe({
                next: (item) => {
                  this._selectedItems.push(item);
                },
                error: () => {
                  this.selectedItems = this.selectedItems.filter(item => item !== value);
                }
              });
          }
        }
      });
      this._selectedItems = this._selectedItems.filter(item => this.selectedItems.includes(item.value));
    }

    if (changes['expanded']?.currentValue) {
      this.accordionValue = this.expanded ? "0" : "";
    }
  }

  itemSelected(control: LazyDropdownComponent, item: any) {
    if (!item) {
      return;
    }

    this._selectedItems.push(item);
    this.selectedItems.push(item.value);

    this.searchValues.emit({
      filter: this.filterName,
      values: this.selectedItems
    });
    control.clear();
  }

  removeItem(value: string) {
    this._selectedItems = this._selectedItems.filter(item => item.value !== value);
    this.selectedItems = this.selectedItems.filter(item => item !== value);

    this.searchValues.emit({
      filter: this.filterName,
      values: this.selectedItems
    });
  }

}
