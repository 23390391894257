import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { Dialog } from 'primeng/dialog';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[scrollTracker]',
    exportAs: "scrollTracker",
    standalone: false
})
export class ScrollTrackerDirective implements AfterViewInit, OnDestroy {
  @Input()
  scrollWindow: any;

  @Input()
  footerHeight = 0;

  @Output()
  scrollingFinished = new EventEmitter<void>();

  emitted = false;

  private _onScroll: any;

  constructor(private elRef: ElementRef) {
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this._isHidden()) {
      return;
    }

    const calculatedHeight = this.getCalculatedHeight();
    const offsetHeight = this.getOffsetHeight();

    if (calculatedHeight >= offsetHeight && !this.emitted) {
      this.emitted = true;
      this.scrollingFinished.emit();
    }
    else if (calculatedHeight < offsetHeight) {
      this.emitted = false;
    }
  }

  private _isHidden(): boolean {
    return this._getScrollWindow() ?
      this._getScrollWindow().offsetParent === null :
      this.elRef.nativeElement.offsetParent === null;
  }

  resetEmitted(): void {
    const calculatedHeight = this.getCalculatedHeight();
    const offsetHeight = this.getOffsetHeight();

    if (calculatedHeight >= offsetHeight) {
      this.emitted = false
    }
  }

  getCalculatedHeight(): number {
    let calculatedHeight;
    if (this.scrollWindow) {
      calculatedHeight = this.scrollWindow.scrollTop + this.scrollWindow.offsetHeight;
    }
    else {
      calculatedHeight = window.innerHeight + window.scrollY + this.footerHeight;
    }
    calculatedHeight = Math.ceil(calculatedHeight) + 1;
    return calculatedHeight;
  }

  getOffsetHeight(): number {
    return this.scrollWindow ? this.scrollWindow.scrollHeight :
      this.elRef.nativeElement.offsetTop + this.elRef.nativeElement.scrollHeight;
  }

  private _getScrollWindow(): any {
    if (this.scrollWindow instanceof Dialog) {
      return this.scrollWindow?.contentViewChild?.nativeElement;
    }
    else {
      return this.scrollWindow;
    }
  }

  ngAfterViewInit(): void {
    if (this.scrollWindow) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const directive = this;
      this._onScroll = function () {
        directive.onScroll.call(directive);
      }
      this.scrollWindow.removeEventListener("scroll", this._onScroll);
      this.scrollWindow.addEventListener("scroll", this._onScroll);
    }
  }

  ngOnDestroy(): void {
    if (this.scrollWindow) {
      this.scrollWindow.removeEventListener("scroll", this._onScroll);
    }
  }
}
