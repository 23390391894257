import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  OPTIONS_LIST_TYPE,
  ReferenceDataService,
  TemplateStatusKey
} from '@alimento-ipv-frontend/application-lib';
import { first } from 'rxjs';

@Component({
    selector: 'alimento-ipv-frontend-template-status-chip',
    templateUrl: './template-status-chip.component.html',
    standalone: false
})
export class TemplateStatusChipComponent implements OnChanges {
  @Input()
  status!: string;

  statusLabel: string;

  constructor(private referenceDataService: ReferenceDataService){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status']?.currentValue) {
      this.referenceDataService.getReferenceDataItem(this.status, OPTIONS_LIST_TYPE.TEMPLATE_STATUSES).pipe(first())
        .subscribe(data => this.statusLabel = data.label);
    }
    else {
      this.statusLabel = "";
    }
  }

  getStatusClass(): string {
    if (this.status === TemplateStatusKey.Active) {
      return "text-primary border border-primary ";
    }
    else {
      return "border-grey-3 text-grey-3";
    }
  }
}
