<alimento-ipv-frontend-overview-page-layout header='communications.menuTitle'>
  <p-tabs [(value)]='activeIndex'>
    <p-tablist>
      <p-tab value='0'>
        <span class='text-xl p-tabview-title'>{{'communications.todo' | translate}}</span>
        <span *ngIf="toDoCount > 0" class="bg-primary font-normal text-white px-2">
          {{toDoCount}}
        </span>
      </p-tab>
      <p-tab value='1'>
        <span class='text-xl p-tabview-title'>{{'communications.all' | translate}}</span>
      </p-tab>
    </p-tablist>

    <p-tabpanels>
      <p-tabpanel value='0'>
        <alimento-ipv-frontend-complete-task-dialog
          #completeTaskToDoDialog
          (submitPopup)='completeTasks($event, completeTaskToDoDialog, toDoCommunications)'>
        </alimento-ipv-frontend-complete-task-dialog>
        <div class='my-4'>
          <lib-search #toDoCommunications
                      [data$]='toDoSearchData$'
                      [filters]='toDOSearchFilters'
                      [cardTemplate]='cardTemplate'
                      [showToggle]='true'
                      [beforeSearchRequest]='beforeSearchRequest'
                      [isToggled]='true'
                      name='to-do-communications'
                      toggleLabel='communications.toggle'
                      searchPlaceholderKey='communications.searchPlaceholder'
                      availableResultsTranslateKey='communications.menuTitle'
          >
            <ng-template #cardTemplate let-communication="item">
              <alimento-ipv-frontend-communication-card
                [readOnly]='readOnly'
                [communication]='communication'
                (completeTaskClicked)='completeTaskToDoDialog.open($event)'
                [showNavigateToBranch]='true'
                [showNavigateToPaidEducationalLeaveAttest]='true'
                [showNavigateToTraining]='true'
                tabindex="0"
              ></alimento-ipv-frontend-communication-card>
            </ng-template>
          </lib-search>
        </div>
      </p-tabpanel>

      <p-tabpanel value='1'>
        <alimento-ipv-frontend-complete-task-dialog
          #completeTaskAllCommunicationDialog
          (submitPopup)='completeTasks($event, completeTaskAllCommunicationDialog, allCommunications)'>
        </alimento-ipv-frontend-complete-task-dialog>
        <div class='my-4'>
          <lib-search #allCommunications
                      [data$]='searchData$'
                      [filters]='searchFilters'
                      [cardTemplate]='cardTemplate'
                      [showToggle]='true'
                      [isToggled]='true'
                      [beforeSearchRequest]='beforeSearchRequest'
                      name='all-communications'
                      toggleLabel='communications.toggle'
                      searchPlaceholderKey='communications.searchPlaceholder'
                      availableResultsTranslateKey='communications.menuTitle'
          >
            <ng-template #cardTemplate let-communication="item">
              <alimento-ipv-frontend-communication-card
                [readOnly]='readOnly'
                [communication]='communication'
                (completeTaskClicked)='completeTaskAllCommunicationDialog.open($event)'
                [showNavigateToBranch]='true'
                [showNavigateToPaidEducationalLeaveAttest]='true'
                [showNavigateToTraining]='true'
                tabindex="0"
              ></alimento-ipv-frontend-communication-card>
            </ng-template>
          </lib-search>
        </div>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>
</alimento-ipv-frontend-overview-page-layout>
