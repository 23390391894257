import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TransitionActionEvent, TransitionItem } from '../../../types/person.type';
import { TransitionActionEnum } from '../../../types/person.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { first } from 'rxjs';

@Component({
    selector: 'alimento-ipv-frontend-transition-action-dialog',
    templateUrl: './transition-action-dialog.component.html',
    standalone: false
})
export class TransitionActionDialogComponent implements OnChanges {
  @Input()
  transition: TransitionItem;

  @Input()
  action: TransitionActionEnum;

  @Output()
  submitDialog = new EventEmitter<TransitionActionEvent>();

  @ViewChild("focusElement")
  focusElement: ElementRef;

  dialogVisible = false;
  loading = false;

  formGroup: FormGroup;
  transitionType: string;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MyMessageService,
    private referenceDataService: ReferenceDataService
  ) {
    this.formGroup = this.formBuilder.group({
      comments: [null]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['transition']?.currentValue) {
      this.referenceDataService.getTransitionType(this.transition.transitionTypeId).pipe(first())
        .subscribe(type => this.transitionType = type.label);
    }
  }

  open(): void {
    this.dialogVisible = true;
    setTimeout(() => this.focusElement?.nativeElement.focus());
  }

  submit(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.submitDialog.emit({
      data: this.formGroup.value,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset();
  }

  getTitle(): string {
    return 'persons.transitions.actions.' + this.action;
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getActionText(): string {
    if (this.action === TransitionActionEnum.activate) {
      return 'persons.transitions.approveText';
    }
    else if (this.action === TransitionActionEnum.close) {
      return 'persons.transitions.closeText';
    }
    return '';
  }
}
