import { NgModule } from '@angular/core';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import {
  PaidEducationalLeaveDetailComponent
} from './pages/paid-educational-leave-detail/paid-educational-leave-detail.component';
import { PaidEducationalLeaveComponent } from './components/paid-educational-leave/paid-educational-leave.component';
import {
  PaidEducationalLeaveCardComponent
} from './components/paid-educational-leave-card/paid-educational-leave-card.component';
import { UtilsModule } from '../utils/utils.module';
import {
  PaidEducationalLeaveListComponent
} from './pages/paid-educational-leave-list/paid-educational-leave-list.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import {
  PaidEducationalLeaveSessionsComponent
} from './components/paid-educational-leave-sessions/paid-educational-leave-sessions.component';
import {
  PaidEducationalLeaveActionDialogComponent
} from './components/paid-educational-leave-action-dialog/paid-educational-leave-action-dialog.component';
import {
  PaidEducationalLeaveContactDialogComponent
} from './components/paid-educational-leave-contact-dialog/paid-educational-leave-contact-dialog.component';
import { CommunicationsModule } from '../communications/communications.module';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    CommunicationsModule
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: [
    PaidEducationalLeaveDetailComponent,
    PaidEducationalLeaveComponent,
    PaidEducationalLeaveCardComponent,
    PaidEducationalLeaveListComponent,
    StatusChipComponent,
    PaidEducationalLeaveActionDialogComponent,
    PaidEducationalLeaveSessionsComponent,
    PaidEducationalLeaveContactDialogComponent
  ],
  exports: [
    PaidEducationalLeaveCardComponent
  ]
})
export class PaidEducationalLeaveModule {
}
