import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
  DateMapper,
  dateValid,
  IFile,
  minLengthArray,
  MyMessageService,
  validateAllFormFields,
  validateMinValue
} from '@alimento-ipv-frontend/ui-lib';
import { TrainingPlan, TrainingPlanUpdateEvent } from '../../../types/branch.type';
import { TRAINING_PLAN_STATE, TrainingPlanActionEnum } from '../../../types/branch.enum';

@Component({
    selector: 'alimento-ipv-frontend-training-plan-popup',
    templateUrl: './training-plan-popup.component.html',
    standalone: false
})
export class TrainingPlanPopupComponent implements OnChanges, OnDestroy {
  @Input()
  trainingPlan?: TrainingPlan;

  @Output()
  formSubmit = new EventEmitter<TrainingPlanUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  files: IFile[] = [];
  fileRequired = false;
  title: string;

  yesNoValues: any[] = [
    {label: this.translateService.instant("true"), data: true},
    {label: this.translateService.instant("false"), data: false},
    {label: "-", data: null},
  ];

  private _subscriptions: (Subscription | undefined)[] = [];

  protected readonly TRAINING_PLAN_STATE = TRAINING_PLAN_STATE;
  protected readonly TrainingPlanActionEnum = TrainingPlanActionEnum;

  constructor(
    private fb: FormBuilder,
    private messageService: MyMessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingPlan']?.currentValue) {
      this.fileRequired = this.trainingPlan?.stateId && this.trainingPlan.stateId !== TRAINING_PLAN_STATE.REQUESTED;
      this.title = (this.trainingPlan?.id ? 'branches.trainingPlans.editTrainingPlan' : 'branches.trainingPlans.new');
      this.createForm();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(isRegisterAction: boolean): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid &&
      (!this.fileRequired || this.files.length >= 1) &&
      (!isRegisterAction || this.formGroup.value.completePlanReceived || this.formGroup.value.statementReceived);
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    data.validFrom = DateMapper.getDateFromDateTimeAsString(data.validFrom);
    data.validTo = DateMapper.getDateFromDateTimeAsString(data.validTo);

    delete data.files;

    const formData: any = new FormData();
    Object.keys(data)
      .filter(key => (data as any)[key] !== null && (data as any)[key] !== undefined)
      .forEach(key => formData.append(key, (data as any)[key]));

    this.files.filter(file => file.data)
      .forEach(file => formData.append('files', file.data));

    this.files.filter(file => !file.data)
      .forEach(file => formData.append('filesToKeep', file.id));

    return formData;
  }

  addOrUpdateTrainingPlan(action?: TrainingPlanActionEnum): void {
    const isRegisterAction = action === TrainingPlanActionEnum.register;
    this._changeValidation(isRegisterAction);

    if (!this.isDataValid(isRegisterAction)) {
      if (isRegisterAction && (!this.formGroup.value.completePlanReceived && !this.formGroup.value.statementReceived)) {
        this.messageService.error("branches.trainingPlans.completeOrStatementChecked");
      }
      else {
        this.messageService.notAllFieldsValid();
      }
      return;
    }

    this.formSubmit.emit({
      id: this.trainingPlan.id,
      action: action,
      trainingPlan: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm() {
    const validFrom = this.fb.control(
      this.trainingPlan?.validFrom ? new Date(this.trainingPlan.validFrom) : undefined,
      [Validators.required, dateValid()]);

    this.formGroup = this.fb.group({
      id: [this.trainingPlan?.id],
      validFrom: validFrom,
      validTo: [this.trainingPlan?.validTo ? new Date(this.trainingPlan.validTo) : undefined,
        [Validators.required, validateMinValue(validFrom), dateValid()]],
      due: [this.trainingPlan?.due ? new Date(this.trainingPlan.due) : undefined, [dateValid()]],
      moreThan20Employees: [this.trainingPlan?.moreThan20Employees],
      validReceptionPolicy: [this.trainingPlan?.validReceptionPolicy],
      validConsultativeBody: [this.trainingPlan?.validConsultativeBody],
      consultedSyndic: [this.trainingPlan?.consultedSyndic],
      completePlanReceived: [this.trainingPlan?.completePlanReceived],
      statementReceived: [this.trainingPlan?.statementReceived],
      comments: [this.trainingPlan?.comments],
      files: [this.trainingPlan?.files || []]
    });

    this.files = this.trainingPlan?.files || [];

    if (this.fileRequired) {
      this.formGroup.get("files").addValidators([minLengthArray(1)]);
    }

    this._addSubscriptions();
  }

  filesChange(files: IFile[]) {
    this.formGroup.get('files').setValue(files);
  }

  private _addSubscriptions(): void {
    this._subscriptions.push(
      this.formGroup.get('validFrom')?.valueChanges.subscribe(() => {
        this.formGroup.get('validTo')?.updateValueAndValidity();
      })
    );

    this._subscriptions.push(
      this.formGroup.get('validConsultativeBody')?.valueChanges.subscribe(newValue => {
        if (!newValue) {
          this.formGroup.get('validReceptionPolicy').setValue(null);
          this.formGroup.get('consultedSyndic').setValue(null);
        }
      })
    );
  }

  private _changeValidation(isRegisterAction: boolean): void {
    const files = this.formGroup.get("files");
    this.fileRequired = isRegisterAction || (this.trainingPlan?.stateId && this.trainingPlan.stateId !== TRAINING_PLAN_STATE.REQUESTED);

    if (this.fileRequired) {
      files.setValidators([minLengthArray(1)]);
    }
    else {
      files.setValidators([]);
    }

    files.updateValueAndValidity();
  }
}
