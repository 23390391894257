import { Component, Input } from '@angular/core';
import { InterimOfficeListItem } from '../../../types/branch.type';

@Component({
    selector: 'alimento-ipv-frontend-interim-office-card',
    templateUrl: './interim-office-card.component.html',
    standalone: false
})
export class InterimOfficeCardComponent {
  @Input()
  office?: InterimOfficeListItem;
}
