<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '50rem' }"
  appendTo="body"
  header="{{ 'communications.completeTaskDialog.header' | translate }}"
>
  <div [formGroup]='formGroup' *ngIf='dialogVisible'>
    <div class='font-bold my-2'>
      {{communication?.subject}}
    </div>

    <div class='mb-2'>
      <alimento-ipv-frontend-select-list
        formControlName='taskIds'
        selectAllKey='communications.completeTaskDialog.selectAll'
        [items]='linkedTasks'
        [label]='getLabel'
        subTextClass='text-danger mt-1'
      >
      </alimento-ipv-frontend-select-list>
    </div>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='communications.completeTaskDialog.comments'
                                      formField='comments'>
      <textarea class='w-full' cols='30' formControlName='comments' id='comments' pTextarea rows='5'></textarea>
    </alimento-ipv-frontend-form-field>
  </div>
  <ng-template pTemplate="footer">
    <div class="flex items-center gap-2 w-full">
      <p-button
        (onClick)="onSubmit()"
        [disabled]='nrOfSelectedTasks === 0'
        [loading]="loading"
        label="{{ 'communications.completeTaskDialog.submit' | translate }} ({{nrOfSelectedTasks}})"
      ></p-button>

      <p-button
        (onClick)="closeDialog()"
        label="{{ 'communications.completeTaskDialog.cancel' | translate }}"
        variant='text'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
