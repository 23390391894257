<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
></alimento-ipv-frontend-notes-sidebar>

<div [formGroup]="trainingForm">
  <alimento-ipv-frontend-detail-page-layout
    header="{{ 'trainings.cevoraTraining' | translate }}"
    [breadcrumb]='breadcrumb'
    fallbackUrl='/trainings'
  >
    <ng-container status>
      <alimento-ipv-frontend-training-status-chip
        [status]="trainingStatus"
        [trainingOngoing]="isOngoing()"
      ></alimento-ipv-frontend-training-status-chip>
    </ng-container>

    <ng-container top-action>
      <div class="flex gap-4" *ngIf='!readOnly'>
        <p-button
          styleClass='w-full'
          label="{{ 'trainings.actions.save' | translate }}"
          severity='secondary'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveTraining()"
          [loading]="savingTraining"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        [type]='TRAINING_TYPE.CEVORA'
        [nrOfSessionsWithDates]='sessionService.nrOfSessionsWithDates'
        [showRequiredFieldErrors]='true'
        formControlName="basicDataForm"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingMethodId]="basicInfoComponent.formGroup.get('methodId')?.value"
        [trainingType]='TRAINING_TYPE.CEVORA'
        (sessionUpdated)='loadTrainingData()'
        (hasChanges)='onSessionChange($event)'
      >
      </alimento-ipv-frontend-sessions>

      <alimento-ipv-frontend-enrollments
        [hidden]="activeTabIndex !== 3"
        [training]="training"
        [trainingStatus]="trainingStatus"
        [trainingType]='TRAINING_TYPE.CEVORA'
        (hasChange)='onEnrollmentChange($event)'
      ></alimento-ipv-frontend-enrollments>

      <alimento-ipv-frontend-training-communications
        *ngIf='activeTabIndex === 4'
        [hidden]='activeTabIndex !== 4'
        [readOnly]='readOnly'
        [training]='training()'
      ></alimento-ipv-frontend-training-communications>

      <alimento-ipv-frontend-invoices-overview
        *ngIf='activeTabIndex === 5'
        [readOnly]='readOnly'
        [trainingId]='trainingId'
        [hasCounselor]='!!training()?.counselorId'
        [purchaseEntries]='purchaseEntries'
        [salesInvoices]='salesInvoices'
        (purchaseEntryChanges)='getPurchaseEntries()'
        (salesInvoiceChanges)='getSalesInvoices()'
      >
      </alimento-ipv-frontend-invoices-overview>
    </ng-container>

    <ng-container metadata>
<!--      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Cancelled' class='flex flex-col gap-4'>-->
<!--        <alimento-ipv-frontend-reactivate-training-dialog-->
<!--          #reactivateTrainingDialog-->
<!--          [training]='training'-->
<!--          [firstSessionDate]='firstSessionDate'-->
<!--          (reactivateTrainingConfirmed)="reactivateTraining($event)"-->
<!--        >-->
<!--        </alimento-ipv-frontend-reactivate-training-dialog>-->

<!--        <p-button-->
<!--          pRipple-->
<!--          label="{{ 'trainings.actions.reactivate' | translate }}"-->
<!--          variant="outlined"-->
<!--          styleClass="w-full"-->
<!--          loadingIcon="pi pi-spin pi-spinner"-->
<!--          [loading]="reactivatingTraining"-->
<!--          (click)="reactivateTrainingDialog.open()"-->
<!--        ></button>-->

<!--      </div>-->

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-close-training-dialog
          #closeTrainingDialog
          [training]='training'
          [firstSessionDate]='firstSessionDate'
          (closeTrainingConfirmed)="closeTraining($event)"
        >
        </alimento-ipv-frontend-close-training-dialog>

        <p-button
          *ngIf='trainingStatus !== trainingStatusKeys.Completed'
          label="{{ 'trainings.actions.close' | translate }}"
          variant='outlined'
          styleClass='w-full'
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="closingTraining"
          (click)="closeTrainingDialog.open()"
        ></p-button>

        <div *ngIf='sessionService.nrOfSessions() > 0 && enrollmentService.enrollments()?.length > 0'>
          <div class='flex items-center justify-between gap-2 w-full'>
            <p-button label="{{ 'trainings.actions.completeAllAbsences' | translate }}"
                      [link]='true'
                      styleClass='whitespace-nowrap pl-0'
                      (onClick)='!completeAllAbsences.disabled ? completeAllAbsences.writeValue(!completeAllAbsences.checked()) : undefined'
            ></p-button>
            <p-toggle-switch #completeAllAbsences
                             [formControl]="completeAllAbsencesToggle"
            ></p-toggle-switch>
          </div>
        </div>

        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId && !isOngoing()'>
          <alimento-ipv-frontend-cancel-training-dialog
            #cancelTrainingDialog
            [training]='training()'
            [firstSessionDate]='firstSessionDate'
            (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"
          >
          </alimento-ipv-frontend-cancel-training-dialog>

          <p-button styleClass='px-0 text-danger '
                    label="{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}"
                    [link]='true'
                    [pTooltip]="isOngoing() ? ('trainings.firstSessionAlreadyOver' | translate) : ''"
                    (onClick)='cancelTrainingDialog.open()'
          ></p-button>
        </div>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>

      <alimento-ipv-frontend-metadata-item *ngIf="reasonCancelled"
                                           header="trainings.reasonCancelled"
                                           value="{{ reasonCancelled }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.cancelledComment"
                                           header="trainings.cancelledComment"
                                           value="{{ training().cancelledComment }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.createdBy"
                                           header="trainings.createdBy"
                                           value="{{ training().createdBy }}"
      ></alimento-ipv-frontend-metadata-item>

      <alimento-ipv-frontend-metadata-item *ngIf="training()?.createdOn"
                                           header="trainings.createdOn"
                                           value="{{ training().createdOn | date : 'dd/MM/yyyy' }}"
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
