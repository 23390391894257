<alimento-ipv-frontend-overview-page-layout header='reimbursementRequests.menuTitle'>
  <ng-container top-right>
    <p-button
      *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
      label="{{ 'reimbursementRequests.actions.add' | translate }}"
      [routerLink]="['create']"
    ></p-button>
  </ng-container>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [sortItems]='sortFilters'
              [cardTemplate]='cardTemplate'
              name='reimbursement-requests'
              searchPlaceholderKey='reimbursementRequests.searchPlaceholder'
              availableResultsTranslateKey='reimbursementRequests.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-reimbursement-request-card
        [reimbursementRequest]='item'
        [routerLink]='["/reimbursement-requests", item.trainingAllowanceApplicationId]'
        tabindex="0"
      ></alimento-ipv-frontend-reimbursement-request-card>
    </ng-template>
  </lib-search>
</alimento-ipv-frontend-overview-page-layout>
