import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, first } from 'rxjs';
import { LANGUAGE_KEY } from '../types/localstorage-constants';
import { PrimeNG } from 'primeng/config';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private _supportedLanguages = ['nl', 'fr'];
  private _defaultLanguage = 'nl';

  languageChange$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  languageChange: WritableSignal<string | undefined> = signal(undefined);

  constructor(
    private translate: TranslateService,
    private config: PrimeNG,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.translate.addLangs(this._supportedLanguages);
    this.translate.setDefaultLang(localStorage.getItem(LANGUAGE_KEY) || this._defaultLanguage);
  }

  setInitLanguage(): void {
    let language: string | null | undefined = localStorage.getItem(LANGUAGE_KEY);
    if (!language) {
      language = this._defaultLanguage;
      localStorage.setItem(LANGUAGE_KEY, language);
    }
    this.changeLanguage(language);
  }

  changeLanguage(language: string): void {
    localStorage.setItem(LANGUAGE_KEY, language);
    this.document.documentElement.lang = language;
    this.translate.use(language).subscribe(() =>
      this.translate
        .get('primeng')
        .pipe(first())
        .subscribe((res) => {
          this.config.setTranslation(res);
          this.languageChange$.next(language);
          this.languageChange.set(language);
        })
    );
  }

  getLanguages(): string[] {
    return this._supportedLanguages;
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }
}
