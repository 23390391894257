import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, map, Observable, Subscription } from 'rxjs';
import { DataLabelType, MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import {
  Reimbursement,
  ReimbursementExtraInfo,
  ReimbursementTransition,
  ReimbursementUpdateEvent,
  TrainingData
} from '../../../types/reimbursement-request.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { InputNumber } from 'primeng/inputnumber';
import { EnrollmentService } from '../../../services/enrollment.service';
import { REIMBURSEMENT_TYPE } from '../../../types/reimbursement-request.enum';

@Component({
    selector: 'alimento-ipv-frontend-reimbursement-popup',
    templateUrl: './reimbursement-popup.component.html',
    standalone: false
})
export class ReimbursementPopupComponent implements OnChanges, OnDestroy {
  @Input()
  reimbursement?: Reimbursement;

  @Input()
  trainingData: TrainingData;

  @Input()
  extraInfo!: ReimbursementExtraInfo;

  @Output()
  formSubmit = new EventEmitter<ReimbursementUpdateEvent>();

  allowedTypes$: Observable<DataLabelType[]>;
  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  type: string;
  selectedTransition?: ReimbursementTransition;

  private _subscriptions: (Subscription | undefined)[] = [];
  protected readonly REIMBURSEMENT_TYPE = REIMBURSEMENT_TYPE;

  constructor(
    private fb: FormBuilder,
    private messageService: MyMessageService,
    private referenceDataService: ReferenceDataService,
    public enrollmentService: EnrollmentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reimbursement']?.currentValue) {
      this.createForm();
    }

    if (changes["extraInfo"]?.currentValue) {
      this._setReadOnlyValues();
      this._setAllowedTypes();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createForm();
    this._setReadOnlyValues();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    return data;
  }

  addOrUpdateReimbursement(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      id: this.reimbursement?.reimbursementId,
      reimbursement: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      reimbursementTypeId: [this.reimbursement?.reimbursementTypeId, Validators.required],
      transitionId: [this.reimbursement?.transitionId],
      amount: [this.reimbursement?.amount],
      remarks: [this.reimbursement?.remarks]
    });

    this._setValidation();
  }

  private _setAllowedTypes(): void {
    this.allowedTypes$ = this.referenceDataService.getReimbursementTypes()
      .pipe(
        map(types => types.filter(type =>
          this.extraInfo.possibleReimbursements.includes(type.data as REIMBURSEMENT_TYPE)))
      );
  }

  private _setValidation(): void {
    this._subscriptions.push(
      this.formGroup.get('reimbursementTypeId').valueChanges.subscribe(() => {
        const amountControl = this.formGroup.get('amount');
        amountControl.setValidators([Validators.required, Validators.max(this.getMaxAmount()), Validators.min(0.01)]);
        amountControl.updateValueAndValidity();

        this.formGroup.get('transitionId').setValidators(
          this.formGroup.get('reimbursementTypeId')?.value === REIMBURSEMENT_TYPE.TRANSITION ?
            [Validators.required] : []);
      }),
      this.formGroup.get('transitionId').valueChanges.subscribe(() => {
        const amountControl = this.formGroup.get('amount');
        amountControl.setValidators([Validators.required, Validators.max(this.getMaxAmount()), Validators.min(0.01)]);
        amountControl.updateValueAndValidity();
      })
    );
  }

  onKeyDown($event: KeyboardEvent, numberControl: InputNumber): void {
    if ($event.key === '-' && numberControl.value && ('' + numberControl.value).indexOf('-') < 0) {
      numberControl.writeValue('-' + numberControl.value);
    }
  }

  getMaxAmount(): number {
    const typeId = this.formGroup.get('reimbursementTypeId').value;
    if (typeId === REIMBURSEMENT_TYPE.TRAINING) {
      return this.extraInfo.trainingReimbursementMaxAmount;
    }
    if (typeId === REIMBURSEMENT_TYPE.PREPARATION) {
      return this.extraInfo.preparationReimbursementMaxAmount;
    }
    if (typeId === REIMBURSEMENT_TYPE.SME_BONUS) {
      return this.extraInfo.smeBonusReimbursementAmount;
    }
    if (typeId === REIMBURSEMENT_TYPE.TRANSITION) {
      const transition = this.extraInfo.reimbursementTransitions
        .filter(transition => transition.transitionId === this.formGroup.get('transitionId').value)[0];
      return transition?.reimbursementMaxAmount || 99999;
    }
    return 0;
  }

  private _setReadOnlyValues(): void {
    if (this.reimbursement?.reimbursementId) {
      this.referenceDataService.getReimbursementType(this.reimbursement.reimbursementTypeId).pipe(first())
        .subscribe(type => this.type = type.label);
    }

    if (this.reimbursement?.reimbursementTypeId && this.reimbursement?.transitionId && this.extraInfo) {
      this.selectedTransition = this.extraInfo.reimbursementTransitions
        ?.filter(transition => transition.transitionId === this.reimbursement.transitionId)[0];
    }
  }

  getTransitionType(type: string): Observable<string> {
    return this.referenceDataService.getTransitionType(type).pipe(map(type => type.label));
  }
}
