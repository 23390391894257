import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { first, map, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  MyMessageService,
  PaginatedResponse,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { SearchesService } from '../../../services/searches.service';
import { PaidEducationalLeaveAttestListItem } from '../../../types/paid-educational-leave.type';
import { BranchService } from '../../../services/branch.service';
import { OPTIONS_LIST_TYPE, ReferenceDataService } from '../../../services/reference-data.service';
import {
  PAID_EDUCATIONAL_LEAVE_ACTION,
  PAID_EDUCATIONAL_LEAVE_ATTEST_STATE,
  PAID_EDUCATIONAL_LEAVE_TYPE
} from '../../../types/paid-educational-leave.enum';
import { PaidEducationalLeaveService } from '../../../services/paid-educational-leave.service';
import {
  PaidEducationalLeaveActionDialogComponent
} from '../../components/paid-educational-leave-action-dialog/paid-educational-leave-action-dialog.component';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { WORK_STATUS } from '@alimento-ipv-frontend/application-lib';

@Component({
    selector: 'alimento-ipv-frontend-paid-educational-leave-list',
    templateUrl: './paid-educational-leave-list.component.html',
    standalone: false
})
export class PaidEducationalLeaveListComponent implements OnInit, OnDestroy {
  searchData$: InfiniteScrollDataAdapter<PaidEducationalLeaveAttestListItem>;
  searchFilters: SearchFilter[];
  canCommunicate = false;
  canGenerate = false;
  canSend = false;
  savedFilters: any[] = [
    {
      label: this.translateService.instant("paidEducationalLeaves.filterAll"),
      filters: []
    },
    {
      label: this.translateService.instant("paidEducationalLeaves.filterProposed"),
      filters: [
        {
          type: FilterType.filterStatuses,
          values: [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.PROPOSED]
        },
        {
          type: FilterType.filterRecognitionTypes,
          values: [PAID_EDUCATIONAL_LEAVE_TYPE.BEV]
        },
        {
          type: FilterType.filterWorkStatuses,
          values: [WORK_STATUS.PC_118, WORK_STATUS.PC_220]
        }
      ]
    },
    {
      label: this.translateService.instant("paidEducationalLeaves.filterApproved"),
      filters: [
        {
          type: FilterType.filterStatuses,
          values: [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.APPROVED]
        },
        {
          type: FilterType.filterRecognitionTypes,
          values: [PAID_EDUCATIONAL_LEAVE_TYPE.BEV]
        },
        {
          type: FilterType.filterWorkStatuses,
          values: [WORK_STATUS.PC_118, WORK_STATUS.PC_220]
        }
      ]
    },
    {
      label: this.translateService.instant("paidEducationalLeaves.filterToSent"),
      filters: [
        {
          type: FilterType.filterStatuses,
          values: [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.GENERATED]
        },
        {
          type: FilterType.filterRecognitionTypes,
          values: [PAID_EDUCATIONAL_LEAVE_TYPE.BEV]
        },
        {
          type: FilterType.filterAttestSent,
          values: ["false"]
        },
        {
          type: FilterType.filterWorkStatuses,
          values: [WORK_STATUS.PC_118, WORK_STATUS.PC_220]
        }
      ]
    },
  ];
  savedFiltersControl: FormControl<string> = new FormControl(undefined);

  currentAction: PAID_EDUCATIONAL_LEAVE_ACTION;
  activeFilter: { [key: string]: string[] } = {};
  defaultFilter: { [key: string]: string[] } = {};

  private _subscriptions: Subscription[] = [];
  private currentSelection: PaidEducationalLeaveAttestListItem[] = [];

  protected readonly PAID_EDUCATION_LEAVE_ACTION = PAID_EDUCATIONAL_LEAVE_ACTION;

  @ViewChild(PaidEducationalLeaveActionDialogComponent)
  paidEducationalLeaveActionDialog: PaidEducationalLeaveActionDialogComponent;

  @ViewChild(SearchComponent)
  searchComponent: SearchComponent;

  constructor(private searchesService: SearchesService,
              private paidEducationalLeaveService: PaidEducationalLeaveService,
              private referenceDataService: ReferenceDataService,
              private translationService: TranslationService,
              private translateService: TranslateService,
              private branchService: BranchService,
              private messageService: MyMessageService) {
    this.defaultFilter = {};
    this.defaultFilter[FilterType.filterWorkStatuses] = [WORK_STATUS.PC_118, WORK_STATUS.PC_220];

    this._subscriptions.push(this.savedFiltersControl.valueChanges.subscribe((value: any) => {
      const activeFilters: { [key: string]: string[] } = {};
      value.filters.forEach((filter: any) => {
        activeFilters[filter.type] = filter.values;
      })
      this.activeFilter = activeFilters;
    }))
  }

  ngOnInit(): void {
    this._subscriptions.push(this.translationService.languageChange$.subscribe(() =>
      this._createSearch()
    ));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    this.searchData$ = new InfiniteScrollDataAdapter<PaidEducationalLeaveAttestListItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<PaidEducationalLeaveAttestListItem>> => {
      return this.searchesService.searchPaidEducationalLeaveAttests(searchRequest);
    }, searchRequest, true);

    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.schoolYear',
        expanded: true,
        key: FilterType.filterSchoolYears,
        data: this.referenceDataService.getPaidEducationalLeaveSchoolYearsFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.category',
        expanded: true,
        key: FilterType.filterRecognitionTypes,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_TYPES, FilterType.filterRecognitionTypes)
          .pipe(map(result => result.filter(filter => filter.value !== PAID_EDUCATIONAL_LEAVE_TYPE.INTERIM)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.region',
        expanded: false,
        key: FilterType.filterRegions,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.REGIONS, FilterType.filterRegions)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.mentor',
        expanded: false,
        key: FilterType.filterMentorTraining,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterMentorTraining)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.status',
        expanded: false,
        key: FilterType.filterStatuses,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.PAID_EDUCATIONAL_LEAVE_STATUSES, FilterType.filterStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'enrollments.workStatus',
        expanded: false,
        key: FilterType.filterWorkStatuses,
        data: this.referenceDataService.getReferenceDataAsFilter(
          OPTIONS_LIST_TYPE.WORK_STATUSES, FilterType.filterWorkStatuses)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.attestRequested',
        key: FilterType.filterAttestNeededRequestSent,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterAttestNeededRequestSent)
      },
      {
        type: SearchFilterType.multiselect,
        label: 'paidEducationalLeaves.attestSent',
        key: FilterType.filterAttestSent,
        data: this.referenceDataService.getYesNoFilter(FilterType.filterAttestSent)
      },
      {
        type: SearchFilterType.lazyLoadItem,
        key: FilterType.filterBranches,
        label: 'paidEducationalLeaves.branches',
        expanded: false,
        dataSource: (searchRequest: SearchRequest) => {
          return this.searchesService.searchBranches(searchRequest)
            .pipe(map((result: PaginatedResponse<any>) => {
              result.data = result.data.map(item => ({
                value: '' + item.branchId,
                label: item.name,
                type: FilterType.filterBranches
              }));
              return result;
            }));
        },
        getItem: (id: string) => this.branchService.getBranch(id)
          .pipe(map(branch => ({
            value: '' + branch.branchId,
            label: branch.branchName,
            type: FilterType.filterBranches
          }) as IFilter))
      }
    ];
  }

  onselectionchange(event: PaidEducationalLeaveAttestListItem[]): void {
    this.currentSelection = event;
    this.canCommunicate = event.length > 0 &&
      event.every(paidEducationalLeave => paidEducationalLeave.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV) &&
      event.every(paidEducationalLeave => [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.APPROVED, PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.ON_HOLD].includes(paidEducationalLeave.stateId));
    this.canGenerate = event.length > 0 &&
      event.every(paidEducationalLeave => paidEducationalLeave.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV) &&
      event.every(paidEducationalLeave => [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.APPROVED, PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.ON_HOLD].includes(paidEducationalLeave.stateId));
    this.canSend = event.length > 0 &&
      event.every(paidEducationalLeave => paidEducationalLeave.typeId === PAID_EDUCATIONAL_LEAVE_TYPE.BEV) &&
      event.every(paidEducationalLeave => [PAID_EDUCATIONAL_LEAVE_ATTEST_STATE.GENERATED].includes(paidEducationalLeave.stateId));
  }

  onFilterChange = () => {
    if (this.savedFiltersControl.value) {
      this.savedFiltersControl.setValue(undefined, {emitEvent: false});
    }
  }

  executeAction(event: any): void {
    event.setLoading(true);
    let request$;
    if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.ATTEST_REQUESTED) {
      request$ = this.paidEducationalLeaveService.requestAttestNeeded(this.currentSelection.map(item => item.id));
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.GENERATE) {
      request$ = this.paidEducationalLeaveService.generateAttest(this.currentSelection.map(item => item.id));
    }
    else if (this.currentAction === PAID_EDUCATIONAL_LEAVE_ACTION.SEND) {
      request$ = this.paidEducationalLeaveService.sendAttest(this.currentSelection.map(item => item.id));
    }

    request$.pipe(first()).subscribe({
      next: () => {
        this.messageService.success('actionExecuted');
        event.setLoading(false);
        this.paidEducationalLeaveActionDialog.closeDialog();
        this.currentSelection = [];
        this.searchComponent.removeSelection();
        this.searchComponent.refresh();
      },
      error: () => {
        event.setLoading(false);
      }
    });
  }

  openActionDialog(action: PAID_EDUCATIONAL_LEAVE_ACTION): void {
    this.currentAction = action;
    this.paidEducationalLeaveActionDialog.open();
  }
}
