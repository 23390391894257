<div *ngIf='visible'
     #sidebar
     class='fixed bottom-0 right-0 z-40 max-h-[calc(100%-184px)] h-screen w-[474px] transition-transform -translate-x-full
     sm:translate-x-0 bg-primary-50 p-4 shadow-lg'>

  <div class='mb-4 '>
    <alimento-ipv-frontend-header title='historyItems.title'>
      <i class='font-material not-italic font-bold cursor-pointer' (click)='close()'>Close</i>
    </alimento-ipv-frontend-header>
  </div>

  <div #commentsContainer class="flex flex-col overflow-auto ml-4 mt-6" (scroll)="onScroll()">
    <p-timeline [value]="historyItems" align="left" styleClass="customized-timeline">
      <ng-template pTemplate="marker" let-historyItem>
        <div class="h-10 w-10 bg-transparent text-primary flex justify-center items-center">
          <span class="rounded-full text-text p-2 text-center">
            {{historyItem.actionExecutedBy | initials}}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content" let-historyItem>
        <div class="flex flex-col w-full mt-1.5 mb-4">
          <div class="flex flex-col items-start mb-2">
            <div class="flex flex-col">
              <span class="text-xl">{{('historyItems.' + historyItem.historyType + "." + historyItem.actionName) | translate}}</span>
              <div class='flex flex-wrap gap-1 items-center'>
                <span *ngIf="historyItem.actionExecutedBy">{{"by" | translate }} <b>{{historyItem.actionExecutedBy}}</b></span>
                <div class="flex gap-1 items-end">
                  <span>{{"on" | translate }}</span>
                  <span>{{historyItem.actionExecutedOn | date: 'EEE d MMMM y'}}</span>
                  <span class="text-sm mb-[2px]">{{historyItem.actionExecutedOn | date: 'H:mm'}}</span>
                </div>
              </div>
            </div>
          </div>
          <span *ngIf="historyItem.comments" class="rounded-lg shadow p-4 bg-white border-input-border">
            {{historyItem.comments}}
          </span>
          <ng-container *ngTemplateOutlet="messageTemplate; context: {historyItem: historyItem}"></ng-container>
        </div>
      </ng-template>
    </p-timeline>
  </div>
</div>
