<div *ngIf="trainingProgram">
  <alimento-ipv-frontend-detail-page-layout
    header="{{ 'trainingPrograms.trainingProgram' | translate }}"
    [breadcrumb]='breadcrumb'
    fallbackUrl='/branches/{{trainingProgram.branchId}}'
    [navigateText]='"trainingPrograms.toBranch" | translate'
  >
    <ng-container top-action>
      <div class="buttons" *ngIf='!readOnly && trainingProgramId'>
        <p-button
          label="{{ 'reimbursementRequests.actions.save' | translate }}"
          severity='secondary'
          styleClass='w-full'
          icon="font-material before:content-['save']"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveReimbursementRequest()"
          [loading]="saving"
        ></p-button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0">
        <alimento-ipv-frontend-header title="{{ 'trainingPrograms.basicData.formTitle' | translate }}">
        </alimento-ipv-frontend-header>
        <alimento-ipv-frontend-training-program
          [trainingProgram]='trainingProgram'
        ></alimento-ipv-frontend-training-program>
      </div>

      <div *ngIf="activeTabIndex === 1">
        <alimento-ipv-frontend-header title="{{ 'reimbursementRequests.trainingTitle' | translate }}">
        </alimento-ipv-frontend-header>

        <lib-search [data$]='searchTrainingData$'
                    [filters]='searchTrainingFilters'
                    [cardTemplate]='cardTemplate'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    name='training-program-reimbursement-requests'
                    searchPlaceholderKey='trainings.searchbarPlaceholder'
                    availableResultsTranslateKey='trainings.nrOfTrainings'
        >
          <ng-template #cardTemplate let-item="item">
            <alimento-ipv-frontend-training-card
              [training]='item'
              tabindex="0"
            >
            </alimento-ipv-frontend-training-card>
          </ng-template>
        </lib-search>
      </div>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-metadata-item *ngIf="branch"
                                           header='trainingPrograms.branch'
                                           [isLink]='true'
                                           [link]='["/branches", trainingProgram.branchId]'
                                           [value]='branch.branchName'
      ></alimento-ipv-frontend-metadata-item>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
