import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, Observable, Subscription } from 'rxjs';
import {
  InfiniteScrollDataAdapter,
  LazyDropdownComponent,
  PaginatedResponse,
  SearchFilterValue,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { SearchesService } from '../../../services/searches.service';
import { Branch } from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { ReimbursementRequest, SearchReimbursementRequestItem } from '../../../types/reimbursement-request.type';
import { ReimbursementRequestService } from '../../../services/reimbursement-request.service';

@Component({
    selector: 'alimento-ipv-frontend-reimbursement-request-select',
    templateUrl: './reimbursement-request-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ReimbursementRequestSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ReimbursementRequestSelectComponent),
            multi: true
        }
    ],
    standalone: false
})
export class ReimbursementRequestSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  formControl: FormControl<SearchReimbursementRequestItem> = new FormControl<SearchReimbursementRequestItem>(undefined);

  @Input()
  extraFilters: SearchFilterValue[] = [];

  @Input()
  placeholder = 'reimbursementRequests.searchPlaceholder';

  data$: InfiniteScrollDataAdapter<SearchReimbursementRequestItem>;

  private _subscriptions: Subscription[] = [];
  private NUMBER_OF_LOADED_ITEMS = 30;

  @ViewChild(LazyDropdownComponent)
  private branchInput: LazyDropdownComponent;

  constructor(private searchesService: SearchesService,
              private reimbursementRequestService: ReimbursementRequestService,
              private branchService: BranchService) {
    this._createDataSource();
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['extraFilters']?.currentValue) {
      this._createDataSource();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  focus(): void {
    setTimeout(() => this.branchInput.setFocus());
  }

  setSearchValue(value: string): void {
    setTimeout(() => this.branchInput.inputControl.setValue(value));
  }

  _createDataSource(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };

    this.extraFilters.forEach(extraFilter => searchRequest.filters.push(extraFilter));

    this.data$ = new InfiniteScrollDataAdapter<SearchReimbursementRequestItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchReimbursementRequestItem>> => {
      return this.searchesService.searchReimbursementRequests(searchRequest);
    }, searchRequest, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value && (typeof value === 'string')) {
      this.reimbursementRequestService.getRequest(value).pipe(first())
        .subscribe((request) =>
          this.branchService.getBranch(request.branchId).pipe(first())
            .subscribe(branch => {
              this.formControl.setValue(this._getSearchItem(request, branch), { emitEvent: false });
            })
        );
    }
    else if (value) {
      this.formControl.setValue(value, { emitEvent: false });
    }
  }

  private _getSearchItem(request: ReimbursementRequest, branch: Branch): SearchReimbursementRequestItem {
    return {
      trainingAllowanceApplicationId: request.trainingAllowanceApplicationId,
      alimentoId: request.trainingAllowanceApplicationAlimentoId,
      branchId: request.branchId,
      branchName: branch.branchName,
      branchAddress: '',
      statusId: request.statusId,
      year: request.year
    };
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.formControl.valid ? null : { branch: { valid: false } };
  }

  clearValue(): void {
    this.formControl.setValue(null);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({ emitEvent: false });
    }
    else {
      this.formControl.enable({ emitEvent: false });
    }
  }

  getSelectedString = (item: SearchReimbursementRequestItem) => (`${item.alimentoId} - ${item.branchName} - ${item.year}`);
}
