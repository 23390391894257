import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {Calendar} from "primeng/calendar";
import { DateFilter } from '../../../types/search.type';
import { TranslationService } from '../../../services/translation.service';
import { DateMapper } from '../../../utils/date.mapper';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ml-date-facet',
    templateUrl: './date-facet.component.html',
    standalone: false
})
export class DateFacetComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  title = "";

  @Input()
  selectedDates: DateFilter;

  @Input()
  canSelectPastDates = false;

  @Input()
  dateFormat = "D - d M yy";

  @Input()
  inlineForm = false;

  dates: FormGroup;
  minDateValue: Date;
  maxSelectedDate: Date;
  minSelectedDate: Date;

  @Output()
  searchValue: EventEmitter<DateFilter> = new EventEmitter<DateFilter>();

  @ViewChildren(Calendar)
  calendars: Calendar[];

  private _subscription: Subscription;

  constructor(private formBuilder: FormBuilder, private localeService: TranslationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dates && changes["selectedDates"]?.currentValue) {
      this.minSelectedDate = this.selectedDates.fromDate ? new Date(this.selectedDates.fromDate) : undefined;
      this.maxSelectedDate = this.selectedDates.toDate ? new Date(this.selectedDates.toDate) : undefined;

      this.dates.controls["fromDate"].setValue(this.minSelectedDate, {emitEvent: false});
      this.dates.controls["toDate"].setValue(this.maxSelectedDate, {emitEvent: false});
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.canSelectPastDates) {
      this.minDateValue = new Date(DateMapper.getDateFromDateTimeAsString(new Date()));
    }

    this.dates = this.formBuilder.group({
      fromDate: new FormControl(this.selectedDates.fromDate ? new Date(this.selectedDates.fromDate) : undefined),
      toDate: new FormControl(this.selectedDates.toDate ? new Date(this.selectedDates.toDate) : undefined)
    });

    this._subscription = this.dates.valueChanges.subscribe(newValue => {
      if (!newValue.fromDate && !this.canSelectPastDates) {
        newValue.fromDate = new Date();
        this.dates.get("fromDate").setValue(newValue.fromDate, {emitEvent: false});
      }

      const event = {
        fromDate: newValue.fromDate ? DateMapper.getDateFromDateTimeAsString(newValue.fromDate) : undefined,
        toDate: newValue.toDate ? DateMapper.getDateFromDateTimeAsString(newValue.toDate) : undefined
      };
      this.searchValue.emit(event);
    });

    this.localeService.languageChange$.subscribe(() => this.calendars?.forEach(cal => cal.updateInputfield()));
  }

  getFilterCount(): number {
    let count = 0;
    if (this.selectedDates.fromDate) {
      count ++;
    }
    if (this.selectedDates.toDate) {
      count ++;
    }

    return count;
  }

  isDifferentFromToday(field: string): boolean {
    return DateMapper.getDateFromDateTimeAsString(this.dates.controls[field].value)
      != DateMapper.getDateFromDateTimeAsString(new Date());
  }
}
