import { NgModule } from '@angular/core';
import { EnterpriseDetailComponent } from './pages/enterprise-detail/enterprise-detail.component';
import { EnterpriseListComponent } from './pages/enterprise-list/enterprise-list.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService } from 'primeng/api';
import { UtilsModule } from '../utils/utils.module';
import { PersonsModule } from '../persons/persons.module';
import { EnterpriseBranchCardComponent } from './components/enterprise-branch-card/enterprise-branch-card.component';
import { AccountNumberCardComponent } from './components/account-number-card/account-number-card.component';
import {
  AccountNumberActionDialogComponent
} from './components/account-number-action-dialog/account-number-action-dialog.component';
import { AccountNumberPopupComponent } from './components/account-number-popup/account-number-popup.component';
import { EnterpriseComponent } from './components/enterprise/enterprise.component';
import { SmeBonusComponent } from './components/sme-bonus/sme-bonus.component';
import { TrainingProgramModule } from '../training-programs/training-program.module';
import { EnterpriseGroupModule } from '../enterprise-groups/enterprise-group.module';

@NgModule({
  imports: [
    UiLibModule,
    UtilsModule,
    PersonsModule,
    TrainingProgramModule,
    EnterpriseGroupModule
  ],
  providers: [
    CanDeactivateGuard,
    ConfirmationService
  ],
  declarations: [
    EnterpriseDetailComponent,
    EnterpriseListComponent,
    EnterpriseBranchCardComponent,
    AccountNumberCardComponent,
    AccountNumberActionDialogComponent,
    AccountNumberPopupComponent,
    EnterpriseComponent,
    SmeBonusComponent
  ]
})
export class EnterprisesModule {
}
